import React, { useState } from 'react';
import {API, graphqlOperation} from "aws-amplify"
import {
    Button,
    Flex,
    Text,
    View
} from '@aws-amplify/ui-react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import {getPSNRavg, getVMAF, getSSIM} from "../../graphql/queries"
import Tooltip from '@mui/material/Tooltip';
import { FaArrowDown, FaArrowLeft, FaArrowRight } from 'react-icons/fa';

require("highcharts/modules/annotations")(Highcharts);

const argPeak = (myArray) => {
    let min = myArray[0]
    let argMin = 0

    for (let i=1; i < myArray.length; i++){
        let value = myArray[i]
        if (value < min ){
            argMin = i
            min = value
        }
    }
    return argMin
}

function VqaasHCMetricChart(props) {
    const localOnSeekTimeChange = props.onSeekTimeChange
    const ana_fps_nominator = props.ana_fps_nominator
    const ana_fps_denominator = props.ana_fps_denominator
    const [lastSelectedFrame, setLastSelectedFrame] = useState(null)
    const [vmafData, setVmafData] = useState([])
    const [ssimData, setSsimData] = useState([])
    const [psnrData, setPsnrData] = useState([])
    const [xExtremes, setXExtremes] = useState()
    const [vmafAvg, setVmafAvg] = useState(0)
    const [psnrAvg, setPsnrAvg] = useState(0)
    const [ssimAvg, setSsimAvg] = useState(0)
    const vmafChartRef = React.useRef()
    const [chartOptions, setChartOptions] = useState({
        chart: {
            animation: false,
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
            marginTop: 100,
        },
        series: [
            { data: [], color: '#3380FF', lineWidth: 1 }
        ],
        xAxis: {
            crosshair: true,
            max: null,
            min: null,
            gridLineWidth: 1,
            title: {
                text: 'Frame number after aligning reference and distorted video'
            },
            events: {
                afterSetExtremes: function(event) {
                    setXExtremes({min:Math.round(event.min), max:Math.round(event.max)})
                    // console.log(event, 'After SetExtremes')
                }
            }
        },
        yAxis: [
            { // Primary axis VMAF
                max: 100,
                labels: {
                    style: {
                        color: '#3380FF'
                    }
                },
                title: {
                    text: 'VMAF',
                    style: {
                        color: '#3380FF'
                    }
                },
                endOnTick: false
            },
            { // Secondary axis PSNR
                max: 60,
                gridLineWidth: 0,
                labels: {
                    style: {
                        color: '#FFB400'
                    }
                },
                title: {
                    text: 'PSNR',
                    style: {
                        color: '#FFB400'
                    }
                },
                opposite: true,
                endOnTick: false
            },
            { // Tertiary axis SSIM
                gridLineWidth: 0,
                max: 1,
                labels: {
                    style: {
                        color: '#FF3333'
                    }
                },
                title: {
                    text: 'SSIM',
                    style: {
                        color: '#FF3333'
                    }
                },
                opposite: true,
                endOnTick: false
            },

        ],
        legend: {
            layout: 'vertical',
            align: 'right',
            x: -50,
            verticalAlign: 'top',
            y: 0,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || // theme
                'rgba(255,255,255,0.25)'
        },
        title: {
            text: 'Video Quality Metric'
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                animation: false,
                cursor: 'crosshair',
                point: {
                    events: {
                        select(e){
                            setLastSelectedFrame(e.target.x)
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: false
        }
    });

    React.useEffect(() => {
        async function onChangeAnalysisId() {
            async function onAvgPSNRChange() {
                let metrics = await API.graphql(
                    graphqlOperation(
                        getPSNRavg,
                        {
                            'id': props.analysis_id,
                            'sequence': 0
                        })
                )
                if (metrics.data.getPSNRavg && mounted){
                    const psnr_list = metrics.data.getPSNRavg.values
                    setPsnrData(psnr_list)
                }
            }

            async function onSSIMChange() {
                let metrics = await API.graphql(
                    graphqlOperation(
                        getSSIM,
                        {
                            'id': props.analysis_id,
                            'sequence': 0
                        })
                )
                if (metrics.data.getSSIM && mounted){
                    const ssim_list = metrics.data.getSSIM.values
                    setSsimData(ssim_list)
                }
            }

            async function onVMAFChange() {
                let metrics = await API.graphql(
                    graphqlOperation(
                        getVMAF,
                        {
                            'id': props.analysis_id,
                            'sequence': 0
                        })
                )
                if (metrics.data.getVMAF && mounted){
                    const vmaf_list = metrics.data.getVMAF.values
                    setVmafData(vmaf_list)
                    setXExtremes({min:0, max: vmaf_list.length})
                }
            }

            await onAvgPSNRChange()
            await onSSIMChange()
            await onVMAFChange()
        }

        let mounted = true
        onChangeAnalysisId().then()
        return function cleanup() {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        let mounted = true
        if (vmafData.length && psnrData.length && ssimData.length && mounted) {
            // console.log('UseEffect setChartOptions', vmafData.length, psnrData.length, ssimData.length )
            setLastSelectedFrame(0)
            const newSeries = {series: [
                    {name:"VMAF", data:vmafData.slice(), color: '#3380FF', lineWidth: 1, yAxis: 0},
                    {name:"PSNR", data:psnrData.slice(), color: '#FFB400', lineWidth: 1, yAxis: 1},
                    {name:"SSIM", data:ssimData.slice(), color: '#FF3333', lineWidth: 1, yAxis: 2}]}
            setChartOptions(newSeries)
        }
        return () => { mounted = false }
    }, [vmafData, psnrData, ssimData])

    React.useEffect(() => {
        let mounted = true
        if (lastSelectedFrame !== null && mounted){
            // console.log('UseEffect lastSelectedFrame')
            setChartOptions(() => {
                let updatedChartOptions = {}
                updatedChartOptions.annotations = [ {
                    labelOptions: {
                        backgroundColor: 'rgba(0,0,0,0.95)',
                        overflow: "none"
                    },
                    labels: [{
                        point: {
                            xAxis: 0,
                            x: lastSelectedFrame,
                            y: 0
                        },
                        text: String(lastSelectedFrame)
                    }]
                }]
                updatedChartOptions.xAxis = {
                    plotLines: [{
                        color: 'black',
                        dashStyle: 'dot',
                        width: 2,
                        value: lastSelectedFrame,
                        label: {
                            rotation: 0,
                            y: 15,
                            style: {
                                fontStyle: 'italic'
                            }
                        },
                        zIndex: 3
                    }]
                }
                return updatedChartOptions
            })
            // vmafChartRef.current.chart.series[0].data[lastSelectedFrame].select()
            // console.log(ana_fps_nominator, ana_fps_denominator)
            if (ana_fps_nominator && ana_fps_denominator){
                localOnSeekTimeChange(lastSelectedFrame * ana_fps_nominator/ ana_fps_denominator)
            }
        }
        return function cleanup() {
            mounted = false;
        };
    }, [lastSelectedFrame, ana_fps_nominator, ana_fps_denominator, localOnSeekTimeChange])

    const average = React.useCallback ((nums) => {
        if (xExtremes){
            let sliced_nums = nums.slice(Math.max(0,xExtremes.min), xExtremes.max)
            sliced_nums = sliced_nums.filter(function(e){ return e === 0 || e })
            let avg = "N/A"
            if (sliced_nums.length){
                avg = (sliced_nums.reduce((a, b) => (a + b)) / sliced_nums.length).toFixed(2)
            }
            return avg;
        }
    },[xExtremes])

    React.useEffect(() => {
        let mounted = true
        if (vmafData && xExtremes && mounted){
            // console.log('UseEffect average vmaf')
            setVmafAvg(average(vmafData))
        }
        return () => { mounted = false }
    }, [average, vmafData, xExtremes])

    React.useEffect(() => {
        let mounted = true
        if (psnrData && xExtremes && mounted){
            // console.log('UseEffect average psnr')
            setPsnrAvg(average(psnrData))
        }
        return function cleanup() {
            mounted = false;
        };
    }, [average, psnrData, xExtremes])

    React.useEffect(() => {
        let mounted = true
        if (ssimData && xExtremes && mounted){
            // console.log('UseEffect average ssim')
            setSsimAvg(average(ssimData))
        }
        return () => { mounted = false }
    }, [average, ssimData, xExtremes])

    async function onButtonGroupClick(metric, action) {
        let selectedMetricData

        if (metric === "Vmaf"){
            selectedMetricData = vmafData
        }
        if (metric === "Psnr"){
            selectedMetricData = psnrData
        }
        if (metric === "Ssim"){
            selectedMetricData = ssimData
        }

        if (action === "peakSearch"){
            let windowMin = (xExtremes.min > 0) ? xExtremes.min : 0
            let windowMax = xExtremes.max

            if (!windowMin) {
                windowMin = 0
                windowMax = selectedMetricData.length
            }
            let localMin = argPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
            setLastSelectedFrame(localMin)
        }
        if (action === "peakLeft"){
            if (lastSelectedFrame !== null){
                let windowMin = (xExtremes.min > 0) ? xExtremes.min : 0
                let windowMax = lastSelectedFrame

                if (!windowMin) {
                    windowMin = 0
                }
                let localMin = argPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
                setLastSelectedFrame(localMin)
            }
        }
        if (action === "peakRight"){
            if (lastSelectedFrame !== null){
                let windowMin
                // exclude current selected point, but limit to length of data
                windowMin = (lastSelectedFrame + 1 < selectedMetricData.length) ? lastSelectedFrame + 1 : selectedMetricData.length - 1
                // Don't go beyond current span
                if (xExtremes.max !== null){
                    windowMin = (windowMin < xExtremes.max) ? windowMin : xExtremes.max
                }
                let windowMax
                // Don't go beyond current span, if no span defined limit to length of data
                if (xExtremes.max !== null){
                    windowMax = (xExtremes.max < selectedMetricData.length) ? xExtremes.max : selectedMetricData.length - 1
                } else {
                    windowMax = selectedMetricData.length - 1
                }
                let localMin = argPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
                setLastSelectedFrame(localMin)
            }
        }
        if (action === "toLeft"){
            setLastSelectedFrame(prevLastSelectedFrame => prevLastSelectedFrame - 1)
        }
        if (action === "toRight"){
            setLastSelectedFrame(prevLastSelectedFrame => prevLastSelectedFrame + 1)
        }
    }


    return(
        <Flex direction={"column"}>
            <Flex direction={"row"} justifyContent={"center"}>
                <Flex direction={"column"} justifyContent={"center"} alignItems={"center"}>
                    <Flex direction={"row"} justifyContent={"center"}>
                        <Tooltip
                            title="Pk Left"
                            placement="top"
                        >
                            <Button className="blue-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('Vmaf', 'peakLeft')}>
                                <FaArrowLeft/>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Pk Search"
                            placement="top"
                        >
                            <Button className="blue-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('Vmaf', 'peakSearch')}>
                                <FaArrowDown/>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Pk Right"
                            placement="top"
                        >
                            <Button className="blue-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('Vmaf', 'peakRight')}>
                                <FaArrowRight/>
                            </Button>
                        </Tooltip>
                    </Flex>
                    <Text as={"p"} lineHeight={"0.75em"}>Avg: {vmafAvg}</Text>
                </Flex>
                <View width={"800px"}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                        ref={vmafChartRef}
                    />
                </View>
                <Flex direction={"column"} justifyContent={"center"} alignItems={"center"}>
                    <Flex direction={"row"} justifyContent={"center"}>
                        <Tooltip
                            title="Pk Left"
                            placement="top"
                        >
                            <Button className="yellow-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('Psnr', 'peakLeft')}>
                                <FaArrowLeft/>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Pk Search"
                            placement="top"
                        >
                            <Button className="yellow-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('Psnr', 'peakSearch')}>
                                <FaArrowDown/>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Pk Right"
                            placement="top"
                        >
                            <Button className="yellow-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('Psnr', 'peakRight')}>
                                <FaArrowRight/>
                            </Button>
                        </Tooltip>
                    </Flex>
                    <Text as={"p"} lineHeight={"0.75em"}>Avg: {psnrAvg}</Text>
                    <Flex direction={"row"} justifyContent={"center"}>
                        <Tooltip
                            title="Pk Left"
                            placement="top"
                        >
                            <Button className="ssim-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('Ssim', 'peakLeft')}>
                                <FaArrowLeft/>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Pk Search"
                            placement="top"
                        >
                            <Button className="ssim-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('Ssim', 'peakSearch')}>
                                <FaArrowDown/>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Pk Right"
                            placement="top"
                        >
                            <Button className="ssim-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('Ssim', 'peakRight')}>
                                <FaArrowRight/>
                            </Button>
                        </Tooltip>
                    </Flex>
                    <Text as={"p"} lineHeight={"0.75em"}>Avg: {ssimAvg}</Text>
                </Flex>
            </Flex>
            <Flex direction={"row"} justifyContent={"center"}>
                    <Tooltip
                        title="Back"
                        placement="top"
                    >
                        <Button className="black-chart-button" size="small"
                                onClick={()=>onButtonGroupClick('Span', 'toLeft')}>
                            <FaArrowLeft/>
                        </Button>
                    </Tooltip>
                    <Tooltip
                        title="Forward"
                        placement="top"
                    >
                        <Button className="black-chart-button" size="small"
                                onClick={()=>onButtonGroupClick('Span', 'toRight')}>
                            <FaArrowRight/>
                        </Button>
                    </Tooltip>
            </Flex>
        </Flex>
    )
}

export default VqaasHCMetricChart;