import React, {useState} from "react";
import {Flex, Heading, View} from '@aws-amplify/ui-react';

// core components
import MediaPageHeader from "components/Headers/MediaPageHeader.js";
import TeamTable from "../../components/vqaas/TeamTable";
import FooterBlack from "components/Footers/FooterBlack.js";
import {API, graphqlOperation} from "aws-amplify";
import {listTeams} from "../../graphql/queries"
import WhiteNavbar from "../../components/Navbars/WhiteNavbar";

function dynamicSort(property) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

function ListTeams(props) {
    const [teams, setTeams] = useState();

    React.useEffect(() => {
        async function listAllTeams() {
            const result = await API.graphql(graphqlOperation(listTeams, {
                limit: 999,
            }))
            if (mounted) {
                setTeams(result.data.listTeams.items.sort(dynamicSort("name")))
            }
        }
        let mounted = true

        listAllTeams().then()
        return function cleanup() {
            mounted = false;
        };
    }, [])

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    });

    return (
        <>
            <WhiteNavbar {...props}/>
            <MediaPageHeader title={"Team dashboard"} />
            <div className="section">
                <Flex justifyContent="center" style={{fontFamily: "SourceSansPro"}}>
                    <View width={{base:'95%', xl:'95%'}}>
                        {/*Import files*/}
                        <View style={{paddingBottom:"2rem"}}>
                            <Heading level={"3"} style={{
                                borderBottomWidth:"1px",
                                borderBottomColor:"black",
                                borderBottomStyle:"solid"}}>Teams</Heading>
                        {teams ? <TeamTable teams={teams}/> : <></>}
                        </View>
                    </View>
                </Flex>
            </div>
            <FooterBlack {...props}/>
        </>
    );
}

export default ListTeams;
