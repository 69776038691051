import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom"
import {API, graphqlOperation, Storage} from "aws-amplify"
import {Button, Expander, ExpanderItem, Flex, View} from '@aws-amplify/ui-react';

// core components
import MediaPageHeader from "components/Headers/MediaPageHeader.js"
import FooterBlack from "components/Footers/FooterBlack.js"
import ReferenceProcessing from "../../components/vqaas/ReferenceProcessing"
import ReferenceSpecPage from "../../components/vqaas/ReferenceSpecPage"
import StreamPlayer from "../../components/vqaas/StreamPlayer";
import WhiteNavbar from "../../components/Navbars/WhiteNavbar";
import {getMedia} from "../../graphql/queries";


function MediaDetailPage(props) {
    const {media_id} = useParams()
    const [mediaDetail, setMediaDetail] = useState();
    const [mediainfo, setMediainfo] = useState("");
    const {
        staticContext,
        signOut,
        account,
        adminVisible,
        onSignOutClick,
        onRefresh,
        user,
        encodeAnalysis,
        ...restOfProps
    } = props

    React.useEffect(() => {
        async function getMyMedia(media_id) {
            const result = await API.graphql(graphqlOperation(getMedia, {
                id: media_id
            }))
            if (mounted) setMediaDetail(result.data['getMedia'])
        }

        let mounted = true
        getMyMedia(media_id).then()
        return function cleanup() {
            mounted = false;
        };
    }, [media_id, props.media])

    // console.log(mediaDetail)

    React.useEffect(() => {
        async function getAWSUrl() {
            try {
                const result = await Storage.get(
                    media_id + "/media.mediainfo.html",
                    {
                        level: 'protected',
                        identityId: mediaDetail.user_identity_id,
                        expires: 86400,
                        download: true
                    });
                let urlPath = await (new Response(result['Body'])).text()
                setMediainfo(urlPath);
            } catch (err) {
                console.log(err.message)
            }
        }

        if (mediaDetail) {
            if (mediaDetail.progress_create_hls === 100) {
                // console.log(analysis)
                getAWSUrl().then()
            }
        }
    }, [mediaDetail, media_id])

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    });

    let history = useHistory();

    return (
        <>
            <WhiteNavbar {...props}/>
            {mediaDetail ?
                <MediaPageHeader
                    title={"Media info"}
                    subTitle={mediaDetail.filename}
                /> :
                <MediaPageHeader
                    title={"Media info"}
                />
            }
            <div className="section">
                <Flex justifyContent="center">
                    <View width={{base: '100%', xl: '75%'}} style={{fontFamily: "SourceSansPro"}}>
                        {mediaDetail ?
                            <>
                                <Expander style={{fontSize: "18px"}} type="single"
                                          isCollapsible={true} {...restOfProps}>
                                    <ExpanderItem title="Summary" value="item-1">
                                        <ReferenceSpecPage refSpecData={mediaDetail}/>
                                    </ExpanderItem>
                                    <ExpanderItem title="Detailed Specifications" value="item-2">
                                        <div dangerouslySetInnerHTML={{__html: mediainfo}}/>
                                    </ExpanderItem>
                                    <ExpanderItem title="Processing" value="item-3">
                                        <ReferenceProcessing RefProc={mediaDetail}/>
                                    </ExpanderItem>
                                    <ExpanderItem title="View HLS stream" value="item-4">
                                        {mediaDetail.interlace_rep.BWDifSendFrameTFF ?
                                            <p>
                                                De-interlaced with YADIF, one frame for each frame, top field first
                                            </p>
                                            : <></>
                                        }
                                        {mediaDetail.interlace_rep.BWDifSendFrameBFF ?
                                            <p>
                                                De-interlaced with YADIF, one frame for each frame, bottom field first
                                            </p>
                                            : <></>
                                        }
                                        {mediaDetail.interlace_rep.BWDifSendFieldTFF ?
                                            <p>
                                                De-interlaced with YADIF, one frame for each field, top field first
                                            </p>
                                            : <></>
                                        }
                                        {mediaDetail.interlace_rep.BWDifSendFieldBFF ?
                                            <p>
                                                De-interlaced with YADIF, one frame for each field, bottom field first
                                            </p>
                                            : <></>
                                        }
                                        {mediaDetail.interlace_rep.DeInterleave ?
                                            <p>
                                                De-interleaved with il=l=d:c=d
                                                Odd lines are moved to the top half, even lines to the bottom half of
                                                the frame
                                            </p>
                                            : <></>
                                        }
                                        <Flex direction={"row"}>
                                            <View id="streamplayerref" width={"320px"} height={"400px"}>
                                                <StreamPlayer
                                                    streamPlayerData={mediaDetail}
                                                    refOrDis={"media"}
                                                    m3u8File={"/media_out_signed.m3u8"}/>
                                            </View>
                                        </Flex>
                                    </ExpanderItem>
                                </Expander>
                                <br/>
                                <Button
                                    onClick={() => {
                                        onRefresh()
                                        history.goBack()
                                    }}
                                    className="reference-card-button"
                                >Back</Button>
                            </> : <></>
                        }
                    </View>
                </Flex>
            </div>
            <FooterBlack {...props}/>
        </>
    );
}

export default MediaDetailPage;
