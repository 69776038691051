import React from "react";
import Popup from "reactjs-popup"
import {API, graphqlOperation, Storage} from 'aws-amplify';
import {
    Button,
    Card,
    Divider,
    Flex,
    Heading,
    Icon,
    Image,
    Link,
    Loader,
    Menu,
    MenuButton,
    MenuItem,
    Text,
    View
} from '@aws-amplify/ui-react';
import {Link as ReactRouterLink} from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import SharePopup from "./SharePopup";

const getComparisonPersons = /* GraphQL */ `
  query GetComparison($id: ID!) {
    getComparison(id: $id) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      createdAt
      deleted
      description
      encodeAnalyses {
        items {
          id
          encodeAnalysisID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      id
      owner
      persons {
        items {
          id
          account {
            id
            email
          }
          accountID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      teams {
        items {
          id
          teamID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      updatedAt
    }
  }
`;


function SynamediaStrip() {
    return <Flex direction="column" gap={0}>
        <View
            backgroundColor={"#3380FF"}
            padding={"5px"}
            height={"12.5%"}
        />
        <View
            backgroundColor={"#FFB400"}
            padding={"5px"}
            height={"12.5%"}
        />
        <View
            backgroundColor={"#3380FF"}
            padding={"5px"}
            height={"75%"}
        />
    </Flex>;
}

function RefMedia(props) {
    // console.log(props)
    const {refMedia} = props || {}
    const [thumbnailURL, setThumbnailURL] = React.useState()

    let mediaFilename
    if (refMedia) {
        let splitRefMediaFilename = refMedia.filename.split('/')
        if (splitRefMediaFilename.length === 1) {
            mediaFilename = splitRefMediaFilename[splitRefMediaFilename.length - 1]
        } else {
            mediaFilename = '.../' + splitRefMediaFilename[splitRefMediaFilename.length - 1]
        }
    }
    React.useEffect(() => {
        let mounted = true
        async function getAWSUrl() {
            try {
                const result = await Storage.get(
                    refMedia['id'] + "/media_01.jpg",
                    {
                        level: 'protected',
                        identityId: refMedia.user_identity_id,
                        expires: 86400
                    });
                // console.log(result)
                if (mounted) {
                    setThumbnailURL(result);
                }
            } catch (err) {
                console.log('URL not found')
            }
        }
        if (refMedia) {
            if (refMedia.progress_create_hls === 100) {
                getAWSUrl().then()
            }
        }
        return function cleanup() {
            mounted = false;
        };

    }, [refMedia])

    return <Flex direction="column" gap={"10px"}>
        <Flex direction={"column"} alignItems={"flex-start"} justifyContent={"flex-start"} gap={"0.2rem"}>
            <View height="81px" minWidth="144px">
                {thumbnailURL ?
                    <Image
                        draggable="false"
                        height="100%"
                        objectFit="contain"
                        src={thumbnailURL}
                        alt="Not available"
                    />
                    :
                    <Flex justifyContent="center" height="61px" padding="10px">
                        <Loader
                            height="100%"
                            filledColor="#FF3333"
                            emptyColor="var(--amplify-colors-brand-secondary-100)"
                        />
                    </Flex>
                }
            </View>
            <Heading level={6} lineHeight={"2rem"}>{mediaFilename}</Heading>
            <Text padding={"0px"}>
                {refMedia ? refMedia.spec_width + "x" + refMedia.spec_height + "@" + refMedia.spec_frame_rate + "fps" : "Media deleted"}
            </Text>
            <Flex direction={"row"}>
                <Text padding={"0px"}>
                    {refMedia ? refMedia.createdAt.split('.')[0] : ""}
                </Text>
            </Flex>
        </Flex>
    </Flex>;
}

function CompMedia(props) {
    // console.log(props)
    const {encodeAnalysis} = props || {}
    const {encMedia} = encodeAnalysis || {}
    const [thumbnailURL, setThumbnailURL] = React.useState()

    let mediaFilename
    if (encMedia) {
        let splitRefMediaFilename = encMedia.filename.split('/')
        if (splitRefMediaFilename.length === 1) {
            mediaFilename = splitRefMediaFilename[splitRefMediaFilename.length - 1]
        } else {
            mediaFilename = '.../' + splitRefMediaFilename[splitRefMediaFilename.length - 1]
        }
    }
    React.useEffect(() => {
        let mounted = true
        async function getAWSUrl() {
            try {
                const result = await Storage.get(
                    encMedia['id'] + "/media_01.jpg",
                    {
                        level: 'protected',
                        identityId: encMedia.user_identity_id,
                        expires: 86400
                    });
                // console.log(result)
                if (mounted) {
                    setThumbnailURL(result);
                }
            } catch (err) {
                console.log('URL not found')
            }
        }
        if (encMedia) {
            if (encMedia.progress_create_hls === 100) {
                getAWSUrl().then()
                return () => { mounted = false }
            }
        }
    }, [encMedia])

    return <Flex direction="column" gap={"10px"}>
        <Flex direction={"column"} alignItems={"flex-start"} justifyContent={"flex-start"} gap={"0.2rem"}>
            <View height="81px" minWidth="144px">
                {thumbnailURL ?
                    <Image
                        draggable="false"
                        height="100%"
                        objectFit="contain"
                        src={thumbnailURL}
                        alt="Not available"
                    />
                    :
                    <Flex justifyContent="center" height="61px" padding="10px">
                        <Loader
                            height="100%"
                            filledColor="#FF3333"
                            emptyColor="var(--amplify-colors-brand-secondary-100)"
                        />
                    </Flex>
                }
            </View>
            <Heading level={6} lineHeight={"2rem"}>{mediaFilename}</Heading>
            <Text padding={"0px"}>
                {encMedia ? encMedia.spec_width + "x" + encMedia.spec_height + "@" + encMedia.spec_frame_rate + "fps" : "Media deleted"}
            </Text>
            <Text padding={"0px"}> {encMedia ? encMedia.createdAt.split('.')[0] : ""} </Text>
            <Text>{'VMAF: '+ encodeAnalysis.metric_vmaf}</Text>
            <Text>{'PSNR: '+ encodeAnalysis.metric_psnr}</Text>
        </Flex>
    </Flex>;
}

function CompareButtons(props) {
    const compare_path = props.encodeAnalyses.items.map(item => item.encodeAnalysis.id).join('/')

    return <Flex direction={"row"} padding={"1rem"}>
        <Tooltip
            title="Share"
            placement="top"
        >
            <Button className="reference-card-button" size="small"
                    onClick={props.onClick}>
                <Icon
                    ariaLabel="Share"
                    viewBox={{width: 24, height: 24}}
                    pathData="M20.000,8.000 C18.788,8.000 17.714,7.447 16.980,6.593 L7.900,11.134 C7.962,11.413 8.000,11.701 8.000,11.998 C8.000,12.269 7.972,12.533 7.920,12.788 L16.984,17.402 C17.718,16.551 18.791,16.000 20.000,16.000 C22.206,16.000 24.000,17.794 24.000,20.000 C24.000,22.206 22.206,24.000 20.000,24.000 C17.794,24.000 16.000,22.206 16.000,20.000 C16.000,19.722 16.029,19.450 16.084,19.188 L7.031,14.580 C6.297,15.441 5.218,16.000 4.000,16.000 C1.794,16.000 0.000,14.205 0.000,11.998 C0.000,9.793 1.794,7.998 4.000,7.998 C5.190,7.998 6.248,8.530 6.981,9.357 L16.082,4.806 C16.028,4.545 16.000,4.276 16.000,4.000 C16.000,1.794 17.794,0.000 20.000,0.000 C22.206,0.000 24.000,1.794 24.000,4.000 C24.000,6.206 22.206,8.000 20.000,8.000 ZM20.000,22.000 C21.103,22.000 22.000,21.103 22.000,20.000 C22.000,18.897 21.103,18.000 20.000,18.000 C18.897,18.000 18.000,18.897 18.000,20.000 C18.000,21.103 18.897,22.000 20.000,22.000 ZM4.000,9.998 C2.897,9.998 2.000,10.895 2.000,11.998 C2.000,13.102 2.897,14.000 4.000,14.000 C5.103,14.000 6.000,13.102 6.000,11.998 C6.000,10.895 5.103,9.998 4.000,9.998 ZM20.000,2.000 C18.897,2.000 18.000,2.897 18.000,4.000 C18.000,5.103 18.897,6.000 20.000,6.000 C21.103,6.000 22.000,5.103 22.000,4.000 C22.000,2.897 21.103,2.000 20.000,2.000 Z"
                />
            </Button>
        </Tooltip>
        <Popup
            modal
            closeOnDocumentClick
            open={props.open}
            onClose={props.onClose}
        >
            <SharePopup
                onClick={props.onClick1}
                shareWith={props.shareWith}
                onCloseShareWith={props.onCloseShareWith}
                searchFieldValue={props.searchFieldValue}
                onSearchFieldChange={props.onSearchFieldChange}
                onSearchFieldSubmit={props.onSearchFieldSubmit}
                onSearchFieldClear={props.onSearchFieldClear}
                shareWithSelection={props.shareWithSelection}
                onClickShareWithSelection={props.onClickShareWithSelection}
                onShareButtonClick={props.onShareButtonClick}
                persons={props.persons}
                teams={props.teams}
            />
        </Popup>
        <Link as={ReactRouterLink} to={"/compare/" + compare_path}>
            <Tooltip
                title="Details"
                placement="top"
            >
                <Button className="reference-card-button" size="small">
                    <Icon
                        ariaLabel="Info"
                        viewBox={{width: 24, height: 24}}
                        pathData="M20.321,20.485 C18.069,22.752 15.074,24.000 11.887,24.000 C8.700,24.000 5.705,22.752 3.453,20.485 C-1.197,15.806 -1.197,8.193 3.453,3.514 C5.705,1.248 8.700,-0.000 11.887,-0.000 C15.074,-0.000 18.069,1.248 20.321,3.514 C22.574,5.781 23.815,8.794 23.815,12.000 C23.815,15.205 22.574,18.218 20.321,20.485 ZM18.916,4.929 C17.038,3.040 14.542,2.000 11.887,2.000 C9.232,2.000 6.736,3.040 4.858,4.929 C0.984,8.827 0.984,15.172 4.858,19.071 C6.736,20.960 9.232,22.000 11.887,22.000 C14.542,22.000 17.038,20.960 18.916,19.071 C20.793,17.182 21.828,14.671 21.828,12.000 C21.828,9.329 20.793,6.818 18.916,4.929 ZM13.876,19.000 L9.900,19.000 C9.351,19.000 8.906,18.552 8.906,18.000 C8.906,17.447 9.351,17.000 9.900,17.000 L10.894,17.000 L10.894,13.000 L9.900,13.000 C9.351,13.000 8.906,12.552 8.906,12.000 C8.906,11.448 9.351,11.000 9.900,11.000 L11.888,11.000 C12.437,11.000 12.882,11.448 12.882,12.000 L12.882,17.000 L13.876,17.000 C14.425,17.000 14.870,17.447 14.870,18.000 C14.870,18.552 14.425,19.000 13.876,19.000 ZM11.888,9.000 C10.792,9.000 9.900,8.102 9.900,6.998 C9.900,5.896 10.792,5.000 11.888,5.000 C12.984,5.000 13.876,5.896 13.876,6.998 C13.876,8.102 12.984,9.000 11.888,9.000 Z"
                    />
                </Button>
            </Tooltip>
        </Link>
        <Menu
            trigger={
                <MenuButton className="reference-card-button" size="small">
                    <Icon
                        ariaLabel="Trash"
                        viewBox={{width: 24, height: 24}}
                        pathData="M19.000,6.000 L17.910,6.000 L16.105,22.118 C15.986,23.173 15.062,24.000 14.000,24.000 L6.000,24.000 C4.937,24.000 4.014,23.172 3.897,22.116 L2.120,6.000 L1.000,6.000 C0.447,6.000 0.000,5.552 0.000,5.000 C0.000,4.448 0.447,4.000 1.000,4.000 L6.000,4.000 L6.000,3.000 C6.000,1.346 7.346,-0.000 9.000,-0.000 L11.000,-0.000 C12.654,-0.000 14.000,1.346 14.000,3.000 L14.000,4.000 L19.000,4.000 C19.553,4.000 20.000,4.448 20.000,5.000 C20.000,5.552 19.553,6.000 19.000,6.000 ZM12.000,3.000 C12.000,2.449 11.552,2.000 11.000,2.000 L9.000,2.000 C8.448,2.000 8.000,2.449 8.000,3.000 L8.000,4.000 L12.000,4.000 L12.000,3.000 ZM4.132,6.000 L5.885,21.896 C5.889,21.935 5.960,22.000 6.000,22.000 L14.000,22.000 C14.040,22.000 14.113,21.935 14.117,21.894 L15.897,6.000 L4.132,6.000 Z"
                    />
                </MenuButton>
            }
        >
            <MenuItem onClick={props.onClick2}>Confirm</MenuItem>
            <Divider/>
            <MenuItem>Cancel</MenuItem>
        </Menu>
    </Flex>;
}

function EASharing(props) {
    const {account, persons, teams} = props || {}

    const sharedItem = (teams.items || []).map(item =>
        <Tooltip key={item.team.id} title={item.team.name} placement="bottom">
            <div data-initials={item.team.name[0]}/>
        </Tooltip>).concat((persons.items || []).map(item => <Tooltip key={item.account.id} title={item.account.email}
                                                                      placement="bottom">
        <div data-initials={((item.account.first_name ? item.account.first_name[0] : item.account.email[0]) + (item.account.last_name ? item.account.last_name[0] : item.account.email[1])).toUpperCase()}/>
    </Tooltip>))

    const sharedBy = <Tooltip title={account.email} placement={"bottom"}>
        <div data-initials={((account.first_name ? account.first_name[0] : account.email[0]) + (account.last_name ? account.last_name[0] : account.email[1])).toUpperCase()}/>
    </Tooltip>

    return <Flex direction={"row"} justifyContent={"flex-start"} alignItems={"center"} height={"40px"}>
        <Heading level={6}>Shared {props.tabIndex === "0" ? "with" : "by"}</Heading>
        <Flex direction={"row"} gap={"0"}>
            {props.tabIndex === "0" ? sharedItem : sharedBy}
        </Flex>
    </Flex>;
}


function ComparisonCard(props) {
    const {comparison, modalState} = props
    const [persons, setPersons] = React.useState(comparison.persons)
    const {account, encodeAnalyses, teams} = comparison || {}
    let encMediaItems = encodeAnalyses.items.map(item => <CompMedia key={item.encodeAnalysis.id} encodeAnalysis={item.encodeAnalysis} />)

    function handleUpdatePersons() {
        setTimeout(() => { API.graphql(graphqlOperation(getComparisonPersons, {id: comparison.id, owner: account.id})).then(response => setPersons(response['data']['getComparison']['persons']))}, 2000)
        setTimeout(() => { API.graphql(graphqlOperation(getComparisonPersons, {id: comparison.id, owner: account.id})).then(response => setPersons(response['data']['getComparison']['persons']))}, 4000)
    }

    return (
        <Card
            draggable="false"
            key={comparison.id}
            id={comparison.id}
            padding="0rem"
            variation="elevated"
        >
            <Flex justifyContent="space-between" gap="5px">
                <SynamediaStrip/>
                <Flex direction="column" gap={"3px"} alignItems={"flex-start"}>
                    <Flex direction={"row"}>
                        <RefMedia refMedia={encodeAnalyses.items[0].encodeAnalysis.refMedia}/>
                        <Divider orientation="vertical"/>
                        {encMediaItems}
                    </Flex>
                    <Flex direction={"column"} gap={"2px"}>
                        <Flex direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                            <CompareButtons
                                onClick={() => {
                                    props.onOpenModal(comparison.id)
                                }}
                                open={modalState}
                                onClose={() => {
                                    props.onCloseModal()
                                }}
                                onClick1={() => {
                                    console.log('Close');
                                    props.onCloseModal()
                                }}
                                shareWith={props.shareWith}
                                onCloseShareWith={(id) => props.onCloseShareWith(id)}
                                searchFieldValue={props.searchFieldValue}
                                onSearchFieldChange={props.onSearchFieldChange}
                                onSearchFieldSubmit={props.onSearchFieldSubmit}
                                onSearchFieldClear={props.onSearchFieldClear}
                                shareWithSelection={props.shareWithSelection}
                                onClickShareWithSelection={(item) => props.onClickShareWithSelection(item)}
                                onShareButtonClick={() => {
                                    props.onShareButtonClick();
                                    props.onCloseModal()
                                    handleUpdatePersons()
                                }}
                                persons={persons}
                                teams={teams}
                                encodeAnalyses={encodeAnalyses}
                                onClick2={props.onRemoveComparison}
                            />
                        </Flex>
                    </Flex>
                    <Divider/>
                    <EASharing tabIndex={props.tabIndex} persons={persons} teams={teams} account={account}/>
                </Flex>
            </Flex>
        </Card>
    )
}

export default ComparisonCard