import React from "react";
import {
    Button,
    Card,
    Collection,
    Divider,
    Flex,
    Heading,
    Icon,
    ScrollView,
    SearchField,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Text,
    View
} from '@aws-amplify/ui-react';
import Tooltip from '@mui/material/Tooltip';

function BadgeWithClose(props) {
    const {backgroundColor, color, value} = props
    return <Flex direction={"row"} justifyContent={"flex-start"}>
        <View backgroundColor={backgroundColor} padding={"2px"} borderRadius={"10px"}>
            <Flex direction={"row"} alignItems={"center"} gap={"5px"} alignContent={"flex-start"}
                  style={{paddingRight: "8px", paddingLeft: "10px"}}>
                <Text color={color}>{value}</Text>
                <Button className="close-badge-button" size={"small"} padding={"2px"} onClick={props.onBadgeClose}>
                    <Icon
                        color={color}
                        ariaLabel="Close"
                        viewBox={{width: 24, height: 24}}
                        pathData="M12.000,24.000 C5.383,24.000 0.000,18.617 0.000,12.000 C0.000,5.383 5.383,0.000 12.000,0.000 C18.617,0.000 24.000,5.383 24.000,12.000 C24.000,18.617 18.617,24.000 12.000,24.000 ZM12.000,2.000 C6.486,2.000 2.000,6.486 2.000,12.000 C2.000,17.514 6.486,22.000 12.000,22.000 C17.514,22.000 22.000,17.514 22.000,12.000 C22.000,6.486 17.514,2.000 12.000,2.000 ZM16.707,16.707 C16.512,16.902 16.256,17.000 16.000,17.000 C15.744,17.000 15.488,16.902 15.293,16.707 L12.000,13.414 L8.707,16.707 C8.512,16.902 8.256,17.000 8.000,17.000 C7.744,17.000 7.488,16.902 7.293,16.707 C6.902,16.316 6.902,15.684 7.293,15.293 L10.586,12.000 L7.293,8.707 C6.902,8.316 6.902,7.684 7.293,7.293 C7.684,6.902 8.316,6.902 8.707,7.293 L12.000,10.586 L15.293,7.293 C15.684,6.902 16.316,6.902 16.707,7.293 C17.098,7.684 17.098,8.316 16.707,8.707 L13.414,12.000 L16.707,15.293 C17.098,15.684 17.098,16.316 16.707,16.707 Z"
                    />
                </Button>
            </Flex>
        </View>
    </Flex>;
}

function SharePopup(props) {
    const {persons, teams} = props || {}
    const tableItem = props.shareWithSelection.map(item =>
        <TableRow key={item['id']}>
            <TableCell onClick={() => props.onClickShareWithSelection(item)}>{item['label'] }</TableCell>
        </TableRow>)

    const sharedItem = (teams.items || []).map(item =>
        <Tooltip key={item.team.id} title={item.team.name} placement="bottom">
            <div data-initials={item.team.name[0]}/>
        </Tooltip>).concat((persons.items || []).map(item => <Tooltip key={item.account.id} title={item.account.email}
                                                                      placement="bottom">
        <div data-initials={(item.account.first_name ? item.account.first_name[0] : item.account.email[0])  + (item.account.last_name ? item.account.last_name[0] : item.account.email[1])}/>
    </Tooltip>))

    // console.log(props.shareWith)
    return <View backgroundColor={"#FFB400"} padding={"5px"}>
        <Card variation="outlined" padding={"1px"}>
            <Flex direction={"column"} gap={0}>
                <Flex direction={"column"} padding={0} alignItems={"flex-end"}
                      alignContent={"flex-end"}>
                    <Button className="close-card-button" size="small" onClick={props.onClick}>
                        <Icon
                            ariaLabel="Close"
                            viewBox={{width: 24, height: 24}}
                            pathData="M12.000,24.000 C5.383,24.000 0.000,18.617 0.000,12.000 C0.000,5.383 5.383,0.000 12.000,0.000 C18.617,0.000 24.000,5.383 24.000,12.000 C24.000,18.617 18.617,24.000 12.000,24.000 ZM12.000,2.000 C6.486,2.000 2.000,6.486 2.000,12.000 C2.000,17.514 6.486,22.000 12.000,22.000 C17.514,22.000 22.000,17.514 22.000,12.000 C22.000,6.486 17.514,2.000 12.000,2.000 ZM16.707,16.707 C16.512,16.902 16.256,17.000 16.000,17.000 C15.744,17.000 15.488,16.902 15.293,16.707 L12.000,13.414 L8.707,16.707 C8.512,16.902 8.256,17.000 8.000,17.000 C7.744,17.000 7.488,16.902 7.293,16.707 C6.902,16.316 6.902,15.684 7.293,15.293 L10.586,12.000 L7.293,8.707 C6.902,8.316 6.902,7.684 7.293,7.293 C7.684,6.902 8.316,6.902 8.707,7.293 L12.000,10.586 L15.293,7.293 C15.684,6.902 16.316,6.902 16.707,7.293 C17.098,7.684 17.098,8.316 16.707,8.707 L13.414,12.000 L16.707,15.293 C17.098,15.684 17.098,16.316 16.707,16.707 Z"
                        />
                    </Button>
                </Flex>
                <Flex direction={"column"} padding={"0.5rem"}>
                    <Heading level={4}>Share with</Heading>
                    <Collection
                        type="list"
                        items={props.shareWith}
                        itemsPerPage={16}
                        direction={'row'}
                        maxWidth={'400px'}
                        wrap={'wrap'}
                    >
                        {(badge, index) => (
                            <BadgeWithClose
                                key={badge['id']}
                                backgroundColor={"#3380FF"}
                                onBadgeClose={() => props.onCloseShareWith(badge['id'])}
                                color={"white"}
                                value={badge['label']}
                            />
                        )}
                    </Collection>
                    <SearchField
                        label="search"
                        placeholder="Search..."
                        value={props.searchFieldValue}
                        onChange={(e) => props.onSearchFieldChange(e)}
                        onSubmit={(e) => props.onSearchFieldSubmit(e)}
                        onClear={() => props.onSearchFieldClear()}

                    />
                    <ScrollView height="114px" orientation="vertical">
                        <Table highlightOnHover width={"400px"} size={"small"}>
                            <TableBody>
                                {tableItem}
                            </TableBody>
                        </Table>
                    </ScrollView>
                    <br/>
                    <Flex direction={"row"} justifyContent={"center"}>
                        <Button
                            className="reference-card-button"
                            size="small"
                            isDisabled={!props.shareWith.length}
                            onClick={() => props.onShareButtonClick()}
                            gap="0.2rem">
                            <Icon
                                ariaLabel="Share"
                                viewBox={{width: 24, height: 24}}
                                pathData="M20.000,8.000 C18.788,8.000 17.714,7.447 16.980,6.593 L7.900,11.134 C7.962,11.413 8.000,11.701 8.000,11.998 C8.000,12.269 7.972,12.533 7.920,12.788 L16.984,17.402 C17.718,16.551 18.791,16.000 20.000,16.000 C22.206,16.000 24.000,17.794 24.000,20.000 C24.000,22.206 22.206,24.000 20.000,24.000 C17.794,24.000 16.000,22.206 16.000,20.000 C16.000,19.722 16.029,19.450 16.084,19.188 L7.031,14.580 C6.297,15.441 5.218,16.000 4.000,16.000 C1.794,16.000 0.000,14.205 0.000,11.998 C0.000,9.793 1.794,7.998 4.000,7.998 C5.190,7.998 6.248,8.530 6.981,9.357 L16.082,4.806 C16.028,4.545 16.000,4.276 16.000,4.000 C16.000,1.794 17.794,0.000 20.000,0.000 C22.206,0.000 24.000,1.794 24.000,4.000 C24.000,6.206 22.206,8.000 20.000,8.000 ZM20.000,22.000 C21.103,22.000 22.000,21.103 22.000,20.000 C22.000,18.897 21.103,18.000 20.000,18.000 C18.897,18.000 18.000,18.897 18.000,20.000 C18.000,21.103 18.897,22.000 20.000,22.000 ZM4.000,9.998 C2.897,9.998 2.000,10.895 2.000,11.998 C2.000,13.102 2.897,14.000 4.000,14.000 C5.103,14.000 6.000,13.102 6.000,11.998 C6.000,10.895 5.103,9.998 4.000,9.998 ZM20.000,2.000 C18.897,2.000 18.000,2.897 18.000,4.000 C18.000,5.103 18.897,6.000 20.000,6.000 C21.103,6.000 22.000,5.103 22.000,4.000 C22.000,2.897 21.103,2.000 20.000,2.000 Z"
                            />Share
                        </Button>
                    </Flex>
                    <Divider orientation="horizontal"/>
                    <br/>
                    <Flex direction={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                        <Heading level={6}>Shared with</Heading>
                        <Flex direction={"row"} gap={"0"}>
                            {sharedItem}
                        </Flex>
                    </Flex>
                    <br/>
                </Flex>
            </Flex>
        </Card>
    </View>;
}
export default SharePopup
