import React from "react";
import ReactPlayer from 'react-player'
import {Card, Flex, Heading, Link, Table, TableBody, TableCell, TableHead, TableRow, View} from '@aws-amplify/ui-react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import {Link as ReactRouterLink} from "react-router-dom";

highchartsMore(Highcharts);

function SmxProbeCard(props) {
    // console.log(props)
    const {
        SmxProbeItem,
        pointer,
        playerChan1,
        playerChan2,
        playerChan3,
        playerChan4,
        bitrateListChan1,
        bitrateListChan2,
        bitrateListChan3,
        bitrateListChan4,
        bitrateListChan1Out,
        bitrateListChan2Out,
        bitrateListChan3Out,
        bitrateListChan4Out,
        vmafListChan1,
        vmafListChan2,
        vmafListChan3,
        vmafListChan4,
        play,
        poolBw,
        poolBwVal} = props

    const [bitrateDataChan1, setBitrateDataChan1] = React.useState([])
    const [bitrateDataChan2, setBitrateDataChan2] = React.useState([])
    const [bitrateDataChan3, setBitrateDataChan3] = React.useState([])
    const [bitrateDataChan4, setBitrateDataChan4] = React.useState([])
    const [bitrateDataChan1Out, setBitrateDataChan1Out] = React.useState([])
    const [bitrateDataChan2Out, setBitrateDataChan2Out] = React.useState([])
    const [bitrateDataChan3Out, setBitrateDataChan3Out] = React.useState([])
    const [bitrateDataChan4Out, setBitrateDataChan4Out] = React.useState([])
    const [vmafDataChan1, setVmafDataChan1] = React.useState([])
    const [vmafDataChan2, setVmafDataChan2] = React.useState([])
    const [vmafDataChan3, setVmafDataChan3] = React.useState([])
    const [vmafDataChan4, setVmafDataChan4] = React.useState([])

    const [chan1Time1Bw, setChan1Time1Bw] = React.useState('')
    const [chan1Time2Bw, setChan1Time2Bw] = React.useState('')
    const [chan1Time3Bw, setChan1Time3Bw] = React.useState('')
    const [chan1Time4Bw, setChan1Time4Bw] = React.useState('')
    const [chan1Time5Bw, setChan1Time5Bw] = React.useState('')
    const [chan2Time1Bw, setChan2Time1Bw] = React.useState('')
    const [chan2Time2Bw, setChan2Time2Bw] = React.useState('')
    const [chan2Time3Bw, setChan2Time3Bw] = React.useState('')
    const [chan2Time4Bw, setChan2Time4Bw] = React.useState('')
    const [chan2Time5Bw, setChan2Time5Bw] = React.useState('')
    const [chan3Time1Bw, setChan3Time1Bw] = React.useState('')
    const [chan3Time2Bw, setChan3Time2Bw] = React.useState('')
    const [chan3Time3Bw, setChan3Time3Bw] = React.useState('')
    const [chan3Time4Bw, setChan3Time4Bw] = React.useState('')
    const [chan3Time5Bw, setChan3Time5Bw] = React.useState('')
    const [chan4Time1Bw, setChan4Time1Bw] = React.useState('')
    const [chan4Time2Bw, setChan4Time2Bw] = React.useState('')
    const [chan4Time3Bw, setChan4Time3Bw] = React.useState('')
    const [chan4Time4Bw, setChan4Time4Bw] = React.useState('')
    const [chan4Time5Bw, setChan4Time5Bw] = React.useState('')

    const [chan1Time1Vqm, setChan1Time1Vqm] = React.useState('')
    const [chan1Time2Vqm, setChan1Time2Vqm] = React.useState('')
    const [chan1Time3Vqm, setChan1Time3Vqm] = React.useState('')
    const [chan1Time4Vqm, setChan1Time4Vqm] = React.useState('')
    const [chan1Time5Vqm, setChan1Time5Vqm] = React.useState('')
    const [chan2Time1Vqm, setChan2Time1Vqm] = React.useState('')
    const [chan2Time2Vqm, setChan2Time2Vqm] = React.useState('')
    const [chan2Time3Vqm, setChan2Time3Vqm] = React.useState('')
    const [chan2Time4Vqm, setChan2Time4Vqm] = React.useState('')
    const [chan2Time5Vqm, setChan2Time5Vqm] = React.useState('')
    const [chan3Time1Vqm, setChan3Time1Vqm] = React.useState('')
    const [chan3Time2Vqm, setChan3Time2Vqm] = React.useState('')
    const [chan3Time3Vqm, setChan3Time3Vqm] = React.useState('')
    const [chan3Time4Vqm, setChan3Time4Vqm] = React.useState('')
    const [chan3Time5Vqm, setChan3Time5Vqm] = React.useState('')
    const [chan4Time1Vqm, setChan4Time1Vqm] = React.useState('')
    const [chan4Time2Vqm, setChan4Time2Vqm] = React.useState('')
    const [chan4Time3Vqm, setChan4Time3Vqm] = React.useState('')
    const [chan4Time4Vqm, setChan4Time4Vqm] = React.useState('')
    const [chan4Time5Vqm, setChan4Time5Vqm] = React.useState('')

    const playerRefChan1 = React.useRef()
    const playerRefChan2 = React.useRef()
    const playerRefChan3 = React.useRef()
    const playerRefChan4 = React.useRef()

    let dateStartProgram1 = new Date()
    let dateStartProgram2 = new Date()
    let dateStartProgram3 = new Date()
    let dateStartProgram4 = new Date()
    let dateStartProgram5 = new Date()
    let dateStartProgram6 = new Date()
    dateStartProgram2.setTime(dateStartProgram2.getTime() + 62000)
    dateStartProgram3.setTime(dateStartProgram3.getTime() + 122000)
    dateStartProgram4.setTime(dateStartProgram4.getTime() + 182000)
    dateStartProgram5.setTime(dateStartProgram5.getTime() + 242000)
    dateStartProgram6.setTime(dateStartProgram6.getTime() + 302000)

    const [vmafChart1Options, setVmafChart1Options] = React.useState({
        time: {
            useUTC: false
        },
        chart: {
            type: 'spline',
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
            marginBottom: 30,
            marginTop: 10,
            height: '150px'
        },
        series: [
            { type: 'arearange', name: 'VQ', data: vmafDataChan1, color: '#3380FF', lineWidth: 1, visible: true },
        ],
        xAxis: {
            crosshair: true,
            type: 'datetime',
            gridLineWidth: 1,
            title: {
                text: ''
            },
            labels: {
                style: {
                    color: '#000',
                    fontSize: '12px'
                }
            },
            plotBands: [
                {
                    from: dateStartProgram1,
                    to: dateStartProgram5,
                    color: '#EEEEEE',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                    label: {
                        text: 'Cartoon 1',
                        style: {
                            color: '#151F20',
                            fontSize: '20px'
                        },
                        y: 105
                    }
                },
                {
                    from: dateStartProgram5,
                    to: dateStartProgram6,
                    color: '#DDDDDD',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                    label: {
                        text: 'Olympics',
                        style: {
                            color: '#151F20',
                            fontSize: '20px'
                        },
                        y: 105
                    }
                }
                ]
        },
        yAxis: [
            {
                className: 'highcharts-vq-channel',
                title: {
                    text: '',
                },
                labels: {
                    style: {
                        color: '#000',
                        fontSize: '15px'
                    }
                },
                max: 100,
                min: 0
            }
        ],
        legend: {
            enabled: false
        },
        title: {
            align: 'left',
            text: ''
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false
                },
                cursor: 'pointer',
                allowPointSelect: true,
                animation: false,
                point: {
                    events: {
                        click: function () {
                            console.log('Jump to ', this.series.name, this)
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: false
        }

    })
    const [vmafChart2Options, setVmafChart2Options] = React.useState({
        time: {
            useUTC: false
        },
        chart: {
            type: 'spline',
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
            marginBottom: 30,
            marginTop: 10,
            height: '150px'
        },
        series: [
            { type: 'arearange', name: 'VQ', data: vmafDataChan2, color: '#FFB400', lineWidth: 1, visible: true },
        ],
        xAxis: {
            crosshair: true,
            type: 'datetime',
            gridLineWidth: 1,
            labels : {
                style: {
                    color: '#000',
                    fontSize: '12px'
                }
            },
            title: {
                text: ''
            },
            plotBands: [
                {
                    from: dateStartProgram1,
                    to: dateStartProgram4,
                    color: '#EEEEEE',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                    label: {
                        text: 'Cartoon 2',
                        style: {
                            color: '#151F20',
                            fontSize: '20px'
                        },
                        y: 105
                    }
                },
                {
                    from: dateStartProgram4,
                    to: dateStartProgram6,
                    color: '#DDDDDD',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                    label: {
                        text: 'Football',
                        style: {
                            color: '#151F20',
                            fontSize: '20px'
                        },
                        y: 105
                    }
                }
            ]
        },
        yAxis: [
            {
                className: 'highcharts-vq-channel',
                title: {
                    text: '',
                },
                labels: {
                    style: {
                        color: '#000',
                        fontSize: '15px'
                    }
                },
                max: 100,
                min: 0
            }
        ],
        legend: {
            enabled: false
        },
        title: {
            align: 'left',
            text: ''
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false
                },
                cursor: 'pointer',
                allowPointSelect: true,
                animation: false,
                point: {
                    events: {
                        click: function () {
                            console.log('Jump to ', this.series.name, this)
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: false
        }

    })
    const [vmafChart3Options, setVmafChart3Options] = React.useState({
        time: {
            useUTC: false
        },
        chart: {
            type: 'spline',
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
            marginBottom: 30,
            marginTop: 10,
            height: '150px'
        },
        series: [
            { type: 'arearange', name: 'VQ', data: vmafDataChan3, color: '#151F20', lineWidth: 1, visible: true },
        ],
        xAxis: {
            crosshair: true,
            type: 'datetime',
            gridLineWidth: 1,
            labels : {
                style: {
                    color: '#000',
                    fontSize: '12px'
                }
            },
            title: {
                text: ''
            },
            plotBands: [
                {
                    from: dateStartProgram1,
                    to: dateStartProgram3,
                    color: '#EEEEEE',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                    label: {
                        text: 'Cartoon 3',
                        style: {
                            color: '#151F20',
                            fontSize: '20px'
                        },
                        y: 105
                    }
                },
                {
                    from: dateStartProgram3,
                    to: dateStartProgram6,
                    color: '#DDDDDD',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                    label: {
                        text: 'Nature',
                        style: {
                            color: '#151F20',
                            fontSize: '20px'
                        },
                        y: 105
                    }
                }
            ]
        },
        yAxis: [
            {
                className: 'highcharts-vq-channel',
                title: {
                    text: '',
                },
                labels: {
                    style: {
                        color: '#000',
                        fontSize: '15px'
                    }
                },
                max: 100,
                min: 0
            }
        ],
        legend: {
            enabled: false
        },
        title: {
            align: 'left',
            text: ''
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false
                },
                cursor: 'pointer',
                allowPointSelect: true,
                animation: false,
                point: {
                    events: {
                        click: function () {
                            console.log('Jump to ', this.series.name, this)
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: false
        }

    })
    const [vmafChart4Options, setVmafChart4Options] = React.useState({
        time: {
            useUTC: false
        },
        chart: {
            type: 'spline',
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
            marginBottom: 30,
            marginTop: 10,
            height: '150px'
        },
        series: [
            { type: 'arearange', name: 'VQ', data: vmafDataChan4, color: '#FF3333', lineWidth: 1, visible: true },
        ],
        xAxis: {
            crosshair: true,
            type: 'datetime',
            gridLineWidth: 1,
            labels : {
                style: {
                    color: '#000',
                    fontSize: '12px'
                }
            },
            title: {
                text: ''
            },
            plotBands: [
                {
                    from: dateStartProgram1,
                    to: dateStartProgram2,
                    color: '#EEEEEE',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                    label: {
                        text: 'Cartoon 4',
                        style: {
                            color: '#151F20',
                            fontSize: '20px'
                        },
                        y: 105
                    }
                },
                {
                    from: dateStartProgram2,
                    to: dateStartProgram6,
                    color: '#DDDDDD',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                    label: {
                        text: 'Cycling',
                        style: {
                            color: '#151F20',
                            fontSize: '20px'
                        },
                        y: 105
                    }
                }
            ]
        },
        yAxis: [
            {
                className: 'highcharts-vq-channel',
                title: {
                    text: '',
                },
                labels: {
                    style: {
                        color: '#000',
                        fontSize: '15px'
                    }
                },
                max: 100,
                min: 0
            }
        ],
        legend: {
            enabled: false
        },
        title: {
            align: 'left',
            text: ''
        },
        plotOptions: {
            series: {
                marker: {
                    enabled: false
                },
                cursor: 'pointer',
                allowPointSelect: true,
                animation: false,
                point: {
                    events: {
                        click: function () {
                            console.log('Jump to ', this.series.name, this)
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: false
        }

    })
    const [bwChartOptions, setBwChartOptions ] = React.useState({
        time: {
            useUTC: false
        },
        chart: {
            type: 'area',
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
            marginBottom: 30,
            marginTop: 10,
            height: '150px'
        },
        series: [
            { name: 'Bitrate', data: bitrateDataChan1, color: '#3380FF', lineWidth: 1, visible: true },
            { name: 'Bitrate', data: bitrateDataChan2, color: '#FFC41C', lineWidth: 1, visible: true },
            { name: 'Bitrate', data: bitrateDataChan3, color: '#151F20', lineWidth: 1, visible: true },
            { name: 'Bitrate', data: bitrateDataChan4, color: '#FF3333', lineWidth: 1, visible: true },
        ],
        xAxis: {
            crosshair: true,
            type: 'datetime',
            gridLineWidth: 1,
            labels : {
                style: {
                    color: '#000',
                    fontSize: '12px'
                }
            },
            title: {
                text: ''
            },
            plotBands: [
                {
                    from: dateStartProgram1,
                    to: dateStartProgram2,
                    color: '#EEEEEE',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                },
                {
                    from: dateStartProgram2,
                    to: dateStartProgram3,
                    color: '#DDDDDD',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                },
                {
                    from: dateStartProgram3,
                    to: dateStartProgram4,
                    color: '#EEEEEE',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                },
                {
                    from: dateStartProgram4,
                    to: dateStartProgram5,
                    color: '#DDDDDD',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                },
                {
                    from: dateStartProgram5,
                    to: dateStartProgram6,
                    color: '#EEEEEE',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                }
            ]

        },
        yAxis: [
            {
                className: 'highcharts-vq-channel',
                title: {
                    text: '',
                },
                labels: {
                    style: {
                        color: '#000',
                        fontSize: '15px'
                    }
                }
            }
        ],
        legend: {
            enabled: false
        },
        title: {
            align: 'left',
            text: ''
        },
        plotOptions: {
            area: {
                stacking: 'normal',
                lineColor: '#666666',
                lineWidth: 0,
                marker: {
                    enabled: false
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: false
        }
    });
    const [bwOutChartOptions, setBwOutChartOptions ] = React.useState({
        time: {
            useUTC: false
        },
        chart: {
            type: 'area',
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
            marginBottom: 30,
            marginTop: 10,
            height: '150px'
        },
        series: [
            { name: 'Bitrate', data: bitrateDataChan1Out, color: '#3380FF', lineWidth: 1, visible: true },
            { name: 'Bitrate', data: bitrateDataChan2Out, color: '#FFC41C', lineWidth: 1, visible: true },
            { name: 'Bitrate', data: bitrateDataChan3Out, color: '#151F20', lineWidth: 1, visible: true },
            { name: 'Bitrate', data: bitrateDataChan4Out, color: '#FF3333', lineWidth: 1, visible: true },
        ],
        xAxis: {
            crosshair: true,
            type: 'datetime',
            gridLineWidth: 1,
            labels : {
                style: {
                    color: '#000',
                    fontSize: '12px'
                }
            },
            title: {
                text: ''
            },
            plotBands: [
                {
                    from: dateStartProgram1,
                    to: dateStartProgram2,
                    color: '#EEEEEE',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                },
                {
                    from: dateStartProgram2,
                    to: dateStartProgram3,
                    color: '#DDDDDD',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                },
                {
                    from: dateStartProgram3,
                    to: dateStartProgram4,
                    color: '#EEEEEE',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                },
                {
                    from: dateStartProgram4,
                    to: dateStartProgram5,
                    color: '#DDDDDD',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                },
                {
                    from: dateStartProgram5,
                    to: dateStartProgram6,
                    color: '#EEEEEE',
                    borderWidth: 1,
                    borderColor: '#CFCBC9',
                }
            ]

        },
        yAxis: [
            {
                className: 'highcharts-vq-channel',
                title: {
                    text: '',
                },
                labels: {
                    style: {
                        color: '#000',
                        fontSize: '15px'
                    }
                }
            }
        ],
        legend: {
            enabled: false
        },
        title: {
            align: 'left',
            text: ''
        },
        plotOptions: {
            area: {
                stacking: 'normal',
                lineColor: '#666666',
                lineWidth: 0,
                marker: {
                    enabled: false
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: false
        }
    });

    React.useEffect(()=> {
        setChan1Time1Bw('1910')
        setChan2Time1Bw('1315')
        setChan3Time1Bw('2103')
        setChan4Time1Bw('2130')
        setChan1Time1Vqm('86.91')
        setChan2Time1Vqm('90.79')
        setChan3Time1Vqm('86.94')
        setChan4Time1Vqm('92.43')
        setChan1Time2Bw('1600')
        setChan2Time2Bw('1188')
        setChan3Time2Bw('1809')
        setChan4Time2Bw('2856')
        setChan1Time2Vqm('84.59')
        setChan2Time2Vqm('89.86')
        setChan3Time2Vqm('84.89')
        setChan4Time2Vqm('88.48')
        setChan1Time3Bw('1561')
        setChan2Time3Bw('1172')
        setChan3Time3Bw('1943')
        setChan4Time3Bw('2770')
        setChan1Time3Vqm('84.27')
        setChan2Time3Vqm('89.65')
        setChan3Time3Vqm('80.63')
        setChan4Time3Vqm('87.81')
        setChan1Time4Bw('1432')
        setChan2Time4Bw('1787')
        setChan3Time4Bw('1757')
        setChan4Time4Bw('2461')
        setChan1Time4Vqm('83.16')
        setChan2Time4Vqm('88.71')
        setChan3Time4Vqm('78.4')
        setChan4Time4Vqm('85.54')
        setChan1Time5Bw('1488')
        setChan2Time5Bw('1773')
        setChan3Time5Bw('1747')
        setChan4Time5Bw('2429')
        setChan1Time5Vqm('89.13')
        setChan2Time5Vqm('88.5')
        setChan3Time5Vqm('77.85')
        setChan4Time5Vqm('85.04')
    }, [])

    React.useEffect(() => {
        if (play){
            let nowDate = Date.now();
            let tmpBitrateListChan1 = 250*(bitrateListChan1[pointer]+bitrateListChan1[pointer+1]+bitrateListChan1[pointer+2]+bitrateListChan1[pointer+3])
            let tmpBitrateListChan2 = 250*(bitrateListChan2[pointer]+bitrateListChan2[pointer+1]+bitrateListChan2[pointer+2]+bitrateListChan2[pointer+3])
            let tmpBitrateListChan3 = 250*(bitrateListChan3[pointer]+bitrateListChan3[pointer+1]+bitrateListChan3[pointer+2]+bitrateListChan3[pointer+3])
            let tmpBitrateListChan4 = 250*(bitrateListChan4[pointer]+bitrateListChan4[pointer+1]+bitrateListChan4[pointer+2]+bitrateListChan4[pointer+3])
            setBitrateDataChan1(prevData => [...prevData, [nowDate, tmpBitrateListChan1]])
            setBitrateDataChan2(prevData => [...prevData, [nowDate, tmpBitrateListChan2]])
            setBitrateDataChan3(prevData => [...prevData, [nowDate, tmpBitrateListChan3]])
            setBitrateDataChan4(prevData => [...prevData, [nowDate, tmpBitrateListChan4]])
            let tmpBitrateListChan1Out = 250*(bitrateListChan1Out[pointer]+bitrateListChan1Out[pointer+1]+bitrateListChan1Out[pointer+2]+bitrateListChan1Out[pointer+3])
            let tmpBitrateListChan2Out = 250*(bitrateListChan2Out[pointer]+bitrateListChan2Out[pointer+1]+bitrateListChan2Out[pointer+2]+bitrateListChan2Out[pointer+3])
            let tmpBitrateListChan3Out = 250*(bitrateListChan3Out[pointer]+bitrateListChan3Out[pointer+1]+bitrateListChan3Out[pointer+2]+bitrateListChan3Out[pointer+3])
            let tmpBitrateListChan4Out = 250*(bitrateListChan4Out[pointer]+bitrateListChan4Out[pointer+1]+bitrateListChan4Out[pointer+2]+bitrateListChan4Out[pointer+3])
            if (tmpBitrateListChan1Out > Math.max(tmpBitrateListChan2Out, tmpBitrateListChan3Out, tmpBitrateListChan4Out)) {
                setBitrateDataChan1Out(prevData => [...prevData, [nowDate, poolBwVal - (tmpBitrateListChan2Out + tmpBitrateListChan3Out + tmpBitrateListChan4Out)]])
            } else {
                setBitrateDataChan1Out(prevData => [...prevData, [nowDate, tmpBitrateListChan1Out]])
            }
            if (tmpBitrateListChan2Out > Math.max(tmpBitrateListChan1Out, tmpBitrateListChan3Out, tmpBitrateListChan4Out)){
                setBitrateDataChan2Out(prevData => [...prevData, [nowDate, poolBwVal - (tmpBitrateListChan1Out+tmpBitrateListChan3Out+tmpBitrateListChan4Out)]])
            } else {
                setBitrateDataChan2Out(prevData => [...prevData, [nowDate, tmpBitrateListChan2Out]])
            }
            if (tmpBitrateListChan3Out > Math.max(tmpBitrateListChan1Out, tmpBitrateListChan2Out, tmpBitrateListChan4Out)){
                setBitrateDataChan3Out(prevData => [...prevData, [nowDate, poolBwVal - (tmpBitrateListChan1Out+tmpBitrateListChan2Out+tmpBitrateListChan4Out)]])
            } else {
                setBitrateDataChan3Out(prevData => [...prevData, [nowDate, tmpBitrateListChan3Out]])
            }
            if (tmpBitrateListChan4Out > Math.max(tmpBitrateListChan1Out, tmpBitrateListChan2Out, tmpBitrateListChan3Out)){
                setBitrateDataChan4Out(prevData => [...prevData, [nowDate, poolBwVal - (tmpBitrateListChan1Out+tmpBitrateListChan2Out+tmpBitrateListChan3Out)]])
            } else {
                setBitrateDataChan4Out(prevData => [...prevData, [nowDate, tmpBitrateListChan4Out]])
            }
        }
    },[poolBwVal, play, pointer, bitrateListChan1, bitrateListChan2, bitrateListChan3, bitrateListChan4,
        bitrateListChan1Out, bitrateListChan2Out, bitrateListChan3Out, bitrateListChan4Out])

    React.useEffect(() => {
        if (poolBw && play){
            let nowDate = Date.now();
            if (vmafListChan1){
                // console.log(vmafListChan1.length)
                setVmafDataChan1(prevData => [...prevData, [nowDate, vmafListChan1[pointer][1], vmafListChan1[pointer][2]]])
            }
        }
    },[poolBw, play, pointer, vmafListChan1])

    React.useEffect(() => {
        if (poolBw && play){
            let nowDate = Date.now();
            if (vmafListChan2){
                setVmafDataChan2(prevData => [...prevData, [nowDate, vmafListChan2[pointer][1], vmafListChan2[pointer][2]]])
            }
        }
    },[poolBw, play, pointer, vmafListChan2])

    React.useEffect(() => {
        if (poolBw && play){
            let nowDate = Date.now();
            if (vmafListChan3){
                setVmafDataChan3(prevData => [...prevData, [nowDate, vmafListChan3[pointer][1], vmafListChan3[pointer][2]]])
            }
        }
    },[poolBw, play, pointer, vmafListChan3])

    React.useEffect(() => {
        if (poolBw && play){
            let nowDate = Date.now();
            if (vmafListChan4){
                setVmafDataChan4(prevData => [...prevData, [nowDate, vmafListChan4[pointer][1], vmafListChan4[pointer][2]]])
            }
        }
    },[poolBw, play, pointer, vmafListChan4])

     // console.log(bitrateDataChan3Out, bitrateDataChan4Out)
    React.useEffect(() => {
        if (bitrateDataChan1) {
            setBwChartOptions({series: [
                    {data: bitrateDataChan1},
                    {data: bitrateDataChan2},
                    {data: bitrateDataChan3},
                    {data: bitrateDataChan4}
                ]})
        }
        if (bitrateDataChan1Out) {
            setBwOutChartOptions({series: [
                    {data: bitrateDataChan1Out},
                    {data: bitrateDataChan2Out},
                    {data: bitrateDataChan3Out},
                    {data: bitrateDataChan4Out}
                ]})
        }
        if (poolBw){
            setVmafChart1Options({series: [
                    {data: vmafDataChan1}
                ]})
            setVmafChart2Options({series: [
                    {data: vmafDataChan2}
                ]})
            setVmafChart3Options({series: [
                    {data: vmafDataChan3}
                ]})
            setVmafChart4Options({series: [
                    {data: vmafDataChan4}
                ]})
        }
    },[poolBw, bitrateDataChan1, bitrateDataChan2, bitrateDataChan3, bitrateDataChan4,
        bitrateDataChan1Out, bitrateDataChan2Out, bitrateDataChan3Out, bitrateDataChan4Out,
        vmafDataChan1, vmafDataChan2, vmafDataChan3, vmafDataChan4])

    return (
        <Card
            draggable="false"
            key={SmxProbeItem.id}
            id={SmxProbeItem.id}
            width={poolBw ? "72%" : "28%"}
            height={poolBw ? "1040px" : "600px"}
            padding="0rem"
        >
            <Flex direction="row" justifyContent="flex-start" gap="2px">
                <Flex direction="column" gap={"2px"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                    <Flex direction={"row"} gap={"2px"}>
                        { poolBw ?
                            <svg id="track4_c" xmlns="http://www.w3.org/2000/svg xmlns:xlink=http://www.w3.org/1999/xlink" height="285px" width="724px" viewBox="10 5 640 253" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                                <g id="my-g-svg" transform="translate(0 -50)">
                                    <g id="track4_c-g1" transform="translate(144.908921 12.313664)">
                                        <g id="track4_c-arrow4" transform="translate(442.046235 54.83667)">
                                            <line id="track4_c-line1" x1="-33.333333" y1="0" x2="170.346252" y2="0" transform="matrix(.883741 0 0 1-517.077123 165.163327)" fill="#f33" stroke="#f33" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line2" x1="5.686275" y1="5.490196" x2="-4.313725" y2="-4.509804" transform="translate(-370.059551 159.673131)" fill="#f33" stroke="#f33" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line3" x1="5.686275" y1="-3.72549" x2="-4.313725" y2="6.27451" transform="translate(-370.059551 168.888817)" fill="#f33" stroke="#f33" strokeWidth="3" strokeLinecap="round"/>
                                        </g>
                                        <g id="track4_c-copy-of-arrow4" transform="translate(817.046235 54.83667)">
                                            <line id="track4_c-line4" x1="-33.333333" y1="0" x2="170.346252" y2="0" transform="matrix(.883741 0 0 1-517.077123 165.163327)" fill="#f33" stroke="#f33" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line5" x1="5.686275" y1="5.490196" x2="-4.313725" y2="-4.509804" transform="translate(-370.059551 159.673131)" fill="#f33" stroke="#f33" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line6" x1="5.686275" y1="-3.72549" x2="-4.313725" y2="6.27451" transform="translate(-370.059551 168.888817)" fill="#f33" stroke="#f33" strokeWidth="3" strokeLinecap="round"/>
                                        </g>
                                        <g id="track4_c-arrow3" transform="translate(444.000032-72.104523)">
                                            <line id="track4_c-line7" x1="-33.333333" y1="0" x2="170.346252" y2="0" transform="matrix(.883741 0 0 1-519.03092 242.104523)" stroke="#000" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line8" x1="5.686275" y1="5.490196" x2="-4.313725" y2="-4.509804" transform="translate(-372.013296 236.614324)" stroke="#000" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line9" x1="5.686275" y1="-3.72549" x2="-4.313725" y2="6.27451" transform="translate(-372.013296 245.83001)" stroke="#000" strokeWidth="3" strokeLinecap="round"/>
                                        </g>
                                        <g id="track4_c-copy-of-arrow3" transform="translate(819.000032-72.104523)">
                                            <line id="track4_c-line10" x1="-33.333333" y1="0" x2="170.346252" y2="0" transform="matrix(.883741 0 0 1-519.03092 242.104523)" stroke="#000" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line11" x1="5.686275" y1="5.490196" x2="-4.313725" y2="-4.509804" transform="translate(-372.013296 236.614324)" stroke="#000" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line12" x1="5.686275" y1="-3.72549" x2="-4.313725" y2="6.27451" transform="translate(-372.013296 245.83001)" stroke="#000" strokeWidth="3" strokeLinecap="round"/>
                                        </g>
                                        <g id="track4_c-arrow2" transform="translate(174.340356-18.392212)">
                                            <line id="track4_c-line13" x1="-33.333333" y1="0" x2="170.346252" y2="0" transform="matrix(.883741 0 0 1-249.371244 137.411815)" fill="#ffb400" stroke="#ffb400" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line14" x1="5.686275" y1="4.509804" x2="-4.313725" y2="-4.509804" transform="translate(-102.353649 132.902011)" fill="#ffb400" stroke="#ffb400" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line15" x1="5.686275" y1="-3.72549" x2="-4.313725" y2="6.27451" transform="translate(-102.353652 141.137305)" fill="#ffb400" stroke="#ffb400" strokeWidth="3" strokeLinecap="round"/>
                                        </g>
                                        <g id="track4_c-copy-of-arrow2" transform="translate(549.340356-18.392212)">
                                            <line id="track4_c-line16" x1="-33.333333" y1="0" x2="170.346252" y2="0" transform="matrix(.883741 0 0 1-249.371244 137.411815)" fill="#ffb400" stroke="#ffb400" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line17" x1="5.686275" y1="4.509804" x2="-4.313725" y2="-4.509804" transform="translate(-102.353649 132.902011)" fill="#ffb400" stroke="#ffb400" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line18" x1="5.686275" y1="-3.72549" x2="-4.313725" y2="6.27451" transform="translate(-102.353652 141.137305)" fill="#ffb400" stroke="#ffb400" strokeWidth="3" strokeLinecap="round"/>
                                        </g>
                                        <g id="track4_c-arrow1" transform="translate(-68.418013 17.490158)">
                                            <line id="track4_c-line19" x1="-33.333333" y1="0" x2="170.346252" y2="0" transform="matrix(.883741 0 0 1-6.612875 52.212198)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line20" x1="5.686275" y1="6.553935" x2="-4.313725" y2="-3.446065" transform="translate(140.404712 45.955903)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line21" x1="5.686275" y1="-4.553934" x2="-4.313725" y2="5.446066" transform="translate(140.404712 57.063772)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                                        </g>
                                        <g id="track4_c-copy-of-arrow1" transform="translate(306.581987 17.490158)">
                                            <line id="track4_c-line22" x1="-33.333333" y1="0" x2="170.346252" y2="0" transform="matrix(.883741 0 0 1-6.612875 52.212198)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line23" x1="5.686275" y1="6.553935" x2="-4.313725" y2="-3.446065" transform="translate(140.404712 45.955903)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                                            <line id="track4_c-line24" x1="5.686275" y1="-4.553934" x2="-4.313725" y2="5.446066" transform="translate(140.404712 57.063772)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                                        </g>
                                        <g id="track4_c-encoders" transform="translate(-94.666666-35.294289)">
                                            <g id="track4_c-encoderblue" transform="translate(516.294091 0.784313)">
                                                <rect id="track4_c-rect1" width="160" height="40" rx="0" ry="0" transform="translate(-326.068533 84.509976)" fill="#3380ff" stroke="#3380ff"/>
                                                <text id="track4_c-text1" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="26" fontWeight="400" transform="translate(-291.149683 113.913887)" fill="#fff" strokeWidth="0">
                                                    <tspan id="track4_c-tspan1" y="0" fontWeight="400" strokeWidth="0">
                                                        {'Encoder'}
                                                    </tspan>
                                                </text>
                                            </g>
                                            <g id="track4_c-smx" transform="translate(516.294091 198.784313)">
                                                <rect id="track4_c-rect2" width="160" height="40" rx="0" ry="0" transform="translate(-326.068533 84.652246)" fill="#3380ff" stroke="#3380ff"/>
                                                <text id="track4_c-text2" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="26" fontWeight="400" transform="translate(-269.128238 113.75102)" fill="#fff" strokeWidth="0">
                                                    <tspan id="track4_c-tspan2" y="0" fontWeight="400" strokeWidth="0">
                                                        {'SMX'}
                                                    </tspan>
                                                </text>
                                            </g>
                                            <g id="track4_c-encoderyellow" transform="translate(516.294109 83.999807)">
                                                <rect id="track4_c-rect3" width="160" height="40" rx="0" ry="0" transform="translate(-326.627443 51.294517)" fill="#ffb400" stroke="#ffb400"/>
                                                <text id="track4_c-text3" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="26" fontWeight="400" transform="translate(-291.708593 78.991956)" strokeWidth="0">
                                                    <tspan id="track4_c-tspan3" y="0" fontWeight="400" strokeWidth="0">
                                                        {'Encoder'}
                                                    </tspan>
                                                </text>
                                            </g>
                                            <g id="track4_c-encoderblack" transform="translate(516.294109 143.490009)">
                                                <rect id="track4_c-rect4" width="160" height="40" rx="0" ry="0" transform="translate(-326.627443 41.80428)" stroke="#000"/>
                                                <text id="track4_c-text4" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="26" fontWeight="400" transform="translate(-291.666433 71.066594)" fill="#fff" strokeWidth="0">
                                                    <tspan id="track4_c-tspan4" y="0" fontWeight="400" strokeWidth="0">
                                                        {'Encoder'}
                                                    </tspan>
                                                </text>
                                            </g>
                                            <g id="track4_c-encoderred" transform="translate(93.823526 42.255059)">
                                                <rect id="track4_c-rect5" width="160" height="40" rx="0" ry="0" transform="translate(95.84314 193.03923)" fill="#f33" stroke="#f33"/>
                                                <text id="track4_c-text5" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(131.955014 221.554637)" fill="#fff" strokeWidth="0">
                                                    <tspan id="track4_c-tspan5" y="0" fontWeight="400" strokeWidth="0">
                                                        {'Encoder '}
                                                    </tspan></text></g>
                                            <rect id="track4_c-rect6" width="180" height="250" rx="0" ry="0" transform="translate(179.666666 77.980612)" fill="none" stroke="#3380ff"/>
                                        </g>
                                    </g>
                                    <path id="track4_c-path1" d="" transform="translate(-369.922279-52.882331)" stroke="#3f5787" strokeWidth="2.56"/>
                                    <text id="track4_c-text6" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="18" fontWeight="400" transform="translate(50 75.235132)" fill="#3380ff" strokeWidth="0">
                                        <tspan id="track4_c-tspan6" y="0" fontWeight="400" strokeWidth="0">
                                            {'720p50@15Mbps '}
                                        </tspan>
                                    </text>
                                    <text id="track4_c-copy-of-text" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="18" fontWeight="400" transform="translate(469 75.235132)" fill="#3380ff" strokeWidth="0">
                                        <tspan id="track4_c-tspan7" y="0" fontWeight="400" strokeWidth="0">
                                            {'720p50'}
                                        </tspan>
                                    </text>
                                    <text id="track4_c-copy-of-text2" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="18" fontWeight="400" transform="translate(50 125.235132)" fill="#ffb400" strokeWidth="0">
                                        <tspan id="track4_c-tspan8" y="0" fontWeight="400" strokeWidth="0">
                                            {'720p50@15Mbps '}
                                        </tspan>
                                    </text>
                                    <text id="track4_c-copy-of-text-2" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="18" fontWeight="400" transform="translate(469 125.235132)" fill="#ffb400" strokeWidth="0">
                                        <tspan id="track4_c-tspan9" y="0" fontWeight="400" strokeWidth="0">
                                            {'720p50'}
                                        </tspan>
                                    </text>
                                    <text id="track4_c-copy-of-text-22" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="18" fontWeight="400" transform="translate(50 175.235132)" strokeWidth="0">
                                        <tspan id="track4_c-tspan10" y="0" fontWeight="400" strokeWidth="0">
                                            {'720p50@15Mbps '}
                                        </tspan>
                                    </text>
                                    <text id="track4_c-copy-of-text-3" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="18" fontWeight="400" transform="translate(469 175.235132)" strokeWidth="0">
                                        <tspan id="track4_c-tspan11" y="0" fontWeight="400" strokeWidth="0">{'720p50'}
                                        </tspan>
                                    </text>
                                    <text id="track4_c-copy-of-text-32" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="18" fontWeight="400" transform="translate(50 225.235132)" fill="#f33" strokeWidth="0">
                                        <tspan id="track4_c-tspan12" y="0" fontWeight="400" strokeWidth="0">{'720p50@15Mbps '}
                                        </tspan>
                                    </text>
                                    <text id="track4_c-copy-of-text-4" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="18" fontWeight="400" transform="translate(469 225.235132)" fill="#f33" strokeWidth="0">
                                        <tspan id="track4_c-tspan13" y="0" fontWeight="400" strokeWidth="0">{'720p50 '}
                                        </tspan>
                                    </text>
                                    <text id="track4_c-text7" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="18" fontWeight="400" transform="translate(496.083465 286.776218)" fill="#3380ff" strokeWidth="0">
                                        <tspan id="track4_c-tspan14" y="0" fontWeight="400" strokeWidth="0">{'= 8Mbps'}
                                        </tspan>
                                    </text>
                                    <line id="track4_c-line25" x1="12.483256" y1="0" x2="-12.483256" y2="0" transform="translate(475.856226 267.004065)" fill="none" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                                    <line id="track4_c-line26" x1="-6.241628" y1="-6.725934" x2="6.241628" y2="6.725934" transform="translate(469.614598 273.73)" fill="none" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                                    <line id="track4_c-line27" x1="6.241628" y1="-7.444249" x2="-6.241628" y2="7.444249" transform="translate(469.614598 287.900183)" fill="none" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                                    <line id="track4_c-line28" x1="-12.483256" y1="0" x2="12.483256" y2="0" transform="translate(475.856226 295.344432)" fill="none" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                                </g>
                            </svg> : <></> }
                        <Flex direction="column" gap={"2px"} justifyContent={"flex-start"} alignItems={"flex-start"} padding={"5px"}>
                            <Flex direction={"row"} gap={"2px"}>
                                <View id="streamplayerref" width={"240px"} height={"139px"} border={"4px solid #3380FF"} padding={"2px"}>
                                    <div className='player-wrapper2'>
                                        <ReactPlayer
                                            muted={true}
                                            width='100%'
                                            height='100%'
                                            url={playerChan1}
                                            className="react-player"
                                            ref={playerRefChan1}
                                            pip={false}
                                            playing={play}
                                            controls={false}
                                            light={false}
                                            loop={true}
                                            playbackRate={1.0}
                                            onStart={() => {}}
                                            onPlay={() => {}}
                                            onEnablePIP={() => {}}
                                            onDisablePIP={() => console.log('handleDisablePIP')}
                                            onPause={() => console.log('handlePause')}
                                            onBuffer={props.onLoop}
                                            onSeek={e => console.log('onSeek', e, SmxProbeItem['name'])}
                                            onError={e => console.log('onError', e)}
                                            onDuration={() => {}}
                                        />
                                    </div>
                                </View>
                                <View id="streamplayerref" width={"240px"} height={"139px"} border={"4px solid #FFB400"} padding={"2px"}>
                                    <div className='player-wrapper2'>
                                        <ReactPlayer
                                            muted={true}
                                            width='100%'
                                            height='100%'
                                            url={playerChan2}
                                            className="react-player"
                                            ref={playerRefChan2}
                                            pip={false}
                                            playing={play}
                                            controls={false}
                                            light={false}
                                            loop={true}
                                            playbackRate={1.0}
                                            onStart={() => {}}
                                            onPlay={() => {}}
                                            onEnablePIP={() => {}}
                                            onDisablePIP={() => console.log('handleDisablePIP')}
                                            onPause={() => console.log('handlePause')}
                                            onBuffer={props.onLoop}
                                            onSeek={e => console.log('onSeek', e, SmxProbeItem['name'])}
                                            onError={e => console.log('onError', e)}
                                            onDuration={() => {}}
                                        />
                                    </div>
                                </View>
                            </Flex>
                            <Flex direction={"row"} gap={"2px"}>
                                <View id="streamplayerref" width={"240px"} height={"139px"} border={"4px solid #151F20"} padding={"2px"}>
                                    <div className='player-wrapper2'>
                                        <ReactPlayer
                                            muted={true}
                                            width='100%'
                                            height='100%'
                                            url={playerChan3}
                                            className="react-player"
                                            ref={playerRefChan3}
                                            pip={false}
                                            playing={play}
                                            controls={false}
                                            light={false}
                                            loop={true}
                                            playbackRate={1.0}
                                            onStart={() => {}}
                                            onPlay={() => {}}
                                            onEnablePIP={() => {}}
                                            onDisablePIP={() => console.log('handleDisablePIP')}
                                            onPause={() => console.log('handlePause')}
                                            onBuffer={props.onLoop}
                                            onSeek={e => console.log('onSeek', e, SmxProbeItem['name'])}
                                            onError={e => console.log('onError', e)}
                                            onDuration={() => {}}
                                        />
                                    </div>
                                </View>
                                <View id="streamplayerref" width={"240px"} height={"139px"} border={"4px solid #FF3333"} padding={"2px"}>
                                    <div className='player-wrapper2'>
                                        <ReactPlayer
                                            muted={true}
                                            width='100%'
                                            height='100%'
                                            url={playerChan4}
                                            className="react-player"
                                            ref={playerRefChan4}
                                            pip={false}
                                            playing={play}
                                            controls={false}
                                            light={false}
                                            loop={true}
                                            playbackRate={1.0}
                                            onStart={() => {}}
                                            onPlay={() => {}}
                                            onEnablePIP={() => {}}
                                            onDisablePIP={() => console.log('handleDisablePIP')}
                                            onPause={() => console.log('handlePause')}
                                            onBuffer={props.onLoop}
                                            onSeek={e => console.log('onSeek', e, SmxProbeItem['name'])}
                                            onError={e => console.log('onError', e)}
                                            onDuration={() => {}}
                                        />
                                    </div>
                                </View>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex direction={'row'} justifyContent={"flex-start"} height={"200px"}>
                        <Flex direction="column" justifyContent={"flex-start"} alignItems={"flex-start"} padding={"10px"}>
                            { poolBw ? <></> :
                            <Flex direction={"row"} justifyContent={"flex-start"}>
                                <Heading level={5}>{'Bitrate ' + SmxProbeItem.name}</Heading>
                            </Flex> }
                            { poolBw ? <></> :
                            <Flex direction={"column"} alignItems={"center"} gap={"5px"}>
                                <View
                                    width={"475px"}
                                    height={"150px"}
                                >
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={bwChartOptions}
                                    />
                                </View>
                            </Flex> }
                            { poolBw ? <></> :
                                <Flex direction={"row"} justifyContent={"flex-start"}>
                                    <Heading level={5}>{'Bitrate SMX Output'}</Heading>
                                </Flex> }
                            { poolBw ? <></> :
                                <Flex direction={"column"} alignItems={"center"} gap={"5px"}>
                                    <View
                                        width={"475px"}
                                        height={"150px"}
                                    >
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={bwOutChartOptions}
                                        />
                                    </View>
                                </Flex> }
                            { poolBw ? <Flex direction={"row"} justifyContent={"flex-start"}>
                                <Heading level={5}>VQM Statmux</Heading>
                            </Flex> : <></> }
                            { poolBw ?
                                <Flex direction={"column"} alignItems={"center"} gap={"5px"}>
                                    <View
                                        width={"475px"}
                                        height={"170px"}
                                    >
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={vmafChart1Options}
                                        />
                                    </View>
                                    <View
                                        width={"475px"}
                                        height={"170px"}
                                    >
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={vmafChart2Options}
                                        />
                                    </View>
                                    <View
                                        width={"475px"}
                                        height={"170px"}
                                    >
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={vmafChart3Options}
                                        />
                                    </View>
                                    <View
                                        width={"475px"}
                                        height={"170px"}
                                    >
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={vmafChart4Options}
                                        />
                                    </View>
                                </Flex>
                                : <></>}
                        </Flex>
                        {poolBw ?
                            <Flex direction={"column"} justifyContent={"flex-start"} alignItems={"flex-start"} padding={"10px"}>
                                <Heading level={5}>Scenarios including VQaaS results</Heading>
                                <Table variation={"bordered"} textAlign={"center"} size={"small"}>
                                    <TableHead>
                                        <TableRow >
                                            <TableCell width={"10rem"} height={"1rem"} rowspan="2" backgroundColor={"#DDDDDD"}>Content</TableCell>
                                            <TableCell colspan="2" backgroundColor={"#3380FF"} color={"white"}>Ch 1</TableCell>
                                            <TableCell colspan="2" backgroundColor={"#FFB400"}>Ch 2</TableCell>
                                            <TableCell colspan="2" backgroundColor={"#151F20"} color={"white"}>Ch 3</TableCell>
                                            <TableCell colspan="2" backgroundColor={"#FF3333"} color={"white"}>Ch 4</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell width={"6rem"} backgroundColor={"#99C0FF"}>Avg Bw [kbps]</TableCell>
                                            <TableCell width={"6rem"} backgroundColor={"#99C0FF"}>Avg VMAF</TableCell>
                                            <TableCell width={"6rem"} backgroundColor={"#FFD166"}>Avg Bw [kbps]</TableCell>
                                            <TableCell width={"6rem"} backgroundColor={"#FFD166"}>Avg VMAF</TableCell>
                                            <TableCell width={"6rem"} backgroundColor={"#666666"} color={"white"}>Avg Bw [kbps]</TableCell>
                                            <TableCell width={"6rem"} backgroundColor={"#666666"} color={"white"}>Avg VMAF</TableCell>
                                            <TableCell width={"6rem"} backgroundColor={"#FF9999"}>Avg Bw [kbps]</TableCell>
                                            <TableCell width={"6rem"} backgroundColor={"#FF9999"}>Avg VMAF</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow height={"5px"}/>
                                        <TableRow>
                                            <TableCell rowspan="2" backgroundColor={"#EEEEEE"}>{'4x Cartoon'}</TableCell>
                                            <TableCell colspan="2" backgroundColor={"#CCDFFF"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"#3380FF"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/3f5da2ef-098e-4f40-9f96-a994c2199cf7"}>Cartoon 1
                                                </Link>
                                            </TableCell>
                                            <TableCell colspan="2" backgroundColor={"#FFE099"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"black"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/7ab4cc30-ed50-4aac-a0df-2169f0d7964b"}>Cartoon 2
                                                </Link>
                                            </TableCell>
                                            <TableCell colspan="2" backgroundColor={"#999999"} color={"white"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"white"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/804af4e4-09f9-481b-90a7-0f177b90f6f4"}>Cartoon 3
                                                </Link>
                                            </TableCell>
                                            <TableCell colspan="2" backgroundColor={"#FFCCCC"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"black"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/c2d0501e-d027-41a2-8ccf-78aa819f9d85"}>Cartoon 4
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell width={"6rem"} backgroundColor={"#CCDFFF"}>{chan1Time1Bw}</TableCell>
                                            <TableCell width={"6rem"} backgroundColor={"#CCDFFF"}>{chan1Time1Vqm}</TableCell>
                                            <TableCell width={"6rem"} backgroundColor={"#FFE099"}>{chan2Time1Bw}</TableCell>
                                            <TableCell width={"6rem"} backgroundColor={"#FFE099"}>{chan2Time1Vqm}</TableCell>
                                            <TableCell width={"6rem"} backgroundColor={"#999999"} color={"white"}>{chan3Time1Bw}</TableCell>
                                            <TableCell width={"6rem"} backgroundColor={"#999999"} color={"white"}>{chan3Time1Vqm}</TableCell>
                                            <TableCell width={"6rem"} backgroundColor={"#FFCCCC"}>{chan4Time1Bw}</TableCell>
                                            <TableCell width={"6rem"} backgroundColor={"#FFCCCC"}>{chan4Time1Vqm}</TableCell>
                                        </TableRow>
                                        <TableRow height={"5px"}/>
                                        <TableRow>
                                            <TableCell rowspan="2" backgroundColor={"#DDDDDD"}>{'3x Cartoon'}</TableCell>
                                            <TableCell colspan="2" backgroundColor={"#99C0FF"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"#3380FF"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/4c7e0596-3e32-48ef-8fcc-9ce6a823643c"}>Cartoon 1
                                                </Link>
                                            </TableCell>
                                            <TableCell colspan="2" backgroundColor={"#FFD166"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"black"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/ca1f0ebe-ca53-4175-9b43-5aeada449ff6"}>Cartoon 2
                                                </Link>
                                            </TableCell>
                                            <TableCell colspan="2" backgroundColor={"#666666"} color={"white"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"white"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/f060cbe9-3381-4918-9ed8-f5ac6c9e88b3"}>Cartoon 3
                                                </Link>
                                            </TableCell>
                                            <TableCell colspan="2" backgroundColor={"#FF9999"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"black"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/af1e1e18-4b9d-49d9-a8d2-f70071928939"}>Cycling
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell backgroundColor={"#99C0FF"}>{chan1Time2Bw} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#99C0FF"}>{chan1Time2Vqm} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#FFD166"}>{chan2Time2Bw} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#FFD166"}>{chan2Time2Vqm} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#666666"} color={"white"}>{chan3Time2Bw} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#666666"} color={"white"}>{chan3Time2Vqm} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#FF9999"}>{chan4Time2Bw} &#8599;</TableCell>
                                            <TableCell backgroundColor={"#FF9999"}>{chan4Time2Vqm} &#8600;</TableCell>
                                        </TableRow>
                                        <TableRow height={"5px"}/>
                                        <TableRow>
                                            <TableCell rowspan="2" backgroundColor={"#EEEEEE"}>{'2x Cartoon'}</TableCell>
                                            <TableCell colspan="2" backgroundColor={"#CCDFFF"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"#3380FF"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/dfc69325-fcf7-4539-8df4-f6fdcb6de101"}>Cartoon 1
                                                </Link>
                                            </TableCell>
                                            <TableCell colspan="2" backgroundColor={"#FFE099"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"black"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/a47554ab-e5bf-42ec-b403-8d1bd0614d53"}>Cartoon 2
                                                </Link>
                                            </TableCell>
                                            <TableCell colspan="2" backgroundColor={"#999999"} color={"white"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"white"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/b79a28fe-9658-456e-af5d-8ae5bf158994"}>Nature
                                                </Link>
                                            </TableCell>
                                            <TableCell colspan="2" backgroundColor={"#FFCCCC"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"black"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/994dc8dc-470b-4136-9db9-cbe730ee4763"}>Cycling
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell backgroundColor={"#CCDFFF"}>{chan1Time3Bw} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#CCDFFF"}>{chan1Time3Vqm} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#FFE099"}>{chan2Time3Bw} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#FFE099"}>{chan2Time3Vqm} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#999999"} color={"white"}>{chan3Time3Bw} &#8599;</TableCell>
                                            <TableCell backgroundColor={"#999999"} color={"white"}>{chan3Time3Vqm} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#FFCCCC"}>{chan4Time3Bw} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#FFCCCC"}>{chan4Time3Vqm} &#8600;</TableCell>
                                        </TableRow>
                                        <TableRow height={"5px"}/>
                                        <TableRow>
                                            <TableCell rowspan="2" backgroundColor={"#DDDDDD"}>{'1x Cartoon'}</TableCell>
                                            <TableCell colspan="2" backgroundColor={"#99C0FF"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"#3380FF"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/1431f401-8ff0-41dd-988a-47c294d91a3f"}>Cartoon 1
                                                </Link>
                                            </TableCell>
                                            <TableCell colspan="2" backgroundColor={"#FFD166"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"black"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/6a8939fa-2b43-4b48-8929-bcc3ccffefba"}>Football
                                                </Link>
                                            </TableCell>
                                            <TableCell colspan="2" backgroundColor={"#666666"} color={"white"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"white"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/cb686a61-0603-4ff5-b678-4245f24c7f08"}>Nature
                                                </Link>
                                            </TableCell>
                                            <TableCell colspan="2" backgroundColor={"#FF9999"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"black"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/fc0a927b-ab0d-4fbf-a2f4-d1219bea6b24"}>Cycling
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell backgroundColor={"#99C0FF"}>{chan1Time4Bw} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#99C0FF"}>{chan1Time4Vqm} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#FFD166"}>{chan2Time4Bw} &#8599;</TableCell>
                                            <TableCell backgroundColor={"#FFD166"}>{chan2Time4Vqm} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#666666"} color={"white"}>{chan3Time4Bw} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#666666"} color={"white"}>{chan3Time4Vqm} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#FF9999"}>{chan4Time4Bw} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#FF9999"}>{chan4Time4Vqm} &#8600;</TableCell>
                                        </TableRow>
                                        <TableRow height={"5px"}/>
                                        <TableRow>
                                            <TableCell rowspan="2" backgroundColor={"#EEEEEE"}>{'No Cartoon'}</TableCell>
                                            <TableCell colspan="2" backgroundColor={"#CCDFFF"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"#3380FF"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/a7bf472a-40ce-49d2-b1f4-523f53aa7323"}>Olympics
                                                </Link>
                                            </TableCell>
                                            <TableCell colspan="2" backgroundColor={"#FFE099"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"black"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/58b39830-5326-4092-a082-67b67993877b"}>Football
                                                </Link>
                                            </TableCell>
                                            <TableCell colspan="2" backgroundColor={"#999999"} color={"white"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"white"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/9056a7b6-ae92-478f-81fd-817fdf383300"}>Nature
                                                </Link>
                                            </TableCell>
                                            <TableCell colspan="2" backgroundColor={"#FFCCCC"}>
                                                <Link
                                                    textDecoration="underline"
                                                    color={"black"}
                                                    as={ReactRouterLink}
                                                    to={"/encode-analysis/c5d5062e-784a-4416-8481-c6e9dfaf506b"}>Cycling
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell backgroundColor={"#CCDFFF"}>{chan1Time5Bw} &#8599;</TableCell>
                                            <TableCell backgroundColor={"#CCDFFF"}>{chan1Time5Vqm} &#8599;</TableCell>
                                            <TableCell backgroundColor={"#FFE099"}>{chan2Time5Bw} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#FFE099"}>{chan2Time5Vqm} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#999999"} color={"white"}>{chan3Time5Bw} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#999999"} color={"white"}>{chan3Time5Vqm} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#FFCCCC"}>{chan4Time5Bw} &#8600;</TableCell>
                                            <TableCell backgroundColor={"#FFCCCC"}>{chan4Time5Vqm} &#8600;</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Flex> :
                            <></>
                        }
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    )
}

export default SmxProbeCard