import React from "react";
import {Button, Flex, Heading, Icon, Text, TextAreaField, TextField} from '@aws-amplify/ui-react';
import Tooltip from '@mui/material/Tooltip';

function VQTextField(props) {
    const [tmpValue, setTmpValue] = React.useState(props.value || '')
    const [changing, setChanging] = React.useState(false)
    let multiLine = (props.label === "Function description" || props.label === "Description")

    React.useEffect(() => {
        if (props.value) setTmpValue(props.value)
    }, [props.value])

    function onCancelClick() {
        setTmpValue(props.value || '')
        setChanging(false)
    }

    function onUpdateClick() {
        setChanging(false)
        props.onChange(tmpValue)
    }

    return (
        <Flex style={{paddingTop:"1rem"}} direction={"row"} alignItems={"center"} gap={"1"}>
            <Heading level={"6"} style={{paddingRight:"1rem"}}>{props.label}</Heading>
            { !changing ? <Text  style={{paddingRight:"6px"}}>{props.value}</Text> :<></>}
            { !changing ?
                <Tooltip
                    title="Edit"
                    placement="bottom"
                >
                    <Button className="pencil-button" size="small" onClick={() => setChanging(true)} style={{paddingLeft:"6px"}}>
                        <Icon
                            ariaLabel="Pencil"
                            viewBox={{ width: 24, height: 24 }}
                            pathData="M22.728,5.403 C22.712,5.419 22.691,5.424 22.675,5.439 C22.660,5.455 22.655,5.476 22.640,5.492 L21.195,6.941 C21.195,6.941 21.195,6.941 21.195,6.941 C21.194,6.942 21.194,6.942 21.194,6.942 L6.932,21.247 C6.931,21.248 6.930,21.249 6.930,21.249 L6.926,21.254 C6.880,21.299 6.823,21.321 6.772,21.356 C6.716,21.394 6.670,21.441 6.606,21.468 L6.600,21.470 C6.600,21.470 6.599,21.471 6.599,21.471 L1.368,23.647 C1.246,23.698 1.118,23.722 0.991,23.722 C0.735,23.722 0.483,23.622 0.295,23.433 C0.013,23.150 -0.072,22.725 0.081,22.355 L2.254,17.101 C2.263,17.079 2.282,17.065 2.292,17.044 C2.339,16.950 2.391,16.857 2.467,16.781 L16.737,2.470 C16.737,2.470 16.737,2.469 16.738,2.468 C16.739,2.467 16.740,2.467 16.740,2.466 L18.183,1.019 C18.198,1.005 18.217,1.000 18.232,0.987 C18.247,0.969 18.253,0.947 18.270,0.930 C19.499,-0.301 21.499,-0.301 22.728,0.930 C23.958,2.164 23.957,4.170 22.728,5.403 ZM3.509,19.224 L2.814,20.906 L4.490,20.208 L3.509,19.224 ZM6.229,19.157 L19.105,6.242 L17.435,4.565 L4.557,17.480 L6.229,19.157 ZM21.335,2.328 C20.873,1.866 20.125,1.865 19.663,2.328 C19.648,2.343 19.628,2.348 19.613,2.362 C19.598,2.379 19.593,2.401 19.576,2.417 L18.828,3.168 L20.499,4.845 L21.247,4.095 C21.262,4.079 21.283,4.074 21.300,4.059 C21.314,4.043 21.319,4.022 21.335,4.006 C21.796,3.544 21.796,2.791 21.335,2.328 Z"
                        />
                    </Button>
                </Tooltip> : <></>
            }
            { changing && multiLine?
                <TextAreaField
                    labelHidden={true}
                    label={props.label}
                    resize={'both'}
                    onChange={e => setTmpValue(e.target.value)}
                    value={tmpValue}
                /> : <></>}
            { changing && !multiLine?
                <TextField
                    labelHidden={true}
                    label={props.label}
                    resize={'both'}
                    onChange={e => setTmpValue(e.target.value)}
                    value={tmpValue}
                /> : <></>}
            { changing ?
                <Tooltip
                    title="Update"
                    placement="bottom"
                >
                    <Button
                        className="pencil-button"
                        size="small"
                        onClick={()=>onUpdateClick()}
                    >
                        <Icon
                            ariaLabel="Check"
                            viewBox={{ width: 24, height: 24 }}
                            pathData="M12.000,24.000 C5.383,24.000 0.000,18.617 0.000,12.000 C0.000,5.383 5.383,0.000 12.000,0.000 C18.617,0.000 24.000,5.383 24.000,12.000 C24.000,18.617 18.617,24.000 12.000,24.000 ZM12.000,2.000 C6.486,2.000 2.000,6.486 2.000,12.000 C2.000,17.514 6.486,22.000 12.000,22.000 C17.514,22.000 22.000,17.514 22.000,12.000 C22.000,6.486 17.514,2.000 12.000,2.000 ZM11.752,16.659 C11.555,16.884 11.278,17.000 10.999,17.000 C10.780,17.000 10.560,16.928 10.376,16.781 L5.376,12.781 C4.944,12.436 4.874,11.807 5.219,11.375 C5.564,10.944 6.195,10.875 6.624,11.219 L10.877,14.622 L17.248,7.341 C17.611,6.927 18.243,6.884 18.659,7.248 C19.074,7.611 19.116,8.243 18.752,8.659 L11.752,16.659 Z"
                        />
                    </Button>
                </Tooltip> : <></>
            }
            { changing ?
                <Tooltip
                    title="Cancel"
                    placement="bottom"
                >
                    <Button
                        className="pencil-button"
                        size="small"
                        style={{paddingLeft:"0"}}
                        onClick={()=>onCancelClick()}
                    >
                        <Icon
                            ariaLabel="Close"
                            viewBox={{ width: 24, height: 24 }}
                            pathData="M12.000,24.000 C5.383,24.000 0.000,18.617 0.000,12.000 C0.000,5.383 5.383,0.000 12.000,0.000 C18.617,0.000 24.000,5.383 24.000,12.000 C24.000,18.617 18.617,24.000 12.000,24.000 ZM12.000,2.000 C6.486,2.000 2.000,6.486 2.000,12.000 C2.000,17.514 6.486,22.000 12.000,22.000 C17.514,22.000 22.000,17.514 22.000,12.000 C22.000,6.486 17.514,2.000 12.000,2.000 ZM16.707,16.707 C16.512,16.902 16.256,17.000 16.000,17.000 C15.744,17.000 15.488,16.902 15.293,16.707 L12.000,13.414 L8.707,16.707 C8.512,16.902 8.256,17.000 8.000,17.000 C7.744,17.000 7.488,16.902 7.293,16.707 C6.902,16.316 6.902,15.684 7.293,15.293 L10.586,12.000 L7.293,8.707 C6.902,8.316 6.902,7.684 7.293,7.293 C7.684,6.902 8.316,6.902 8.707,7.293 L12.000,10.586 L15.293,7.293 C15.684,6.902 16.316,6.902 16.707,7.293 C17.098,7.684 17.098,8.316 16.707,8.707 L13.414,12.000 L16.707,15.293 C17.098,15.684 17.098,16.316 16.707,16.707 Z"
                        />
                    </Button>
                </Tooltip> : <></>
            }
        </Flex>
    );
}

export default VQTextField;