import React, {useState} from "react";
import {Flex, Heading, View} from '@aws-amplify/ui-react';

// core components
import MediaPageHeader from "components/Headers/MediaPageHeader.js";
import AccountTable from "../../components/vqaas/AccountTable";
import FooterBlack from "components/Footers/FooterBlack.js";
import {API, Auth, graphqlOperation} from "aws-amplify";
import {createAccount} from "../../graphql/mutations";
import WhiteNavbar from "../../components/Navbars/WhiteNavbar";

const gqlListdBAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        company_name
        credits
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
        cross_account_s3 {
          nextToken
        }
      }
      nextToken
    }
  }
`;

function ListAccounts(props) {
    const [accounts, setAccounts] = useState();
    const [dbAccounts, setDbAccounts] = useState();

    React.useEffect(() => {
        let nextToken;
        async function listAccounts(limit){
            let apiName = 'AdminQueries';
            let path = '/listUsers';
            let myInit = {
                queryStringParameters: {
                    "limit": limit,
                    "token": nextToken
                },
                headers: {
                    'Content-Type' : 'application/json',
                    Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                }
            }
            const { NextToken, ...rest } =  await API.get(apiName, path, myInit);
            nextToken = NextToken;
            // console.log(rest)
            return rest;
        }
        async function listDbAccounts() {
            const result = await API.graphql(graphqlOperation(gqlListdBAccounts))
            // console.log(result.data.listAccounts.items)
            setDbAccounts(result.data.listAccounts.items)
        }
        listDbAccounts().then()
        listAccounts(60).then(data => setAccounts(data))
    }, [])

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    });

    React.useEffect(() => {
        async function syncAccounts() {
            if (accounts && dbAccounts) {
                if (accounts.Users) {
                    for (let i = 0; i < accounts.Users.length; i++) {
                        // console.log("Evaluate", accounts.Users[i])
                        let match = false
                        for (let j = 0; j < dbAccounts.length; j++) {
                            // console.log("? Match with", dbAccounts[j].id)
                            if (accounts.Users[i].Username === dbAccounts[j].id) {
                                match = true
                                break
                            }
                        }
                        if (match === false) {
                            // console.log("Add", accounts, "to database")
                            let new_email = ""
                            for (let k=0; k< accounts.Users[i].Attributes.length ; k++){
                                if (accounts.Users[i].Attributes[k].Name === "email"){
                                    new_email = accounts.Users[i].Attributes[k].Value
                                }
                            }
                            const addAccount = {
                                id: accounts.Users[i].Username,
                                email: new_email,
                                owner: accounts.Users[i].Username,
                                first_name: "",
                                last_name: "",
                                company_name: "",
                                s3_bucket: "",
                                s3_bucket_region: "",
                                description: "",
                                following: [],
                                followed_by: [],
                                num_references: 0,
                                num_distorteds: 0,
                                credits: 200
                            }
                            await API.graphql({query: createAccount, variables: {input: addAccount}});
                        }
                    }
                }
            }
        }
        syncAccounts().then()
    },[accounts, dbAccounts])

    return (
        <>
            <WhiteNavbar {...props}/>
            <MediaPageHeader />
            <View className="section">
                <Flex justifyContent="center" style={{fontFamily: "SourceSansPro"}}>
                    <View width={{base:'95%', xl:'95%'}}>
                        <Heading level={"3"} style={{
                            borderBottomWidth:"1px",
                            borderBottomColor:"black",
                            borderBottomStyle:"solid"}}>Accounts</Heading>
                        {accounts ? <AccountTable accounts={accounts}/> : <></>}
                    </View>
                </Flex>
            </View>
            <FooterBlack {...props}/>
        </>
    );
}

export default ListAccounts;
