import React, {useState} from "react";
import {Button, Text, Flex, Heading, View} from '@aws-amplify/ui-react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import FooterBlack from "components/Footers/FooterBlack.js";
import MediaPageHeader from "../../components/Headers/MediaPageHeader";
import {API, graphqlOperation} from "aws-amplify";
import {deleteAccountTeam, deleteTeam} from "../../graphql/mutations";

import {useHistory, useParams} from "react-router-dom";
import WhiteNavbar from "../../components/Navbars/WhiteNavbar";

export const getTeamWithAccounts = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      description
      accounts {
        items {
          id
          account {
            id 
            email
          }
        }
        nextToken
      }
      references {
        items {
          id
          reference {
            id
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

function DeleteTeam(props) {
    const { team_id } = useParams()
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [members, setMembers] = React.useState([{id:'001', label:'dummy'}]);

    React.useEffect(() => {
        async function getTeamInfo() {
            const result = await API.graphql(graphqlOperation(getTeamWithAccounts, {
                id: team_id
            }))
            // console.log(result.data.getTeam)
            if (mounted) {
                setName(result.data.getTeam.name)
                setDescription(result.data.getTeam.description)
                for (let i=0; i < result.data.getTeam.accounts.items.length; i++){
                    result.data.getTeam.accounts.items[i].label = result.data.getTeam.accounts.items[i].account.email
                    result.data.getTeam.accounts.items[i].value = result.data.getTeam.accounts.items[i].account.id
                    delete result.data.getTeam.accounts.items[i].account
                }
                setMembers(result.data.getTeam.accounts.items)
            }
        }

        let mounted = true
        getTeamInfo().then()
        return function cleanup() {
            mounted = false;
        };
    }, [team_id])


    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    }, []);

    let history = useHistory();


    const handleSubmit = async () => {
        // Delete link to members
        for (let i=0; i < members.length; i++){
            const DeleteAccountTeamInput = {
                id: members[i].id
            }
            await API.graphql({ query: deleteAccountTeam, variables: { input: DeleteAccountTeamInput } });
        }
        // Delete Team
        const TeamInput = {
            id: team_id
        }
        await API.graphql({ query: deleteTeam, variables: { input: TeamInput } });
        history.goBack()
    }

    const handleReset = async () => {
        history.goBack()
    }
    // console.log(members)
    return (
        <>
            <WhiteNavbar {...props}/>
            <MediaPageHeader  title={"Delete Team"} />
            <div className="section">
                <Flex justifyContent="center">
                    <View width={'75%'} >
                        {/*Personal information*/}
                        <View style={{paddingBottom:"2rem"}} direction={"column"} gap={"0.25rem"}>
                            <Heading level={"3"} style={{
                                borderBottomWidth:"1px",
                                borderBottomColor:"black",
                                borderBottomStyle:"solid"}}>Team information
                            </Heading>
                            <Flex style={{paddingTop:"1rem"}} direction={"row"} alignItems={"center"} gap={"0"}>
                                <Heading level={"6"} style={{paddingRight:"1rem"}}>Team</Heading>
                                <Text  style={{paddingRight:"6px"}}>{name}</Text>
                            </Flex>
                            <Flex style={{paddingTop:"1rem"}} direction={"row"} alignItems={"center"} gap={"0"}>
                                <Heading level={"6"} style={{paddingRight:"1rem"}}>Description</Heading>
                                <Text  style={{paddingRight:"6px"}}>{description}</Text>
                            </Flex>

                                    <br/>
                                    <Autocomplete
                                        multiple
                                        id="id_memberss"
                                        options={members}
                                        renderInput={params => (
                                            <TextField {...params} label="Members" />
                                        )}
                                        getOptionLabel={option => option.label}
                                        style={{ width: 270 }}
                                        value={members}
                                    />
                                    <br/>
                                    <Flex direction={"row"} justifyContent={"center"} >
                                        <Button
                                            className="reference-card-button"
                                            variation="primary"
                                            type={"reset"}
                                            onClick={()=> handleReset()}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="reference-card-button"
                                            variation="primary"
                                            type={"submit"}
                                            onClick={()=> handleSubmit()}
                                        >
                                            Delete
                                        </Button>
                                    </Flex>
                        </View>
                    </View>
                </Flex>
            </div>
            <FooterBlack {...props}/>
        </>
    );
}

export default DeleteTeam;
