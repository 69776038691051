import React from 'react';
import {Flex, View} from '@aws-amplify/ui-react';
import background from 'assets/img/synamedia/GettyImages-136591756-GRADED.jpg';


export default function SplashScreen(props) {
    let pageHeader = React.createRef();
    React.useEffect(() => {
        if (!props.signedIn) {
            props.onUserChanged()
        }
    }, [props]);

    React.useEffect(() => {
        let isSubscribed = true
        if (window.innerWidth > 991) {
            const updateScroll = () => {
                if (isSubscribed){
                    let windowScrollTop = window.pageYOffset / 3;
                    try {
                        pageHeader.current.style.transform =
                            "translate3d(0," + windowScrollTop + "px,0)";
                    } catch (e) {
                        console.log(e)
                    }
                }
            };
            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                isSubscribed = false
                window.removeEventListener("scroll", updateScroll);
            };
        }
        // cancel subscription to useEffect
        return () => {isSubscribed = false}
    });

    return (
        <>
            <div
                className="synamedia-page-header"
                ref={pageHeader}
                style={{
                    backgroundImage: `url(${background})`,
                }}
            >
                <div className="content-center">
                    <View>
                        <div className="motto">
                            <h1 className="title">Video Quality as a Service</h1>
                            <h3 className="description">
                                Loading your data
                            </h3>
                            <br/>
                            <Flex direction={"row"} justifyContent={"center"} alignItems={"center"}>
                                <div className="dot-elastic"/>
                            </Flex>
                        </div>
                    </View>
                </div>
            </div>
        </>
    )
}
