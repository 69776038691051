import React from "react";

import {Button, Flex, Table, TableBody, TableCell, TableHead, TableRow} from '@aws-amplify/ui-react';
import LinearProgressWithLabel  from '@mui/material/LinearProgress';
import {Storage} from "aws-amplify";
import {FaRegFile, FaRegFileAlt} from 'react-icons/fa'

function DistortedProcessing(props) {
    const [signedUrlExtractES, setSignedUrlExtractES] = React.useState(null)
    const [signedUrlDecode, setSignedUrlDecode] = React.useState(null)
    const [signedUrlExtractFeat, setSignedUrlExtractFeat] = React.useState(null)
    const [signedUrlCalcMetric, setSignedUrlCalcMetric] = React.useState(null)
    const [signedUrlJSON, setSignedUrlJSON] = React.useState(null)

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (props.disProc.progress_extract_es === 100) {
                const fileAccessURLReference = await Storage.get(
                    props.disProc.id + "/distorted.es.txt",
                    {
                        level: 'protected',
                        identityId: props.disProc.user_identity_id,
                        expires: 86400 });
                setSignedUrlExtractES(fileAccessURLReference);
            }
        }
        getAWSUrl()
    }, [props.disProc.id, props.disProc.progress_extract_es, props.disProc.user_identity_id])

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (props.disProc.progress_decode === 100) {
                const fileAccessURLReference = await Storage.get(
                    props.disProc.id + "/distorted.decode.txt",
                    {
                        level: 'protected',
                        identityId: props.disProc.user_identity_id,
                        expires: 86400 });
                setSignedUrlDecode(fileAccessURLReference);
            }
        }
        getAWSUrl()
    }, [props.disProc.id, props.disProc.progress_decode, props.disProc.user_identity_id])

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (props.disProc.progress_extract_feat === 100) {
                const fileAccessURLReference = await Storage.get(
                    props.disProc.id + "/distorted.extract_feature.txt",
                    {
                        level: 'protected',
                        identityId: props.disProc.user_identity_id,
                        expires: 86400 });
                setSignedUrlExtractFeat(fileAccessURLReference);
            }
        }
        getAWSUrl()
    }, [props.disProc.id, props.disProc.progress_extract_feat, props.disProc.user_identity_id])

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (props.disProc.progress_calc_metric === 100) {
                const fileAccessURLReference = await Storage.get(
                    props.disProc.id + "/vmaf.txt",
                    {
                        level: 'protected',
                        identityId: props.disProc.user_identity_id,
                        expires: 86400 });
                setSignedUrlCalcMetric(fileAccessURLReference);
                const fileAccessJSON = await Storage.get(
                    props.disProc.id + "/vmaf.json",
                    {
                        level: 'protected',
                        identityId: props.disProc.user_identity_id,
                        expires: 86400 });
                setSignedUrlJSON(fileAccessJSON);
            }
        }
        getAWSUrl()
    }, [props.disProc.id, props.disProc.progress_calc_metric, props.disProc.user_identity_id])

    return (
        <>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell as={"th"} className="synamedia-th">Process</TableCell>
                    <TableCell as={"th"} className="synamedia-th">Progress</TableCell>
                    <TableCell as={"th"}/>
                    <TableCell as={"th"} className="synamedia-th text-right">Log</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {props.disProc.progress_cross_copy ?
                    <TableRow>
                        <TableCell className="synamedia-td">Cross Account S3 copy</TableCell>
                        <TableCell className="synamedia-td"> {props.disProc.progress_cross_copy === 100 ? "Completed" :
                            <LinearProgressWithLabel
                                value={props.disProc.progress_cross_copy ? props.disProc.progress_cross_copy : 0}
                                />
                        }
                        </TableCell>
                        <TableCell className="synamedia-td">{props.disProc.progress_cross_copy ? props.disProc.progress_cross_copy + "%": ""}</TableCell>
                        <TableCell/>
                    </TableRow> : <></> }

                { props.disProc.progress_extract_es ?
                    <TableRow>
                    <TableCell className="synamedia-td">Extract Elementary stream</TableCell>
                    <TableCell className="synamedia-td">{props.disProc.progress_extract_es === 100 ? "Completed" :
                        <LinearProgressWithLabel
                            value={props.disProc.progress_extract_es ? props.disProc.progress_extract_es : 0}
                            />
                    }
                    </TableCell>
                    <TableCell className="synamedia-td">{props.disProc.progress_extract_es ? props.disProc.progress_extract_es + "%": ""}</TableCell>
                    <TableCell className="td-actions text-right">
                        {
                            (props.disProc.progress_extract_es === 100) ?
                                <a href={signedUrlExtractES} target="_blank" rel="noopener noreferrer">
                                    <Button
                                        className="yellow-chart-button"
                                        size="small"
                                        type="button"
                                    >
                                        <FaRegFile/>
                                    </Button>
                                </a>
                                :
                                ""
                        }
                    </TableCell>
                </TableRow> : <></> }
                {props.disProc.progress_decode ?
                    <TableRow>
                    <TableCell className="synamedia-td">Decode</TableCell>
                    <TableCell className="synamedia-td"> {props.disProc.progress_decode === 100 ? "Completed" :
                        <LinearProgressWithLabel
                        value={props.disProc.progress_decode ? props.disProc.progress_decode : 0}
                        />
                    }
                    </TableCell>
                    <TableCell className="synamedia-td">{props.disProc.progress_decode ? props.disProc.progress_decode + "%": ""}</TableCell>
                    <TableCell className="td-actions text-right">
                        {
                            (props.disProc.progress_decode === 100) ?

                                <a href={signedUrlDecode} target="_blank" rel="noopener noreferrer">
                                    <Button
                                        className="yellow-chart-button"
                                        size="small"
                                        type="button"
                                    >
                                        <FaRegFile/>
                                    </Button>
                                </a>
                                :
                                ""
                        }
                    </TableCell>
                </TableRow> : <></> }
                {props.disProc.progress_extract_feat ?
                    <TableRow>
                    <TableCell className="synamedia-td">Extract features</TableCell>
                    <TableCell className="synamedia-td"> {props.disProc.progress_extract_feat === 100 ? "Completed" :
                        <LinearProgressWithLabel
                            value={props.disProc.progress_extract_feat ? props.disProc.progress_extract_feat : 0}
                            />
                    }
                    </TableCell>
                    <TableCell className="synamedia-td">{props.disProc.progress_extract_feat ? props.disProc.progress_extract_feat + "%": ""}</TableCell>
                    <TableCell className="td-actions text-right">
                        {
                            (props.disProc.progress_extract_feat === 100) ?
                                <a href={signedUrlExtractFeat} target="_blank" rel="noopener noreferrer">
                                    <Button
                                        className="yellow-chart-button"
                                        size="small"
                                        type="button"
                                    >
                                        <FaRegFile/>
                                    </Button>
                                </a>
                                :
                                ""
                        }
                    </TableCell>
                </TableRow> : <></> }
                {props.disProc.progress_calc_metric ?
                    <TableRow>
                    <TableCell className="synamedia-td">Calculate Metrics & Create HLS stream</TableCell>
                    <TableCell className="synamedia-td"> {props.disProc.progress_calc_metric === 100 ? "Completed" :
                        <LinearProgressWithLabel
                            value={props.disProc.progress_calc_metric ? props.disProc.progress_calc_metric : 0}
                            />
                    }
                    </TableCell>
                    <TableCell className="synamedia-td">{props.disProc.progress_calc_metric ? props.disProc.progress_calc_metric + "%": ""}</TableCell>
                    <TableCell className="td-actions text-right">
                        {
                            (props.disProc.progress_calc_metric === 100) ?
                                <Flex direction={'row'} gap={"10px"}>
                                    <a href={signedUrlJSON} target="_blank" rel="noopener noreferrer">
                                        <Button
                                            className="yellow-chart-button"
                                            size="small"
                                            type="button"
                                        >
                                            <FaRegFileAlt/>
                                        </Button>
                                    </a>
                                    <a href={signedUrlCalcMetric} target="_blank" rel="noopener noreferrer">
                                        <Button
                                            className="yellow-chart-button"
                                            size="small"
                                            type="button"
                                        >
                                            <FaRegFile/>
                                        </Button>
                                    </a>
                                </Flex>
                                :
                                ""
                        }
                    </TableCell>
                </TableRow> : <></> }
                </TableBody>
            </Table>
        </>
    );
}

export default DistortedProcessing;
