import React from "react";
import {TableCell} from '@aws-amplify/ui-react';

function CreatedAtCell(props) {
  return (
      <>
        <TableCell className="synamedia-td">
          {
            props.distorted ?
                props.distorted.createdAt
                :
                ''
          }
        </TableCell>
      </>
  );
}

export default CreatedAtCell;
