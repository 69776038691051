import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from '@aws-amplify/ui-react';


// core components
import TeamTableEntry from "../../components/vqaas/TeamTableEntry";

function TeamTable(props) {
    // console.log(props)

    const tableEntries = props.teams.map(userData =>
        <TeamTableEntry
            key={userData.id}
            userData={userData} />)

    return (
        <>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell as={"th"}>#</TableCell>
                    <TableCell as={"th"} className="synamedia-th">Name</TableCell>
                    <TableCell as={"th"} className="synamedia-th">Description</TableCell>
                    <TableCell as={"th"} className="synamedia-th"><div className="text-right">Actions</div></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {tableEntries}
                </TableBody>
            </Table>
        </>
    );
}

export default TeamTable;
