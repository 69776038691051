import React, {useState} from "react";

import {Button, Expander, ExpanderItem, Flex, Heading, Text, View} from '@aws-amplify/ui-react';
import FooterBlack from "components/Footers/FooterBlack.js";
import {API, graphqlOperation, Storage} from "aws-amplify";
import {useHistory, useParams} from "react-router-dom";
import DistortedProcessing from "../../components/vqaas/DistortedProcessing";
import VqaasHCCorrelationChart from "../sections-sections/VqaasHCCorrelationChart";
import VqaasHCMetricChart from "../sections-sections/VqaasHCMetricChart";
import {ReactCompareSlider} from "react-compare-slider";
import ReactPlayer from "react-player";
import FeatureExtraction from "../../components/vqaas/FeatureExtraction";
import WhiteNavbar from "../../components/Navbars/WhiteNavbar";
import MediaPageHeader from "../../components/Headers/MediaPageHeader";
import {getEncodeAnalysis} from "../../graphql/queries";


function EncodeAnalysisPage(props) {
    const {staticContext, onSignOutClick, onRefresh, signOut, adminVisible, encodeAnalyses, ...restOfProps} = props
    const {encode_analysis_id} = useParams()
    const [encodeAnalysis, setEncodeAnalysis] = React.useState({refMedia:{}, encMedia:{}, ana_fps_nominator:1, ana_fps_denominator:1})
    const [refMedia, setRefMedia] = React.useState({})
    const [encMedia, setEncMedia] = React.useState({})
    const playerReference = React.useRef()
    const playerDistorted = React.useRef()
    const [signedUrlReference, setSignedUrlReference] = React.useState(null)
    const [signedUrlDistorted, setSignedUrlDistorted] = React.useState(null)
    const [referenceStatus, setReferenceStatus] = useState('Loading')
    const [distortedStatus, setDistortedStatus] = useState('Loading')

    // console.log(encodeAnalyses)
    React.useEffect(() => {
        async function getMyEncodeAnalysis(encode_analysis_id) {
            const result = await API.graphql(graphqlOperation(getEncodeAnalysis, {
                id: encode_analysis_id
            }))
            // console.log(result)
            if (mounted) setEncodeAnalysis(result.data['getEncodeAnalysis'])
        }

        let mounted = true

        if (encode_analysis_id){
            getMyEncodeAnalysis(encode_analysis_id).then()
        }
        return function cleanup() {
            mounted = false;
        };

    }, [encode_analysis_id, encodeAnalyses])

    React.useEffect(() => {
        if (encodeAnalysis){
            setRefMedia(encodeAnalysis['refMedia'])
            setEncMedia(encodeAnalysis['encMedia'])
        }
    }, [encodeAnalysis])

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    });

    React.useEffect(()=> {
        async function getAWSUrl() {
            if (encodeAnalysis){
                if (encodeAnalysis.progress_calc_metric === 100) {
                    // console.log('UseEffect SignedUrlReference')
                    const fileAccessURLReference = await Storage.get(
                        encode_analysis_id + "/360_reference_out_signed.m3u8",
                        {
                            identityId: encodeAnalysis.user_identity_id,
                            level: 'protected',
                            expires: 86400 });
                    // console.log(analysis)
                    const fileAccessURLDistorted = await Storage.get(
                        encode_analysis_id + "/360_distorted_out_signed.m3u8",
                        {
                            identityId: encodeAnalysis.user_identity_id,
                            level: 'protected',
                            expires: 86400 });
                    setSignedUrlReference(fileAccessURLReference);
                    setSignedUrlDistorted(fileAccessURLDistorted);
                }
            }
        }
        getAWSUrl().then()
    }, [encodeAnalysis, encode_analysis_id])

    const handleSeekTimeChange = React.useCallback((e) => {
        // console.log(e, encodeAnalysis.ana_fps_denominator, encodeAnalysis.ana_fps_nominator)
        if (signedUrlDistorted && signedUrlReference && encodeAnalysis){
            // console.log('handleSeekTimeChange')
            try {
                playerReference.current.seekTo(parseFloat(e), 'seconds')
                setReferenceStatus('Seeking')
                playerDistorted.current.seekTo(parseFloat(e), 'seconds')
                setDistortedStatus('Seeking')
            } catch {}
        }
    }, [encodeAnalysis, signedUrlDistorted, signedUrlReference])

    let history = useHistory();

    return (
        <>
            <WhiteNavbar {...props}/>
            <MediaPageHeader
                title={"Encode Analysis"}
                subTitle={refMedia.filename ? refMedia.filename : '' }
                subTitle2={encMedia.filename ? encMedia.filename : '' }
            />
            <div className="section">
                <Flex justifyContent="center" style={{fontFamily: "SourceSansPro"}}>
                    <View width={{base:'95%', xl:'95%'}}>
                        {/*Import files*/}
                        <View style={{paddingBottom:"2rem"}}>
                            <Heading level={"3"} style={{
                                borderBottomWidth:"1px",
                                borderBottomColor:"black",
                                borderBottomStyle:"solid"}}>Encode Analysis</Heading>

                            { encodeAnalysis && props.account ?
                                <View>
                                    <Expander type="single" defaultValue="item-6" isCollapsible={true} {...restOfProps}>
                                        <ExpanderItem title="Processing" value="item-3">
                                            <DistortedProcessing disProc={encodeAnalysis}/>
                                        </ExpanderItem>
                                        <ExpanderItem title="Alignment" value="item-4">
                                            <FeatureExtraction
                                                reference_id = {refMedia['id']}
                                                distorted_id = {encMedia['id']}
                                                ref_extract_feature_progress = {encodeAnalysis.progress_extract_feat}
                                                dis_extract_feature_progress = {encodeAnalysis.progress_extract_feat}
                                            />
                                            <VqaasHCCorrelationChart
                                                analysis_id = {encMedia['id']}
                                                ref_extract_feature_progress = {encodeAnalysis.progress_extract_feat}
                                                dis_extract_feature_progress = {encodeAnalysis.progress_extract_feat}
                                            />
                                            {
                                                (encodeAnalysis && encodeAnalysis.align_offset != null) ?
                                                    <p>{"Align offset: " + encodeAnalysis.align_offset + " frames"}</p>
                                                    :
                                                    <br/>
                                            }
                                        </ExpanderItem>
                                        <ExpanderItem title="Video Quality Metrics" value="item-6">
                                            {encodeAnalysis.progress_create_hls === 100 ?
                                                <>
                                                    <VqaasHCMetricChart
                                                        analysis_id={encode_analysis_id}
                                                        progress_calc_metric={encodeAnalysis.progress_calc_metric}
                                                        ana_fps_denominator={encodeAnalysis.ana_fps_denominator}
                                                        ana_fps_nominator={encodeAnalysis.ana_fps_nominator}
                                                        onSeekTimeChange={handleSeekTimeChange}
                                                    />
                                                    <Flex direction={"row"} justifyContent={"center"}>
                                                        <Flex direction={"row"} justifyContent={"flex-end"}>
                                                            <Text as={"p"}>Reference:{" " + referenceStatus}</Text>
                                                        </Flex>
                                                        <ReactCompareSlider
                                                            itemOne={
                                                                <ReactPlayer
                                                                    width='100%'
                                                                    height='100%'
                                                                    url={signedUrlReference}
                                                                    className="react-player"
                                                                    ref={playerReference}
                                                                    pip={false}
                                                                    playing={false}
                                                                    controls={false}
                                                                    light={false}
                                                                    loop={false}
                                                                    playbackRate={1.0}
                                                                    onSeek={() => {
                                                                        setReferenceStatus('Ready')
                                                                    }}
                                                                />
                                                            }
                                                            itemTwo={
                                                                <ReactPlayer
                                                                    width='100%'
                                                                    height='100%'
                                                                    url={signedUrlDistorted}
                                                                    className="react-player"
                                                                    ref={playerDistorted}
                                                                    pip={false}
                                                                    playing={false}
                                                                    controls={false}
                                                                    light={false}
                                                                    loop={false}
                                                                    playbackRate={1.0}
                                                                    onSeek={() => {
                                                                        setDistortedStatus('Ready')
                                                                    }}
                                                                />
                                                            }
                                                            style={{width: '1920px', height: '1080px'}}
                                                        />
                                                        <Text as={"p"}>Distorted:{" " + distortedStatus}</Text>
                                                    </Flex>
                                                </>
                                                :
                                                <></>
                                            }
                                        </ExpanderItem>
                                    </Expander>
                                    <br/>
                                    <Button
                                        onClick={() => {
                                            onRefresh()
                                            history.goBack()
                                        }}
                                        className="reference-card-button"
                                    >Back</Button>
                                </View>
                                : <></>
                            }
                        </View>
                    </View>
                </Flex>
            </div>
            <FooterBlack {...props}/>
        </>
    );
}

export default EncodeAnalysisPage;
