import React, {useState} from "react";
import {Button, Expander, ExpanderItem, Flex, Text, View} from '@aws-amplify/ui-react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

// core components
import FooterBlack from "components/Footers/FooterBlack.js";
import {API, graphqlOperation, Storage} from "aws-amplify";
import {getEncodeAnalysis, getPSNRavg, getVMAF} from "../../graphql/queries";
import {useHistory, useParams} from "react-router-dom";
import CumulativeDistributionChart from "../../components/vqaas/CumulativeDistributionChart";
import MetricChart from "../../components/vqaas/MetricChart";
import {ReactCompareSlider} from "react-compare-slider";
import ReactPlayer from "react-player";
import WhiteNavbar from "../../components/Navbars/WhiteNavbar";
import MediaPageHeader from "../../components/Headers/MediaPageHeader";
import CompareAnalysisSpecs from "../../components/vqaas/CompareAnalysisSpecs";


function CompareAnalysisPage(props) {
    const {encode_analysis_id_1, encode_analysis_id_2, encode_analysis_id_3, encode_analysis_id_4} = useParams()
    const {staticContext, onSignOutClick, signOut, adminVisible, encodeAnalyses, onRefresh, ...restOfProps} = props
    const [encode_analysis1, setEncode_analysis1] = useState();
    const [encode_analysis2, setEncode_analysis2] = useState();
    const [encode_analysis3, setEncode_analysis3] = useState();
    const [encode_analysis4, setEncode_analysis4] = useState();
    const [selectionList, setSelectionList] = useState([]);
    const [xExtremes, setXExtremes] = useState({min: null, max: null})
    const [encode_analysis1Url, setEncode_analysis1Url] = useState();
    const [encode_analysis2Url, setEncode_analysis2Url] = useState();
    const [encode_analysis3Url, setEncode_analysis3Url] = useState();
    const [encode_analysis4Url, setEncode_analysis4Url] = useState();
    const [channelAStatus, setChannelAStatus] = useState('Loading')
    const [channelBStatus, setChannelBStatus] = useState('Loading')
    const [channelADuration, setChannelADuration] = useState()
    const [channelBDuration, setChannelBDuration] = useState()
    const [channelA, setChannelA] = React.useState({id: '0', label: ''});
    const [channelB, setChannelB] = React.useState({id: '0', label: ''});
    const [metric, setMetric] = React.useState('VMAF')
    const [dataAminB, setDataAminB] = React.useState()
    const playerChannelA = React.useRef()
    const playerChannelB = React.useRef()
    const [signedUrlChannelA, setSignedUrlChannelA] = React.useState(null)
    const [signedUrlChannelB, setSignedUrlChannelB] = React.useState(null)
    const [compareStatus, setCompareStatus] = React.useState('Initial')
    const [frameCursorTime, setFrameCursorTime] = React.useState(0)
    const [frameCursorTimeA, setFrameCursorTimeA] = React.useState(0)
    const [frameCursorTimeB, setFrameCursorTimeB] = React.useState(0)
    const [playedSecondsA, setPlayedSecondsA] = React.useState(0)
    const [playedSecondsB, setPlayedSecondsB] = React.useState(0)
    const [maxFramerate, setMaxFramerate] = React.useState(1)

    // console.log(maxFramerate)
    // if (encode_analysis2) {
    //     console.log(compareStatus, encode_analysis2.vmaf, encode_analysis2.scaledVmaf)
    // }
    // console.log(channelA)
    // console.log('frameCursorTime', frameCursorTime)
    // console.log('frameCursorTime A', frameCursorTimeA)
    // console.log('frameCursorTime B', frameCursorTimeB)
    // console.log('playedSecondsA', playedSecondsA)
    // console.log('playedSecondsB', playedSecondsB)
    // console.log('channelADuration', channelADuration)
    // console.log('channelBDuration', channelBDuration)
    // console.log('channelA_spec_frame_rate', channelA.spec_frame_rate)
    // console.log('channelB_spec_frame_rate', channelB.spec_frame_rate)

    let history = useHistory();
    React.useEffect(() => {
        // console.log('compareStatus: ', compareStatus)
        if (compareStatus === 'Initial'){
            setCompareStatus ('Load data')
        }
        if (compareStatus === 'Load data' && channelA && channelB){
            setCompareStatus ('Load URL')
        }
        if (compareStatus === 'Load URL' && encode_analysis1Url && encode_analysis2Url){
            setCompareStatus ('Load signedUrlChannel')
        }
        if (compareStatus === 'Load signedUrlChannel' && signedUrlChannelA && signedUrlChannelB){
            setCompareStatus ('Data Loaded')
        }
        if (compareStatus === 'Data Loaded' && channelADuration && channelBDuration){
            setCompareStatus ('Active')
        }
    },[compareStatus, dataAminB, channelA, channelB, encode_analysis1Url, encode_analysis2Url, channelADuration,
        channelBDuration, signedUrlChannelA, signedUrlChannelB])

    async function getFullEncodeAnalysis(encode_analysis_id) {
        const result = await API.graphql(graphqlOperation(getEncodeAnalysis, {
            id: encode_analysis_id
        }))
        let tmpEA = result.data['getEncodeAnalysis']
        const graphql_getVmaf_response = await API.graphql(
            graphqlOperation(
                getVMAF,{
                    id: tmpEA.id,
                    sequence: 0
                })
        )
        if (graphql_getVmaf_response.data.getVMAF){
            let data_vmaf
            if (tmpEA.align_offset > 0){
                data_vmaf = new Array(tmpEA.align_offset).fill(null)
            } else {
                data_vmaf = []
            }
            tmpEA.vmaf = data_vmaf.concat(graphql_getVmaf_response.data.getVMAF.values)
            tmpEA.scaledVmaf = tmpEA.vmaf
        }
        const graphql_getPSNRavg_response = await API.graphql(
            graphqlOperation(
                getPSNRavg,
                {
                    'id': tmpEA.id,
                    'sequence': 0
                })
        )
        if (graphql_getPSNRavg_response.data.getPSNRavg) {
            let data_psnr
            if (tmpEA.align_offset > 0){
                data_psnr = new Array(tmpEA.align_offset).fill(null)
            } else {
                data_psnr = []
            }
            tmpEA.psnr = data_psnr.concat(graphql_getPSNRavg_response.data.getPSNRavg.values)
            tmpEA.scaledPsnr = tmpEA.psnr
        }
        return tmpEA
    }

    async function loadEncode_analysisUrl(encode_analysis) {
        // console.log('UseEffect loadEncode_analysis1Url')
        const encode_analysis_url = await Storage.get(
            encode_analysis.id + "/360_distorted_out_signed.m3u8",
            {
                level: 'protected',
                identityId: encode_analysis.user_identity_id,
                expires: 86400 });
        return encode_analysis_url
    }


    React.useEffect(() => {
        let mounted = true
        if (encode_analysis_id_1) {
            getFullEncodeAnalysis(encode_analysis_id_1).then(
                data => {
                    if (mounted) {
                        setMaxFramerate(prevMax => {
                            if (parseFloat(data.encMedia.spec_frame_rate) > prevMax)
                            {
                                return parseFloat(data.encMedia.spec_frame_rate)
                            } else
                            {
                                return prevMax
                            }
                        })
                        setEncode_analysis1(data)
                        setChannelA({
                            value:data.id,
                            label:data.encMedia.filename,
                            identityId:data.user_identity_id,
                            align_offset:data.align_offset,
                            spec_frame_rate:data.encMedia.spec_frame_rate
                        })
                        loadEncode_analysisUrl(data).then(dataUrl => {if (mounted) {
                            setEncode_analysis1Url(dataUrl)
                            setSignedUrlChannelA(dataUrl)
                        }})
                    }
                }
            )
        }
        return function cleanup() {
            mounted = false;
        };
    }, [encode_analysis_id_1])

    React.useEffect(() => {
        let mounted = true
        if (encode_analysis_id_2) {
            getFullEncodeAnalysis(encode_analysis_id_2).then(
                data => {
                    if (mounted) {
                        setMaxFramerate(prevMax => {
                            if (parseFloat(data.encMedia.spec_frame_rate) > prevMax)
                            {
                                return parseFloat(data.encMedia.spec_frame_rate)
                            } else
                            {
                                return prevMax
                            }
                        })
                        setEncode_analysis2(data)
                        setChannelB({
                            value:data.id,
                            label:data.encMedia.filename,
                            identityId:data.user_identity_id,
                            align_offset:data.align_offset,
                            spec_frame_rate:data.encMedia.spec_frame_rate
                        })
                        loadEncode_analysisUrl(data).then(dataUrl => {if (mounted) {
                            setEncode_analysis2Url(dataUrl)
                            setSignedUrlChannelB(dataUrl)
                        }})
                    }}
            )
        }
        return function cleanup() {
            mounted = false;
        };
    }, [encode_analysis_id_2])

    React.useEffect(() => {
        let mounted = true
        if (encode_analysis_id_3) {
            getFullEncodeAnalysis(encode_analysis_id_3).then(
                data => {
                    if (mounted) {
                        setMaxFramerate(prevMax => {
                            if (parseFloat(data.encMedia.spec_frame_rate) > prevMax)
                            {
                                return parseFloat(data.encMedia.spec_frame_rate)
                            } else
                            {
                                return prevMax
                            }
                        })
                        setEncode_analysis3(data)
                        loadEncode_analysisUrl(data).then(dataUrl => {if (mounted) setEncode_analysis3Url(dataUrl)})
                    }}
            )
        }
        return function cleanup() {
            mounted = false;
        };
    }, [encode_analysis_id_3])

    React.useEffect(() => {
        let mounted = true
        if (encode_analysis_id_4) {
            getFullEncodeAnalysis(encode_analysis_id_4).then(
                data => {
                    if (mounted) {
                        setMaxFramerate(prevMax => {
                            if (parseFloat(data.encMedia.spec_frame_rate) > prevMax)
                            {
                                return parseFloat(data.encMedia.spec_frame_rate)
                            } else
                            {
                                return prevMax
                            }
                        })
                        setEncode_analysis4(data)
                        loadEncode_analysisUrl(data).then(dataUrl => {if (mounted) setEncode_analysis4Url(dataUrl)})
                    }}
            )
        }
        return function cleanup() {
            mounted = false;
        };
    }, [encode_analysis_id_4])


    React.useEffect(() => {
        if (maxFramerate > 1){
            setEncode_analysis1((prevData) => {
                if (prevData) {
                    if (prevData.encMedia) {
                        let newVmaf = []
                        let newPsnr = []
                        if (maxFramerate > parseFloat(prevData.encMedia.spec_frame_rate)){
                            for (let i = 0; i < prevData['vmaf'].length; i++) {
                                newVmaf.push(prevData['vmaf'][i])
                                newVmaf.push(prevData['vmaf'][i])
                                newPsnr.push(prevData['psnr'][i])
                                newPsnr.push(prevData['psnr'][i])
                            }
                            return {...prevData, 'scaledVmaf': newVmaf, 'scaledPsnr': newPsnr}
                        } else {
                            return {...prevData, 'scaledVmaf': prevData['vmaf'], 'scaledPsnr': prevData['psnr']}
                        }
                    }
                }
            })
        }
    }, [maxFramerate])

    React.useEffect(() => {
        if (maxFramerate > 1){
            setEncode_analysis2((prevData) => {
                if (prevData) {
                    if (prevData.encMedia) {
                        let newVmaf = []
                        let newPsnr = []
                        if (maxFramerate > parseFloat(prevData.encMedia.spec_frame_rate)){
                            for (let i = 0; i < prevData['vmaf'].length; i++) {
                                newVmaf.push(prevData['vmaf'][i])
                                newVmaf.push(prevData['vmaf'][i])
                                newPsnr.push(prevData['psnr'][i])
                                newPsnr.push(prevData['psnr'][i])
                            }
                            return {...prevData, 'scaledVmaf': newVmaf, 'scaledPsnr': newPsnr}
                        } else {
                            return {...prevData, 'scaledVmaf': prevData['vmaf'], 'scaledPsnr': prevData['psnr']}
                        }
                    }
                }
            })
        }
    }, [maxFramerate, compareStatus])


    React.useEffect(() => {
        if (maxFramerate > 1){
            setEncode_analysis3((prevData) => {
                if (prevData) {
                    if (prevData.encMedia) {
                        let newVmaf = []
                        let newPsnr = []
                        if (maxFramerate > parseFloat(prevData.encMedia.spec_frame_rate)){
                            for (let i = 0; i < prevData['vmaf'].length; i++) {
                                newVmaf.push(prevData['vmaf'][i])
                                newVmaf.push(prevData['vmaf'][i])
                                newPsnr.push(prevData['psnr'][i])
                                newPsnr.push(prevData['psnr'][i])
                            }
                            return {...prevData, 'scaledVmaf': newVmaf, 'scaledPsnr': newPsnr}
                        } else {
                            return {...prevData, 'scaledVmaf': prevData['vmaf'], 'scaledPsnr': prevData['psnr']}
                        }
                    }
                }
            })
        }
    }, [maxFramerate])

    React.useEffect(() => {
        if (maxFramerate > 1){
            setEncode_analysis4((prevData) => {
                if (prevData) {
                    if (prevData.encMedia) {
                        let newVmaf = []
                        let newPsnr = []
                        if (maxFramerate > parseFloat(prevData.encMedia.spec_frame_rate)){
                            for (let i = 0; i < prevData['vmaf'].length; i++) {
                                newVmaf.push(prevData['vmaf'][i])
                                newVmaf.push(prevData['vmaf'][i])
                                newPsnr.push(prevData['psnr'][i])
                                newPsnr.push(prevData['psnr'][i])
                            }
                            return {...prevData, 'scaledVmaf': newVmaf, 'scaledPsnr': newPsnr}
                        } else {
                            return {...prevData, 'scaledVmaf': prevData['vmaf'], 'scaledPsnr': prevData['psnr']}
                        }
                    }
                }
            })
        }
    }, [maxFramerate])

    React.useEffect(() => {
        let mounted = true
        let tmpSelectionList = []
        if ((compareStatus === 'Load URL') || (compareStatus === 'Load signedUrlChannel') || (compareStatus === 'Data Loaded')) {
            if (encode_analysis1) {
                const selection = {
                    value: encode_analysis1.id,
                    label: encode_analysis1.encMedia.filename,
                    identityId: encode_analysis1.user_identity_id,
                    align_offset: encode_analysis1.align_offset,
                    spec_frame_rate: encode_analysis1.encMedia.spec_frame_rate
                }
                tmpSelectionList = [...tmpSelectionList, selection]
            }
            if (encode_analysis2) {
                const selection = {
                    value: encode_analysis2.id,
                    label: encode_analysis2.encMedia.filename,
                    identityId: encode_analysis2.user_identity_id,
                    align_offset: encode_analysis2.align_offset,
                    spec_frame_rate: encode_analysis2.encMedia.spec_frame_rate
                }
                tmpSelectionList = [...tmpSelectionList, selection]
            }
            if (encode_analysis3) {
                const selection = {
                    value: encode_analysis3.id,
                    label: encode_analysis3.encMedia.filename,
                    identityId: encode_analysis3.user_identity_id,
                    align_offset: encode_analysis3.align_offset,
                    spec_frame_rate: encode_analysis3.encMedia.spec_frame_rate
                }
                tmpSelectionList = [...tmpSelectionList, selection]
            }
            if (encode_analysis4) {
                const selection = {
                    value: encode_analysis4.id,
                    label: encode_analysis4.encMedia.filename,
                    identityId: encode_analysis4.user_identity_id,
                    align_offset: encode_analysis4.align_offset,
                    spec_frame_rate: encode_analysis4.encMedia.spec_frame_rate
                }
                tmpSelectionList = [...tmpSelectionList, selection]
            }
            if (mounted) setSelectionList(tmpSelectionList)
        }
        return function cleanup() {
            mounted = false;
        };
    }, [compareStatus, encode_analysis1, encode_analysis2, encode_analysis3, encode_analysis4])

    React.useEffect(() => {
        if (compareStatus === 'Active') {
            playerChannelA.current.seekTo(frameCursorTimeA, 'seconds')
        }
    }, [compareStatus, frameCursorTimeA, playedSecondsA])

    React.useEffect(() => {
        if (compareStatus === 'Active') {
            playerChannelB.current.seekTo(frameCursorTimeB, 'seconds')
        }
    }, [compareStatus, frameCursorTimeB, playedSecondsB])

    const compensateOffset = React.useCallback( (cursor, channelOffset, framerate, duration) => {
        let tmp
        let compensate
        if (channelOffset > 0){
            tmp = cursor - (channelOffset / parseFloat(framerate))
        } else {
            tmp = cursor
        }
        if (tmp >= 0 && tmp < duration) {
            compensate = tmp
        } else {
            compensate = duration
        }
        return compensate
    }, [])

    React.useEffect(()=> {
        let timer
        if (channelA && channelADuration){
            setFrameCursorTimeA(compensateOffset(frameCursorTime, channelA.align_offset, channelA.spec_frame_rate, channelADuration))
            // timer = setTimeout(() => {
            //     setFrameCursorTimeA(compensateOffset(frameCursorTime, channelA.align_offset, encode_analysis1.encMedia.spec_frame_rate, channelADuration))
            // }, 250)
        }
        return function cleanup() {
            clearTimeout(timer)
        };
    }, [frameCursorTime, channelA, channelADuration,compensateOffset])

    React.useEffect(()=> {
        let timer
        if (channelB && channelBDuration){
            setFrameCursorTimeB(compensateOffset(frameCursorTime, channelB.align_offset, channelB.spec_frame_rate, channelBDuration))
            // timer = setTimeout(() => {
            //     setFrameCursorTimeB(compensateOffset(frameCursorTime, channelB.align_offset, encode_analysis1.encMedia.spec_frame_rate, channelBDuration))
            // }, 250)
        }
        return function cleanup() {
            clearTimeout(timer)
        };
    }, [frameCursorTime, channelB, channelBDuration, compensateOffset])

    React.useEffect(() => {
        let mounted = true

        if ((compareStatus === 'Data Loaded' || compareStatus === 'Active') && mounted){
            let A = {...encode_analysis1}
            let B = {...encode_analysis2}
            if (channelA.value === encode_analysis1.id) {
                A = {...encode_analysis1}
                setSignedUrlChannelA(encode_analysis1Url)
            }
            if (channelA.value === encode_analysis2.id) {
                A = {...encode_analysis2}
                setSignedUrlChannelA(encode_analysis2Url)
            }
            if (encode_analysis3 && encode_analysis3Url){
                if (channelA.value === encode_analysis3.id) {
                    A = {...encode_analysis3}
                    setSignedUrlChannelA(encode_analysis3Url)
                }
                if (channelB.value === encode_analysis3.id) {
                    B = {...encode_analysis3}
                }
            }
            if (encode_analysis4 && encode_analysis4Url){
                if (channelA.value === encode_analysis4.id) {
                    A = {...encode_analysis4}
                    setSignedUrlChannelA(encode_analysis4Url)
                }
                if (channelB.value === encode_analysis4.id) {
                    B = {...encode_analysis4}
                    setSignedUrlChannelB(encode_analysis4Url)
                }
            }
            if (channelB.value === encode_analysis1.id) {
                B = {...encode_analysis1}
                setSignedUrlChannelB(encode_analysis1Url)}
            if (channelB.value === encode_analysis2.id) {
                B = {...encode_analysis2}
                setSignedUrlChannelB(encode_analysis2Url)}
            setDataAminB(
                {
                    vmaf: A.scaledVmaf.map(function(item, index) {
                        if (item === null || B.scaledVmaf[index] === null) {
                            return null
                        } else {return item - B.scaledVmaf[index]}
                    }),
                    psnr: A.scaledPsnr.map(function(item, index) {
                        if (item === null || B.scaledPsnr[index] === null) {
                            return null
                        } else { return item - B.scaledPsnr[index]}})
                })
        }
        return function cleanup() {
            mounted = false;
        };
    }, [channelA, channelB, encode_analysis1, encode_analysis1Url, encode_analysis2, encode_analysis2Url, encode_analysis3, encode_analysis3Url, encode_analysis4, encode_analysis4Url, compareStatus])


    const handleXtremesChange = React.useCallback((e) => {
        // console.log('handleXtremesChange')
        setXExtremes(e)
    }, [])


    return (
        <>
            <WhiteNavbar {...props}/>
            <MediaPageHeader title={"Compare"} />
            <React.StrictMode>
                <div className="section">
                    <Flex justifyContent="center">
                        <View width={'95%'} style={{fontFamily: "SourceSansPro"}}>
                            <Expander
                                type="single"
                                isCollapsible={true}
                                defaultValue={"item-3"}
                                {...restOfProps}
                            >
                                <ExpanderItem title="Summary" value="item-1" style={{fontSize: "18px"}}>
                                    <CompareAnalysisSpecs
                                        encode_analysis1 = {encode_analysis1}
                                        encode_analysis2 = {encode_analysis2}
                                        encode_analysis3 = {encode_analysis3}
                                        encode_analysis4 = {encode_analysis4}
                                    />
                                </ExpanderItem>
                                <ExpanderItem title="Distribution Curves" value="item-2" style={{fontSize: "18px"}}>
                                    <CumulativeDistributionChart
                                        collapse={true}
                                        metric = "VMAF"
                                        xExtremes = {xExtremes}
                                        distorted1 = {encode_analysis1}
                                        distorted2 = {encode_analysis2}
                                        distorted3 = {encode_analysis3}
                                        distorted4 = {encode_analysis4}
                                    />
                                    <br/>
                                    <CumulativeDistributionChart
                                        collapse={true}
                                        metric = "PSNR"
                                        xExtremes = {xExtremes}
                                        distorted1 = {encode_analysis1}
                                        distorted2 = {encode_analysis2}
                                        distorted3 = {encode_analysis3}
                                        distorted4 = {encode_analysis4}
                                    />
                                </ExpanderItem>
                                <ExpanderItem title="Metrics" value="item-3" style={{fontSize: "18px"}}>
                                    { compareStatus === 'Active' ?
                                        <FormControl sx={{ m: 1, width: 1000 }}>
                                            <InputLabel id="select-chanA-label">Channel A</InputLabel>
                                            <Select
                                                labelId="select-chanA-label"
                                                label={"Channel A"}
                                                value={channelA.label}
                                                onChange={(e) => {
                                                    setChannelA(selectionList.find(item => item.label === e.target.value))
                                                }}
                                            >
                                                {selectionList.map((selection) => (
                                                    <MenuItem key={selection.value} value={selection.label} name={selection.value}>
                                                        {selection.label}
                                                    </MenuItem>
                                                ))}
                                                <MenuItem key={"0"} value={""}></MenuItem>
                                            </Select>
                                        </FormControl> : <></>}
                                    <br/>
                                    { compareStatus === 'Active' ?
                                        <FormControl sx={{ m: 1, width: 1000 }}>
                                            <InputLabel id="select-chanB-label">Channel B</InputLabel>
                                            <Select
                                                labelId="select-chanB-label"
                                                label={"Channel B"}
                                                value={channelB.label}
                                                onChange={(e) => {
                                                    setChannelB(selectionList.find(item => item.label === e.target.value))}}
                                            >
                                                {selectionList.map((selection) => (
                                                    <MenuItem key={selection.value} value={selection.label}>
                                                        {selection.label}
                                                    </MenuItem>
                                                ))}
                                                <MenuItem key={"0"} value={""}></MenuItem>
                                            </Select>
                                        </FormControl> : <></>}
                                    <br/>
                                    { compareStatus === 'Active' ?
                                        <FormControl sx={{ m: 1, width: 300 }}>
                                            <InputLabel id="select-metric-label">Metric</InputLabel>
                                            <Select
                                                labelId="select-metric-label"
                                                label={"Metric"}
                                                value={metric}
                                                onChange={(e) => setMetric(e.target.value)}
                                            >
                                                <MenuItem
                                                    key='VMAF'
                                                    value={'VMAF'}
                                                >
                                                    VMAF
                                                </MenuItem>
                                                <MenuItem
                                                    key='PSNR'
                                                    value={'PSNR'}
                                                >
                                                    PSNR
                                                </MenuItem>
                                            </Select>
                                        </FormControl> : <></> }
                                    { compareStatus === 'Active' ? <MetricChart
                                        metric={metric}
                                        distorted1={encode_analysis1}
                                        distorted2={encode_analysis2}
                                        distorted3={encode_analysis3}
                                        distorted4={encode_analysis4}
                                        dataAminB={dataAminB}
                                        onSeekTimeChange={(e) => {
                                            setFrameCursorTime(parseFloat(e))
                                            // console.log('FrameCursorTime', parseFloat(e))
                                        }}
                                        onXtremesChange={handleXtremesChange}
                                        channelAStatus={channelAStatus}
                                        channelBStatus={channelBStatus}
                                    /> : <></>}
                                    {signedUrlChannelA && signedUrlChannelB &&
                                        <Flex direction={"row"} justifyContent={"center"}>
                                            <Text as={"p"}>Channel A:{" " + channelAStatus}</Text>
                                            <View width={'1500px'}/>
                                            <Text as={"p"}>Channel B:{" " + channelBStatus}</Text>
                                        </Flex>
                                    }
                                    { signedUrlChannelA && signedUrlChannelB &&
                                        <View>
                                            <Flex direction={"row"} justifyContent={"center"}>

                                                <ReactCompareSlider
                                                    itemOne={
                                                        <ReactPlayer
                                                            width='100%'
                                                            height='100%'
                                                            url={signedUrlChannelA}
                                                            className="react-player"
                                                            ref={playerChannelA}
                                                            pip={false}
                                                            playing={false}
                                                            controls={false}
                                                            light={false}
                                                            loop={false}
                                                            playbackRate={1.0}
                                                            onReady={() => {
                                                                // console.log('onReady A')
                                                                setChannelAStatus('Loaded')}
                                                            }
                                                            onSeek={() => {
                                                                // console.log('onSeek A')
                                                                setChannelAStatus('Ready')}
                                                            }
                                                            onProgress={(data) => {
                                                                // console.log('onProgress A',)
                                                                setPlayedSecondsA(data.playedSeconds)
                                                                setChannelAStatus('Seeking')}}
                                                            onStart={() => {}}
                                                            onPlay={() => {}}
                                                            onEnablePIP={() => {}}
                                                            onDisablePIP={() => {}}
                                                            onPause={() => {}}
                                                            onBuffer={() => {console.log('Buffer')}}
                                                            onBufferEnd={() => {console.log('onBufferEnd')}}
                                                            onEnded={() => {}}
                                                            onError={(error, data, hlsInstance) => {console.log('Error A: ', error, data, hlsInstance)}}
                                                            onDuration={(duration) => {
                                                                // console.log('ChannelADuration: ', duration)
                                                                setChannelADuration(duration)}}
                                                        />
                                                    }
                                                    itemTwo={
                                                        <ReactPlayer
                                                            width='100%'
                                                            height='100%'
                                                            url={signedUrlChannelB}
                                                            className="react-player"
                                                            ref={playerChannelB}
                                                            pip={false}
                                                            playing={false}
                                                            controls={false}
                                                            light={false}
                                                            loop={false}
                                                            playbackRate={1.0}
                                                            onReady={() => {
                                                                // console.log('onReady B')
                                                                setChannelBStatus('Loaded')}
                                                            }
                                                            onProgress={(data) => {
                                                                // console.log('onProgress B',)
                                                                setPlayedSecondsB(data.playedSeconds)
                                                                setChannelBStatus('Seeking')}}
                                                            onSeek={() => {
                                                                // console.log('onSeek B')
                                                                setChannelBStatus('Ready')}
                                                            }
                                                            onStart={() => {}}
                                                            onPlay={() => {}}
                                                            onEnablePIP={() => {}}
                                                            onDisablePIP={() => {}}
                                                            onPause={() => {}}
                                                            onBuffer={() => {}}
                                                            onBufferEnd={() => {}}
                                                            onEnded={() => {}}
                                                            onError={(error, data, hlsInstance) => {console.log('Error B: ', error, data, hlsInstance)}}
                                                            onDuration={(duration) => {
                                                                // console.log('ChannelBDuration: ', duration)
                                                                setChannelBDuration(duration)}}
                                                        />
                                                    }
                                                    style={{ width: '1920px', height: '1080px' }}
                                                />

                                            </Flex>
                                        </View> }
                                </ExpanderItem>
                            </Expander>
                            <br/>
                            <Button
                                onClick={() => {
                                    onRefresh()
                                    history.goBack()
                                }}
                                className="reference-card-button"
                            >Back</Button>
                        </View>
                    </Flex>
                </div>
            </React.StrictMode>
            <FooterBlack {...props}/>
        </>
    );
}

export default CompareAnalysisPage;
