import React from "react";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import {Button, Card, Flex, Icon, View} from '@aws-amplify/ui-react';
import {API, Auth} from "aws-amplify";
import S3Table from "../../components/vqaas/S3Table";

function S3Browser(props) {
    const [s3Path, setS3Path] = React.useState([])
    const [s3ActivePath, setS3ActivePath] = React.useState('')
    const [s3Folders, setS3Folders] = React.useState([])
    const [s3Objects, setS3Objects] = React.useState([])

    React.useEffect(() => {
        async function getS3(currentPath) {
            const user = await Auth.currentAuthenticatedUser()
            const apiName = 'vqaasapi';
            const path = currentPath ? '/s3/' + props.account.s3_bucket+ '/' + currentPath.slice(0,-1) : '/s3/' + props.account.s3_bucket;
            const token = user.signInUserSession.idToken.jwtToken
            let myInit = {
                headers: {
                    Authorization: token,
                }
            }
            return API.get(apiName, path, myInit);
        }
        let mounted = true
        let currentPath= ''
        if (s3Path.length){
            currentPath= s3Path.join('/')
            if (s3ActivePath){
                currentPath = currentPath + '/' + s3ActivePath + '/'
            }
        } else {
            if (s3ActivePath){
                currentPath = s3ActivePath + '/'
            }
        }
        getS3(currentPath).then((response)=> {
            if (mounted) {
                if (!response['CommonPrefixes']) response['CommonPrefixes']=[]
                let folderList = []
                response['CommonPrefixes'].forEach(folder => {
                    let folderObjectSplit = folder['Prefix'].split('/')
                    let folderObject = {
                        id: folderObjectSplit[folderObjectSplit.length-2],
                        path: folderObjectSplit[folderObjectSplit.length-2]
                    }
                    folderList.push(folderObject)}
                )
                setS3Folders(folderList)
                if (!response['Contents']) response['Contents']=[]
                let objectList = []
                response['Contents'].forEach(object => {
                    if (!object.Key.endsWith('/')) objectList = objectList.concat(object)
                })
                setS3Objects(objectList)
            }
        })

        return function cleanup() {
            mounted = false;
        };
    }, [s3ActivePath, s3Path, props.user, props.account.s3_bucket])

    const onClickS3PathItem = async (e) => {
        e.preventDefault();
        let dataLevel = e.target.attributes.getNamedItem('data-level').value
        if (dataLevel === "path"){
            let dataIndex = e.target.attributes.getNamedItem('data-index').value
            let dataName = e.target.attributes.getNamedItem('data-name').value
            // console.log(dataIndex, dataName)
            if (e.target) {
                if (dataIndex) {
                    setS3ActivePath(dataName)
                    setS3Path(prevS3Path => [...prevS3Path.slice(0, parseInt(dataIndex))])
                }
            }
        }
        if (dataLevel === "bucket"){
            setS3ActivePath('')
            setS3Path([])
        }
    }

    const onFolderClick = async (e) => {
        // console.log(e.target.attributes.getNamedItem('data-folder').value);
        setS3Path(prevS3Path => {
            // console.log(prevS3Path)
            if (s3ActivePath === '') {
                return [...prevS3Path]
            } else {
                return [...prevS3Path, s3ActivePath]
            }
        })
        setS3ActivePath(e.target.attributes.getNamedItem('data-folder').value)
    }

    const LinkTrail = s3Path.map((s3PathItem, index) =>
        <Link
            underline="hover"
            data-level="path"
            data-index={index}
            data-name={s3PathItem}
            key={s3PathItem}
            href={"/"+ s3PathItem}
        >
            {s3PathItem}
        </Link>
    )

    return (
        <View backgroundColor={"#FFB400"} minWidth={"1000px"} padding={"5px"}>
            <Card variation="outlined" padding={"1px"}>
                <Flex direction={"column"} padding={0} alignItems={"flex-end"}
                      alignContent={"flex-end"}>
                    <Button className="close-card-button" size="small" onClick={props.onClick}>
                        <Icon
                            ariaLabel="Close"
                            viewBox={{width: 24, height: 24}}
                            pathData="M12.000,24.000 C5.383,24.000 0.000,18.617 0.000,12.000 C0.000,5.383 5.383,0.000 12.000,0.000 C18.617,0.000 24.000,5.383 24.000,12.000 C24.000,18.617 18.617,24.000 12.000,24.000 ZM12.000,2.000 C6.486,2.000 2.000,6.486 2.000,12.000 C2.000,17.514 6.486,22.000 12.000,22.000 C17.514,22.000 22.000,17.514 22.000,12.000 C22.000,6.486 17.514,2.000 12.000,2.000 ZM16.707,16.707 C16.512,16.902 16.256,17.000 16.000,17.000 C15.744,17.000 15.488,16.902 15.293,16.707 L12.000,13.414 L8.707,16.707 C8.512,16.902 8.256,17.000 8.000,17.000 C7.744,17.000 7.488,16.902 7.293,16.707 C6.902,16.316 6.902,15.684 7.293,15.293 L10.586,12.000 L7.293,8.707 C6.902,8.316 6.902,7.684 7.293,7.293 C7.684,6.902 8.316,6.902 8.707,7.293 L12.000,10.586 L15.293,7.293 C15.684,6.902 16.316,6.902 16.707,7.293 C17.098,7.684 17.098,8.316 16.707,8.707 L13.414,12.000 L16.707,15.293 C17.098,15.684 17.098,16.316 16.707,16.707 Z"
                        />
                    </Button>
                </Flex>
                <Flex direction={"column"} style={{fontFamily: "SourceSansPro"}} padding={"1rem"}>
                    <div role="presentation" onClick={onClickS3PathItem}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link data-level="root">
                                Cross Account S3
                            </Link>
                            <Link
                                underline="hover"
                                data-level={"bucket"}
                                href={"/"+ props.account.s3_bucket}
                            >
                                {props.account.s3_bucket}
                            </Link>
                            {LinkTrail}
                            <Typography color="text.primary" data-level={"active"}>{s3ActivePath}</Typography>
                        </Breadcrumbs>
                    </div>
                    <S3Table
                        onImportFromS3={props.onImportFromS3}
                        s3Folders={s3Folders}
                        s3Objects={s3Objects}
                        onFolderClick={onFolderClick}
                    />
                </Flex>
            </Card>
        </View>
    );
}

export default S3Browser;
