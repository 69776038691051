import React from "react";
import {TableCell} from '@aws-amplify/ui-react';

function DurationFramesCell(props) {
    return (
        <>
            { props.distorted ?
                <TableCell className="synamedia-td">
                    {
                        props.distorted.spec_duration_frames ?
                            props.distorted.spec_duration_frames + ' frames'
                            :
                            ''
                    }
                </TableCell>: <TableCell/>
            }
        </>
    );
}

export default DurationFramesCell;
