import React from "react";
import {
    Expander,
    ExpanderItem,
    Flex,
    Heading,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Text,
    View
} from '@aws-amplify/ui-react';
import {Link as ReactRouterLink} from 'react-router-dom';
// core components
import MediaPageHeader from "components/Headers/MediaPageHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
// import S3Browser from "../../components/vqaas/S3Browser";
import WhiteNavbar from "../../components/Navbars/WhiteNavbar";
// import ReferenceUpload from "../../components/vqaas/ReferenceUpload";
// import {postMedia, putMedia} from "../../components/vqaas/ApiFunctions";


function S3Page(props) {
    const { staticContext, signOut, account, adminVisible,
        onSignOutClick, user, ...restOfProps } = props

    // const handleMediaImportFromS3 = async (e) => {
    //     const s3_object_key = e.target.attributes.getNamedItem('data-s3_object_key').value
    //     const filename = "arn:aws:s3:::" + account.s3_bucket + "/" + s3_object_key
    //     let response = await postMedia(filename);
    //     // console.log(response)
    //     const media_id = response['id']
    //     response = await putMedia(media_id);
    //     // console.log(response)
    // }

    return (
        <>
            <WhiteNavbar {...props}/>
            <MediaPageHeader title={"REST API"} subTitle={"Documentation"}/>
            <div className="section">
                <Flex justifyContent="center">
                    <View width={'75%'} style={{fontFamily: "SourceSansPro"}}>
                        {/*Import from PC*/}
                        {/*<View style={{paddingBottom:"2rem"}} direction={"column"} gap={"0.25rem"}>*/}
                        {/*    <Heading level={"3"} style={{*/}
                        {/*        borderBottomWidth:"1px",*/}
                        {/*        borderBottomColor:"black",*/}
                        {/*        borderBottomStyle:"solid"}}>Import from PC</Heading>*/}
                        {/*    <ReferenceUpload*/}
                        {/*        user={props.user}*/}
                        {/*    />*/}
                        {/*</View>*/}
                        {/*Import from script*/}
                        <View direction={"column"} gap={"0.25rem"} style={{paddingBottom:"2rem"}}>
                            <Heading level={"3"} style={{
                                borderBottomWidth:"1px",
                                borderBottomColor:"black",
                                borderBottomStyle:"solid"}}>Import from script</Heading>
                            <br/>
                            <Heading level={"5"}> VQaaS supports a REST API for automating the import of Media files</Heading>
                            <Text>The API is secured based on your Access Key ID and Secret Access Key. These can be generated
                                <Link as={ReactRouterLink} to={"/profile-page"} color={"#3380FF"}>{' '}here</Link>
                            </Text>
                            <br/>
                            <Heading level={"6"}>Base URL: https://unczqxc8fj.execute-api.us-east-2.amazonaws.com/dev</Heading>
                            <br/>
                            <Expander
                                type="single"
                                isCollapsible={true}
                                {...restOfProps}
                            >
                                <ExpanderItem title="GET /media/{MediaId}" value="item-1" style={{fontSize: "18px"}}>
                                    <br/>
                                    <Heading level={"5"}>Path parameters</Heading>
                                    <Text>MediaId (required)</Text>
                                    <Text>Path Parameter — id that uniquely defines the Media resource </Text>
                                    <br/>
                                    <Heading level={"5"}>Request headers</Heading>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell as="th">Header</TableCell>
                                                <TableCell as="th">Required</TableCell>
                                                <TableCell as="th">Type</TableCell>
                                                <TableCell as="th">Info</TableCell>
                                                <TableCell as="th">Example</TableCell>
                                                <TableCell as="th">Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>X-Amz-Date</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell/>
                                                <TableCell/>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Authorization</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Info</TableCell>
                                                <TableCell>AWS4-HMAC-SHA256
                                                    Credential=AKIAIOSFODNN7EXAMPLE/20130524/us-east-1/s3/aws4_request,<br/>
                                                    SignedHeaders=host;range;x-amz-date,<br/>
                                                    Signature=fe5f80f77d5fa3beca038a248ff027d0445342fe2855ddc963176630326f1024</TableCell>
                                                <TableCell>
                                                    <a href={"https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-auth-using-authorization-header.html"}>AWS Documentation</a>
                                                    <br/>
                                                    <a href={"https://learning.postman.com/docs/sending-requests/authorization/#aws-signature"}>Postman</a>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <br/>
                                    <Heading level={"5"}>Return data - Media</Heading>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell as="th">Parameter</TableCell>
                                                <TableCell as="th">Required</TableCell>
                                                <TableCell as="th">Type</TableCell>
                                                <TableCell as="th">Info</TableCell>
                                                <TableCell as="th">Example</TableCell>
                                                <TableCell as="th">Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>id</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Read only</TableCell>
                                                <TableCell>a214a3ca-abcd-4917-1234-74d692234039</TableCell>
                                                <TableCell>UUID that uniquely defines the resource</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>identity_id</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Read only</TableCell>
                                                <TableCell>us-east-2:4d7d9cd0-8558-48e8-a6ce-65333aa0c26c</TableCell>
                                                <TableCell>UUID that uniquely defines the user storage location</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>s3_bucket</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Read only</TableCell>
                                                <TableCell>vqaas-storage105718-staging</TableCell>
                                                <TableCell>VQaaS S3 bucket</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>s3_key</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Read only</TableCell>
                                                <TableCell>protected/us-east-2:4d7d9cd0-8558-48e8-a6ce-65333aa0c26c/a214a3ca-abcd-4917-1234-74d692234039/1080p60 H.265.ts</TableCell>
                                                <TableCell>UUID that uniquely defines the user</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>aws_cli_copy</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Read only</TableCell>
                                                <TableCell>aws s3 cp "1080p60 H.265.ts" "s3://vqaas-storage105718-staging/protected/us-east-2:4d7d9cd0-8558-48e8-a6ce-65333aa0c26c/a214a3ca-abcd-4917-1234-74d692234039/1080p60 H.265.ts"</TableCell>
                                                <TableCell>Example copy command using aws cli</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <br/>
                                    <Heading level={"5"}>Return code</Heading>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell as="th">Code</TableCell>
                                                <TableCell as="th">Message</TableCell>
                                                <TableCell as="th">Response type</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>200</TableCell>
                                                <TableCell>Success</TableCell>
                                                <TableCell>Media</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>401</TableCell>
                                                <TableCell>Not authenticated</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>403</TableCell>
                                                <TableCell>Not authorized</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>500</TableCell>
                                                <TableCell>Service Error</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </ExpanderItem>
                                <ExpanderItem title="GET /media" value="item-2" style={{fontSize: "18px"}}>
                                    <br/>
                                    <Heading level={"5"}>Request headers</Heading>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell as="th">Header</TableCell>
                                                <TableCell as="th">Required</TableCell>
                                                <TableCell as="th">Type</TableCell>
                                                <TableCell as="th">Info</TableCell>
                                                <TableCell as="th">Example</TableCell>
                                                <TableCell as="th">Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>X-Amz-Date</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell/>
                                                <TableCell/>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Authorization</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Info</TableCell>
                                                <TableCell>AWS4-HMAC-SHA256
                                                    Credential=AKIAIOSFODNN7EXAMPLE/20130524/us-east-1/s3/aws4_request,<br/>
                                                    SignedHeaders=host;range;x-amz-date,<br/>
                                                    Signature=fe5f80f77d5fa3beca038a248ff027d0445342fe2855ddc963176630326f1024</TableCell>
                                                <TableCell>
                                                    <a href={"https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-auth-using-authorization-header.html"}>AWS Documentation</a>
                                                    <br/>
                                                    <a href={"https://learning.postman.com/docs/sending-requests/authorization/#aws-signature"}>Postman</a>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <br/>
                                    <Heading level={"5"}>Return data - media all</Heading>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell as="th">Parameter</TableCell>
                                                <TableCell as="th">Required</TableCell>
                                                <TableCell as="th">Type</TableCell>
                                                <TableCell as="th">Info</TableCell>
                                                <TableCell as="th">Example</TableCell>
                                                <TableCell as="th">Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Media</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>array[Media]</TableCell>
                                                <TableCell/>
                                                <TableCell/>
                                                <TableCell/>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <br/>
                                    <Heading level={"5"}>Return code</Heading>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell as="th">Code</TableCell>
                                                <TableCell as="th">Message</TableCell>
                                                <TableCell as="th">Response type</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>200</TableCell>
                                                <TableCell>Success</TableCell>
                                                <TableCell>media all</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>401</TableCell>
                                                <TableCell>Not authenticated</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>403</TableCell>
                                                <TableCell>Not authorized</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>500</TableCell>
                                                <TableCell>Service Error</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </ExpanderItem>
                                <ExpanderItem title="POST /media" value="item-3" style={{fontSize: "18px"}}>
                                    <Text>When you create a media resource, VQaaS permits you to store a media file in the cloud. The return data provides you with the information you need to store the file.</Text>
                                    <br/>
                                    <Heading level={"5"}>Request body</Heading>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell as="th">Parameter</TableCell>
                                                <TableCell as="th">Required</TableCell>
                                                <TableCell as="th">Type</TableCell>
                                                <TableCell as="th">Info</TableCell>
                                                <TableCell as="th">Example</TableCell>
                                                <TableCell as="th">Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{width:"100px"}}>filename</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Info</TableCell>
                                                <TableCell>1080p60 H.265.ts</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <br/>
                                    <Heading level={"5"}>Request headers</Heading>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell as="th">Header</TableCell>
                                                <TableCell as="th">Required</TableCell>
                                                <TableCell as="th">Type</TableCell>
                                                <TableCell as="th">Info</TableCell>
                                                <TableCell as="th">Example</TableCell>
                                                <TableCell as="th">Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>X-Amz-Date</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell/>
                                                <TableCell/>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Authorization</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Info</TableCell>
                                                <TableCell>AWS4-HMAC-SHA256
                                                    Credential=AKIAIOSFODNN7EXAMPLE/20130524/us-east-1/s3/aws4_request,<br/>
                                                    SignedHeaders=host;range;x-amz-date,<br/>
                                                    Signature=fe5f80f77d5fa3beca038a248ff027d0445342fe2855ddc963176630326f1024</TableCell>
                                                <TableCell>
                                                    <a href={"https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-auth-using-authorization-header.html"}>AWS Documentation</a>
                                                    <br/>
                                                    <a href={"https://learning.postman.com/docs/sending-requests/authorization/#aws-signature"}>Postman</a>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <br/>
                                    <Heading level={"5"}>Return data - new resource</Heading>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell as="th">Parameter</TableCell>
                                                <TableCell as="th">Required</TableCell>
                                                <TableCell as="th">Type</TableCell>
                                                <TableCell as="th">Info</TableCell>
                                                <TableCell as="th">Example</TableCell>
                                                <TableCell as="th">Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>id</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Read only</TableCell>
                                                <TableCell>a214a3ca-abcd-4917-1234-74d692234039</TableCell>
                                                <TableCell>UUID that uniquely defines the resource</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>identity_id</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Read only</TableCell>
                                                <TableCell>us-east-2:4d7d9cd0-8558-48e8-a6ce-65333aa0c26c</TableCell>
                                                <TableCell>UUID that uniquely defines the user storage location</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>s3_bucket</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Read only</TableCell>
                                                <TableCell>vqaas-storage105718-staging</TableCell>
                                                <TableCell>VQaaS S3 bucket</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>s3_key</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Read only</TableCell>
                                                <TableCell>protected/us-east-2:4d7d9cd0-8558-48e8-a6ce-65333aa0c26c/a214a3ca-abcd-4917-1234-74d692234039/1080p60 H.265.ts</TableCell>
                                                <TableCell>UUID that uniquely defines the user</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>aws_cli_copy</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Read only</TableCell>
                                                <TableCell>aws s3 cp "1080p60 H.265.ts" "s3://vqaas-storage105718-staging/protected/us-east-2:4d7d9cd0-8558-48e8-a6ce-65333aa0c26c/a214a3ca-abcd-4917-1234-74d692234039/1080p60 H.265.ts"</TableCell>
                                                <TableCell>Example copy command using aws cli</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <br/>
                                    <Heading level={"5"}>Return code</Heading>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell as="th">Code</TableCell>
                                                <TableCell as="th">Message</TableCell>
                                                <TableCell as="th">Response type</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>201</TableCell>
                                                <TableCell>Created</TableCell>
                                                <TableCell>new resource</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>401</TableCell>
                                                <TableCell>Not authenticated</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>403</TableCell>
                                                <TableCell>Not authorized</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>500</TableCell>
                                                <TableCell>Service Error</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </ExpanderItem>
                                <ExpanderItem title="PUT /media/{MediaId}/import" value="item-4" style={{fontSize: "18px"}}>
                                    <br/>
                                    <Heading level={"5"}>Path parameters</Heading>
                                    <Text>MediaId (required)</Text>
                                    <Text>Path Parameter — id that uniquely defines the Media resource </Text>
                                    <br/>
                                    <Heading level={"5"}>Request headers</Heading>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell as="th">Header</TableCell>
                                                <TableCell as="th">Required</TableCell>
                                                <TableCell as="th">Type</TableCell>
                                                <TableCell as="th">Info</TableCell>
                                                <TableCell as="th">Example</TableCell>
                                                <TableCell as="th">Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>X-Amz-Date</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell/>
                                                <TableCell/>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Authorization</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Info</TableCell>
                                                <TableCell>AWS4-HMAC-SHA256
                                                    Credential=AKIAIOSFODNN7EXAMPLE/20130524/us-east-1/s3/aws4_request,<br/>
                                                    SignedHeaders=host;range;x-amz-date,<br/>
                                                    Signature=fe5f80f77d5fa3beca038a248ff027d0445342fe2855ddc963176630326f1024</TableCell>
                                                <TableCell>
                                                    <a href={"https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-auth-using-authorization-header.html"}>AWS Documentation</a>
                                                    <br/>
                                                    <a href={"https://learning.postman.com/docs/sending-requests/authorization/#aws-signature"}>Postman</a>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <br/>
                                    <Heading level={"5"}>Return code</Heading>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell as="th">Code</TableCell>
                                                <TableCell as="th">Message</TableCell>
                                                <TableCell as="th">Response type</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>202</TableCell>
                                                <TableCell>Accepted</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>401</TableCell>
                                                <TableCell>Not authenticated</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>403</TableCell>
                                                <TableCell>Not authorized</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>500</TableCell>
                                                <TableCell>Service Error</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </ExpanderItem>
                                <ExpanderItem title="DELETE /media/{MediaId}" value="item-5" style={{fontSize: "18px"}}>
                                    <br/>
                                    <Heading level={"5"}>Path parameters</Heading>
                                    <Text>MediaId (required)</Text>
                                    <Text>Path Parameter — id that uniquely defines the Media resource </Text>
                                    <br/>
                                    <Heading level={"5"}>Request headers</Heading>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell as="th">Header</TableCell>
                                                <TableCell as="th">Required</TableCell>
                                                <TableCell as="th">Type</TableCell>
                                                <TableCell as="th">Info</TableCell>
                                                <TableCell as="th">Example</TableCell>
                                                <TableCell as="th">Description</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>X-Amz-Date</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell/>
                                                <TableCell/>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Authorization</TableCell>
                                                <TableCell>yes</TableCell>
                                                <TableCell>String</TableCell>
                                                <TableCell>Info</TableCell>
                                                <TableCell>AWS4-HMAC-SHA256
                                                    Credential=AKIAIOSFODNN7EXAMPLE/20130524/us-east-1/s3/aws4_request,<br/>
                                                    SignedHeaders=host;range;x-amz-date,<br/>
                                                    Signature=fe5f80f77d5fa3beca038a248ff027d0445342fe2855ddc963176630326f1024</TableCell>
                                                <TableCell>
                                                    <a href={"https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-auth-using-authorization-header.html"}>AWS Documentation</a>
                                                    <br/>
                                                    <a href={"https://learning.postman.com/docs/sending-requests/authorization/#aws-signature"}>Postman</a>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                    <br/>
                                    <Heading level={"5"}>Return code</Heading>
                                    <Table size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell as="th">Code</TableCell>
                                                <TableCell as="th">Message</TableCell>
                                                <TableCell as="th">Response type</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>200</TableCell>
                                                <TableCell>Success</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>401</TableCell>
                                                <TableCell>Not authenticated</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>403</TableCell>
                                                <TableCell>Not authorized</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>500</TableCell>
                                                <TableCell>Service Error</TableCell>
                                                <TableCell/>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </ExpanderItem>
                            </Expander>
                        </View>
                        {/*Import from S3*/}
                        {/*<View style={{paddingBottom:"2rem"}} direction={"column"} gap={"0.25rem"}>*/}
                        {/*    <Heading level={"3"} style={{*/}
                        {/*        borderBottomWidth:"1px",*/}
                        {/*        borderBottomColor:"black",*/}
                        {/*        borderBottomStyle:"solid"}}>Import from S3</Heading>*/}
                        {/*    <br/>*/}
                        {/*    <Heading level={"5"}> VQaaS supports the import of Media files from an S3 bucket in your account</Heading>*/}
                        {/*    <Text>To allow VQaaS to access your bucket, you need to add a permission policy to your bucket. This policy can be generated*/}
                        {/*        <Link as={ReactRouterLink} to={"/profile-page"} color={"#3380FF"}>{' '}here</Link>*/}
                        {/*    </Text>*/}
                        {/*    <br/>*/}
                        {/*    <S3Browser*/}
                        {/*        user={props.user}*/}
                        {/*        onImportFromS3={handleMediaImportFromS3}*/}
                        {/*        account={props.account}*/}
                        {/*    />*/}
                        {/*</View>*/}
                    </View>
                </Flex>
            </div>
            <FooterBlack {...props}/>
        </>
    );
}

export default S3Page;
