import React, {useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

function compareNumbers(a, b) {
    return a - b;
}

function CumulativeDistributionChart(props) {
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'spline',
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
            animation: false
        },
        series: [
            { data: [], color: '#3380FF' }
        ],
        xAxis: {
            title: {
                text: ''
            }
        },
        yAxis: {
            max: 100,
            title: {
                text: 'Probability [%]'
            }
        },
        legend: {
            enabled: true
        },
        title: {
            text: 'VMAF'
        },
        subtitle: {
            text: 'Cumulative Distribution: '
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                animation: false,
                marker:{enabled: false}
            }
        }
    });

    React.useEffect(() => {
        if (props.collapse){
            console.log('Update distribution chart')
            setChartOptions(prevChartOptions => {
                let updatedChartOptions = {...prevChartOptions}
                updatedChartOptions.title.text = props.metric
                updatedChartOptions.subtitle.text =
                    'Cumulative Distribution: y% of the frames have a ' + props.metric + ' lower than x'
                updatedChartOptions.xAxis.title.text = props.metric + ' score'
                updatedChartOptions.series = []
                let sorted = []
                let x_min = Math.max(0, props.xExtremes.min)
                if (props.distorted1){
                    let dis1_series = []
                    if (props.metric === 'VMAF'){
                        if (props.xExtremes.max){
                            sorted = [...props.distorted1.vmaf].slice(x_min,props.xExtremes.max).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        } else {
                            sorted = [...props.distorted1.vmaf].slice(x_min).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        }
                        for (let i=0; i <= 100; i++){
                            let list_index = Math.floor(i*(sorted.length-1)/100)
                            dis1_series = dis1_series.concat(
                                [[sorted[list_index], (100*list_index)/sorted.length]]
                            )
                        }
                    }
                    if (props.metric === 'PSNR'){
                        if (props.xExtremes.max){
                            sorted = [...props.distorted1.psnr].slice(x_min,props.xExtremes.max).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        } else {
                            sorted = [...props.distorted1.psnr].slice(x_min).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        }
                        for (let i=0; i <= 100; i++){
                            let list_index = Math.floor(i*(sorted.length-1)/100)
                            dis1_series = dis1_series.concat(
                                [[sorted[list_index], (100*list_index)/sorted.length]]
                            )
                        }
                    }
                    updatedChartOptions.series = updatedChartOptions.series.concat(
                        {
                            name: props.distorted1.filename,
                            data: dis1_series,
                            color: '#000000'
                        })
                }
                if (props.distorted2){
                    let dis2_series = []
                    if (props.metric === 'VMAF'){
                        if (props.xExtremes.max){
                            sorted = [...props.distorted2.vmaf].slice(x_min,props.xExtremes.max).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        } else {
                            sorted = [...props.distorted2.vmaf].slice(x_min).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        }
                        for (let i=0; i <= 100; i++){
                            let list_index = Math.floor(i*(sorted.length-1)/100)
                            dis2_series = dis2_series.concat(
                                [[sorted[list_index], (100*list_index)/sorted.length]]
                            )
                        }
                    }
                    if (props.metric === 'PSNR'){
                        if (props.xExtremes.max){
                            sorted = [...props.distorted2.psnr].slice(x_min,props.xExtremes.max).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        } else {
                            sorted = [...props.distorted2.psnr].slice(x_min).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        }
                        for (let i=0; i <= 100; i++){
                            let list_index = Math.floor(i*(sorted.length-1)/100)
                            dis2_series = dis2_series.concat(
                                [[sorted[list_index], (100*list_index)/sorted.length]]
                            )
                        }
                    }
                    updatedChartOptions.series = updatedChartOptions.series.concat(
                        {
                            name: props.distorted2.filename,
                            data: dis2_series,
                            color: '#FFB400'
                        })
                }
                if (props.distorted3){
                    let dis3_series = []
                    if (props.metric === 'VMAF'){
                        if (props.xExtremes.max){
                            sorted = [...props.distorted3.vmaf].slice(x_min,props.xExtremes.max).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        } else {
                            sorted = [...props.distorted3.vmaf].slice(x_min).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        }
                        for (let i=0; i <= 100; i++){
                            let list_index = Math.floor(i*(sorted.length-1)/100)
                            dis3_series = dis3_series.concat(
                                [[sorted[list_index], (100*list_index)/sorted.length]]
                            )
                        }
                    }
                    if (props.metric === 'PSNR'){
                        if (props.xExtremes.max){
                            sorted = [...props.distorted3.psnr].slice(x_min,props.xExtremes.max).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        } else {
                            sorted = [...props.distorted3.psnr].slice(x_min).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        }
                        for (let i=0; i <= 100; i++){
                            let list_index = Math.floor(i*(sorted.length-1)/100)
                            dis3_series = dis3_series.concat(
                                [[sorted[list_index], (100*list_index)/sorted.length]]
                            )
                        }
                    }
                    updatedChartOptions.series = updatedChartOptions.series.concat(
                        {
                            name: props.distorted3.filename,
                            data: dis3_series,
                            color: '#3380FF'
                        })
                }
                if (props.distorted4){
                    let dis4_series = []
                    if (props.metric === 'VMAF'){
                        if (props.xExtremes.max){
                            sorted = [...props.distorted4.vmaf].slice(x_min,props.xExtremes.max).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        } else {
                            sorted = [...props.distorted4.vmaf].slice(x_min).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        }
                        for (let i=0; i <= 100; i++){
                            let list_index = Math.floor(i*(sorted.length-1)/100)
                            dis4_series = dis4_series.concat(
                                [[sorted[list_index], (100*list_index)/sorted.length]]
                            )
                        }
                    }
                    if (props.metric === 'PSNR'){
                        if (props.xExtremes.max){
                            sorted = [...props.distorted4.psnr].slice(x_min,props.xExtremes.max).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        } else {
                            sorted = [...props.distorted4.psnr].slice(x_min).filter(function(e){ return e === 0 || e }).sort(compareNumbers)
                        }
                        for (let i=0; i <= 100; i++){
                            let list_index = Math.floor(i*(sorted.length-1)/100)
                            dis4_series = dis4_series.concat(
                                [[sorted[list_index], (100*list_index)/sorted.length]]
                            )
                        }
                    }
                    updatedChartOptions.series = updatedChartOptions.series.concat(
                        {
                            name: props.distorted4.filename,
                            data: dis4_series,
                            color: '#ff3333'
                        })
                }
                return updatedChartOptions
            })
        }
    }, [props])

    return(
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
        />
    )
}

export default CumulativeDistributionChart;