import React from "react";
import {
    Alert,
    Button,
    Collection,
    Divider,
    Flex,
    Heading,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Text,
    View
} from '@aws-amplify/ui-react';
import {API, Auth} from "aws-amplify";
import MediaPageHeader from "components/Headers/MediaPageHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
// import TeamCard from "components/vqaas/TeamCard";
import WhiteNavbar from "../Navbars/WhiteNavbar";
import Tooltip from '@mui/material/Tooltip';
import VQTextField from "./VQTextField";


function ProfilePage(props) {
    const [accessKeys, setAccessKeys] = React.useState([])
    const [refAlertList, setRefAlertList] = React.useState([])

    let bucket_policy = JSON.stringify({
        "Version": "2012-10-17",
        "Statement": [
            {
                "Effect": "Allow",
                "Principal": {
                    "AWS": [
                        "arn:aws:iam::017733813652:role/vqaasLambdaRole88c705e0-test",
                        "arn:aws:iam::017733813652:role/vqaasLambdaRole88c705e0-staging",
                        "arn:aws:iam::017733813652:role/vqaasLambdaRole88c705e0-dev"
                    ]
                },
                "Action": [
                    "s3:ListBucket",
                    "s3:GetBucketLocation"
                ],
                "Resource": "arn:aws:s3:::" + (props.account.s3_bucket || '')
            },
            {
                "Effect": "Allow",
                "Principal": {
                    "AWS": "arn:aws:iam::017733813652:user/amplify-vqaas-ccoene"
                },
                "Action": "s3:GetObject",
                "Resource": "arn:aws:s3:::" + (props.account.s3_bucket || '') + "/*"
            }
        ]
    }, null, 4)

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    });

    React.useEffect(() => {
        async function getData() {
            const user = await Auth.currentAuthenticatedUser()
            const apiName = 'vqaasapi';
            const path = '/access-keys';
            const token = user.signInUserSession.idToken.jwtToken
            let myInit = {
                headers: {
                    Authorization: token,
                }
            }
            return API.get(apiName, path, myInit);
        }
        getData().then((response)=> setAccessKeys(response.AccessKeys));
    },[props.user])

    const handleCreateAccessKey = async () => {
        async function postData() {
            const user = await Auth.currentAuthenticatedUser()
            const apiName = 'vqaasapi';
            const path = '/access-keys';
            const token = user.signInUserSession.idToken.jwtToken
            const credentials = await Auth.currentCredentials();
            let myInit = {
                headers: {
                    Authorization: token,
                },
                body: {'identityId': credentials.identityId}
            }
            return API.post(apiName, path, myInit);
        }
        const response = await postData();
        let newAlert = 'This is the only time that the secret access keys can be viewed. You cannot recover them later. However, you can create new access keys at any time.'
        let newAlert2 = 'AccessKeyId: ' + response.AccessKeyId + ' SecretAccessKey: ' + response.SecretAccessKey
        setRefAlertList(oldArray => [...oldArray, newAlert, newAlert2]);
        setAccessKeys(oldArray => [...oldArray, response])
    }

    const handleStatusChangeAccessKey = async (statusAccessKey, accessKeyId) => {
        async function putData() {
            const user = await Auth.currentAuthenticatedUser()
            const apiName = 'vqaasapi';
            const path = '/access-keys';
            const token = user.signInUserSession.idToken.jwtToken
            let myInit = {
                headers: {
                    Authorization: token,
                },
                body: {'AccessKeyId': accessKeyId, 'Status': statusAccessKey}
            }
            return API.put(apiName, path, myInit);
        }
        await putData();
        let newAlert
        if (statusAccessKey === 'Active') {
            newAlert = 'Access key ' + accessKeyId + ' activated.'
        } else {
            newAlert = 'Access key ' + accessKeyId + ' deactivated.'
        }
        setRefAlertList(oldArray => [...oldArray, newAlert]);
        setAccessKeys(prevAccessKeys => {
            return prevAccessKeys.map(item => {
                // console.log(item)
                if (item.AccessKeyId === accessKeyId) item.Status = statusAccessKey
                return item
            })
        })
    }

    const handleDeleteAccessKey = async (accessKeyId) => {
        async function deleteData() {
            const user = await Auth.currentAuthenticatedUser()
            const apiName = 'vqaasapi';
            const path = '/access-keys';
            const token = user.signInUserSession.idToken.jwtToken
            let myInit = {
                headers: {
                    Authorization: token,
                },
                body: {'AccessKeyId': accessKeyId}
            }
            return API.del(apiName, path, myInit);
        }
        await deleteData();
        let newAlert = 'Access key ' + accessKeyId + ' deleted.'
        setRefAlertList(oldArray => [...oldArray, newAlert]);
        setAccessKeys(accessKeys.filter(item => item.AccessKeyId !== accessKeyId))
    }

    const tableEntries = accessKeys.map(item =>
        <TableRow key={item.AccessKeyId}>
            <TableCell>{item.AccessKeyId}</TableCell>
            <TableCell>{item.CreateDate}</TableCell>
            <TableCell>{item.AccessKeyLastUsed}</TableCell>
            <TableCell color={"#3380FF"}>{item.Status}</TableCell>
            <TableCell>
                <Flex gap={"1rem"}>
                    {item.Status === 'Active' ?
                        <Tooltip
                            title="Deactivate"
                            placement="right"
                        >
                            <Button
                                className="reference-card-button"
                                size="small"
                                onClick={() => handleStatusChangeAccessKey('Inactive', item.AccessKeyId)}
                            >
                                <Icon
                                    ariaLabel="Close"
                                    viewBox={{ width: 24, height: 24 }}
                                    pathData="M12.000,24.000 C5.383,24.000 0.000,18.617 0.000,12.000 C0.000,5.383 5.383,0.000 12.000,0.000 C18.617,0.000 24.000,5.383 24.000,12.000 C24.000,18.617 18.617,24.000 12.000,24.000 ZM12.000,2.000 C6.486,2.000 2.000,6.486 2.000,12.000 C2.000,17.514 6.486,22.000 12.000,22.000 C17.514,22.000 22.000,17.514 22.000,12.000 C22.000,6.486 17.514,2.000 12.000,2.000 ZM16.707,16.707 C16.512,16.902 16.256,17.000 16.000,17.000 C15.744,17.000 15.488,16.902 15.293,16.707 L12.000,13.414 L8.707,16.707 C8.512,16.902 8.256,17.000 8.000,17.000 C7.744,17.000 7.488,16.902 7.293,16.707 C6.902,16.316 6.902,15.684 7.293,15.293 L10.586,12.000 L7.293,8.707 C6.902,8.316 6.902,7.684 7.293,7.293 C7.684,6.902 8.316,6.902 8.707,7.293 L12.000,10.586 L15.293,7.293 C15.684,6.902 16.316,6.902 16.707,7.293 C17.098,7.684 17.098,8.316 16.707,8.707 L13.414,12.000 L16.707,15.293 C17.098,15.684 17.098,16.316 16.707,16.707 Z"
                                />
                            </Button>
                        </Tooltip> :
                        <Tooltip
                            title="Activate"
                            placement="left"
                        >
                            <Button
                                className="reference-card-button"
                                size="small"
                                onClick={() => handleStatusChangeAccessKey('Active', item.AccessKeyId)}
                            >
                                <Icon
                                    ariaLabel="Check"
                                    viewBox={{ width: 24, height: 24 }}
                                    pathData="M12.000,24.000 C5.383,24.000 0.000,18.617 0.000,12.000 C0.000,5.383 5.383,0.000 12.000,0.000 C18.617,0.000 24.000,5.383 24.000,12.000 C24.000,18.617 18.617,24.000 12.000,24.000 ZM12.000,2.000 C6.486,2.000 2.000,6.486 2.000,12.000 C2.000,17.514 6.486,22.000 12.000,22.000 C17.514,22.000 22.000,17.514 22.000,12.000 C22.000,6.486 17.514,2.000 12.000,2.000 ZM11.752,16.659 C11.555,16.884 11.278,17.000 10.999,17.000 C10.780,17.000 10.560,16.928 10.376,16.781 L5.376,12.781 C4.944,12.436 4.874,11.807 5.219,11.375 C5.564,10.944 6.195,10.875 6.624,11.219 L10.877,14.622 L17.248,7.341 C17.611,6.927 18.243,6.884 18.659,7.248 C19.074,7.611 19.116,8.243 18.752,8.659 L11.752,16.659 Z"
                                />
                            </Button>
                        </Tooltip>
                    }
                    {item.Status === 'Inactive' ?
                        <Menu
                            trigger={
                                <MenuButton className="reference-card-button" size="small">
                                    <Icon
                                        ariaLabel="Trash"
                                        viewBox={{width: 24, height: 24}}
                                        pathData="M19.000,6.000 L17.910,6.000 L16.105,22.118 C15.986,23.173 15.062,24.000 14.000,24.000 L6.000,24.000 C4.937,24.000 4.014,23.172 3.897,22.116 L2.120,6.000 L1.000,6.000 C0.447,6.000 0.000,5.552 0.000,5.000 C0.000,4.448 0.447,4.000 1.000,4.000 L6.000,4.000 L6.000,3.000 C6.000,1.346 7.346,-0.000 9.000,-0.000 L11.000,-0.000 C12.654,-0.000 14.000,1.346 14.000,3.000 L14.000,4.000 L19.000,4.000 C19.553,4.000 20.000,4.448 20.000,5.000 C20.000,5.552 19.553,6.000 19.000,6.000 ZM12.000,3.000 C12.000,2.449 11.552,2.000 11.000,2.000 L9.000,2.000 C8.448,2.000 8.000,2.449 8.000,3.000 L8.000,4.000 L12.000,4.000 L12.000,3.000 ZM4.132,6.000 L5.885,21.896 C5.889,21.935 5.960,22.000 6.000,22.000 L14.000,22.000 C14.040,22.000 14.113,21.935 14.117,21.894 L15.897,6.000 L4.132,6.000 Z"
                                    />
                                </MenuButton>
                            }
                        >
                            <MenuItem onClick={() => handleDeleteAccessKey(item.AccessKeyId)}>Confirm</MenuItem>
                            <Divider />
                            <MenuItem>Cancel</MenuItem>
                        </Menu> : <></>
                    }
                </Flex>
            </TableCell>
        </TableRow>
    )

    return (
        <>
            <WhiteNavbar {...props}/>
            <MediaPageHeader title={props.account.first_name+' '+props.account.last_name} subTitle={props.account.description}/>
            <div className="section">
                <Flex justifyContent="center">
                    <View width={'75%'} >
                        {/*Personal information*/}
                        <View style={{paddingBottom:"2rem"}} direction={"column"} gap={"0.25rem"}>
                            <Heading level={"3"} style={{
                                borderBottomWidth:"1px",
                                borderBottomColor:"black",
                                borderBottomStyle:"solid"}}>Personal information</Heading>
                            <VQTextField
                                label="First Name"
                                value={props.account.first_name || ''}
                                onChange={(value)=>props.onChangeAccount("first_name",value)}/>
                            <VQTextField
                                label="Last Name"
                                value={props.account.last_name || ''}
                                onChange={(value)=>props.onChangeAccount("last_name",value)}/>
                            <VQTextField
                                label="Company Name"
                                value={props.account.company_name || ''}
                                onChange={(value)=>props.onChangeAccount("company_name",value)}/>
                            <Flex style={{paddingTop:"1rem"}} direction={"row"} alignItems={"center"} gap={"0"}>
                                <Heading level={"6"} style={{paddingRight:"1rem"}}>Email</Heading>
                                <Text  style={{paddingRight:"6px"}}>{props.account.email}</Text>
                            </Flex>
                            <VQTextField
                                label="Function description"
                                value={props.account.description || ''}
                                onChange={(value)=>props.onChangeAccount("description",value)}/>
                        </View>
                        {/*Access keys*/}
                        <View style={{paddingBottom:"2rem"}}>
                            <Heading level={"3"} style={{
                                borderBottomWidth:"1px",
                                borderBottomColor:"black",
                                borderBottomStyle:"solid"}}>Access keys</Heading>
                            <Text style={{paddingTop:"1rem"}}>Use access keys to make programmatic calls to VQaaS. You can have a maximum of two access keys (active or inactive) at a time.</Text>
                            <Text>For your protection, you should never share your secret keys with anyone. As a best practice, we recommend frequent key rotation.</Text>
                            <Text color={"#3380FF"}>If you lose or forget your secret key, you cannot retrieve it. Instead, create a new access key and make the old key inactive.</Text>
                            <Collection
                                type="list"
                                items={refAlertList}
                                direction="column"
                                justifyContent="flex-start"
                            >
                                {(refAlert, index) => (
                                    <Alert key={index} variation="success" isDismissible={true}>{refAlert}</Alert>
                                )}
                            </Collection>
                            <View style={{paddingTop:"1rem", paddingBottom:"1rem"}}>
                                <Button
                                    className="reference-card-button"
                                    variation="primary"
                                    onClick={()=> handleCreateAccessKey()}
                                >
                                    Create access key
                                </Button>
                            </View>
                            <Table highlightOnHover={true} size={"small"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell as="th">Access key ID</TableCell>
                                        <TableCell as="th">Created </TableCell>
                                        <TableCell as="th">Last used</TableCell>
                                        <TableCell as="th">Status</TableCell>
                                        <TableCell as="th">Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableEntries}
                                </TableBody>
                            </Table>
                        </View>
                        {/*Cross account S3 bucket*/}
                        <View style={{paddingBottom:"2rem"}}>
                            <Heading level={"3"} style={{
                                borderBottomWidth:"1px",
                                borderBottomColor:"black",
                                borderBottomStyle:"solid"}}>Cross account S3 bucket</Heading>
                            <Text style={{paddingTop:"1rem"}}>VQaas can use any bucket as a media source. All you need to do is to set the correct permissions.</Text>
                            <Text>If you provide bucket name and region, VQaaS generates the bucket policy. This can be copied below.</Text>
                            <VQTextField
                                label="S3 bucket name"
                                value={props.account.s3_bucket || ''}
                                onChange={(value)=>props.onChangeAccount("s3_bucket",value)}/>
                            <VQTextField
                                label="S3 region"
                                value={props.account.s3_bucket_region || ''}
                                onChange={(value)=>props.onChangeAccount("s3_bucket_region",value)}/>
                            <Flex style={{paddingTop:"1rem"}} direction={"row"} alignItems={"flex-start"} gap={"0.5rem"}>
                                <Heading level={"6"}>Bucket policy</Heading>
                                <Tooltip
                                    title="Copy to clipboard"
                                    placement="bottom"
                                >
                                    <Button className="pencil-button" size="small" onClick={() => navigator.clipboard.writeText(bucket_policy)} style={{padding:"3px"}}>
                                        <Icon
                                            ariaLabel="Copy"
                                            viewBox={{ width: 24, height: 24 }}
                                            pathData="M23.981,7.083 L23.981,18.950 C23.981,20.050 23.084,20.944 21.983,20.944 L17.986,20.944 C17.433,20.944 16.986,20.498 16.986,19.947 C16.986,19.396 17.433,18.950 17.986,18.950 L21.983,18.950 L21.983,6.986 C21.983,6.969 21.991,6.955 21.992,6.938 L17.000,6.938 C16.448,6.938 15.987,7.537 15.987,6.986 L15.987,1.938 L9.000,1.938 C8.448,1.938 7.993,1.555 7.993,1.004 C7.993,0.453 8.440,0.007 8.992,0.007 L16.986,0.007 C17.009,0.007 17.027,0.019 17.049,0.020 C17.139,0.026 17.226,0.043 17.311,0.073 C17.343,0.084 17.376,0.092 17.407,0.107 C17.508,0.154 17.603,0.213 17.686,0.294 C17.688,0.297 17.692,0.298 17.694,0.301 L23.580,6.172 C23.612,6.195 23.627,6.233 23.656,6.260 C23.685,6.286 23.707,6.317 23.733,6.347 C23.887,6.523 24.001,6.735 24.001,6.986 C24.001,7.021 23.984,7.049 23.981,7.083 ZM17.986,3.411 L17.986,5.989 L20.570,5.989 L17.986,3.411 ZM9.050,3.010 C9.143,3.015 9.233,3.033 9.320,3.064 C9.351,3.075 9.382,3.083 9.412,3.097 C9.513,3.144 9.609,3.204 9.692,3.285 C9.694,3.288 9.698,3.289 9.700,3.292 L15.586,9.163 C15.618,9.186 15.633,9.225 15.662,9.251 C15.691,9.277 15.713,9.308 15.739,9.338 C15.893,9.514 16.007,9.725 16.007,9.977 C16.007,10.012 15.990,10.041 15.987,10.074 L15.987,21.941 C15.987,23.041 15.090,23.935 13.989,23.935 L1.998,23.935 C0.896,23.935 -0.001,23.041 -0.001,21.941 L-0.001,4.992 C-0.001,3.893 0.896,2.998 1.998,2.998 L8.992,2.998 C9.013,2.998 9.030,3.009 9.050,3.010 ZM9.992,6.402 L9.992,8.980 L12.575,8.980 L9.992,6.402 ZM7.993,9.977 L7.993,4.938 L2.000,4.938 L1.998,21.941 L13.989,21.941 L13.989,10.938 L9.000,10.938 C8.448,10.938 7.993,10.528 7.993,9.977 Z"
                                        />
                                    </Button>
                                </Tooltip>
                                <View>
                                    <pre >
                                        <code>
                                            {bucket_policy}
                                        </code>
                                    </pre>
                                </View>
                            </Flex>
                        </View>
                        {/*Teams*/}
                        {/*<View style={{paddingBottom:"2rem"}}>*/}
                        {/*    <Heading level={"3"} style={{*/}
                        {/*        borderBottomWidth:"1px",*/}
                        {/*        borderBottomColor:"black",*/}
                        {/*        borderBottomStyle:"solid"}}>Teams</Heading>*/}
                        {/*    <br/>*/}
                        {/*    <Collection*/}
                        {/*        type="list"*/}
                        {/*        items={props.account.teams.items}*/}
                        {/*        itemsPerPage={16}*/}
                        {/*        isPaginated*/}
                        {/*        direction="row"*/}
                        {/*        justifyContent="flex-start"*/}
                        {/*        wrap="wrap"*/}
                        {/*    >*/}
                        {/*        {(item, index) => (*/}
                        {/*            <TeamCard*/}
                        {/*                key={item.team.id}*/}
                        {/*                team={item.team} />*/}
                        {/*        )}*/}
                        {/*    </Collection>*/}
                        {/*</View>*/}
                    </View>
                </Flex>
            </div>
            <FooterBlack {...props}/>
        </>
    );
}

export default ProfilePage;
