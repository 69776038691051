import React from "react";
import {TableCell} from '@aws-amplify/ui-react';

function DurationTimeCell(props) {
    return (
        <>
            { props.distorted ?
                <TableCell className="synamedia-td">
                    {
                        props.distorted.spec_duration_time ?
                            props.distorted.spec_duration_time + ' s'
                            :
                            ''
                    }
                </TableCell> : <TableCell/>
            }
        </>
    );
}

export default DurationTimeCell;
