/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAccount = /* GraphQL */ `
  mutation CreateAccount(
    $input: CreateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    createAccount(input: $input, condition: $condition) {
      id
      company_name
      credits
      cross_account_s3 {
        items {
          id
          Key
          ETag
          Size
          LastModified
          LastSeen
          accountID
          referenceID
          createdAt
          updatedAt
        }
        nextToken
      }
      description
      email
      first_name
      following {
        name
        ownerID
        accepted
      }
      followed_by {
        name
        ownerID
        accepted
      }
      last_login
      last_upload
      last_name
      num_references
      num_distorteds
      owner
      media {
        items {
          id
          accountID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      encode_analyses {
        items {
          id
          accountID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      comparisons {
        items {
          id
          accountID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      references {
        items {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          description
          filename
          viewers
          id
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_extract_feat
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          scan_type
          scan_order
          updatedAt
          user_identity_id
        }
        nextToken
      }
      refresh
      s3_bucket
      s3_bucket_region
      subscriptions {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      user_identity_id
      createdAt
      updatedAt
    }
  }
`;
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      company_name
      credits
      cross_account_s3 {
        items {
          id
          Key
          ETag
          Size
          LastModified
          LastSeen
          accountID
          referenceID
          createdAt
          updatedAt
        }
        nextToken
      }
      description
      email
      first_name
      following {
        name
        ownerID
        accepted
      }
      followed_by {
        name
        ownerID
        accepted
      }
      last_login
      last_upload
      last_name
      num_references
      num_distorteds
      owner
      media {
        items {
          id
          accountID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      encode_analyses {
        items {
          id
          accountID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      comparisons {
        items {
          id
          accountID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      references {
        items {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          description
          filename
          viewers
          id
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_extract_feat
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          scan_type
          scan_order
          updatedAt
          user_identity_id
        }
        nextToken
      }
      refresh
      s3_bucket
      s3_bucket_region
      subscriptions {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      user_identity_id
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount(
    $input: DeleteAccountInput!
    $condition: ModelAccountConditionInput
  ) {
    deleteAccount(input: $input, condition: $condition) {
      id
      company_name
      credits
      cross_account_s3 {
        items {
          id
          Key
          ETag
          Size
          LastModified
          LastSeen
          accountID
          referenceID
          createdAt
          updatedAt
        }
        nextToken
      }
      description
      email
      first_name
      following {
        name
        ownerID
        accepted
      }
      followed_by {
        name
        ownerID
        accepted
      }
      last_login
      last_upload
      last_name
      num_references
      num_distorteds
      owner
      media {
        items {
          id
          accountID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      encode_analyses {
        items {
          id
          accountID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      comparisons {
        items {
          id
          accountID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      references {
        items {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          description
          filename
          viewers
          id
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_extract_feat
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          scan_type
          scan_order
          updatedAt
          user_identity_id
        }
        nextToken
      }
      refresh
      s3_bucket
      s3_bucket_region
      subscriptions {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      user_identity_id
      createdAt
      updatedAt
    }
  }
`;
export const createAccountSubscription = /* GraphQL */ `
  mutation CreateAccountSubscription(
    $input: CreateAccountSubscriptionInput!
    $condition: ModelAccountSubscriptionConditionInput
  ) {
    createAccountSubscription(input: $input, condition: $condition) {
      id
      accountID
      subscriptionID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAccountSubscription = /* GraphQL */ `
  mutation UpdateAccountSubscription(
    $input: UpdateAccountSubscriptionInput!
    $condition: ModelAccountSubscriptionConditionInput
  ) {
    updateAccountSubscription(input: $input, condition: $condition) {
      id
      accountID
      subscriptionID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccountSubscription = /* GraphQL */ `
  mutation DeleteAccountSubscription(
    $input: DeleteAccountSubscriptionInput!
    $condition: ModelAccountSubscriptionConditionInput
  ) {
    deleteAccountSubscription(input: $input, condition: $condition) {
      id
      accountID
      subscriptionID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAccountTeam = /* GraphQL */ `
  mutation CreateAccountTeam(
    $input: CreateAccountTeamInput!
    $condition: ModelAccountTeamConditionInput
  ) {
    createAccountTeam(input: $input, condition: $condition) {
      id
      accountID
      teamID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAccountTeam = /* GraphQL */ `
  mutation UpdateAccountTeam(
    $input: UpdateAccountTeamInput!
    $condition: ModelAccountTeamConditionInput
  ) {
    updateAccountTeam(input: $input, condition: $condition) {
      id
      accountID
      teamID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAccountTeam = /* GraphQL */ `
  mutation DeleteAccountTeam(
    $input: DeleteAccountTeamInput!
    $condition: ModelAccountTeamConditionInput
  ) {
    deleteAccountTeam(input: $input, condition: $condition) {
      id
      accountID
      teamID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReferenceSubscription = /* GraphQL */ `
  mutation CreateReferenceSubscription(
    $input: CreateReferenceSubscriptionInput!
    $condition: ModelReferenceSubscriptionConditionInput
  ) {
    createReferenceSubscription(input: $input, condition: $condition) {
      id
      referenceID
      subscriptionID
      reference {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        createdAt
        cross_account_s3_id
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        viewers
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        id
        owner
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        scan_type
        scan_order
        updatedAt
        user_identity_id
      }
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateReferenceSubscription = /* GraphQL */ `
  mutation UpdateReferenceSubscription(
    $input: UpdateReferenceSubscriptionInput!
    $condition: ModelReferenceSubscriptionConditionInput
  ) {
    updateReferenceSubscription(input: $input, condition: $condition) {
      id
      referenceID
      subscriptionID
      reference {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        createdAt
        cross_account_s3_id
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        viewers
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        id
        owner
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        scan_type
        scan_order
        updatedAt
        user_identity_id
      }
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteReferenceSubscription = /* GraphQL */ `
  mutation DeleteReferenceSubscription(
    $input: DeleteReferenceSubscriptionInput!
    $condition: ModelReferenceSubscriptionConditionInput
  ) {
    deleteReferenceSubscription(input: $input, condition: $condition) {
      id
      referenceID
      subscriptionID
      reference {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        createdAt
        cross_account_s3_id
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        viewers
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        id
        owner
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        scan_type
        scan_order
        updatedAt
        user_identity_id
      }
      subscription {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReferenceTeam = /* GraphQL */ `
  mutation CreateReferenceTeam(
    $input: CreateReferenceTeamInput!
    $condition: ModelReferenceTeamConditionInput
  ) {
    createReferenceTeam(input: $input, condition: $condition) {
      id
      referenceID
      teamID
      reference {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        createdAt
        cross_account_s3_id
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        viewers
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        id
        owner
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        scan_type
        scan_order
        updatedAt
        user_identity_id
      }
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateReferenceTeam = /* GraphQL */ `
  mutation UpdateReferenceTeam(
    $input: UpdateReferenceTeamInput!
    $condition: ModelReferenceTeamConditionInput
  ) {
    updateReferenceTeam(input: $input, condition: $condition) {
      id
      referenceID
      teamID
      reference {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        createdAt
        cross_account_s3_id
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        viewers
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        id
        owner
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        scan_type
        scan_order
        updatedAt
        user_identity_id
      }
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteReferenceTeam = /* GraphQL */ `
  mutation DeleteReferenceTeam(
    $input: DeleteReferenceTeamInput!
    $condition: ModelReferenceTeamConditionInput
  ) {
    deleteReferenceTeam(input: $input, condition: $condition) {
      id
      referenceID
      teamID
      reference {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        createdAt
        cross_account_s3_id
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        viewers
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        id
        owner
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_extract_feat
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        scan_type
        scan_order
        updatedAt
        user_identity_id
      }
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVQaaSSubscription = /* GraphQL */ `
  mutation CreateVQaaSSubscription(
    $input: CreateVQaaSSubscriptionInput!
    $condition: ModelVQaaSSubscriptionConditionInput
  ) {
    createVQaaSSubscription(input: $input, condition: $condition) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVQaaSSubscription = /* GraphQL */ `
  mutation UpdateVQaaSSubscription(
    $input: UpdateVQaaSSubscriptionInput!
    $condition: ModelVQaaSSubscriptionConditionInput
  ) {
    updateVQaaSSubscription(input: $input, condition: $condition) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVQaaSSubscription = /* GraphQL */ `
  mutation DeleteVQaaSSubscription(
    $input: DeleteVQaaSSubscriptionInput!
    $condition: ModelVQaaSSubscriptionConditionInput
  ) {
    deleteVQaaSSubscription(input: $input, condition: $condition) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTeam = /* GraphQL */ `
  mutation CreateTeam(
    $input: CreateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    createTeam(input: $input, condition: $condition) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          id
          referenceID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      comparisons {
        items {
          id
          teamID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      encode_analyses {
        items {
          id
          teamID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      media {
        items {
          id
          teamID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam(
    $input: UpdateTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    updateTeam(input: $input, condition: $condition) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          id
          referenceID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      comparisons {
        items {
          id
          teamID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      encode_analyses {
        items {
          id
          teamID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      media {
        items {
          id
          teamID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam(
    $input: DeleteTeamInput!
    $condition: ModelTeamConditionInput
  ) {
    deleteTeam(input: $input, condition: $condition) {
      id
      name
      description
      accounts {
        items {
          id
          accountID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      references {
        items {
          id
          referenceID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      comparisons {
        items {
          id
          teamID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      encode_analyses {
        items {
          id
          teamID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      media {
        items {
          id
          teamID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCrossAccountS3 = /* GraphQL */ `
  mutation CreateCrossAccountS3(
    $input: CreateCrossAccountS3Input!
    $condition: ModelCrossAccountS3ConditionInput
  ) {
    createCrossAccountS3(input: $input, condition: $condition) {
      id
      Key
      ETag
      Size
      LastModified
      LastSeen
      accountID
      referenceID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCrossAccountS3 = /* GraphQL */ `
  mutation UpdateCrossAccountS3(
    $input: UpdateCrossAccountS3Input!
    $condition: ModelCrossAccountS3ConditionInput
  ) {
    updateCrossAccountS3(input: $input, condition: $condition) {
      id
      Key
      ETag
      Size
      LastModified
      LastSeen
      accountID
      referenceID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCrossAccountS3 = /* GraphQL */ `
  mutation DeleteCrossAccountS3(
    $input: DeleteCrossAccountS3Input!
    $condition: ModelCrossAccountS3ConditionInput
  ) {
    deleteCrossAccountS3(input: $input, condition: $condition) {
      id
      Key
      ETag
      Size
      LastModified
      LastSeen
      accountID
      referenceID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCrossCorrelation = /* GraphQL */ `
  mutation CreateCrossCorrelation(
    $input: CreateCrossCorrelationInput!
    $condition: ModelCrossCorrelationConditionInput
  ) {
    createCrossCorrelation(input: $input, condition: $condition) {
      id
      groups
      owner
      offset
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const updateCrossCorrelation = /* GraphQL */ `
  mutation UpdateCrossCorrelation(
    $input: UpdateCrossCorrelationInput!
    $condition: ModelCrossCorrelationConditionInput
  ) {
    updateCrossCorrelation(input: $input, condition: $condition) {
      id
      groups
      owner
      offset
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const deleteCrossCorrelation = /* GraphQL */ `
  mutation DeleteCrossCorrelation(
    $input: DeleteCrossCorrelationInput!
    $condition: ModelCrossCorrelationConditionInput
  ) {
    deleteCrossCorrelation(input: $input, condition: $condition) {
      id
      groups
      owner
      offset
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const createDisMSE = /* GraphQL */ `
  mutation CreateDisMSE(
    $input: CreateDisMSEInput!
    $condition: ModelDisMSEConditionInput
  ) {
    createDisMSE(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const updateDisMSE = /* GraphQL */ `
  mutation UpdateDisMSE(
    $input: UpdateDisMSEInput!
    $condition: ModelDisMSEConditionInput
  ) {
    updateDisMSE(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const deleteDisMSE = /* GraphQL */ `
  mutation DeleteDisMSE(
    $input: DeleteDisMSEInput!
    $condition: ModelDisMSEConditionInput
  ) {
    deleteDisMSE(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const createDistorted = /* GraphQL */ `
  mutation CreateDistorted(
    $input: CreateDistortedInput!
    $condition: ModelDistortedConditionInput
  ) {
    createDistorted(input: $input, condition: $condition) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      align_offset
      build_number
      createdAt
      cross_account_s3_id
      description
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      persons
      subscriptions
      teams
      id
      metric_psnr
      metric_psnr_bin
      metric_psnr_cdf
      metric_vmaf
      metric_vmaf_bin
      metric_vmaf_cdf
      owner
      progress_calc_metric
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      reference_id
      ana_fps_nominator
      ana_fps_denominator
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      scan_type
      scan_order
      updatedAt
      user_identity_id
    }
  }
`;
export const updateDistorted = /* GraphQL */ `
  mutation UpdateDistorted(
    $input: UpdateDistortedInput!
    $condition: ModelDistortedConditionInput
  ) {
    updateDistorted(input: $input, condition: $condition) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      align_offset
      build_number
      createdAt
      cross_account_s3_id
      description
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      persons
      subscriptions
      teams
      id
      metric_psnr
      metric_psnr_bin
      metric_psnr_cdf
      metric_vmaf
      metric_vmaf_bin
      metric_vmaf_cdf
      owner
      progress_calc_metric
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      reference_id
      ana_fps_nominator
      ana_fps_denominator
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      scan_type
      scan_order
      updatedAt
      user_identity_id
    }
  }
`;
export const deleteDistorted = /* GraphQL */ `
  mutation DeleteDistorted(
    $input: DeleteDistortedInput!
    $condition: ModelDistortedConditionInput
  ) {
    deleteDistorted(input: $input, condition: $condition) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      align_offset
      build_number
      createdAt
      cross_account_s3_id
      description
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      persons
      subscriptions
      teams
      id
      metric_psnr
      metric_psnr_bin
      metric_psnr_cdf
      metric_vmaf
      metric_vmaf_bin
      metric_vmaf_cdf
      owner
      progress_calc_metric
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      reference_id
      ana_fps_nominator
      ana_fps_denominator
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      scan_type
      scan_order
      updatedAt
      user_identity_id
    }
  }
`;
export const createEncodeAnalysis = /* GraphQL */ `
  mutation CreateEncodeAnalysis(
    $input: CreateEncodeAnalysisInput!
    $condition: ModelEncodeAnalysisConditionInput
  ) {
    createEncodeAnalysis(input: $input, condition: $condition) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      refMedia {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      refMediaID
      encMedia {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      encMediaID
      align_offset
      build_number
      createdAt
      cross_account_s3_id
      deleted
      description
      encode_id
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      comparisons {
        items {
          id
          encodeAnalysisID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      measurementPointId
      measurementPoint {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
      persons {
        items {
          id
          accountID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscriptions
      teams {
        items {
          id
          teamID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      id
      vmaf_reduct
      psnr_reduct
      metric_psnr
      metric_vmaf
      owner
      progress_calc_metric
      progress_create_hls
      progress_deploy
      progress_extract_feat
      progress_upload
      reference_id
      ana_fps_nominator
      ana_fps_denominator
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      status
      scan_type
      scan_order
      scan_mode
      updatedAt
      user_identity_id
    }
  }
`;
export const updateEncodeAnalysis = /* GraphQL */ `
  mutation UpdateEncodeAnalysis(
    $input: UpdateEncodeAnalysisInput!
    $condition: ModelEncodeAnalysisConditionInput
  ) {
    updateEncodeAnalysis(input: $input, condition: $condition) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      refMedia {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      refMediaID
      encMedia {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      encMediaID
      align_offset
      build_number
      createdAt
      cross_account_s3_id
      deleted
      description
      encode_id
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      comparisons {
        items {
          id
          encodeAnalysisID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      measurementPointId
      measurementPoint {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
      persons {
        items {
          id
          accountID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscriptions
      teams {
        items {
          id
          teamID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      id
      vmaf_reduct
      psnr_reduct
      metric_psnr
      metric_vmaf
      owner
      progress_calc_metric
      progress_create_hls
      progress_deploy
      progress_extract_feat
      progress_upload
      reference_id
      ana_fps_nominator
      ana_fps_denominator
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      status
      scan_type
      scan_order
      scan_mode
      updatedAt
      user_identity_id
    }
  }
`;
export const deleteEncodeAnalysis = /* GraphQL */ `
  mutation DeleteEncodeAnalysis(
    $input: DeleteEncodeAnalysisInput!
    $condition: ModelEncodeAnalysisConditionInput
  ) {
    deleteEncodeAnalysis(input: $input, condition: $condition) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      refMedia {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      refMediaID
      encMedia {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      encMediaID
      align_offset
      build_number
      createdAt
      cross_account_s3_id
      deleted
      description
      encode_id
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      comparisons {
        items {
          id
          encodeAnalysisID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      measurementPointId
      measurementPoint {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
      persons {
        items {
          id
          accountID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      subscriptions
      teams {
        items {
          id
          teamID
          encodeAnalysisID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      id
      vmaf_reduct
      psnr_reduct
      metric_psnr
      metric_vmaf
      owner
      progress_calc_metric
      progress_create_hls
      progress_deploy
      progress_extract_feat
      progress_upload
      reference_id
      ana_fps_nominator
      ana_fps_denominator
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      status
      scan_type
      scan_order
      scan_mode
      updatedAt
      user_identity_id
    }
  }
`;
export const createComparison = /* GraphQL */ `
  mutation CreateComparison(
    $input: CreateComparisonInput!
    $condition: ModelComparisonConditionInput
  ) {
    createComparison(input: $input, condition: $condition) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      createdAt
      deleted
      description
      encodeAnalyses {
        items {
          id
          encodeAnalysisID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      id
      owner
      persons {
        items {
          id
          accountID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      teams {
        items {
          id
          teamID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateComparison = /* GraphQL */ `
  mutation UpdateComparison(
    $input: UpdateComparisonInput!
    $condition: ModelComparisonConditionInput
  ) {
    updateComparison(input: $input, condition: $condition) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      createdAt
      deleted
      description
      encodeAnalyses {
        items {
          id
          encodeAnalysisID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      id
      owner
      persons {
        items {
          id
          accountID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      teams {
        items {
          id
          teamID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteComparison = /* GraphQL */ `
  mutation DeleteComparison(
    $input: DeleteComparisonInput!
    $condition: ModelComparisonConditionInput
  ) {
    deleteComparison(input: $input, condition: $condition) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      createdAt
      deleted
      description
      encodeAnalyses {
        items {
          id
          encodeAnalysisID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      id
      owner
      persons {
        items {
          id
          accountID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      teams {
        items {
          id
          teamID
          comparisonID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      updatedAt
    }
  }
`;
export const createMedia = /* GraphQL */ `
  mutation CreateMedia(
    $input: CreateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    createMedia(input: $input, condition: $condition) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      build_number
      referenceMedia {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          updatedAt
          user_identity_id
        }
        nextToken
      }
      encodedMedia {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          updatedAt
          user_identity_id
        }
        nextToken
      }
      createdAt
      cross_account_s3_id
      deleted
      description
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      id
      measurementPointId
      measurementPoint {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
      owner
      persons {
        items {
          id
          accountID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      teams {
        items {
          id
          teamID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      status
      scan_type
      scan_order
      scan_mode
      fps
      bitrate_list_per_sec
      width
      height
      extension
      es_filename
      updatedAt
      user_identity_id
    }
  }
`;
export const updateMedia = /* GraphQL */ `
  mutation UpdateMedia(
    $input: UpdateMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    updateMedia(input: $input, condition: $condition) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      build_number
      referenceMedia {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          updatedAt
          user_identity_id
        }
        nextToken
      }
      encodedMedia {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          updatedAt
          user_identity_id
        }
        nextToken
      }
      createdAt
      cross_account_s3_id
      deleted
      description
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      id
      measurementPointId
      measurementPoint {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
      owner
      persons {
        items {
          id
          accountID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      teams {
        items {
          id
          teamID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      status
      scan_type
      scan_order
      scan_mode
      fps
      bitrate_list_per_sec
      width
      height
      extension
      es_filename
      updatedAt
      user_identity_id
    }
  }
`;
export const deleteMedia = /* GraphQL */ `
  mutation DeleteMedia(
    $input: DeleteMediaInput!
    $condition: ModelMediaConditionInput
  ) {
    deleteMedia(input: $input, condition: $condition) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      build_number
      referenceMedia {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          updatedAt
          user_identity_id
        }
        nextToken
      }
      encodedMedia {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          updatedAt
          user_identity_id
        }
        nextToken
      }
      createdAt
      cross_account_s3_id
      deleted
      description
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      id
      measurementPointId
      measurementPoint {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
      owner
      persons {
        items {
          id
          accountID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      teams {
        items {
          id
          teamID
          mediaID
          createdAt
          updatedAt
          owner
        }
        nextToken
      }
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      status
      scan_type
      scan_order
      scan_mode
      fps
      bitrate_list_per_sec
      width
      height
      extension
      es_filename
      updatedAt
      user_identity_id
    }
  }
`;
export const createMeasurementPoint = /* GraphQL */ `
  mutation CreateMeasurementPoint(
    $input: CreateMeasurementPointInput!
    $condition: ModelMeasurementPointConditionInput
  ) {
    createMeasurementPoint(input: $input, condition: $condition) {
      createdAt
      description
      id
      media {
        items {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        nextToken
      }
      encodeAnalyses {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          updatedAt
          user_identity_id
        }
        nextToken
      }
      owner
      updatedAt
      children {
        items {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        nextToken
      }
      parentID
      parent {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
    }
  }
`;
export const updateMeasurementPoint = /* GraphQL */ `
  mutation UpdateMeasurementPoint(
    $input: UpdateMeasurementPointInput!
    $condition: ModelMeasurementPointConditionInput
  ) {
    updateMeasurementPoint(input: $input, condition: $condition) {
      createdAt
      description
      id
      media {
        items {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        nextToken
      }
      encodeAnalyses {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          updatedAt
          user_identity_id
        }
        nextToken
      }
      owner
      updatedAt
      children {
        items {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        nextToken
      }
      parentID
      parent {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
    }
  }
`;
export const deleteMeasurementPoint = /* GraphQL */ `
  mutation DeleteMeasurementPoint(
    $input: DeleteMeasurementPointInput!
    $condition: ModelMeasurementPointConditionInput
  ) {
    deleteMeasurementPoint(input: $input, condition: $condition) {
      createdAt
      description
      id
      media {
        items {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        nextToken
      }
      encodeAnalyses {
        items {
          accountID
          refMediaID
          encMediaID
          align_offset
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          encode_id
          filename
          measurementPointId
          subscriptions
          id
          vmaf_reduct
          psnr_reduct
          metric_psnr
          metric_vmaf
          owner
          progress_calc_metric
          progress_create_hls
          progress_deploy
          progress_extract_feat
          progress_upload
          reference_id
          ana_fps_nominator
          ana_fps_denominator
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          updatedAt
          user_identity_id
        }
        nextToken
      }
      owner
      updatedAt
      children {
        items {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        nextToken
      }
      parentID
      parent {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
    }
  }
`;
export const createChannel = /* GraphQL */ `
  mutation CreateChannel(
    $input: CreateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    createChannel(input: $input, condition: $condition) {
      id
      name
      owner
      rootID
      root {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateChannel = /* GraphQL */ `
  mutation UpdateChannel(
    $input: UpdateChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    updateChannel(input: $input, condition: $condition) {
      id
      name
      owner
      rootID
      root {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteChannel = /* GraphQL */ `
  mutation DeleteChannel(
    $input: DeleteChannelInput!
    $condition: ModelChannelConditionInput
  ) {
    deleteChannel(input: $input, condition: $condition) {
      id
      name
      owner
      rootID
      root {
        createdAt
        description
        id
        media {
          nextToken
        }
        encodeAnalyses {
          nextToken
        }
        owner
        updatedAt
        children {
          nextToken
        }
        parentID
        parent {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPSNRavg = /* GraphQL */ `
  mutation CreatePSNRavg(
    $input: CreatePSNRavgInput!
    $condition: ModelPSNRavgConditionInput
  ) {
    createPSNRavg(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const updatePSNRavg = /* GraphQL */ `
  mutation UpdatePSNRavg(
    $input: UpdatePSNRavgInput!
    $condition: ModelPSNRavgConditionInput
  ) {
    updatePSNRavg(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const deletePSNRavg = /* GraphQL */ `
  mutation DeletePSNRavg(
    $input: DeletePSNRavgInput!
    $condition: ModelPSNRavgConditionInput
  ) {
    deletePSNRavg(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const createRefMSE = /* GraphQL */ `
  mutation CreateRefMSE(
    $input: CreateRefMSEInput!
    $condition: ModelRefMSEConditionInput
  ) {
    createRefMSE(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const updateRefMSE = /* GraphQL */ `
  mutation UpdateRefMSE(
    $input: UpdateRefMSEInput!
    $condition: ModelRefMSEConditionInput
  ) {
    updateRefMSE(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const deleteRefMSE = /* GraphQL */ `
  mutation DeleteRefMSE(
    $input: DeleteRefMSEInput!
    $condition: ModelRefMSEConditionInput
  ) {
    deleteRefMSE(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const createReference = /* GraphQL */ `
  mutation CreateReference(
    $input: CreateReferenceInput!
    $condition: ModelReferenceConditionInput
  ) {
    createReference(input: $input, condition: $condition) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      build_number
      createdAt
      cross_account_s3_id
      description
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      viewers
      subscriptions {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          referenceID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      id
      owner
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      scan_type
      scan_order
      updatedAt
      user_identity_id
    }
  }
`;
export const updateReference = /* GraphQL */ `
  mutation UpdateReference(
    $input: UpdateReferenceInput!
    $condition: ModelReferenceConditionInput
  ) {
    updateReference(input: $input, condition: $condition) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      build_number
      createdAt
      cross_account_s3_id
      description
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      viewers
      subscriptions {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          referenceID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      id
      owner
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      scan_type
      scan_order
      updatedAt
      user_identity_id
    }
  }
`;
export const deleteReference = /* GraphQL */ `
  mutation DeleteReference(
    $input: DeleteReferenceInput!
    $condition: ModelReferenceConditionInput
  ) {
    deleteReference(input: $input, condition: $condition) {
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      accountID
      build_number
      createdAt
      cross_account_s3_id
      description
      filename
      interlace_rep {
        Legacy
        DeInterlaceOff
        BWDifSendFrameTFF
        BWDifSendFrameBFF
        BWDifSendFieldTFF
        BWDifSendFieldBFF
        DeInterleave
      }
      viewers
      subscriptions {
        items {
          id
          referenceID
          subscriptionID
          createdAt
          updatedAt
        }
        nextToken
      }
      teams {
        items {
          id
          referenceID
          teamID
          createdAt
          updatedAt
        }
        nextToken
      }
      id
      owner
      progress_create_hls
      progress_cross_copy
      progress_decode
      progress_deploy
      progress_extract_es
      progress_extract_feat
      progress_upload
      spec_codec
      spec_duration_frames
      spec_duration_time
      spec_frame_rate
      spec_height
      spec_pid
      spec_pix_fmt
      spec_width
      scan_type
      scan_order
      updatedAt
      user_identity_id
    }
  }
`;
export const createSSIM = /* GraphQL */ `
  mutation CreateSSIM(
    $input: CreateSSIMInput!
    $condition: ModelSSIMConditionInput
  ) {
    createSSIM(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const updateSSIM = /* GraphQL */ `
  mutation UpdateSSIM(
    $input: UpdateSSIMInput!
    $condition: ModelSSIMConditionInput
  ) {
    updateSSIM(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const deleteSSIM = /* GraphQL */ `
  mutation DeleteSSIM(
    $input: DeleteSSIMInput!
    $condition: ModelSSIMConditionInput
  ) {
    deleteSSIM(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const createVMAF = /* GraphQL */ `
  mutation CreateVMAF(
    $input: CreateVMAFInput!
    $condition: ModelVMAFConditionInput
  ) {
    createVMAF(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const updateVMAF = /* GraphQL */ `
  mutation UpdateVMAF(
    $input: UpdateVMAFInput!
    $condition: ModelVMAFConditionInput
  ) {
    updateVMAF(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const deleteVMAF = /* GraphQL */ `
  mutation DeleteVMAF(
    $input: DeleteVMAFInput!
    $condition: ModelVMAFConditionInput
  ) {
    deleteVMAF(input: $input, condition: $condition) {
      id
      groups
      owner
      sequence
      values
      createdAt
      updatedAt
    }
  }
`;
export const createAccountMedia = /* GraphQL */ `
  mutation CreateAccountMedia(
    $input: CreateAccountMediaInput!
    $condition: ModelAccountMediaConditionInput
  ) {
    createAccountMedia(input: $input, condition: $condition) {
      id
      accountID
      mediaID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      media {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAccountMedia = /* GraphQL */ `
  mutation UpdateAccountMedia(
    $input: UpdateAccountMediaInput!
    $condition: ModelAccountMediaConditionInput
  ) {
    updateAccountMedia(input: $input, condition: $condition) {
      id
      accountID
      mediaID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      media {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAccountMedia = /* GraphQL */ `
  mutation DeleteAccountMedia(
    $input: DeleteAccountMediaInput!
    $condition: ModelAccountMediaConditionInput
  ) {
    deleteAccountMedia(input: $input, condition: $condition) {
      id
      accountID
      mediaID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      media {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAccountEncodeAnalysis = /* GraphQL */ `
  mutation CreateAccountEncodeAnalysis(
    $input: CreateAccountEncodeAnalysisInput!
    $condition: ModelAccountEncodeAnalysisConditionInput
  ) {
    createAccountEncodeAnalysis(input: $input, condition: $condition) {
      id
      accountID
      encodeAnalysisID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      encodeAnalysis {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        refMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        refMediaID
        encMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        encMediaID
        align_offset
        build_number
        createdAt
        cross_account_s3_id
        deleted
        description
        encode_id
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        comparisons {
          nextToken
        }
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        persons {
          nextToken
        }
        subscriptions
        teams {
          nextToken
        }
        id
        vmaf_reduct
        psnr_reduct
        metric_psnr
        metric_vmaf
        owner
        progress_calc_metric
        progress_create_hls
        progress_deploy
        progress_extract_feat
        progress_upload
        reference_id
        ana_fps_nominator
        ana_fps_denominator
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAccountEncodeAnalysis = /* GraphQL */ `
  mutation UpdateAccountEncodeAnalysis(
    $input: UpdateAccountEncodeAnalysisInput!
    $condition: ModelAccountEncodeAnalysisConditionInput
  ) {
    updateAccountEncodeAnalysis(input: $input, condition: $condition) {
      id
      accountID
      encodeAnalysisID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      encodeAnalysis {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        refMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        refMediaID
        encMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        encMediaID
        align_offset
        build_number
        createdAt
        cross_account_s3_id
        deleted
        description
        encode_id
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        comparisons {
          nextToken
        }
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        persons {
          nextToken
        }
        subscriptions
        teams {
          nextToken
        }
        id
        vmaf_reduct
        psnr_reduct
        metric_psnr
        metric_vmaf
        owner
        progress_calc_metric
        progress_create_hls
        progress_deploy
        progress_extract_feat
        progress_upload
        reference_id
        ana_fps_nominator
        ana_fps_denominator
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAccountEncodeAnalysis = /* GraphQL */ `
  mutation DeleteAccountEncodeAnalysis(
    $input: DeleteAccountEncodeAnalysisInput!
    $condition: ModelAccountEncodeAnalysisConditionInput
  ) {
    deleteAccountEncodeAnalysis(input: $input, condition: $condition) {
      id
      accountID
      encodeAnalysisID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      encodeAnalysis {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        refMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        refMediaID
        encMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        encMediaID
        align_offset
        build_number
        createdAt
        cross_account_s3_id
        deleted
        description
        encode_id
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        comparisons {
          nextToken
        }
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        persons {
          nextToken
        }
        subscriptions
        teams {
          nextToken
        }
        id
        vmaf_reduct
        psnr_reduct
        metric_psnr
        metric_vmaf
        owner
        progress_calc_metric
        progress_create_hls
        progress_deploy
        progress_extract_feat
        progress_upload
        reference_id
        ana_fps_nominator
        ana_fps_denominator
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAccountComparison = /* GraphQL */ `
  mutation CreateAccountComparison(
    $input: CreateAccountComparisonInput!
    $condition: ModelAccountComparisonConditionInput
  ) {
    createAccountComparison(input: $input, condition: $condition) {
      id
      accountID
      comparisonID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      comparison {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        createdAt
        deleted
        description
        encodeAnalyses {
          nextToken
        }
        id
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAccountComparison = /* GraphQL */ `
  mutation UpdateAccountComparison(
    $input: UpdateAccountComparisonInput!
    $condition: ModelAccountComparisonConditionInput
  ) {
    updateAccountComparison(input: $input, condition: $condition) {
      id
      accountID
      comparisonID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      comparison {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        createdAt
        deleted
        description
        encodeAnalyses {
          nextToken
        }
        id
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAccountComparison = /* GraphQL */ `
  mutation DeleteAccountComparison(
    $input: DeleteAccountComparisonInput!
    $condition: ModelAccountComparisonConditionInput
  ) {
    deleteAccountComparison(input: $input, condition: $condition) {
      id
      accountID
      comparisonID
      account {
        id
        company_name
        credits
        cross_account_s3 {
          nextToken
        }
        description
        email
        first_name
        following {
          name
          ownerID
          accepted
        }
        followed_by {
          name
          ownerID
          accepted
        }
        last_login
        last_upload
        last_name
        num_references
        num_distorteds
        owner
        media {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        comparisons {
          nextToken
        }
        references {
          nextToken
        }
        refresh
        s3_bucket
        s3_bucket_region
        subscriptions {
          nextToken
        }
        teams {
          nextToken
        }
        user_identity_id
        createdAt
        updatedAt
      }
      comparison {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        createdAt
        deleted
        description
        encodeAnalyses {
          nextToken
        }
        id
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTeamComparison = /* GraphQL */ `
  mutation CreateTeamComparison(
    $input: CreateTeamComparisonInput!
    $condition: ModelTeamComparisonConditionInput
  ) {
    createTeamComparison(input: $input, condition: $condition) {
      id
      teamID
      comparisonID
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      comparison {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        createdAt
        deleted
        description
        encodeAnalyses {
          nextToken
        }
        id
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTeamComparison = /* GraphQL */ `
  mutation UpdateTeamComparison(
    $input: UpdateTeamComparisonInput!
    $condition: ModelTeamComparisonConditionInput
  ) {
    updateTeamComparison(input: $input, condition: $condition) {
      id
      teamID
      comparisonID
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      comparison {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        createdAt
        deleted
        description
        encodeAnalyses {
          nextToken
        }
        id
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTeamComparison = /* GraphQL */ `
  mutation DeleteTeamComparison(
    $input: DeleteTeamComparisonInput!
    $condition: ModelTeamComparisonConditionInput
  ) {
    deleteTeamComparison(input: $input, condition: $condition) {
      id
      teamID
      comparisonID
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      comparison {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        createdAt
        deleted
        description
        encodeAnalyses {
          nextToken
        }
        id
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTeamEncodeAnalysis = /* GraphQL */ `
  mutation CreateTeamEncodeAnalysis(
    $input: CreateTeamEncodeAnalysisInput!
    $condition: ModelTeamEncodeAnalysisConditionInput
  ) {
    createTeamEncodeAnalysis(input: $input, condition: $condition) {
      id
      teamID
      encodeAnalysisID
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      encodeAnalysis {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        refMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        refMediaID
        encMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        encMediaID
        align_offset
        build_number
        createdAt
        cross_account_s3_id
        deleted
        description
        encode_id
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        comparisons {
          nextToken
        }
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        persons {
          nextToken
        }
        subscriptions
        teams {
          nextToken
        }
        id
        vmaf_reduct
        psnr_reduct
        metric_psnr
        metric_vmaf
        owner
        progress_calc_metric
        progress_create_hls
        progress_deploy
        progress_extract_feat
        progress_upload
        reference_id
        ana_fps_nominator
        ana_fps_denominator
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTeamEncodeAnalysis = /* GraphQL */ `
  mutation UpdateTeamEncodeAnalysis(
    $input: UpdateTeamEncodeAnalysisInput!
    $condition: ModelTeamEncodeAnalysisConditionInput
  ) {
    updateTeamEncodeAnalysis(input: $input, condition: $condition) {
      id
      teamID
      encodeAnalysisID
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      encodeAnalysis {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        refMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        refMediaID
        encMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        encMediaID
        align_offset
        build_number
        createdAt
        cross_account_s3_id
        deleted
        description
        encode_id
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        comparisons {
          nextToken
        }
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        persons {
          nextToken
        }
        subscriptions
        teams {
          nextToken
        }
        id
        vmaf_reduct
        psnr_reduct
        metric_psnr
        metric_vmaf
        owner
        progress_calc_metric
        progress_create_hls
        progress_deploy
        progress_extract_feat
        progress_upload
        reference_id
        ana_fps_nominator
        ana_fps_denominator
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTeamEncodeAnalysis = /* GraphQL */ `
  mutation DeleteTeamEncodeAnalysis(
    $input: DeleteTeamEncodeAnalysisInput!
    $condition: ModelTeamEncodeAnalysisConditionInput
  ) {
    deleteTeamEncodeAnalysis(input: $input, condition: $condition) {
      id
      teamID
      encodeAnalysisID
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      encodeAnalysis {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        refMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        refMediaID
        encMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        encMediaID
        align_offset
        build_number
        createdAt
        cross_account_s3_id
        deleted
        description
        encode_id
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        comparisons {
          nextToken
        }
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        persons {
          nextToken
        }
        subscriptions
        teams {
          nextToken
        }
        id
        vmaf_reduct
        psnr_reduct
        metric_psnr
        metric_vmaf
        owner
        progress_calc_metric
        progress_create_hls
        progress_deploy
        progress_extract_feat
        progress_upload
        reference_id
        ana_fps_nominator
        ana_fps_denominator
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createTeamMedia = /* GraphQL */ `
  mutation CreateTeamMedia(
    $input: CreateTeamMediaInput!
    $condition: ModelTeamMediaConditionInput
  ) {
    createTeamMedia(input: $input, condition: $condition) {
      id
      teamID
      mediaID
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      media {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateTeamMedia = /* GraphQL */ `
  mutation UpdateTeamMedia(
    $input: UpdateTeamMediaInput!
    $condition: ModelTeamMediaConditionInput
  ) {
    updateTeamMedia(input: $input, condition: $condition) {
      id
      teamID
      mediaID
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      media {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteTeamMedia = /* GraphQL */ `
  mutation DeleteTeamMedia(
    $input: DeleteTeamMediaInput!
    $condition: ModelTeamMediaConditionInput
  ) {
    deleteTeamMedia(input: $input, condition: $condition) {
      id
      teamID
      mediaID
      team {
        id
        name
        description
        accounts {
          nextToken
        }
        references {
          nextToken
        }
        comparisons {
          nextToken
        }
        encode_analyses {
          nextToken
        }
        media {
          nextToken
        }
        createdAt
        updatedAt
      }
      media {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        build_number
        referenceMedia {
          nextToken
        }
        encodedMedia {
          nextToken
        }
        createdAt
        cross_account_s3_id
        deleted
        description
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        id
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        progress_create_hls
        progress_cross_copy
        progress_decode
        progress_deploy
        progress_extract_es
        progress_upload
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        fps
        bitrate_list_per_sec
        width
        height
        extension
        es_filename
        updatedAt
        user_identity_id
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createEncodeAnalysisComparison = /* GraphQL */ `
  mutation CreateEncodeAnalysisComparison(
    $input: CreateEncodeAnalysisComparisonInput!
    $condition: ModelEncodeAnalysisComparisonConditionInput
  ) {
    createEncodeAnalysisComparison(input: $input, condition: $condition) {
      id
      encodeAnalysisID
      comparisonID
      encodeAnalysis {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        refMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        refMediaID
        encMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        encMediaID
        align_offset
        build_number
        createdAt
        cross_account_s3_id
        deleted
        description
        encode_id
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        comparisons {
          nextToken
        }
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        persons {
          nextToken
        }
        subscriptions
        teams {
          nextToken
        }
        id
        vmaf_reduct
        psnr_reduct
        metric_psnr
        metric_vmaf
        owner
        progress_calc_metric
        progress_create_hls
        progress_deploy
        progress_extract_feat
        progress_upload
        reference_id
        ana_fps_nominator
        ana_fps_denominator
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        updatedAt
        user_identity_id
      }
      comparison {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        createdAt
        deleted
        description
        encodeAnalyses {
          nextToken
        }
        id
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateEncodeAnalysisComparison = /* GraphQL */ `
  mutation UpdateEncodeAnalysisComparison(
    $input: UpdateEncodeAnalysisComparisonInput!
    $condition: ModelEncodeAnalysisComparisonConditionInput
  ) {
    updateEncodeAnalysisComparison(input: $input, condition: $condition) {
      id
      encodeAnalysisID
      comparisonID
      encodeAnalysis {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        refMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        refMediaID
        encMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        encMediaID
        align_offset
        build_number
        createdAt
        cross_account_s3_id
        deleted
        description
        encode_id
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        comparisons {
          nextToken
        }
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        persons {
          nextToken
        }
        subscriptions
        teams {
          nextToken
        }
        id
        vmaf_reduct
        psnr_reduct
        metric_psnr
        metric_vmaf
        owner
        progress_calc_metric
        progress_create_hls
        progress_deploy
        progress_extract_feat
        progress_upload
        reference_id
        ana_fps_nominator
        ana_fps_denominator
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        updatedAt
        user_identity_id
      }
      comparison {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        createdAt
        deleted
        description
        encodeAnalyses {
          nextToken
        }
        id
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteEncodeAnalysisComparison = /* GraphQL */ `
  mutation DeleteEncodeAnalysisComparison(
    $input: DeleteEncodeAnalysisComparisonInput!
    $condition: ModelEncodeAnalysisComparisonConditionInput
  ) {
    deleteEncodeAnalysisComparison(input: $input, condition: $condition) {
      id
      encodeAnalysisID
      comparisonID
      encodeAnalysis {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        refMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        refMediaID
        encMedia {
          accountID
          build_number
          createdAt
          cross_account_s3_id
          deleted
          description
          filename
          id
          measurementPointId
          owner
          progress_create_hls
          progress_cross_copy
          progress_decode
          progress_deploy
          progress_extract_es
          progress_upload
          spec_codec
          spec_duration_frames
          spec_duration_time
          spec_frame_rate
          spec_height
          spec_pid
          spec_pix_fmt
          spec_width
          status
          scan_type
          scan_order
          scan_mode
          fps
          bitrate_list_per_sec
          width
          height
          extension
          es_filename
          updatedAt
          user_identity_id
        }
        encMediaID
        align_offset
        build_number
        createdAt
        cross_account_s3_id
        deleted
        description
        encode_id
        filename
        interlace_rep {
          Legacy
          DeInterlaceOff
          BWDifSendFrameTFF
          BWDifSendFrameBFF
          BWDifSendFieldTFF
          BWDifSendFieldBFF
          DeInterleave
        }
        comparisons {
          nextToken
        }
        measurementPointId
        measurementPoint {
          createdAt
          description
          id
          owner
          updatedAt
          parentID
        }
        persons {
          nextToken
        }
        subscriptions
        teams {
          nextToken
        }
        id
        vmaf_reduct
        psnr_reduct
        metric_psnr
        metric_vmaf
        owner
        progress_calc_metric
        progress_create_hls
        progress_deploy
        progress_extract_feat
        progress_upload
        reference_id
        ana_fps_nominator
        ana_fps_denominator
        spec_codec
        spec_duration_frames
        spec_duration_time
        spec_frame_rate
        spec_height
        spec_pid
        spec_pix_fmt
        spec_width
        status
        scan_type
        scan_order
        scan_mode
        updatedAt
        user_identity_id
      }
      comparison {
        account {
          id
          company_name
          credits
          description
          email
          first_name
          last_login
          last_upload
          last_name
          num_references
          num_distorteds
          owner
          refresh
          s3_bucket
          s3_bucket_region
          user_identity_id
          createdAt
          updatedAt
        }
        accountID
        createdAt
        deleted
        description
        encodeAnalyses {
          nextToken
        }
        id
        owner
        persons {
          nextToken
        }
        teams {
          nextToken
        }
        updatedAt
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
