import React from "react";
import {TableCell} from '@aws-amplify/ui-react';

function PsnrCell(props) {
    return (
        <>
            { props.distorted ?
                <TableCell className="synamedia-td">
                    {
                        props.distorted.metric_psnr ?
                            props.distorted.metric_psnr + ' dB'
                            :
                            ''
                    }
                </TableCell> : <TableCell/> }
        </>
    );
}

export default PsnrCell;
