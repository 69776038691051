import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from '@aws-amplify/ui-react';

// core components
import AccountTableEntry from "../../components/vqaas/AccountTableEntry";

function AccountTable(props) {
    // console.log(props)

    const tableEntries = props.accounts.Users.map(userData =>
        <AccountTableEntry
            key={userData.Username}
            userData={userData} />)

    return (
        <>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell as={"th"} className="text-center">#</TableCell>
                    <TableCell as={"th"} className="synamedia-th">Email</TableCell>
                    <TableCell as={"th"} className="synamedia-th">User Status</TableCell>
                    <TableCell as={"th"} className="synamedia-th">Create Date</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {tableEntries}
                </TableBody>
            </Table>
        </>
    );
}

export default AccountTable;
