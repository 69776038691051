import React from "react"
import {Flex, Link, TableCell, TableRow, Text, View} from '@aws-amplify/ui-react';

function S3FolderEntry(props) {
    return (
        <TableRow>
            <TableCell>
                { props.s3_object.path ?
                    <Link
                        padding={"0"}
                        className="btn-link"
                        data-folder={props.s3_object.path}
                        onClick={props.onFolderClick}>
                        <Flex direction={"row"} justifyContent="flex-start" alignItems="center" gap={10} data-folder={props.s3_object.path}>
                            <View width={"20px"} height={"20px"} data-folder={props.s3_object.path}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    data-folder={props.s3_object.path}
                                >
                                    <path data-folder={props.s3_object.path} fill="#FFB900" d="M10 5L8.586 3.586A2 2 0 007.172 3H2a1 1 0 00-1 1v12a1 1 0 001 1h16a1 1 0 001-1V6a1 1 0 00-1-1h-8z"/>
                                    <path data-folder={props.s3_object.path} fill="#FFD75E" d="M10 5L8.586 6.414A2 2 0 017.172 7H1v9a1 1 0 001 1h16a1 1 0 001-1V6a1 1 0 00-1-1h-8z"/>
                                    <linearGradient data-folder={props.s3_object.path} id="a" gradientUnits="userSpaceOnUse" x1="10" y1="5" x2="10" y2="17">
                                        <stop data-folder={props.s3_object.path} offset="0" stopColor="#fff" stopOpacity="0"/>
                                        <stop data-folder={props.s3_object.path} offset=".999" stopColor="#ffd75e" stopOpacity=".3"/>
                                    </linearGradient>
                                    <path data-folder={props.s3_object.path} fill="url(#a)" d="M10 5L8.586 6.414A2 2 0 017.172 7H1v9a1 1 0 001 1h16a1 1 0 001-1V6a1 1 0 00-1-1h-8z"/>
                                    <path data-folder={props.s3_object.path} fill="#E67628" d="M2 16.5c-.373 0-.71-.142-.973-.367A.988.988 0 002 17h16c.505 0 .905-.38.973-.867-.262.226-.6.367-.973.367H2z"/>
                                    <path data-folder={props.s3_object.path} opacity=".4" fill="#FFF" d="M8.836 6.914L10.75 5H10L8.586 6.414A2 2 0 017.172 7H1v.5h6.422a2 2 0 001.414-.586z"/>
                                </svg>
                            </View>
                            <Text
                                data-folder={props.s3_object.path}
                            >
                                {props.s3_object.path}
                            </Text>
                        </Flex>
                    </Link> :
                    <div className="uil-reload-css reload-small mr-1 "><div/></div>
                }
            </TableCell>
            <TableCell>
            </TableCell>
            <TableCell/>
            <TableCell/>
        </TableRow>
    )
}

export default S3FolderEntry