import React, {useState} from "react"
import {TableCell, TableRow} from '@aws-amplify/ui-react';

function AccountTableEntry(props) {
    const [email, setEmail] = useState("")
    // console.log(props.userData)
    React.useEffect(() => {
        for (let i=0; i< props.userData.Attributes.length ; i++){
            if (props.userData.Attributes[i].Name === "email"){
                setEmail(props.userData.Attributes[i].Value)
            }
        }
    }, [props])

    return (
        <TableRow>
            <TableCell className="text-center">
                    {props.userData.Username}
            </TableCell>
            <TableCell>{email}</TableCell>
            <TableCell>
                {
                    props.userData.UserStatus
                }
            </TableCell>
            <TableCell>
                {
                    props.userData.UserCreateDate
                }
            </TableCell>
        </TableRow>
    )
}

export default AccountTableEntry