import React from "react";
import ReactPlayer from 'react-player'
import {Card, Flex, Heading, Text, View} from '@aws-amplify/ui-react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';

highchartsMore(Highcharts);

function SynamediaStrip() {
    return <Flex direction="column" gap={0}>
        <View
            backgroundColor={"#3380FF"}
            padding={"5px"}
            height={"22px"}
        />
        <View
            backgroundColor={"#FFB400"}
            padding={"5px"}
            height={"22px"}
        />
        <View
            backgroundColor={"#3380FF"}
            padding={"5px"}
            height={"136px"}
        />
    </Flex>;
}

function VQProbeCard(props) {
    // console.log(props)
    const {VQProbeItem, playerUrl, bitrateList, vmafReduct, play, seek, repeat} = props
    const [pointer, setPointer] = React.useState(0)
    const [bitrateData, setBitrateData] = React.useState([])
    const [vmafData, setVmafData] = React.useState([])
    const playerReference = React.useRef()
    const [chartOptions, setChartOptions ] = React.useState({
        chart: {
            type: 'spline',
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
            marginBottom: 20,
            marginTop: 10,
            height: '180px'
        },
        series: [
            { name: 'Bitrate', data: bitrateData, color: '#3380FF', lineWidth: 2, visible: true },
            { type: 'arearange', name: 'VQ', data: vmafData, color: '#FFB400', lineWidth: 2, visible: true, yAxis: 1 },
        ],
        xAxis: {
            crosshair: true,
            type: 'datetime',
            gridLineWidth: 1,
            title: {
                text: ''
            }
        },
        yAxis: [
            {
                className: 'highcharts-vq-channel',
                title: {
                    text: '',
                },
                labels: {
                    style: {
                        color: '#000'
                    }
                },
                min: 0
            },
            {
                className: 'highcharts-vq-channel',
                opposite: true,
                title: {
                    text: '',
                },
                labels: {
                    style: {
                        color: '#000'
                    }
                },
                tickInterval: 25,
                max: 100,
                min: 0
            }
        ],
        legend: {
            enabled: false
        },
        title: {
            align: 'left',
            text: ''
        },
        plotOptions: {
            series: {
                cursor: 'pointer',
                allowPointSelect: true,
                animation: false,
                point: {
                    events: {
                        click: function () {
                            console.log('Jump to ', this.series.name, this)
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: false
        }
    });

    React.useEffect(() => {
        if (play) {
            const interval = setInterval(function() {
                if (bitrateList) {
                    setPointer(number => {if (number === bitrateList.length-1){return 0} else { return number + 1}})}
            }, 1000);
            return () => clearInterval(interval);
        } else {
            setPointer(18)
        }
    },[play, bitrateList])

    React.useEffect(() => {
        if (play){
            let nowDate = Date.now();
            if (bitrateList) {
                setBitrateData(prevData => [...prevData, [nowDate, 1000*bitrateList[pointer]]])
            }
            if (vmafReduct){
                // console.log([nowDate, vmafReduct[pointer][1], vmafReduct[pointer][2]])
                setVmafData(prevData => [...prevData, [nowDate, vmafReduct[pointer][1], vmafReduct[pointer][2]]])
            }
        }
    },[play, pointer, bitrateList, vmafReduct])

    React.useEffect(() => {
        if (bitrateList) {
            setChartOptions( {series: [{data: bitrateData}, {data: vmafData}]})
        }
    },[bitrateData,bitrateList, vmafData])

    React.useEffect(() => {
        if (repeat) playerReference.current.seekTo(seek, 'seconds')
    },[seek, repeat])

    return (
        <Card
            draggable="false"
            key={VQProbeItem.id}
            id={VQProbeItem.id}
            width={"24%"}
            height={"400px"}
            padding="0rem"
            variation="elevated"
        >
            <Flex width={"24%"} height={"450px"} direction="row" justifyContent="flex-start" gap="0px">
                <SynamediaStrip/>
                <Flex direction="column" gap={"3px"} justifyContent={"flex-start"} alignItems={"flex-start"}>
                    <Flex direction={"row"} gap={0}>
                        <View id="streamplayerref" width={"320px"} height={"180px"}>
                            <div className='player-wrapper2'>
                                <ReactPlayer
                                    width='100%'
                                    height='100%'
                                    url={playerUrl}
                                    className="react-player"
                                    ref={playerReference}
                                    pip={false}
                                    playing={play}
                                    controls={false}
                                    light={false}
                                    loop={true}
                                    playbackRate={1.0}
                                    onStart={() => console.log('onStart')}
                                    onPlay={() => {}}
                                    onEnablePIP={() => {}}
                                    onDisablePIP={() => console.log('handleDisablePIP')}
                                    onPause={() => console.log('handlePause')}
                                    onBuffer={props.onLoop}
                                    onSeek={e => console.log('onSeek', e, VQProbeItem['name'])}
                                    onError={e => console.log('onError', e)}
                                    onDuration={() => {}}
                                />
                            </div>
                        </View>
                        <svg width={"100px"}>
                            <g id="track4_b-circle1" transform="translate(-5 14.999985)">
                            <ellipse id="track4_b-ellipse4" rx="40" ry="40" transform="translate(56 72)" fill="#3380ff" stroke="#3380ff"/>
                            <text id="track4_b-text6" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="40" fontWeight="400" transform="translate(46 84)" fill="#fff" strokeWidth="0">
                                <tspan id="track4_b-tspan8" y="0" fontWeight="400" strokeWidth="0">
                                    {VQProbeItem.id}
                                </tspan>
                            </text>
                        </g>
                        </svg>
                    </Flex>
                    <Flex direction={"row"} justifyContent={"space-between"} width={"400px"}>
                        <Heading
                            width={"75px"}
                            color={'#3380FF'}
                            style={{textDecorationLine:'underline', textDecorationColor:'#3380FF'}}>Bitrate</Heading>
                        <Text width={"150px"}>{VQProbeItem.name}</Text>
                        <Heading
                            width={"50px"}
                            color={'#FFB400'}
                            style={{textDecorationLine:'underline', textDecorationColor:'#FFB400'}}>VQM</Heading>
                    </Flex>
                    <Flex direction={"row"} height={"200px"}>
                        <View
                            width={"400px"}
                            height={"200px"}
                        >
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={chartOptions}
                            />
                        </View>
                    </Flex>
                </Flex>
            </Flex>
        </Card>
    )
}

export default VQProbeCard