import React from "react";
import {Flex, Heading, View} from '@aws-amplify/ui-react';
import background from 'assets/img/sections/shutterstock_1125901340-GRADED.jpg';

function MediaPageHeader(props) {
    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    });
    return (
        <View
            minHeight={"700px"}
            height={"700px"}
            width={"100%"}
            backgroundColor={"#B2AFAB"}
            style={{
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundImage: `url(${background})`,
                overflow: "hidden"
            }}
        >
            <Flex id="Flex-MPH-0" direction="column" gap={0}>
                <Flex id="Flex-MPH-1" height="400px" gap={0}/>
                <Flex id="Flex-MPH-2"  direction="row" height="300px" gap={0}>
                    <Flex
                        id="Flex-MPH-3"
                        direction="column"
                        width={{ base: '0', small: '0', medium: '10%', large: '20%', xl: '30%', xxl: '30%'}}
                        gap={0}
                    >
                        <Flex id="Flex-MPH-5" height="50%" gap={0}/>
                        <Flex id="Flex-MPH-6" backgroundColor="white" height="50%" gap={0}/>
                    </Flex>
                    <Flex
                        id="Flex-MPH-4"
                        direction="row"
                        width={{ base: '100%', small: '100%', medium: '90%', large: '80%', xl: '70%', xxl: '70%'}}
                        gap={0}
                    >
                        <Flex
                            id="Flex-MPH-7"
                            backgroundColor="#3380FF"
                            height="100%"
                            width={{ base: '100%', small: '90%', medium: '90%', large: '80%', xl: '70%', xxl: '60%'}}
                            gap={0}
                            direction="column"
                        >
                            {props.title ? <Heading level={1} color={"white"} padding={"1rem"}>{props.title}</Heading> : <></>}
                            {props.subTitle ? <Heading level={2} color={"white"} padding={"1rem"}>{props.subTitle}</Heading> : <></>}
                            {props.subTitle2 ? <Heading level={2} color={"white"} padding={"1rem"}>{props.subTitle2}</Heading> : <></>}
                        </Flex>
                        <Flex
                            id="Flex-MPH-8"
                            backgroundColor="#292929"
                            height="100%"
                            width={{ base: '0%', small: '10%', medium: '10%', large: '20%', xl: '30%', xxl: '40%'}}
                            gap={0}/>
                    </Flex>
                </Flex>
            </Flex>
        </View>
    );
}

export default MediaPageHeader;
