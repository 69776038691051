import React from "react"
import {Link} from "react-router-dom";
import {Button, Divider, Menu, MenuButton, MenuItem, Icon, TableCell, TableRow} from '@aws-amplify/ui-react';
import Tooltip from '@mui/material/Tooltip';

function TeamTableEntry(props) {
    // console.log(props)

    return (
        <TableRow>
            <TableCell>
                <Link to={"/edit-team/" + props.userData.id} >
                    {props.userData.id.slice(0,6)}
                </Link>
            </TableCell>
            <TableCell>{props.userData.name}</TableCell>
            <TableCell>{props.userData.description}</TableCell>
            <TableCell >
                <div className="td-actions text-right">
                    <Link to={"/edit-team/" + props.userData.id} style={{paddingRight:"10px"}}>
                        <Tooltip
                            title="Edit"
                            placement="bottom"
                        >
                            <Button className="reference-card-button" size="small" to={"/edit-team/" + props.userData.id} style={{paddingLeft:"10px"}}>
                                <Icon
                                    ariaLabel="Pencil"
                                    viewBox={{ width: 24, height: 24 }}
                                    pathData="M22.728,5.403 C22.712,5.419 22.691,5.424 22.675,5.439 C22.660,5.455 22.655,5.476 22.640,5.492 L21.195,6.941 C21.195,6.941 21.195,6.941 21.195,6.941 C21.194,6.942 21.194,6.942 21.194,6.942 L6.932,21.247 C6.931,21.248 6.930,21.249 6.930,21.249 L6.926,21.254 C6.880,21.299 6.823,21.321 6.772,21.356 C6.716,21.394 6.670,21.441 6.606,21.468 L6.600,21.470 C6.600,21.470 6.599,21.471 6.599,21.471 L1.368,23.647 C1.246,23.698 1.118,23.722 0.991,23.722 C0.735,23.722 0.483,23.622 0.295,23.433 C0.013,23.150 -0.072,22.725 0.081,22.355 L2.254,17.101 C2.263,17.079 2.282,17.065 2.292,17.044 C2.339,16.950 2.391,16.857 2.467,16.781 L16.737,2.470 C16.737,2.470 16.737,2.469 16.738,2.468 C16.739,2.467 16.740,2.467 16.740,2.466 L18.183,1.019 C18.198,1.005 18.217,1.000 18.232,0.987 C18.247,0.969 18.253,0.947 18.270,0.930 C19.499,-0.301 21.499,-0.301 22.728,0.930 C23.958,2.164 23.957,4.170 22.728,5.403 ZM3.509,19.224 L2.814,20.906 L4.490,20.208 L3.509,19.224 ZM6.229,19.157 L19.105,6.242 L17.435,4.565 L4.557,17.480 L6.229,19.157 ZM21.335,2.328 C20.873,1.866 20.125,1.865 19.663,2.328 C19.648,2.343 19.628,2.348 19.613,2.362 C19.598,2.379 19.593,2.401 19.576,2.417 L18.828,3.168 L20.499,4.845 L21.247,4.095 C21.262,4.079 21.283,4.074 21.300,4.059 C21.314,4.043 21.319,4.022 21.335,4.006 C21.796,3.544 21.796,2.791 21.335,2.328 Z"
                                />
                            </Button>
                        </Tooltip>
                    </Link>
                    <Menu
                        trigger={
                            <MenuButton className="reference-card-button" size="small">
                                <Icon
                                    ariaLabel="Trash"
                                    viewBox={{width: 24, height: 24}}
                                    pathData="M19.000,6.000 L17.910,6.000 L16.105,22.118 C15.986,23.173 15.062,24.000 14.000,24.000 L6.000,24.000 C4.937,24.000 4.014,23.172 3.897,22.116 L2.120,6.000 L1.000,6.000 C0.447,6.000 0.000,5.552 0.000,5.000 C0.000,4.448 0.447,4.000 1.000,4.000 L6.000,4.000 L6.000,3.000 C6.000,1.346 7.346,-0.000 9.000,-0.000 L11.000,-0.000 C12.654,-0.000 14.000,1.346 14.000,3.000 L14.000,4.000 L19.000,4.000 C19.553,4.000 20.000,4.448 20.000,5.000 C20.000,5.552 19.553,6.000 19.000,6.000 ZM12.000,3.000 C12.000,2.449 11.552,2.000 11.000,2.000 L9.000,2.000 C8.448,2.000 8.000,2.449 8.000,3.000 L8.000,4.000 L12.000,4.000 L12.000,3.000 ZM4.132,6.000 L5.885,21.896 C5.889,21.935 5.960,22.000 6.000,22.000 L14.000,22.000 C14.040,22.000 14.113,21.935 14.117,21.894 L15.897,6.000 L4.132,6.000 Z"
                                />
                            </MenuButton>
                        }
                    >
                        <MenuItem onClick={props.onClick2}>Confirm</MenuItem>
                        <Divider/>
                        <MenuItem>Cancel</MenuItem>
                    </Menu>
                </div>
            </TableCell>
        </TableRow>
    )
}

export default TeamTableEntry