/*eslint-disable*/
import React from "react";
import { Flex, Text, View} from '@aws-amplify/ui-react';


function FooterBlack(props) {
  return (
      <>
        <footer className="footer footer-black footer-white">
          <View width={{base:'95%', xl:'95%'}}>
          <Flex justifyContent="center" style={{fontFamily: "SourceSansPro"}}>
              <Flex direction={"row"} justifyContent="space-around" alignContent={"center"} alignItems={"center"} >
                <Text color={"white"}>{"Username: "+ props.account.email}</Text>
                <div className="credits ml-auto">
              <span className="copyright">
                © {new Date().getFullYear()}
                , made with <i className="fa fa-heart heart" /> by Synamedia
              </span>
                </div>
              </Flex>
          </Flex>
          </View>
        </footer>
      </>
  );
}

export default FooterBlack;
