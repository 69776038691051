import React from "react";
import {Button, Flex, Link, Text, View} from '@aws-amplify/ui-react';
import {Storage} from "aws-amplify";
import VQProbeCard from "../../components/vqaas/VQProbeCard";
import NetworkDrawing from "../../components/vqaas/NetworkDrawing";
import {Link as ReactRouterLink} from "react-router-dom";

const monitorButtonStyleNoAlarm = {
    borderWidth:4,
    borderColor:'#3380FF',
    alignItems:'center',
    justifyContent:'center',
    width:50,
    backgroundColor:'#fff',
    borderRadius:100,
}

const monitorButtonStyleAlarm = {
    borderWidth:4,
    borderColor:'#FF3333',
    alignItems:'center',
    justifyContent:'center',
    width:50,
    backgroundColor:'#fff',
    borderRadius:100,
}

const MonitorButton = props => {
    const {description} = props
    let buttonLetter
    if (props.type === 'root') {
        buttonLetter = 'R'
    } else {
        buttonLetter = 'M'
    }
    let monitorButtonStyle
    if (props.alarm) {monitorButtonStyle = monitorButtonStyleAlarm}
    else {monitorButtonStyle = monitorButtonStyleNoAlarm}
    console.log(description)
    if (props.hasChildren === false) {
        return <Flex direction={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={0}>
            <Flex direction={"column"} alignItems={"flex-end"} gap={0}>
                <Text style={{paddingRight:"1rem"}}>{description.split(' ')[0]}</Text>
                <View height={"2px"} width={"150px"} backgroundColor={"black"} padding={0}/>
                <Text style={{paddingRight:"1rem"}}>{description.split(' ')[1]}</Text>
            </Flex>
            <Button style={monitorButtonStyle} onClick={() => props.onClick()}>{buttonLetter}</Button>
        </Flex>

    } else {
        return <Flex direction={"row"} justifyContent={"flex-start"} alignItems={"center"} gap={0}>
            <Flex direction={"column"} alignItems={"flex-end"} gap={0}>
                <Text style={{paddingRight:"1rem"}}>{description.split(' ')[0]}</Text>
                <View height={"2px"} width={"150px"} backgroundColor={"black"} padding={0}/>
                <Text style={{paddingRight:"1rem"}}>{description.split(' ')[1]}</Text>
            </Flex>
            <Button style={monitorButtonStyle} onClick={() => props.onClick()}>{buttonLetter}</Button>
            <View height={"2px"} width={"50px"} backgroundColor={"black"} padding={0}/>
            <Text>{">"}</Text>
        </Flex>

    }
}

const Splitter = props => {
    if (props.hasChildren === false) {
        return <Flex/>
    } else {
        return <Flex direction="column">
            <View
                backgroundColor={"black"}
                padding={"3px"}
                height={"100%"}
            />
        </Flex>
    }
}

function Branch(props) {
    console.log(props.tree)
    const {children, id, type, alarm, format, bitrate, description} = props.tree
    let hasChildren = false
    if (children)
        if (children.items)
            if (children.items.length) hasChildren = true
    console.log(id, hasChildren)
    let nextLevels
    if (hasChildren){
        nextLevels = children.items.map(node => {return <Branch id={node.id} key={node.id} tree={node}/>})
    } else {
        nextLevels = [].map(node => {return <Branch id={node.id} key={node.id} tree={node}/>})
    }
    return <Flex direction={"row"} justifyContent={"flex-start"} alignItems={"stretch"} gap={0}>
        <MonitorButton
            id={id}
            type={type}
            alarm={alarm}
            format={format}
            description={description || 'root root'}
            hasChildren={hasChildren}
            bitrate={bitrate}
            onClick={() => {props.onClick(id)}}/>
        <Splitter hasChildren={hasChildren}/>
        <Flex direction={"column"} justifyContent={"center"} gap={"20px"}>
            {nextLevels}
        </Flex>
    </Flex>;
}

function ChannelPage(props) {
    const [defaultTS0Url, setDefaultTS0Url] = React.useState()
    const [defaultTS1Url, setDefaultTS1Url] = React.useState()
    const [defaultTS2Url, setDefaultTS2Url] = React.useState()
    const [impairmentES0Url, setImpairmentES0Url] = React.useState()
    const [impairmentES1Url, setImpairmentES1Url] = React.useState()
    const [impairmentES2Url, setImpairmentES2Url] = React.useState()
    const [impairmentTS0Url, setImpairmentTS0Url] = React.useState()
    const [impairmentTS1Url, setImpairmentTS1Url] = React.useState()
    const [impairmentTS2Url, setImpairmentTS2Url] = React.useState()
    const [black0Url, setBlack0Url] = React.useState()
    const [black1Url, setBlack1Url] = React.useState()
    const [black2Url, setBlack2Url] = React.useState()
    const [load1Url, setLoad1Url] = React.useState()
    const [load2Url, setLoad2Url] = React.useState()
    const [playerUrl_Source, setPlayerUrl_Source] = React.useState()
    const [playerUrlBeforeEncoder, setPlayerUrlBeforeEncoder] = React.useState()
    const [playerUrlABR, setPlayerUrlABR] = React.useState()
    const [playerUrlCBR, setPlayerUrlCBR] = React.useState()
    const [playSource, setPlaySource] = React.useState(false)
    const [playBeforeEncoder, setPlayBeforeEncoder] = React.useState(false)
    const [playCBR, setPlayCBR] = React.useState(false)
    const [playABR, setPlayABR] = React.useState(false)
    const [seekSource, setSeekSource] = React.useState(0)
    const [seekBeforeEncoder, setSeekBeforeEncoder] = React.useState(0)
    const [seekCBR, setSeekCBR] = React.useState(0)
    const [seekABR, setSeekABR] = React.useState(0)
    const [repeatSource, setRepeatSource] = React.useState(false)
    const [repeatBeforeEncoder, setRepeatBeforeEncoder] = React.useState(false)
    const [repeatCBR, setRepeatCBR] = React.useState(false)
    const [repeatABR, setRepeatABR] = React.useState(false)
    const [showNormal, setShowNormal] = React.useState(false)
    const [showBlack, setShowBlack] = React.useState(false)
    const [showPoor, setShowPoor] = React.useState(false)
    const [showNetwork, setShowNetwork] = React.useState(false)
    const [showLoad, setShowLoad] = React.useState(false)

    let timecodeReference = {
        id: '94f00c9e-477a-46e0-9960-fdf531710219',
        filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/default/Synamedia_Volleyball_1080p50_timecode.ts',
        measurementPointId: 'b9165908-e23a-472b-8220-df1a67608e5f',
        bitrateList: [60609, 45396, 57123, 46496, 29738, 36511, 50964, 40941, 35019, 21310, 51431, 38298, 21084, 24801, 22718, 18661, 22018, 53319, 15404, 18975, 20372, 42445, 32724, 19075, 29179, 27553, 27652, 12011, 26476, 27071, 29640, 31438, 35685, 30420, 30639, 34912, 38862, 24652, 21211, 18850, 23021, 53423, 46176, 40020, 35235, 36134, 45083, 40379, 26517, 46742, 39124, 13222, 35339, 43457, 47954, 41528, 30624, 33530, 36294, 34882, 25591, 18712, 18932, 16051, 16151, 15252, 51444, 53057, 32513, 16555, 25528, 28046, 33522, 36337]
    }
    let default_TS = [
        {
            id: 'b88d3c2b-897b-46fd-a389-d97a195926b1',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/default/egress_capture__10_9_24_10__232_10_10_1__49410.ts',
            demo: 'default',
            measurementPointId: 'b9165908-e23a-472b-8220-df1a67608e5f',
            bitrateList: [41626, 30978, 34394, 38627, 31514, 32827, 31028, 46464, 27786, 32954, 31728, 33023, 36821, 30823, 37488, 34635, 34934, 26412, 44871, 33767, 32306, 32170, 29208, 35866, 40583, 31008, 29369, 33741, 41848, 29584, 34169, 41084, 36942, 32093, 28504, 30357, 40050, 38719, 29291, 36594, 32340, 34477, 35518, 29008, 35143, 28541, 41036, 31167, 36313, 32239, 34025, 34293, 40814, 31783, 33992, 28667, 39991, 36199, 29710],
            eaId: 'e3cf6604-9663-413b-b12f-66d49939d0b4',
            vmafReduct: [[0, 97, 100], [30, 100, 100], [60, 100, 100], [90, 100, 100], [120, 100, 100], [150, 100, 100], [180, 100, 100], [210, 100, 100], [240, 100, 100], [270, 100, 100], [300, 98, 100], [330, 95, 100], [360, 92, 99], [390, 91, 100], [420, 100, 100], [450, 100, 100], [480, 100, 100], [510, 99, 100], [540, 100, 100], [570, 95, 100], [600, 98, 100], [630, 100, 100], [660, 100, 100], [690, 98, 100], [720, 97, 100], [750, 98, 100], [780, 98, 100], [810, 100, 100], [840, 100, 100], [870, 99, 100], [900, 100, 100], [930, 100, 100], [960, 98, 100], [990, 99, 100], [1020, 100, 100], [1050, 96, 100], [1080, 96, 100], [1110, 96, 100], [1140, 100, 100], [1170, 100, 100], [1200, 100, 100], [1230, 99, 100], [1260, 93, 100], [1290, 94, 100], [1320, 97, 100], [1350, 100, 100], [1380, 100, 100], [1410, 100, 100], [1440, 98, 100], [1470, 100, 100], [1500, 100, 100], [1530, 93, 100], [1560, 95, 99], [1590, 92, 98], [1620, 93, 98], [1650, 95, 98], [1680, 93, 100], [1710, 100, 100], [1740, 100, 100], [1770, 96, 100], [1800, 97, 100], [1830, 95, 100], [1860, 97, 100], [1890, 96, 100], [1920, 100, 100], [1950, 100, 100], [1980, 99, 100], [2010, 97, 100], [2040, 98, 100], [2070, 100, 100], [2100, 96, 100], [2130, 93, 98], [2160, 92, 97], [2190, 93, 98], [2220, 92, 98], [2250, 94, 98], [2280, 94, 100], [2310, 98, 100], [2340, 98, 100], [2370, 98, 100], [2400, 100, 100], [2430, 100, 100], [2460, 99, 100], [2490, 98, 100], [2519, 99, 100], [2548, 100, 100], [2577, 100, 100], [2606, 100, 100], [2635, 100, 100], [2664, 100, 100], [2693, 100, 100], [2722, 100, 100], [2751, 100, 100], [2780, 93, 100], [2809, 91, 100], [2838, 93, 100], [2867, 93, 98], [2896, 92, 98], [2925, 95, 100], [2954, 100, 100]]
        },
        {
            id: 'c1aa5d06-2b4d-4d61-a43d-b0d011640a2a',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/default/egress_capture__10_9_24_10__232_10_10_2__49410.ts',
            demo: 'default',
            measurementPointId: '82287e69-aabf-400f-b01b-7f33888733ec',
            bitrateList: [7717, 9571, 6746, 7108, 6894, 9953, 6839, 7689, 8510, 7960, 7170, 9038, 8613, 5968, 7371, 8627, 8551, 6516, 6566, 9990, 9746, 6287, 5430, 11363, 7493, 7100, 5959, 8791, 7620, 8378, 7110, 7227, 7446, 8299, 9156, 7659, 7876, 9250, 6204, 8061, 7197, 8401, 7356, 7453, 9613, 5549, 9147, 7931, 8089, 6359, 8398, 6681, 9189, 8052, 7806, 7154, 7941, 7537, 8026],
            eaId: '188f6b8a-3bfb-4427-803e-600f30d98c4c',
            vmafReduct: [[0, 89, 98], [30, 78, 95], [60, 81, 90], [90, 83, 90], [120, 84, 98], [150, 88, 98], [180, 86, 100], [210, 91, 98], [240, 87, 100], [270, 86, 99], [300, 84, 99], [330, 91, 100], [360, 92, 100], [390, 94, 100], [420, 89, 100], [450, 92, 100], [480, 93, 100], [510, 93, 100], [540, 94, 100], [570, 85, 99], [600, 84, 94], [630, 79, 100], [660, 96, 100], [690, 99, 100], [720, 98, 100], [750, 90, 100], [780, 83, 100], [810, 70, 93], [840, 79, 100], [870, 95, 100], [900, 93, 100], [930, 90, 100], [960, 88, 97], [990, 89, 97], [1020, 87, 100], [1050, 95, 100], [1080, 95, 100], [1110, 94, 100], [1140, 95, 100], [1170, 94, 100], [1200, 85, 99], [1230, 83, 98], [1260, 81, 100], [1290, 86, 100], [1320, 85, 96], [1350, 86, 100], [1380, 87, 100], [1410, 90, 100], [1440, 90, 100], [1470, 89, 100], [1500, 81, 96], [1530, 84, 92], [1560, 84, 99], [1590, 83, 99], [1620, 98, 100], [1650, 94, 100], [1680, 92, 100], [1710, 92, 100], [1740, 93, 100], [1770, 86, 100], [1800, 83, 91], [1830, 82, 91], [1860, 83, 89], [1890, 82, 89], [1920, 81, 98], [1950, 85, 100], [1980, 86, 100], [2010, 83, 94], [2040, 82, 93], [2070, 83, 93], [2100, 87, 97], [2130, 86, 100], [2160, 76, 100], [2190, 71, 97], [2220, 72, 96], [2250, 85, 94], [2280, 86, 100], [2310, 100, 100], [2340, 82, 100], [2370, 82, 90], [2400, 83, 88], [2430, 82, 89], [2459, 81, 89], [2488, 77, 92], [2517, 82, 95], [2546, 85, 96], [2575, 83, 99], [2604, 82, 100], [2633, 89, 100], [2662, 87, 97], [2691, 88, 99], [2720, 87, 98], [2749, 87, 100], [2778, 93, 100], [2807, 98, 100], [2836, 99, 100], [2865, 94, 100], [2894, 92, 100], [2923, 94, 100], [2952, 94, 100]]
        },
        {
            id: '9aaa4173-ad5b-49c5-b558-c05e66368257',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/default/egress_capture__10_9_24_10__232_10_10_3__49410.ts',
            demo: 'default',
            measurementPointId: 'ecee35c2-19b2-4bb5-afb6-e3a6d45e2253',
            bitrateList: [5840, 4110, 4855, 4626, 4992, 5112, 4316, 4841, 4717, 5601, 5428, 4858, 5019, 4060, 4768, 5546, 3701, 5315, 5922, 4593, 5250, 4233, 3932, 6455, 5247, 4646, 4056, 5290, 4569, 4612, 5388, 4195, 4701, 5783, 4471, 5693, 4374, 4453, 4760, 5120, 5375, 4987, 4735, 4286, 5063, 4787, 5876, 4952, 4383, 4471, 4469, 4966, 4736, 4860, 5748, 4523, 4657, 4606, 4526],
            eaId: '4b9c9e02-f1ac-49e6-9557-9b246a621337',
            vmafReduct: [[0, 82, 91], [30, 69, 90], [60, 68, 77], [90, 71, 77], [120, 72, 92], [150, 82, 93], [180, 81, 95], [210, 87, 95], [240, 68, 92], [270, 70, 90], [300, 71, 92], [330, 80, 94], [360, 78, 96], [390, 84, 100], [420, 82, 95], [450, 85, 98], [480, 88, 97], [510, 88, 97], [540, 89, 98], [570, 76, 97], [600, 75, 89], [630, 74, 100], [660, 94, 100], [690, 93, 100], [720, 92, 100], [750, 83, 100], [780, 74, 99], [810, 61, 80], [840, 62, 100], [870, 92, 100], [900, 91, 100], [930, 90, 97], [960, 71, 93], [990, 71, 88], [1020, 71, 92], [1050, 77, 100], [1080, 90, 97], [1110, 89, 98], [1140, 89, 100], [1170, 84, 100], [1200, 74, 94], [1230, 67, 87], [1260, 67, 95], [1290, 78, 95], [1320, 74, 89], [1350, 71, 89], [1380, 76, 96], [1410, 79, 97], [1440, 79, 97], [1470, 80, 90], [1500, 64, 93], [1530, 70, 82], [1560, 66, 86], [1590, 70, 95], [1620, 88, 100], [1650, 93, 100], [1680, 88, 97], [1710, 85, 100], [1740, 86, 100], [1770, 77, 95], [1800, 71, 79], [1830, 71, 79], [1860, 73, 80], [1890, 73, 79], [1920, 72, 88], [1950, 69, 94], [1980, 74, 91], [2010, 72, 90], [2040, 73, 87], [2070, 70, 87], [2100, 73, 87], [2130, 72, 91], [2160, 59, 99], [2190, 56, 87], [2220, 53, 83], [2250, 69, 88], [2280, 70, 100], [2310, 100, 100], [2340, 71, 100], [2370, 68, 75], [2400, 69, 75], [2430, 70, 76], [2460, 72, 82], [2490, 71, 83], [2520, 66, 84], [2550, 67, 86], [2579, 73, 95], [2608, 78, 94], [2637, 70, 87], [2666, 70, 89], [2695, 71, 88], [2724, 69, 87], [2753, 67, 100], [2782, 88, 100], [2811, 93, 100], [2840, 93, 100], [2869, 88, 99], [2898, 88, 100], [2927, 91, 100], [2956, 90, 100]]
        },
        {
            id: '8e3828d2-f5aa-4571-b905-2e2ce960683f',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/default/egress_capture__10_9_24_10__232_10_10_4__49410.ts',
            demo: 'default',
            measurementPointId: 'c6322b9f-4f37-4943-85eb-3db2b2bd2626',
            bitrateList: [3790, 3606, 3500, 3412, 4052, 2606, 3339, 3906, 3909, 3583, 3147, 3630, 3024, 3548, 2984, 3904, 4326, 2629, 3987, 2803, 3865, 2196, 5368, 3204, 3232, 2362, 4328, 3229, 3965, 3161, 3932, 2258, 4179, 3979, 3402, 3260, 3171, 3330, 3529, 3548, 3672, 3059, 3816, 3638, 3563, 3124, 4559, 2863, 3181, 3179, 2986, 4770, 2813, 3664, 3564, 2510, 3871, 3045],
            eaId: '2ddc1368-3c9f-430d-aa39-e86d685bd3c6',
            vmafReduct: [[0, 75, 79], [15, 78, 84], [30, 78, 85], [45, 78, 93], [60, 84, 95], [75, 90, 100], [90, 90, 96], [105, 75, 97], [120, 76, 97], [135, 78, 95], [150, 86, 99], [165, 92, 100], [180, 89, 100], [195, 88, 100], [210, 92, 100], [225, 91, 100], [240, 91, 100], [255, 91, 100], [270, 72, 100], [285, 69, 81], [300, 77, 100], [315, 96, 100], [330, 100, 100], [345, 96, 100], [360, 80, 100], [375, 78, 100], [390, 73, 95], [405, 62, 95], [420, 78, 98], [435, 90, 99], [450, 85, 98], [465, 85, 94], [480, 85, 95], [495, 83, 100], [510, 91, 100], [525, 93, 100], [540, 92, 100], [555, 82, 100], [570, 90, 100], [585, 75, 95], [600, 72, 90], [615, 79, 99], [630, 82, 99], [645, 79, 96], [660, 78, 100], [675, 89, 100], [690, 88, 100], [705, 89, 100], [720, 86, 98], [735, 68, 95], [750, 70, 89], [765, 87, 98], [780, 88, 100], [795, 97, 100], [810, 93, 100], [825, 91, 100], [840, 92, 100], [855, 91, 100], [870, 75, 100], [885, 71, 79], [900, 71, 79], [915, 76, 82], [930, 76, 84], [945, 76, 97], [960, 80, 98], [975, 80, 96], [990, 75, 84], [1005, 77, 90], [1020, 77, 94], [1035, 81, 91], [1050, 81, 100], [1065, 74, 100], [1080, 69, 95], [1095, 67, 92], [1109, 78, 93], [1123, 79, 100], [1137, 100, 100], [1151, 78, 100], [1165, 77, 85], [1179, 75, 81], [1193, 73, 80], [1207, 73, 84], [1221, 75, 83], [1235, 71, 91], [1249, 71, 88], [1263, 70, 98], [1277, 83, 100], [1291, 84, 100], [1305, 83, 97], [1319, 84, 94], [1333, 79, 96], [1347, 75, 91], [1361, 78, 100], [1375, 92, 100], [1389, 98, 100], [1403, 98, 100], [1417, 92, 100], [1431, 93, 100], [1445, 95, 100], [1459, 95, 100]]
        },
        {
            id: '7bec0a28-5f65-4e1f-981a-baa0782502b4',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/default/egress_capture__10_9_24_10__232_10_10_5__49410.ts',
            demo: 'default',
            measurementPointId: '5e6d8f6a-0648-4a96-ad63-7fc00dae7996',
            bitrateList: [3094, 2675, 2143, 2427, 2877, 2106, 2599, 2906, 2947, 2639, 2290, 3134, 1978, 2729, 2326, 2754, 3142, 2744, 2377, 1573, 2506, 2298, 4181, 2278, 2496, 1725, 3016, 2599, 2870, 2409, 2826, 1720, 3532, 2949, 2655, 2498, 2026, 2339, 2478, 2714, 2621, 2486, 3056, 2578, 2181, 2794, 3715, 2018, 2108, 2074, 2410, 3448, 2106, 2739, 2861, 2197, 3002, 2072],
            eaId: 'e58cda55-b4d5-4b83-8b63-b47d320cb627',
            vmafReduct: [[0, 72, 79], [15, 74, 82], [30, 76, 83], [45, 73, 90], [60, 81, 92], [75, 82, 100], [90, 85, 92], [105, 70, 92], [120, 77, 93], [135, 79, 96], [150, 84, 96], [165, 89, 100], [180, 85, 100], [195, 81, 96], [210, 85, 98], [225, 86, 96], [240, 85, 97], [255, 86, 99], [270, 69, 96], [285, 69, 83], [300, 76, 100], [315, 93, 100], [330, 95, 100], [345, 92, 100], [360, 78, 100], [375, 72, 98], [390, 70, 89], [405, 70, 100], [420, 90, 100], [435, 89, 100], [450, 75, 94], [465, 72, 84], [480, 73, 88], [495, 71, 93], [510, 77, 97], [525, 91, 98], [540, 90, 100], [555, 76, 100], [570, 83, 96], [585, 70, 90], [600, 68, 87], [615, 76, 95], [630, 77, 95], [645, 72, 88], [660, 71, 100], [675, 86, 98], [690, 81, 98], [705, 82, 94], [720, 79, 94], [735, 69, 90], [750, 68, 84], [765, 82, 93], [780, 82, 98], [795, 95, 100], [810, 88, 100], [825, 85, 97], [840, 86, 100], [855, 87, 100], [870, 73, 98], [885, 66, 74], [900, 64, 74], [915, 67, 73], [930, 69, 79], [945, 70, 87], [960, 72, 96], [975, 75, 93], [990, 71, 82], [1005, 72, 87], [1020, 71, 89], [1035, 78, 87], [1050, 79, 100], [1064, 67, 100], [1078, 63, 91], [1092, 61, 83], [1106, 67, 85], [1120, 69, 100], [1134, 100, 100], [1148, 99, 100], [1162, 73, 100], [1176, 70, 77], [1190, 68, 75], [1204, 66, 79], [1218, 68, 75], [1232, 65, 79], [1246, 68, 82], [1260, 73, 91], [1274, 80, 97], [1288, 78, 96], [1302, 78, 94], [1316, 81, 90], [1330, 79, 93], [1344, 76, 88], [1358, 73, 100], [1372, 91, 100], [1386, 94, 100], [1400, 97, 100], [1414, 87, 100], [1428, 86, 100], [1442, 87, 100], [1456, 86, 100]]
        },
        {
            id: '314d5f09-8dfb-4648-bff3-a146113f0cb0',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/default/egress_capture__10_9_24_10__232_10_10_6__49410.ts',
            demo: 'default',
            measurementPointId: 'b473a1eb-1645-4ee1-a25d-9eab50d3d52b',
            bitrateList: [2374, 1916, 1664, 2112, 2636, 1576, 1794, 1923, 2337, 2087, 1573, 2488, 1657, 2184, 1844, 2160, 2152, 1765, 2036, 1513, 2423, 1374, 2962, 1851, 1963, 1307, 2646, 1960, 1989, 1794, 2606, 1443, 2013, 2305, 2308, 2203, 1762, 1774, 1648, 1892, 2064, 1951, 2251, 2162, 1732, 2077, 2715, 1699, 1568, 1802, 1813, 3360, 1362, 1848, 2195, 1601, 2144, 1537, 2069],
            eaId: '20748dd2-85cf-4f47-a50f-ac235e676eac',
            vmafReduct: [[0, 63, 73], [15, 65, 74], [30, 67, 74], [45, 66, 88], [60, 71, 89], [75, 71, 99], [90, 75, 85], [105, 68, 89], [120, 72, 89], [135, 69, 84], [150, 72, 86], [165, 76, 92], [180, 71, 100], [195, 61, 81], [210, 68, 88], [225, 73, 88], [240, 72, 84], [255, 71, 86], [270, 62, 84], [285, 59, 72], [300, 63, 96], [315, 83, 100], [330, 83, 99], [345, 85, 100], [360, 72, 93], [375, 69, 90], [390, 61, 82], [405, 64, 92], [420, 77, 91], [435, 75, 88], [450, 69, 82], [465, 71, 82], [480, 71, 84], [495, 70, 96], [510, 78, 94], [525, 77, 85], [540, 77, 88], [555, 74, 100], [570, 74, 90], [585, 60, 84], [600, 60, 82], [615, 61, 86], [630, 68, 85], [645, 63, 86], [660, 66, 95], [675, 71, 87], [690, 68, 89], [705, 67, 82], [720, 65, 80], [735, 63, 75], [750, 62, 73], [765, 72, 86], [780, 73, 85], [795, 80, 99], [810, 74, 99], [825, 72, 85], [840, 73, 91], [855, 77, 100], [870, 66, 91], [885, 59, 73], [900, 61, 70], [915, 60, 72], [930, 62, 73], [945, 59, 75], [960, 58, 83], [975, 64, 83], [990, 61, 74], [1005, 63, 79], [1020, 63, 80], [1035, 66, 78], [1050, 65, 100], [1065, 59, 100], [1080, 53, 85], [1095, 52, 80], [1110, 63, 78], [1125, 64, 99], [1140, 88, 100], [1155, 67, 98], [1170, 64, 73], [1185, 61, 71], [1199, 60, 68], [1213, 58, 70], [1227, 60, 72], [1241, 66, 79], [1255, 65, 80], [1269, 64, 93], [1283, 72, 93], [1297, 70, 89], [1311, 66, 83], [1325, 69, 83], [1339, 69, 82], [1353, 65, 82], [1367, 67, 97], [1381, 82, 100], [1395, 81, 99], [1409, 78, 93], [1423, 73, 87], [1437, 77, 97], [1451, 70, 83], [1465, 72, 100]]
        },
        {
            id: '0215f12c-6c5f-4e61-8967-a5aa68ebfe58',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/default/egress_capture__10_9_24_10__232_10_10_7__49410.ts',
            demo: 'default',
            measurementPointId: 'b830fcc4-f98e-49c7-a5ea-f541ca55e8e8',
            bitrateList: [1824, 1491, 1217, 1704, 1886, 1135, 1415, 1484, 1694, 1585, 1274, 1984, 1053, 1615, 1500, 1535, 1741, 1493, 1440, 1063, 1762, 1101, 2267, 1417, 1393, 937, 2209, 1412, 1566, 1358, 1841, 1064, 1512, 1910, 1608, 1595, 1296, 1261, 1427, 1616, 1552, 1309, 1732, 1314, 1317, 2113, 1991, 1151, 1161, 1211, 1446, 2550, 879, 1443, 1780, 1261, 1673, 1146, 1490],
            eaId: '5da50744-a9ff-4d3a-9f8b-dbff45298f58',
            vmafReduct: [[0, 55, 65], [15, 58, 67], [30, 60, 66], [45, 59, 80], [60, 63, 82], [75, 64, 93], [90, 70, 79], [105, 55, 78], [120, 60, 77], [135, 57, 77], [150, 66, 80], [165, 69, 85], [180, 65, 93], [195, 54, 73], [210, 61, 80], [225, 66, 80], [240, 65, 76], [255, 64, 79], [270, 54, 76], [285, 52, 65], [300, 58, 86], [315, 78, 93], [330, 76, 93], [345, 81, 95], [360, 69, 87], [375, 63, 83], [390, 55, 71], [405, 58, 86], [420, 72, 84], [435, 68, 83], [450, 57, 76], [465, 57, 68], [480, 61, 74], [495, 58, 86], [510, 68, 84], [525, 72, 79], [540, 71, 82], [555, 67, 92], [570, 67, 82], [585, 52, 75], [600, 50, 71], [615, 45, 76], [630, 59, 75], [645, 56, 80], [660, 60, 85], [675, 62, 78], [690, 60, 78], [705, 61, 74], [720, 58, 72], [735, 49, 68], [750, 49, 62], [765, 62, 76], [780, 62, 77], [795, 74, 92], [810, 68, 91], [825, 66, 78], [840, 67, 84], [855, 70, 93], [870, 58, 83], [885, 51, 63], [900, 51, 60], [915, 52, 65], [930, 53, 64], [945, 52, 65], [960, 53, 77], [975, 59, 78], [990, 53, 66], [1005, 53, 70], [1020, 53, 69], [1035, 56, 66], [1050, 55, 97], [1065, 48, 98], [1080, 42, 73], [1095, 45, 65], [1110, 52, 70], [1125, 59, 95], [1140, 83, 96], [1155, 56, 94], [1170, 53, 62], [1185, 51, 59], [1199, 49, 58], [1213, 48, 64], [1227, 49, 60], [1241, 53, 66], [1255, 56, 72], [1269, 54, 88], [1283, 67, 85], [1297, 58, 77], [1311, 52, 70], [1325, 58, 75], [1339, 60, 74], [1353, 56, 74], [1367, 57, 92], [1381, 76, 94], [1395, 73, 92], [1409, 71, 85], [1423, 66, 80], [1437, 69, 89], [1451, 61, 74], [1465, 64, 100]]
        },
        {
            id: 'e2d7edc8-b764-4a0b-b677-42d6568c3276',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/default/egress_capture__10_9_24_10__232_10_10_8__49410.ts',
            demo: 'default',
            measurementPointId: '0c86d423-c20e-48e5-82da-aa59ac597e12',
            bitrateList: [814, 670, 471, 697, 813, 502, 619, 580, 697, 675, 563, 790, 436, 753, 610, 608, 663, 722, 733, 365, 606, 502, 968, 555, 584, 454, 942, 540, 580, 603, 743, 475, 726, 758, 687, 680, 558, 545, 555, 666, 687, 500, 754, 481, 651, 810, 869, 468, 460, 515, 597, 1064, 411, 565, 714, 582, 725, 506],
            eaId: 'f693524d-f87f-4f36-9816-9cddec0c207a',
            vmafReduct: [[0, 21, 27], [15, 24, 28], [30, 24, 27], [45, 24, 48], [60, 34, 51], [75, 33, 56], [90, 36, 44], [105, 25, 43], [120, 25, 44], [135, 26, 46], [150, 35, 49], [165, 33, 47], [180, 30, 54], [195, 23, 37], [210, 26, 42], [225, 30, 43], [240, 30, 40], [255, 28, 41], [270, 21, 40], [285, 20, 27], [300, 24, 51], [315, 42, 57], [330, 39, 61], [345, 49, 63], [360, 39, 55], [375, 36, 50], [390, 30, 41], [405, 30, 51], [420, 39, 51], [435, 37, 50], [450, 31, 48], [465, 25, 38], [480, 23, 39], [495, 21, 42], [510, 32, 46], [525, 36, 45], [540, 37, 49], [555, 32, 60], [570, 32, 43], [585, 19, 38], [600, 19, 33], [615, 19, 41], [630, 28, 42], [645, 27, 44], [660, 26, 50], [675, 30, 41], [690, 26, 42], [705, 27, 37], [720, 25, 37], [735, 21, 32], [750, 21, 28], [765, 23, 36], [780, 23, 49], [795, 45, 62], [810, 31, 59], [825, 31, 40], [840, 30, 45], [855, 35, 55], [870, 24, 49], [885, 19, 27], [900, 20, 25], [915, 22, 28], [930, 22, 28], [945, 21, 37], [960, 26, 41], [975, 28, 42], [990, 21, 31], [1004, 22, 34], [1018, 18, 27], [1032, 19, 31], [1046, 20, 70], [1060, 24, 65], [1074, 21, 39], [1088, 20, 37], [1102, 20, 42], [1116, 26, 51], [1130, 45, 62], [1144, 48, 62], [1158, 23, 59], [1172, 22, 25], [1186, 20, 25], [1200, 17, 23], [1214, 18, 26], [1228, 18, 36], [1242, 23, 29], [1256, 23, 36], [1270, 21, 57], [1284, 34, 51], [1298, 28, 45], [1312, 22, 38], [1326, 22, 38], [1340, 24, 38], [1354, 19, 38], [1368, 22, 58], [1382, 38, 64], [1396, 36, 54], [1410, 32, 48], [1424, 29, 41], [1438, 28, 51], [1452, 25, 100]]
        }
    ]

    const [bitrateListSource, ] = React.useState(timecodeReference['bitrateList'])
    const [bitrateListBeforeEncoder, setBitrateListBeforeEncoder] = React.useState(default_TS[0]['bitrateList'])
    const [bitrateListCBR, setBitrateListCBR] = React.useState(default_TS[1]['bitrateList'])
    const [bitrateListABR, setBitrateListABR] = React.useState(default_TS[2]['bitrateList'])
    const [vmafReductBeforeEncoder, setVmafReductBeforeEncoder] = React.useState(default_TS[0]['vmafReduct'])
    const [vmafReductCBR, setVmafReductCBR] = React.useState(default_TS[1]['vmafReduct'])
    const [vmafReductABR, setVmafReductABR] = React.useState(default_TS[2]['vmafReduct'])

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    });


    let impairmentEs5000kbps = [
        {
            id: '944e4bf8-282a-45bc-8ba3-c91cc8ace627',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_ES_5000kBps/egress_capture__10_9_24_10__232_10_10_1__49410.ts',
            demo: 'impairment_ES_5000kBps',
            measurementPointId: 'b9165908-e23a-472b-8220-df1a67608e5f',
            bitrateList: [5330, 4828, 5027, 5099, 4807, 4497, 7107, 3234, 4144, 4975, 4700, 5369, 4746, 5959, 5216, 5074, 2765, 6421, 5886, 4328, 3299, 5984, 4525, 4723, 5318, 4163, 4282, 5755, 5363, 4382, 5513, 5671, 4180, 4735, 5032, 5467, 4932, 3072, 5030, 4987, 5149, 5895, 4181, 5191, 3893, 5388, 3379, 6573, 4937, 5009, 3862, 5561, 4201, 5601, 4608, 4653, 5626, 3890, 4749],
            eaId: 'f78099a3-1782-4763-ad71-69079eec9d26',
            vmafReduct: [[0, 68, 91], [30, 69, 95], [60, 82, 96], [90, 84, 100], [120, 85, 100], [150, 80, 97], [180, 89, 99], [210, 88, 98], [240, 90, 97], [270, 90, 100], [300, 78, 95], [330, 72, 98], [360, 90, 100], [390, 91, 100], [420, 93, 100], [450, 89, 100], [480, 85, 100], [510, 67, 100], [540, 64, 88], [570, 79, 100], [600, 90, 100], [630, 89, 98], [660, 79, 96], [690, 78, 91], [720, 77, 92], [750, 76, 100], [780, 89, 100], [810, 88, 97], [840, 92, 100], [870, 89, 100], [900, 77, 100], [930, 76, 91], [960, 66, 90], [990, 78, 96], [1020, 77, 88], [1050, 75, 92], [1080, 75, 94], [1110, 83, 96], [1140, 82, 98], [1170, 82, 97], [1200, 76, 97], [1230, 70, 82], [1260, 71, 85], [1290, 67, 90], [1320, 68, 100], [1350, 95, 100], [1380, 90, 100], [1410, 88, 98], [1440, 87, 100], [1470, 84, 99], [1500, 78, 91], [1530, 73, 81], [1560, 72, 81], [1590, 73, 80], [1620, 74, 82], [1650, 73, 93], [1680, 77, 96], [1710, 75, 94], [1740, 71, 86], [1770, 68, 86], [1800, 72, 91], [1830, 76, 91], [1860, 73, 100], [1890, 57, 90], [1920, 55, 89], [1950, 59, 91], [1980, 76, 97], [2010, 93, 100], [2040, 99, 100], [2070, 75, 100], [2100, 75, 82], [2130, 76, 83], [2160, 72, 81], [2190, 72, 81], [2220, 63, 85], [2250, 66, 87], [2280, 66, 91], [2309, 73, 94], [2338, 70, 96], [2367, 72, 94], [2396, 73, 92], [2425, 72, 91], [2454, 71, 92], [2483, 70, 97], [2512, 88, 100], [2541, 95, 100], [2570, 92, 100], [2599, 87, 100], [2628, 95, 100], [2657, 90, 99], [2686, 92, 100], [2715, 84, 100], [2744, 62, 95], [2773, 67, 88], [2802, 68, 84], [2831, 78, 84], [2860, 75, 83], [2889, 80, 100], [2918, 93, 100], [2947, 47, 100]]
        },
        {
            id: '32bba344-1ef3-4d1e-a9c8-d1f63d4f2fb1',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_ES_5000kBps/egress_capture__10_9_24_10__232_10_10_2__49410.ts',
            demo: 'impairment_ES_5000kBps',
            measurementPointId: '82287e69-aabf-400f-b01b-7f33888733ec',
            bitrateList: [9793, 8759, 6496, 7614, 8372, 7284, 6835, 9549, 7140, 7894, 8020, 7805, 7197, 8505, 7283, 6881, 12146, 5772, 7530, 7902, 7264, 6942, 10236, 7655, 7750, 6855, 6155, 7709, 9490, 8374, 6394, 6657, 10601, 7748, 6218, 8100, 7815, 9746, 5920, 8881, 7739, 9303, 4489, 8910, 7649, 7065, 9430, 6573, 6525, 9403, 8837, 5649, 9752, 8133, 7926, 5623, 9763, 6856, 7815],
            eaId: '2f6bc642-00ac-4c37-85f4-d1ae5385ff7b',
            vmafReduct: [[0, 73, 86], [30, 72, 81], [60, 73, 80], [90, 74, 79], [120, 71, 94], [150, 79, 90], [180, 79, 94], [210, 76, 94], [240, 68, 89], [270, 67, 90], [300, 69, 93], [330, 81, 95], [360, 82, 100], [390, 79, 100], [420, 79, 97], [450, 88, 98], [480, 87, 97], [510, 90, 97], [540, 78, 99], [570, 76, 87], [600, 68, 100], [630, 89, 100], [660, 90, 100], [690, 91, 100], [720, 85, 100], [750, 83, 100], [780, 67, 90], [810, 63, 87], [840, 89, 100], [870, 89, 100], [900, 88, 97], [930, 78, 95], [960, 77, 90], [990, 74, 91], [1020, 81, 100], [1050, 88, 97], [1080, 87, 96], [1110, 90, 100], [1140, 87, 100], [1170, 76, 97], [1200, 73, 89], [1230, 64, 87], [1260, 76, 94], [1290, 76, 89], [1320, 74, 89], [1350, 73, 95], [1380, 82, 97], [1410, 81, 96], [1440, 81, 93], [1470, 71, 96], [1500, 70, 84], [1530, 68, 83], [1560, 66, 87], [1590, 67, 100], [1620, 95, 100], [1650, 87, 100], [1680, 87, 100], [1710, 84, 100], [1740, 83, 97], [1770, 74, 87], [1800, 72, 80], [1830, 71, 80], [1860, 71, 79], [1890, 72, 80], [1920, 71, 89], [1950, 75, 94], [1980, 74, 93], [2010, 70, 85], [2040, 69, 85], [2070, 71, 89], [2100, 75, 91], [2129, 70, 100], [2158, 56, 88], [2187, 53, 87], [2216, 58, 89], [2245, 75, 96], [2274, 92, 100], [2303, 99, 100], [2332, 71, 100], [2361, 73, 80], [2390, 74, 80], [2419, 70, 81], [2448, 71, 79], [2477, 62, 84], [2506, 60, 85], [2535, 66, 86], [2564, 69, 92], [2593, 69, 95], [2622, 74, 93], [2651, 72, 91], [2680, 72, 90], [2709, 71, 90], [2738, 69, 93], [2767, 87, 100], [2796, 90, 100], [2825, 93, 100], [2854, 89, 100], [2883, 86, 100], [2912, 89, 100], [2941, 54, 100]]
        },
        {
            id: '5df68405-9e09-4453-9670-80a65cc16a92',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_ES_5000kBps/egress_capture__10_9_24_10__232_10_10_3__49410.ts',
            demo: 'impairment_ES_5000kBps',
            measurementPointId: 'ecee35c2-19b2-4bb5-afb6-e3a6d45e2253',
            bitrateList: [5632, 4229, 4761, 4219, 5218, 5265, 4254, 4888, 4724, 5738, 5529, 4724, 4438, 4478, 5306, 4354, 5941, 4432, 5612, 4510, 5092, 3934, 4101, 6032, 5239, 4897, 3987, 5316, 4832, 4709, 5834, 4428, 4150, 5117, 4602, 5866, 4543, 4515, 5002, 4562, 5404, 4988, 4880, 4320, 6122, 4512, 4413, 5405, 4791, 4144, 4271, 5245, 4631, 4895, 6058, 4509, 4760, 4620, 4416],
            eaId: 'aaffe607-436a-43ef-80d4-6f5588ed9c09',
            vmafReduct: [[0, 73, 80], [30, 66, 76], [60, 63, 70], [90, 64, 70], [120, 64, 81], [150, 74, 87], [180, 73, 92], [210, 77, 89], [240, 59, 88], [270, 62, 81], [300, 59, 79], [330, 72, 89], [360, 72, 82], [390, 74, 91], [420, 72, 84], [450, 76, 89], [480, 82, 90], [510, 82, 89], [540, 84, 94], [570, 71, 94], [600, 67, 76], [630, 68, 98], [660, 88, 100], [690, 88, 97], [720, 85, 99], [750, 77, 92], [780, 73, 92], [810, 59, 79], [840, 64, 97], [870, 84, 91], [900, 85, 92], [930, 84, 90], [960, 63, 80], [990, 63, 80], [1020, 62, 86], [1050, 68, 93], [1080, 85, 93], [1110, 85, 95], [1140, 84, 98], [1170, 78, 93], [1200, 68, 83], [1230, 61, 81], [1260, 53, 82], [1290, 68, 83], [1320, 67, 84], [1350, 66, 78], [1380, 69, 84], [1410, 73, 82], [1440, 72, 83], [1470, 74, 84], [1500, 60, 81], [1530, 63, 75], [1560, 59, 75], [1590, 61, 85], [1620, 78, 100], [1650, 86, 100], [1680, 83, 93], [1710, 79, 93], [1740, 79, 89], [1770, 75, 90], [1800, 65, 76], [1830, 64, 70], [1860, 63, 70], [1890, 64, 72], [1920, 65, 81], [1950, 60, 78], [1980, 65, 86], [2010, 64, 81], [2040, 60, 71], [2070, 62, 70], [2100, 66, 75], [2130, 67, 88], [2160, 54, 89], [2190, 49, 78], [2220, 49, 83], [2250, 64, 79], [2280, 62, 100], [2310, 95, 100], [2340, 68, 100], [2370, 65, 71], [2400, 65, 70], [2430, 63, 69], [2460, 60, 69], [2490, 56, 73], [2520, 57, 67], [2549, 58, 77], [2578, 67, 82], [2607, 67, 86], [2636, 59, 71], [2665, 56, 70], [2694, 59, 70], [2723, 59, 70], [2752, 56, 90], [2781, 81, 98], [2810, 86, 100], [2839, 89, 100], [2868, 84, 92], [2897, 82, 100], [2926, 85, 100], [2955, 87, 100]]
        },
        {
            id: '06051a11-6218-4528-8322-693bf83cc6bd',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_ES_5000kBps/egress_capture__10_9_24_10__232_10_10_4__49410.ts',
            demo: 'impairment_ES_5000kBps',
            measurementPointId: 'c6322b9f-4f37-4943-85eb-3db2b2bd2626',
            bitrateList: [3609, 4062, 3121, 3199, 4166, 2649, 3300, 4029, 3984, 3568, 2807, 3999, 2763, 3559, 3107, 3508, 4455, 2850, 3947, 2847, 3533, 2388, 5279, 3350, 3295, 2410, 4293, 3104, 3828, 3455, 4217, 2259, 3706, 3597, 3465, 3357, 3316, 3529, 3765, 3814, 3344, 2933, 3724, 4087, 3496, 2830, 4291, 3062, 3276, 3095, 2981, 4658, 3100, 3708, 3529, 2341, 3891, 3218],
            eaId: 'eb5a1ab7-51b1-4931-bd55-5e8ed46f5be6',
            vmafReduct: [[0, 65, 69], [15, 67, 75], [30, 69, 76], [45, 69, 85], [60, 75, 89], [75, 75, 100], [90, 81, 92], [105, 68, 91], [120, 69, 85], [135, 69, 83], [150, 76, 86], [165, 80, 89], [180, 82, 100], [195, 78, 91], [210, 82, 96], [225, 85, 93], [240, 84, 92], [255, 85, 98], [270, 69, 94], [285, 69, 79], [300, 72, 99], [315, 90, 100], [330, 94, 100], [345, 90, 100], [360, 79, 100], [375, 70, 92], [390, 65, 81], [405, 72, 97], [420, 82, 90], [435, 85, 93], [450, 77, 92], [465, 76, 89], [480, 75, 88], [495, 72, 97], [510, 79, 93], [525, 87, 92], [540, 87, 99], [555, 77, 100], [570, 81, 96], [585, 69, 87], [600, 66, 81], [615, 60, 91], [630, 74, 94], [645, 72, 84], [660, 69, 88], [675, 78, 89], [690, 79, 89], [705, 81, 90], [720, 79, 89], [735, 67, 83], [750, 66, 78], [765, 71, 82], [780, 72, 86], [795, 89, 100], [810, 87, 100], [825, 84, 92], [840, 84, 98], [855, 82, 100], [870, 71, 93], [885, 65, 72], [900, 64, 67], [915, 67, 71], [930, 68, 74], [945, 70, 84], [960, 71, 82], [975, 73, 84], [990, 67, 75], [1005, 61, 72], [1020, 61, 75], [1035, 70, 77], [1050, 72, 90], [1065, 61, 90], [1080, 57, 69], [1095, 57, 87], [1109, 70, 83], [1123, 70, 100], [1137, 100, 100], [1151, 70, 100], [1165, 69, 72], [1179, 67, 71], [1193, 66, 72], [1207, 64, 70], [1221, 66, 70], [1235, 59, 80], [1249, 60, 75], [1263, 62, 87], [1277, 72, 93], [1291, 70, 97], [1305, 73, 92], [1319, 71, 80], [1333, 68, 77], [1347, 60, 74], [1361, 67, 90], [1375, 83, 97], [1389, 92, 100], [1403, 95, 100], [1417, 87, 96], [1431, 87, 100], [1445, 90, 100], [1459, 91, 100]]
        },
        {
            id: '737d046a-d866-409b-b251-d37574b238b3',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_ES_5000kBps/egress_capture__10_9_24_10__232_10_10_5__49410.ts',
            demo: 'impairment_ES_5000kBps',
            measurementPointId: '5e6d8f6a-0648-4a96-ad63-7fc00dae7996',
            bitrateList: [2882, 2568, 2453, 2579, 2790, 1978, 2684, 3017, 3059, 2479, 2041, 3187, 2004, 2713, 2479, 2549, 3504, 2297, 2419, 1739, 2690, 2110, 4076, 2178, 2457, 1970, 2941, 2626, 2914, 2353, 3029, 1850, 2878, 2694, 2762, 2476, 2228, 2621, 2795, 2679, 2505, 2423, 2873, 2888, 2228, 2542, 3649, 1631, 2474, 2303, 2295, 3480, 2189, 2774, 2756, 2069, 2948, 2046, 2802],
            eaId: '64fb4e84-ceb5-4bf9-aefd-8a871e624b9a',
            vmafReduct: [[0, 64, 75], [15, 64, 71], [30, 64, 70], [45, 65, 84], [60, 74, 87], [75, 73, 100], [90, 78, 87], [105, 62, 87], [120, 65, 79], [135, 66, 79], [150, 75, 83], [165, 78, 87], [180, 78, 99], [195, 73, 86], [210, 76, 91], [225, 80, 89], [240, 79, 88], [255, 81, 95], [270, 67, 91], [285, 67, 76], [300, 71, 94], [315, 86, 100], [330, 90, 97], [345, 88, 97], [360, 75, 100], [375, 67, 90], [390, 60, 76], [405, 67, 100], [420, 83, 92], [435, 82, 91], [450, 72, 86], [465, 70, 81], [480, 69, 81], [495, 65, 92], [510, 76, 88], [525, 84, 89], [540, 83, 95], [555, 72, 100], [570, 75, 90], [585, 64, 81], [600, 59, 75], [615, 56, 90], [630, 72, 90], [645, 65, 79], [660, 63, 89], [675, 76, 88], [690, 76, 87], [705, 76, 86], [720, 74, 85], [735, 60, 79], [750, 60, 74], [765, 67, 78], [780, 68, 86], [795, 86, 100], [810, 83, 100], [825, 80, 89], [840, 80, 95], [855, 79, 97], [870, 70, 90], [885, 56, 70], [900, 56, 63], [915, 61, 64], [930, 62, 68], [945, 66, 82], [960, 66, 80], [975, 68, 81], [990, 61, 71], [1005, 59, 69], [1020, 58, 73], [1035, 67, 73], [1050, 68, 89], [1065, 57, 89], [1080, 52, 67], [1095, 55, 78], [1110, 64, 77], [1125, 65, 100], [1140, 96, 100], [1154, 69, 100], [1168, 60, 70], [1182, 56, 62], [1196, 58, 62], [1210, 61, 66], [1224, 61, 67], [1238, 56, 75], [1252, 59, 79], [1266, 61, 85], [1280, 71, 88], [1294, 67, 90], [1308, 68, 85], [1322, 68, 78], [1336, 66, 75], [1350, 61, 72], [1364, 66, 90], [1378, 82, 96], [1392, 89, 100], [1406, 88, 100], [1420, 82, 93], [1434, 87, 100], [1448, 83, 99], [1462, 84, 100]]
        },
        {
            id: '1eaa91a8-83b9-4b64-935f-e350983ab032',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_ES_5000kBps/egress_capture__10_9_24_10__232_10_10_6__49410.ts',
            demo: 'impairment_ES_5000kBps',
            measurementPointId: 'b473a1eb-1645-4ee1-a25d-9eab50d3d52b',
            bitrateList: [2232, 1853, 1679, 2232, 2622, 1474, 1754, 1967, 2328, 2128, 1751, 2445, 1466, 2273, 1805, 2062, 2295, 1886, 2038, 1541, 2122, 1460, 2904, 1854, 1927, 1356, 2559, 2061, 1998, 1926, 2564, 1304, 2010, 2437, 2311, 2037, 1667, 1670, 1895, 2067, 2209, 1758, 2163, 1973, 1662, 2232, 2812, 1623, 1715, 1776, 1817, 3095, 1559, 1872, 2039, 1766, 2340, 1664, 2268],
            eaId: '7b67ae46-902c-4e5d-aef6-ace4e78ff26a',
            vmafReduct: [[0, 57, 68], [15, 56, 65], [30, 57, 63], [45, 58, 80], [60, 65, 84], [75, 64, 90], [90, 69, 79], [105, 60, 84], [120, 64, 77], [135, 59, 72], [150, 64, 75], [165, 69, 80], [180, 66, 90], [195, 57, 72], [210, 61, 80], [225, 70, 81], [240, 69, 78], [255, 71, 84], [270, 59, 81], [285, 57, 67], [300, 63, 83], [315, 79, 95], [330, 79, 88], [345, 80, 91], [360, 68, 89], [375, 63, 82], [390, 54, 70], [405, 57, 90], [420, 74, 83], [435, 72, 83], [450, 64, 78], [465, 65, 79], [480, 67, 78], [495, 61, 86], [510, 69, 83], [525, 72, 77], [540, 72, 84], [555, 73, 95], [570, 69, 85], [585, 56, 75], [600, 53, 69], [615, 47, 81], [630, 61, 78], [645, 58, 75], [660, 59, 81], [675, 66, 79], [690, 64, 79], [705, 63, 76], [720, 62, 74], [735, 57, 68], [750, 56, 64], [765, 60, 70], [780, 59, 77], [795, 76, 93], [810, 71, 94], [825, 69, 79], [840, 67, 85], [855, 70, 89], [870, 61, 82], [885, 56, 63], [900, 52, 58], [915, 55, 61], [930, 54, 62], [945, 55, 70], [960, 57, 72], [975, 59, 75], [990, 56, 66], [1005, 55, 64], [1020, 54, 66], [1035, 58, 66], [1050, 59, 85], [1065, 50, 84], [1080, 44, 61], [1095, 45, 73], [1110, 57, 71], [1125, 59, 98], [1140, 86, 99], [1154, 61, 96], [1168, 58, 62], [1182, 55, 61], [1196, 54, 60], [1210, 52, 58], [1224, 54, 59], [1238, 51, 72], [1252, 55, 72], [1266, 57, 80], [1280, 64, 83], [1294, 60, 88], [1308, 62, 78], [1322, 62, 70], [1336, 60, 69], [1350, 54, 64], [1364, 57, 82], [1378, 75, 89], [1392, 79, 92], [1406, 77, 92], [1420, 71, 83], [1434, 73, 95], [1448, 68, 88], [1462, 69, 100]]
        },
        {
            id: '68d2385d-c789-430c-a3e8-8ca9c0a47627',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_ES_5000kBps/egress_capture__10_9_24_10__232_10_10_7__49410.ts',
            demo: 'impairment_ES_5000kBps',
            measurementPointId: 'b830fcc4-f98e-49c7-a5ea-f541ca55e8e8',
            bitrateList: [1709, 1418, 1250, 1700, 1997, 1097, 1373, 1506, 1702, 1565, 1244, 1935, 1080, 1663, 1477, 1569, 1779, 1568, 1416, 837, 1718, 1326, 2208, 1358, 1378, 1061, 1995, 1556, 1469, 1474, 1939, 952, 1481, 2046, 1574, 1551, 1294, 1299, 1374, 1597, 1720, 1207, 1661, 1546, 1123, 2061, 2021, 1005, 1294, 1404, 1413, 2278, 1005, 1399, 1712, 1335, 1797, 1246],
            eaId: '63122966-e003-434c-87a2-19901a71762a',
            vmafReduct: [[0, 50, 62], [15, 50, 58], [30, 53, 57], [45, 52, 73], [60, 59, 77], [75, 60, 85], [90, 64, 73], [105, 52, 77], [120, 56, 69], [135, 52, 66], [150, 60, 69], [165, 64, 75], [180, 61, 85], [195, 51, 66], [210, 54, 74], [225, 63, 74], [240, 62, 71], [255, 62, 77], [270, 52, 73], [285, 50, 60], [300, 57, 77], [315, 76, 91], [330, 73, 83], [345, 76, 87], [360, 65, 83], [375, 59, 77], [390, 49, 63], [405, 52, 84], [420, 68, 79], [435, 66, 78], [450, 54, 72], [465, 53, 65], [480, 52, 64], [495, 48, 78], [510, 63, 77], [525, 68, 73], [540, 68, 78], [555, 62, 88], [570, 62, 78], [585, 48, 67], [600, 45, 60], [615, 40, 73], [630, 55, 72], [645, 52, 69], [660, 52, 76], [675, 60, 73], [690, 58, 72], [705, 56, 68], [720, 54, 66], [735, 49, 61], [750, 49, 55], [765, 52, 63], [780, 51, 70], [795, 70, 86], [810, 66, 87], [825, 64, 74], [840, 60, 79], [855, 64, 83], [870, 55, 76], [885, 49, 55], [900, 46, 52], [915, 48, 55], [930, 48, 56], [945, 50, 61], [960, 49, 65], [975, 53, 69], [990, 49, 60], [1005, 48, 59], [1020, 48, 59], [1035, 49, 57], [1050, 50, 82], [1065, 45, 79], [1080, 40, 57], [1095, 39, 59], [1109, 44, 60], [1123, 47, 94], [1137, 86, 94], [1151, 51, 92], [1165, 48, 55], [1179, 44, 52], [1193, 45, 50], [1207, 45, 52], [1221, 47, 53], [1235, 45, 65], [1249, 48, 61], [1263, 49, 75], [1277, 59, 76], [1291, 56, 78], [1305, 54, 67], [1319, 52, 60], [1333, 53, 61], [1347, 51, 60], [1361, 52, 77], [1375, 72, 85], [1389, 71, 83], [1403, 72, 86], [1417, 65, 77], [1431, 64, 88], [1445, 59, 87], [1459, 61, 100]]
        },
        {
            id: '317fdfe0-ac82-4a8a-bb66-d1895297db19',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_ES_5000kBps/egress_capture__10_9_24_10__232_10_10_8__49410.ts',
            demo: 'impairment_ES_5000kBps',
            measurementPointId: '0c86d423-c20e-48e5-82da-aa59ac597e12',
            bitrateList: [775, 662, 523, 711, 798, 499, 615, 559, 739, 633, 549, 834, 425, 732, 586, 609, 689, 691, 742, 403, 633, 477, 956, 567, 600, 416, 959, 537, 613, 642, 781, 425, 663, 749, 714, 687, 559, 584, 543, 639, 668, 518, 765, 490, 646, 723, 853, 537, 503, 518, 582, 1052, 462, 582, 673, 559, 767, 541, 654],
            eaId: '552b42fc-a81a-445a-8b10-14d279166c96',
            vamfReduct: [[0, 19, 26], [15, 21, 25], [30, 23, 25], [45, 22, 47], [60, 35, 50], [75, 33, 53], [90, 34, 41], [105, 23, 41], [120, 24, 41], [135, 25, 42], [150, 33, 44], [165, 31, 43], [180, 29, 51], [195, 25, 36], [210, 28, 40], [225, 30, 40], [240, 29, 38], [255, 27, 40], [270, 22, 39], [285, 21, 26], [300, 24, 46], [315, 42, 57], [330, 38, 57], [345, 48, 59], [360, 36, 52], [375, 35, 48], [390, 30, 40], [405, 30, 51], [420, 39, 50], [435, 36, 48], [450, 30, 47], [465, 25, 39], [480, 24, 38], [495, 21, 43], [510, 32, 44], [525, 35, 41], [540, 35, 45], [555, 33, 55], [570, 32, 42], [585, 19, 35], [600, 19, 32], [615, 18, 38], [630, 25, 38], [645, 24, 41], [660, 25, 46], [675, 29, 39], [690, 26, 40], [705, 27, 37], [720, 25, 36], [735, 20, 32], [750, 14, 24], [765, 23, 34], [780, 21, 42], [795, 42, 57], [810, 31, 55], [825, 30, 39], [840, 29, 43], [855, 35, 53], [870, 23, 47], [885, 19, 24], [900, 19, 23], [915, 21, 24], [930, 21, 26], [945, 21, 36], [960, 24, 37], [975, 26, 39], [990, 19, 29], [1005, 19, 30], [1020, 18, 29], [1035, 18, 25], [1050, 20, 62], [1065, 20, 54], [1080, 20, 32], [1095, 21, 40], [1110, 24, 39], [1125, 25, 59], [1140, 45, 59], [1155, 23, 57], [1170, 22, 24], [1185, 20, 24], [1200, 18, 23], [1214, 17, 24], [1228, 16, 34], [1242, 22, 27], [1256, 21, 34], [1270, 19, 51], [1284, 32, 48], [1298, 28, 45], [1312, 22, 35], [1326, 22, 35], [1340, 21, 32], [1354, 18, 33], [1368, 20, 50], [1382, 37, 58], [1396, 36, 52], [1410, 33, 47], [1424, 30, 41], [1438, 29, 50], [1452, 23, 34], [1466, 5, 100]]
        }]

    let impairmentTS35MBps = [
        {
            id: '422199e9-36b8-49b8-bd79-22ec3114297d',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_TS_35MBps/egress_capture__10_9_24_10__232_10_10_1__49410.ts',
            demo: 'impairment_TS_35MBps',
            measurementPointId: 'b9165908-e23a-472b-8220-df1a67608e5f',
            eaId: '2bb477ce-2357-4a06-bed8-49df6e7a9096',
            bitrateList: [41750, 30876, 35065, 37707, 31276, 33262, 34244, 38005, 30145, 33630, 34013, 36714, 30855, 30685, 38423, 32233, 33509, 25901, 44813, 33897, 34097, 25175, 33767, 40519, 35996, 27929, 33225, 42583, 31277, 33466, 41246, 32644, 36775, 30480, 32947, 34631, 39283, 31629, 32255, 37663, 32505, 36913, 28995, 34840, 28765, 41109, 29761, 37252, 31999, 35169, 38448, 38270, 30456, 34866, 27128, 41160, 35397, 31590],
            vmafReduct:  [[0, 6, 13], [30, 11, 20], [60, 16, 28], [90, 26, 34], [120, 8, 28], [150, 5, 26], [180, 0, 16], [210, 0, 9], [240, 5, 13], [270, 2, 20], [300, 0, 11], [330, 4, 16], [360, 0, 20], [389, 0, 57], [418, 0, 55], [447, 22, 50], [476, 4, 36], [505, 0, 37], [534, 13, 62], [563, 3, 42], [592, 4, 12], [621, 5, 9], [650, 3, 26], [679, 7, 20], [708, 4, 8], [737, 8, 16], [766, 17, 27], [795, 8, 43], [824, 47, 61], [853, 33, 59], [882, 22, 43], [911, 15, 24], [940, 13, 18], [969, 14, 23], [998, 15, 22], [1027, 17, 40], [1056, 7, 36], [1085, 7, 15], [1114, 7, 14], [1143, 7, 15], [1172, 6, 16], [1201, 8, 15], [1230, 5, 12], [1259, 5, 54], [1288, 3, 6], [1317, 4, 17], [1346, 17, 30], [1375, 26, 52], [1404, 10, 50], [1433, 11, 17], [1462, 18, 34], [1491, 7, 38], [1520, 6, 75], [1549, 68, 77], [1578, 68, 75], [1607, 71, 75], [1636, 71, 77], [1665, 9, 77], [1694, 0, 100], [1723, 96, 100], [1752, 96, 100], [1781, 95, 100], [1810, 97, 100], [1839, 96, 100], [1868, 0, 100], [1897, 0, 24], [1926, 18, 27], [1955, 14, 30], [1984, 9, 20], [2013, 10, 45], [2042, 24, 38], [2071, 2, 69], [2100, 62, 71], [2129, 60, 72], [2158, 33, 59], [2187, 33, 57], [2216, 8, 59], [2245, 12, 21], [2274, 6, 23], [2303, 7, 31], [2332, 10, 18], [2361, 10, 11], [2390, 9, 10], [2419, 9, 11], [2448, 9, 10], [2477, 2, 12], [2506, 0, 31], [2535, 6, 44], [2564, 0, 12], [2593, 0, 23], [2622, 13, 30], [2651, 3, 30], [2680, 2, 19], [2709, 15, 27], [2738, 0, 32], [2767, 0, 46], [2796, 29, 76], [2825, 68, 80], [2854, 0, 78], [2883, 0, 100]]
        },
        {
            id: 'c87d98b3-6916-46e3-a22c-8dbd1902a6ea',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_TS_35MBps/egress_capture__10_9_24_10__232_10_10_2__49410.ts',
            demo: 'impairment_TS_35MBps',
            measurementPointId: '82287e69-aabf-400f-b01b-7f33888733ec',
            eaId: '9af7b505-5b2a-4ff4-a593-d3f1070f4b08',
            bitrateList: [8104, 10495, 7879, 5583, 7140, 8761, 7589, 7658, 7671, 7718, 7281, 12620, 5796, 6982, 8123, 9027, 5431, 7077, 8286, 9478, 9049, 5081, 6744, 10221, 9053, 5985, 8447, 7171, 8563, 7590, 6700, 7939, 7318, 8955, 7066, 8235, 8049, 8676, 6452, 7741, 7401, 9947, 5113, 10626, 7378, 6668, 10360, 7244, 6876, 7416, 7199, 6242, 9070, 7938, 7811, 11057, 5983, 4815, 7919],
            vmafReduct:  [[0, 79, 96], [30, 80, 91], [60, 83, 91], [90, 85, 91], [120, 0, 87], [150, 76, 87], [180, 89, 100], [210, 85, 97], [240, 84, 98], [270, 83, 100], [300, 40, 99], [330, 16, 100], [360, 95, 100], [390, 89, 100], [420, 88, 100], [450, 94, 100], [480, 93, 99], [510, 5, 98], [540, 10, 99], [570, 84, 92], [600, 82, 100], [630, 97, 100], [660, 98, 100], [690, 100, 100], [720, 8, 100], [750, 89, 100], [780, 80, 96], [810, 69, 95], [840, 96, 100], [870, 95, 100], [900, 93, 100], [930, 11, 99], [960, 88, 96], [990, 88, 100], [1020, 98, 100], [1050, 96, 100], [1080, 93, 98], [1110, 39, 100], [1140, 21, 100], [1170, 86, 100], [1200, 86, 97], [1230, 85, 96], [1260, 90, 100], [1290, 84, 96], [1320, 12, 96], [1350, 10, 100], [1380, 91, 100], [1410, 90, 100], [1440, 89, 100], [1470, 81, 100], [1500, 83, 92], [1530, 4, 93], [1560, 7, 98], [1590, 91, 100], [1620, 100, 100], [1650, 92, 100], [1680, 93, 100], [1710, 93, 100], [1740, 15, 100], [1770, 82, 94], [1800, 82, 91], [1830, 83, 91], [1860, 82, 89], [1890, 82, 89], [1920, 9, 100], [1950, 9, 100], [1980, 83, 98], [2010, 84, 95], [2040, 84, 94], [2070, 85, 97], [2100, 85, 99], [2130, 0, 100], [2160, 10, 91], [2189, 69, 93], [2218, 66, 95], [2247, 84, 98], [2276, 96, 100], [2305, 99, 100], [2334, 1, 100], [2363, 81, 88], [2392, 80, 86], [2421, 81, 86], [2450, 80, 88], [2479, 78, 89], [2508, 82, 93], [2537, 11, 98], [2566, 81, 99], [2595, 78, 100], [2624, 88, 100], [2653, 88, 99], [2682, 88, 98], [2711, 87, 98], [2740, 5, 96], [2769, 5, 100], [2798, 94, 100], [2827, 93, 100], [2856, 88, 99], [2885, 96, 100], [2914, 94, 100], [2943, 14, 100]]
        },
        {
            id: '451324ff-7eb7-491d-98d4-45bd9f7b81ec',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_TS_35MBps/egress_capture__10_9_24_10__232_10_10_3__49410.ts',
            demo: 'impairment_TS_35MBps',
            measurementPointId: 'ecee35c2-19b2-4bb5-afb6-e3a6d45e2253',
            eaId: 'eb11aa3e-cdca-4ef2-9e39-71fdd7407893',
            bitrateList: [5624, 4579, 5842, 3937, 5334, 4737, 3953, 5199, 5312, 4989, 5562, 5317, 4014, 4235, 4785, 4399, 5401, 5130, 5838, 5091, 5226, 3572, 3881, 7495, 3951, 4483, 4742, 5059, 4626, 4626, 4962, 5269, 4036, 5726, 4467, 5693, 4370, 4378, 4361, 6046, 5525, 4792, 3694, 4735, 4738, 5721, 3538, 7064, 4113, 4776, 4252, 5095, 4686, 4893, 5540, 6386, 3366, 3887, 4799],
            vmafReduct: [[0, 71, 89], [30, 70, 80], [60, 73, 79], [90, 73, 79], [120, 0, 77], [150, 42, 87], [180, 79, 100], [210, 80, 94], [240, 66, 87], [270, 67, 89], [300, 70, 92], [330, 15, 96], [360, 81, 100], [390, 84, 100], [420, 82, 98], [450, 90, 97], [480, 88, 96], [510, 8, 97], [540, 4, 95], [570, 72, 87], [600, 69, 100], [630, 93, 100], [660, 94, 100], [690, 90, 100], [720, 9, 100], [750, 21, 94], [780, 74, 94], [810, 64, 84], [840, 76, 100], [870, 92, 100], [900, 90, 100], [930, 10, 97], [960, 11, 91], [990, 72, 88], [1020, 71, 100], [1050, 79, 96], [1080, 87, 94], [1110, 93, 100], [1140, 21, 100], [1170, 58, 92], [1200, 67, 86], [1230, 65, 86], [1260, 82, 95], [1290, 76, 89], [1320, 23, 89], [1350, 10, 91], [1380, 81, 96], [1410, 79, 97], [1440, 79, 97], [1470, 78, 93], [1500, 63, 81], [1530, 4, 85], [1560, 3, 82], [1590, 69, 100], [1620, 90, 100], [1650, 88, 100], [1680, 88, 97], [1710, 85, 100], [1740, 15, 100], [1770, 73, 95], [1800, 71, 79], [1830, 72, 80], [1860, 73, 79], [1890, 73, 79], [1920, 69, 87], [1950, 9, 100], [1980, 72, 90], [2010, 72, 86], [2040, 69, 87], [2070, 71, 87], [2100, 71, 90], [2130, 0, 100], [2160, 0, 90], [2189, 51, 83], [2218, 54, 89], [2247, 78, 93], [2276, 87, 100], [2305, 93, 100], [2334, 1, 100], [2363, 5, 80], [2392, 74, 80], [2421, 74, 80], [2450, 72, 82], [2479, 71, 83], [2508, 66, 83], [2537, 12, 89], [2566, 11, 93], [2595, 75, 92], [2624, 71, 87], [2653, 69, 89], [2682, 72, 88], [2711, 70, 87], [2740, 9, 88], [2769, 5, 91], [2798, 73, 99], [2827, 89, 100], [2856, 86, 97], [2885, 86, 100], [2914, 91, 100], [2943, 14, 100]]
        },
        {
            id: '6677eb58-858e-4376-a94e-0e5e019770a9',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_TS_35MBps/egress_capture__10_9_24_10__232_10_10_4__49410.ts',
            demo: 'impairment_TS_35MBps',
            measurementPointId: 'c6322b9f-4f37-4943-85eb-3db2b2bd2626',
            eaId: '4a915521-598c-4b62-90f8-0894ae2dc66b',
            bitrateList: [3809, 3846, 3133, 3469, 4255, 2686, 3370, 3591, 3782, 3600, 3316, 3634, 3123, 3577, 3285, 3633, 4139, 2771, 4566, 2317, 3444, 2397, 4889, 3006, 3985, 2390, 4619, 2918, 3824, 3174, 4823, 2107, 3492, 3673, 3779, 3306, 3305, 3331, 3468, 3702, 3696, 3062, 3314, 4005, 3450, 2694, 5850, 1857, 3274, 3208, 3326, 4291, 2808, 3830, 4364, 2282, 3297, 3273, 4115],
            vmafReduct: [[0, 75, 79], [15, 78, 84], [30, 80, 87], [45, 0, 83], [60, 44, 81], [75, 80, 100], [90, 90, 96], [105, 78, 97], [120, 79, 99], [135, 81, 95], [150, 22, 100], [165, 40, 100], [180, 87, 100], [195, 86, 100], [210, 90, 100], [225, 91, 100], [240, 90, 100], [255, 5, 97], [270, 72, 98], [285, 69, 81], [300, 77, 100], [315, 97, 100], [330, 98, 100], [345, 19, 100], [360, 12, 100], [375, 75, 100], [390, 75, 94], [405, 67, 97], [420, 84, 99], [435, 91, 100], [450, 13, 97], [465, 14, 94], [480, 79, 94], [495, 76, 100], [510, 86, 99], [525, 93, 100], [540, 93, 100], [555, 23, 100], [570, 24, 99], [585, 80, 97], [600, 80, 95], [615, 83, 99], [630, 82, 99], [645, 80, 98], [660, 11, 99], [675, 87, 98], [690, 87, 100], [705, 89, 99], [720, 86, 98], [735, 70, 95], [750, 49, 89], [765, 6, 87], [780, 75, 94], [795, 91, 100], [810, 92, 100], [825, 90, 99], [840, 92, 100], [855, 17, 100], [870, 26, 99], [885, 71, 79], [900, 71, 82], [915, 76, 82], [930, 76, 84], [945, 73, 92], [960, 13, 90], [975, 79, 96], [990, 77, 86], [1005, 77, 90], [1020, 77, 94], [1035, 81, 92], [1050, 80, 100], [1065, 0, 100], [1080, 22, 92], [1095, 64, 92], [1110, 78, 94], [1125, 78, 100], [1140, 97, 100], [1154, 2, 100], [1168, 7, 81], [1182, 75, 81], [1196, 76, 83], [1210, 74, 84], [1224, 76, 83], [1238, 73, 91], [1252, 73, 95], [1266, 13, 98], [1280, 84, 96], [1294, 80, 100], [1308, 83, 97], [1322, 84, 96], [1336, 83, 95], [1350, 75, 93], [1364, 7, 91], [1378, 15, 93], [1392, 81, 100], [1406, 96, 100], [1420, 93, 100], [1434, 98, 100], [1448, 93, 100], [1462, 16, 100]]
        },
        {
            id: 'ff3f53c8-5ca2-431f-b591-8c65a2479f6f',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_TS_35MBps/egress_capture__10_9_24_10__232_10_10_5__49410.ts',
            demo: 'impairment_TS_35MBps',
            measurementPointId: '5e6d8f6a-0648-4a96-ad63-7fc00dae7996',
            eaId: 'e545bd4c-56c8-4db0-a410-c6666d86a126',
            bitrateList: [3112, 2718, 2081, 2356, 3062, 2174, 2680, 2663, 2947, 2626, 2718, 2853, 2037, 2602, 2731, 2497, 2657, 2475, 3061, 1555, 2533, 2288, 3969, 1868, 2742, 2095, 3064, 2435, 2952, 2407, 3303, 1689, 2725, 2450, 3118, 2669, 2351, 2475, 2547, 2562, 2599, 2463, 2903, 2744, 2087, 2437, 4374, 1619, 2312, 2109, 2541, 3196, 2105, 2742, 3225, 1971, 2478, 2075],
            vmafReduct: [[0, 72, 80], [15, 74, 82], [30, 76, 83], [45, 0, 78], [60, 38, 79], [75, 76, 100], [90, 85, 92], [105, 74, 92], [120, 80, 96], [135, 82, 94], [150, 21, 100], [165, 39, 99], [180, 83, 100], [195, 81, 96], [210, 86, 98], [225, 86, 96], [240, 85, 97], [255, 5, 94], [270, 68, 95], [285, 67, 81], [300, 74, 100], [315, 92, 100], [330, 94, 100], [345, 19, 100], [360, 13, 99], [375, 72, 97], [390, 66, 84], [405, 66, 100], [420, 88, 100], [435, 87, 98], [450, 13, 93], [465, 13, 91], [480, 73, 88], [495, 71, 92], [510, 78, 97], [525, 91, 99], [540, 90, 99], [555, 22, 100], [570, 24, 95], [585, 70, 89], [600, 68, 87], [615, 76, 99], [630, 80, 98], [645, 72, 88], [660, 11, 100], [675, 84, 96], [690, 83, 99], [705, 82, 95], [720, 79, 94], [735, 69, 90], [750, 26, 84], [765, 5, 88], [780, 70, 94], [795, 91, 100], [810, 86, 100], [825, 82, 94], [840, 86, 100], [855, 16, 100], [870, 25, 95], [885, 68, 77], [900, 67, 77], [915, 70, 78], [930, 71, 81], [945, 70, 87], [960, 12, 85], [975, 73, 91], [990, 71, 83], [1005, 72, 87], [1020, 71, 89], [1035, 79, 87], [1050, 79, 100], [1065, 0, 100], [1080, 21, 86], [1095, 56, 83], [1109, 68, 85], [1123, 69, 100], [1137, 97, 100], [1151, 8, 100], [1165, 1, 79], [1179, 71, 82], [1193, 74, 80], [1207, 68, 79], [1221, 69, 79], [1235, 68, 79], [1249, 69, 82], [1263, 13, 95], [1277, 80, 93], [1291, 76, 96], [1305, 78, 94], [1319, 81, 90], [1333, 79, 93], [1347, 72, 88], [1361, 6, 85], [1375, 6, 90], [1389, 82, 100], [1403, 91, 100], [1417, 87, 98], [1431, 86, 100], [1445, 87, 100], [1459, 26, 100]]
        },
        {
            id: '7a641230-d807-4479-b37a-872917b2047a',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_TS_35MBps/egress_capture__10_9_24_10__232_10_10_6__49410.ts',
            demo: 'impairment_TS_35MBps',
            measurementPointId: 'b473a1eb-1645-4ee1-a25d-9eab50d3d52b',
            eaId: '1e88b21b-12ea-4546-8071-d82e9d66e38d',
            bitrateList: [2499, 2092, 1739, 2003, 2637, 1529, 1710, 1813, 2482, 2089, 1859, 2220, 1654, 2079, 2024, 2032, 2166, 1764, 2209, 1211, 2455, 1626, 2705, 1593, 2258, 1324, 3049, 1729, 1937, 1788, 2659, 1261, 2159, 2304, 2359, 2091, 1609, 1617, 1899, 2142, 2225, 1765, 1955, 2241, 1630, 1932, 3165, 1324, 1863, 1910, 1914, 2734, 1568, 1963, 2574, 1244, 1882, 1699, 2425],
            vmafReduct: [[0, 64, 73], [15, 66, 75], [30, 69, 75], [45, 0, 72], [60, 39, 63], [75, 58, 98], [90, 76, 85], [105, 67, 89], [120, 72, 89], [135, 69, 83], [150, 19, 90], [165, 34, 90], [180, 70, 98], [195, 65, 82], [210, 70, 88], [225, 73, 88], [240, 72, 84], [255, 3, 84], [270, 60, 84], [285, 58, 71], [300, 62, 97], [315, 83, 100], [330, 83, 98], [345, 15, 100], [360, 10, 89], [375, 69, 88], [390, 62, 82], [405, 63, 92], [420, 77, 91], [435, 75, 88], [450, 10, 82], [465, 11, 80], [480, 68, 78], [495, 67, 95], [510, 78, 94], [525, 79, 87], [540, 78, 86], [555, 20, 89], [570, 21, 87], [585, 61, 82], [600, 64, 81], [615, 64, 86], [630, 68, 85], [645, 65, 90], [660, 10, 89], [675, 69, 85], [690, 67, 87], [705, 67, 81], [720, 65, 80], [735, 63, 75], [750, 22, 74], [765, 4, 79], [780, 59, 86], [795, 83, 100], [810, 74, 100], [825, 72, 85], [840, 73, 91], [855, 11, 100], [870, 20, 85], [885, 58, 71], [900, 59, 68], [915, 59, 70], [930, 60, 72], [945, 60, 79], [960, 10, 82], [975, 66, 85], [990, 63, 76], [1005, 64, 81], [1020, 63, 78], [1035, 64, 74], [1050, 65, 100], [1065, 0, 100], [1080, 19, 76], [1095, 48, 77], [1110, 63, 78], [1125, 64, 100], [1140, 81, 99], [1154, 0, 95], [1168, 4, 72], [1182, 65, 75], [1196, 64, 73], [1210, 59, 72], [1224, 63, 73], [1238, 62, 79], [1252, 65, 83], [1266, 12, 89], [1280, 72, 87], [1294, 67, 94], [1308, 70, 86], [1322, 72, 85], [1336, 70, 82], [1350, 65, 82], [1364, 3, 81], [1378, 6, 89], [1392, 75, 91], [1406, 79, 95], [1420, 75, 88], [1434, 77, 97], [1448, 71, 90], [1462, 11, 100]]
        },
        {
            id: 'db6f87d2-553f-447f-a6ca-8e827285083b',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_TS_35MBps/egress_capture__10_9_24_10__232_10_10_7__49410.ts',
            demo: 'impairment_TS_35MBps',
            measurementPointId: 'b830fcc4-f98e-49c7-a5ea-f541ca55e8e8',
            eaId: 'bdad6390-a8ba-4c86-b4d2-6d32e1ce0550',
            bitrateList: [1916, 1617, 1425, 1504, 1872, 1135, 1426, 1359, 1755, 1584, 1472, 1809, 1035, 1621, 1532, 1442, 1743, 1492, 1572, 819, 1784, 1331, 1962, 1305, 1637, 1040, 2052, 1420, 1564, 1387, 1905, 1033, 1621, 1910, 1635, 1589, 1297, 1261, 1454, 1512, 1550, 1307, 1595, 1637, 1124, 1649, 2601, 921, 1367, 1279, 1341, 2403, 941, 1439, 1965, 1052, 1374, 1230, 1736],
            vmafReduct: [[0, 56, 66], [15, 59, 68], [30, 61, 67], [45, 0, 64], [60, 37, 58], [75, 53, 91], [90, 69, 78], [105, 55, 78], [120, 60, 77], [135, 57, 77], [150, 19, 85], [165, 31, 82], [180, 64, 91], [195, 54, 72], [210, 61, 80], [225, 65, 80], [240, 65, 76], [255, 2, 75], [270, 52, 76], [285, 50, 64], [300, 56, 86], [315, 78, 93], [330, 76, 93], [345, 14, 95], [360, 10, 82], [375, 63, 80], [390, 55, 70], [405, 58, 86], [420, 72, 84], [435, 68, 83], [450, 9, 76], [465, 10, 64], [480, 56, 65], [495, 53, 83], [510, 68, 84], [525, 74, 81], [540, 72, 79], [555, 20, 82], [570, 21, 80], [585, 54, 73], [600, 54, 71], [615, 53, 78], [630, 61, 77], [645, 56, 78], [660, 9, 83], [675, 62, 78], [690, 60, 78], [705, 61, 74], [720, 58, 72], [735, 51, 68], [750, 22, 64], [765, 5, 69], [780, 51, 79], [795, 77, 94], [810, 68, 93], [825, 66, 78], [840, 67, 84], [855, 11, 93], [870, 18, 77], [885, 51, 63], [900, 52, 60], [915, 52, 65], [930, 53, 64], [945, 52, 65], [960, 10, 72], [975, 56, 75], [990, 52, 66], [1005, 53, 70], [1020, 53, 69], [1035, 56, 65], [1050, 55, 96], [1065, 0, 99], [1080, 18, 68], [1095, 41, 63], [1110, 49, 64], [1125, 54, 95], [1140, 74, 93], [1154, 0, 89], [1168, 4, 66], [1182, 58, 67], [1196, 53, 64], [1210, 49, 66], [1224, 54, 62], [1238, 51, 69], [1252, 54, 73], [1266, 11, 84], [1280, 69, 84], [1294, 61, 80], [1308, 55, 73], [1322, 59, 75], [1336, 60, 74], [1350, 56, 74], [1364, 2, 72], [1378, 7, 83], [1392, 66, 83], [1406, 70, 87], [1420, 66, 80], [1434, 70, 90], [1448, 62, 82], [1462, 9, 100]]
        },
        {
            id: '825ecadb-a89d-42ad-8fd0-c66250c62a2d',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/impairment_TS_35MBps/egress_capture__10_9_24_10__232_10_10_8__49410.ts',
            demo: 'impairment_TS_35MBps',
            measurementPointId: '0c86d423-c20e-48e5-82da-aa59ac597e12',
            eaId: '059ba20f-932f-4296-88b5-cb6421cc77e3',
            bitrateList: [811, 647, 511, 679, 768, 509, 612, 544, 756, 634, 639, 725, 449, 696, 632, 663, 700, 647, 673, 348, 713, 502, 887, 566, 651, 413, 949, 545, 606, 658, 787, 401, 674, 766, 712, 644, 558, 545, 675, 713, 568, 495, 688, 647, 518, 663, 1079, 396, 562, 556, 590, 958, 412, 567, 812, 461, 572, 538],
            vmafReduct: [[0, 21, 27], [15, 24, 28], [30, 24, 27], [45, 0, 27], [60, 27, 36], [75, 28, 57], [90, 36, 44], [105, 24, 43], [120, 23, 42], [135, 25, 50], [150, 13, 54], [165, 20, 44], [180, 29, 52], [195, 27, 40], [210, 29, 42], [225, 30, 41], [240, 29, 40], [255, 0, 41], [270, 21, 40], [285, 19, 26], [300, 23, 52], [315, 41, 57], [330, 38, 61], [345, 9, 62], [360, 5, 49], [375, 34, 47], [390, 33, 45], [405, 30, 51], [420, 38, 51], [435, 36, 49], [450, 6, 43], [465, 1, 36], [480, 23, 39], [495, 22, 47], [510, 36, 49], [525, 36, 45], [540, 37, 47], [555, 16, 49], [570, 17, 43], [585, 21, 39], [600, 21, 34], [615, 21, 41], [630, 28, 40], [645, 26, 44], [660, 6, 47], [675, 29, 41], [690, 26, 42], [705, 28, 37], [720, 26, 38], [735, 22, 34], [750, 14, 30], [765, 4, 32], [780, 18, 47], [795, 43, 60], [810, 32, 57], [825, 31, 40], [840, 31, 47], [855, 2, 57], [870, 7, 40], [885, 19, 27], [900, 20, 25], [915, 22, 28], [930, 22, 28], [945, 21, 36], [960, 9, 42], [975, 29, 44], [990, 20, 31], [1005, 18, 31], [1020, 17, 31], [1035, 19, 27], [1050, 21, 70], [1064, 0, 63], [1078, 14, 34], [1092, 17, 29], [1106, 21, 37], [1120, 22, 56], [1134, 43, 61], [1148, 15, 55], [1162, 0, 28], [1176, 23, 29], [1190, 23, 27], [1204, 17, 28], [1218, 19, 23], [1232, 19, 36], [1246, 24, 34], [1260, 9, 40], [1274, 9, 54], [1288, 30, 47], [1302, 22, 38], [1316, 22, 31], [1330, 24, 38], [1344, 23, 38], [1358, 5, 38], [1372, 0, 40], [1386, 33, 51], [1400, 33, 48], [1414, 32, 43], [1428, 29, 45], [1442, 26, 52], [1456, 9, 100]]
        }
    ]

    let loadThrottle95 = [
        {
            id: '0b06bd36-c2cd-4d25-8132-5aa3f46cf352',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/load_throttle_95pct/egress_capture__10_9_24_10__232_10_10_1__49410.ts',
            demo: 'load_throttle_95pct',
            measurementPointId: 'b9165908-e23a-472b-8220-df1a67608e5f',
            bitrateList: [35705, 35063, 33967, 40430, 35120, 30868, 29589, 45247, 32288, 32337, 29549, 36597, 32982, 30444, 36849, 31995, 37204, 27553, 40518, 34642, 34921, 33489, 36814, 27427, 34185, 37530, 33995, 31745, 32018, 39651, 29167, 39338, 40128, 34520, 31832, 31908, 33842, 37102, 25607, 38605, 37549, 28870, 35200, 31042, 38318, 31323, 36870, 36219, 28877, 31750, 44887, 27747, 37344, 28890, 36831, 28905, 36076, 34655, 35496],
            eaId: 'f51ed667-cdfd-4afe-b6c8-fd2a006b0132',
            vmafReduct: [[0, 94, 100], [30, 94, 100], [60, 99, 100], [90, 100, 100], [120, 100, 100], [150, 100, 100], [180, 100, 100], [210, 100, 100], [240, 99, 100], [270, 99, 100], [300, 97, 100], [330, 92, 100], [360, 92, 98], [390, 94, 100], [420, 100, 100], [450, 100, 100], [480, 100, 100], [510, 98, 100], [540, 100, 100], [570, 92, 100], [600, 90, 100], [630, 100, 100], [660, 99, 100], [690, 97, 100], [720, 93, 100], [750, 96, 100], [780, 96, 100], [810, 100, 100], [840, 100, 100], [870, 98, 100], [900, 100, 100], [930, 100, 100], [960, 96, 100], [990, 97, 100], [1020, 99, 100], [1050, 96, 100], [1080, 90, 100], [1110, 91, 100], [1140, 96, 100], [1170, 98, 100], [1200, 98, 100], [1230, 97, 100], [1260, 90, 100], [1290, 89, 99], [1320, 92, 100], [1350, 98, 100], [1380, 100, 100], [1410, 100, 100], [1440, 98, 100], [1470, 99, 100], [1500, 100, 100], [1530, 96, 100], [1560, 94, 98], [1590, 94, 98], [1620, 95, 98], [1650, 95, 97], [1680, 94, 100], [1710, 99, 100], [1740, 98, 100], [1770, 93, 100], [1800, 92, 100], [1830, 91, 99], [1860, 93, 100], [1890, 93, 100], [1920, 95, 100], [1950, 95, 100], [1980, 95, 100], [2010, 94, 100], [2040, 96, 100], [2070, 100, 100], [2100, 95, 100], [2130, 94, 97], [2160, 93, 97], [2190, 90, 97], [2220, 90, 98], [2250, 92, 98], [2280, 89, 100], [2310, 89, 100], [2340, 93, 100], [2370, 96, 100], [2400, 92, 100], [2430, 92, 100], [2460, 97, 100], [2490, 96, 100], [2520, 96, 100], [2549, 100, 100], [2578, 100, 100], [2607, 100, 100], [2636, 100, 100], [2665, 99, 100], [2694, 100, 100], [2723, 100, 100], [2752, 100, 100], [2781, 91, 100], [2810, 88, 100], [2839, 89, 99], [2868, 93, 97], [2897, 93, 97], [2926, 94, 100], [2955, 100, 100]]
        },
        {
            id: 'a2b59547-abf4-4794-8d69-fac2d971223a',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/load_throttle_95pct/egress_capture__10_9_24_10__232_10_10_2__49410.ts',
            demo: 'load_throttle_95pct',
            measurementPointId: '82287e69-aabf-400f-b01b-7f33888733ec',
            bitrateList: [10282, 7202, 8323, 5435, 9108, 7024, 7058, 8134, 7938, 7268, 8492, 7755, 8942, 5661, 7906, 8590, 7028, 6922, 9060, 7722, 8863, 6893, 7464, 9384, 8496, 7865, 5505, 8289, 7439, 8907, 7160, 6743, 8241, 7862, 8940, 7711, 10536, 6441, 5719, 8951, 9399, 7022, 5267, 7933, 10021, 6134, 6191, 10493, 8434, 7398, 7712, 6354, 8491, 8413, 9723, 5484, 8266, 6756, 7328],
            eaId: '9ba3ddca-3adb-40f6-9956-f19f5e547d28',
            vmafReduct: [[0, 79, 94], [30, 73, 92], [60, 76, 86], [90, 76, 85], [120, 78, 90], [150, 77, 90], [180, 81, 100], [210, 84, 95], [240, 70, 100], [270, 69, 90], [300, 72, 94], [330, 80, 100], [360, 84, 100], [390, 84, 100], [420, 81, 100], [450, 82, 99], [480, 84, 98], [510, 86, 97], [540, 87, 100], [570, 74, 95], [600, 74, 93], [630, 79, 100], [660, 86, 100], [690, 85, 100], [720, 92, 100], [750, 87, 100], [780, 76, 100], [810, 61, 85], [840, 66, 100], [870, 85, 100], [900, 87, 100], [930, 75, 97], [960, 72, 88], [990, 74, 90], [1020, 75, 100], [1050, 83, 100], [1080, 89, 100], [1110, 87, 100], [1140, 87, 100], [1170, 82, 100], [1200, 74, 94], [1230, 71, 97], [1260, 70, 100], [1290, 77, 97], [1320, 73, 92], [1350, 73, 97], [1380, 74, 95], [1410, 79, 99], [1440, 78, 98], [1470, 77, 97], [1500, 68, 88], [1530, 67, 84], [1560, 69, 91], [1590, 74, 95], [1620, 86, 100], [1650, 86, 100], [1680, 85, 97], [1710, 79, 98], [1740, 78, 100], [1770, 80, 97], [1800, 79, 90], [1830, 78, 90], [1860, 75, 88], [1890, 75, 86], [1920, 70, 94], [1950, 70, 100], [1980, 76, 98], [2010, 71, 94], [2040, 68, 91], [2070, 69, 91], [2100, 66, 90], [2130, 72, 100], [2160, 59, 100], [2190, 58, 91], [2220, 58, 92], [2250, 72, 96], [2280, 81, 100], [2310, 89, 100], [2340, 85, 100], [2370, 81, 91], [2400, 74, 88], [2430, 73, 84], [2460, 76, 87], [2490, 71, 88], [2520, 70, 82], [2550, 69, 85], [2580, 71, 99], [2610, 73, 98], [2640, 72, 98], [2670, 72, 94], [2700, 72, 94], [2730, 73, 94], [2760, 73, 99], [2789, 79, 100], [2818, 90, 100], [2847, 88, 100], [2876, 86, 100], [2905, 94, 100], [2934, 88, 98], [2963, 88, 100]]
        },
        {
            id: 'f49b6ae3-3a4b-494c-a219-aadb6ea24bb6',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/load_throttle_95pct/egress_capture__10_9_24_10__232_10_10_3__49410.ts',
            demo: 'load_throttle_95pct',
            measurementPointId: 'ecee35c2-19b2-4bb5-afb6-e3a6d45e2253',
            bitrateList: [5811, 4917, 5205, 3706, 4991, 5036, 4199, 5035, 5092, 5440, 4998, 4770, 5296, 3521, 5246, 4696, 6021, 5310, 4233, 4286, 5780, 4075, 4278, 5298, 6041, 5383, 3250, 5068, 5215, 5492, 5377, 3621, 4139, 5493, 4418, 6286, 4780, 4407, 4677, 4710, 5058, 4520, 4482, 5404, 4601, 5200, 4056, 6439, 5020, 4543, 4728, 4191, 4873, 4710, 6273, 4857, 4173, 4720, 4320],
            eaId: 'd4ad0431-e6b6-4185-8ea9-cd78673faa13',
            vmafReduct: [[0, 70, 87], [30, 64, 87], [60, 61, 77], [90, 68, 77], [120, 71, 84], [150, 75, 84], [180, 76, 96], [210, 75, 92], [240, 60, 92], [270, 55, 82], [300, 61, 85], [330, 70, 93], [360, 68, 92], [390, 71, 100], [420, 73, 93], [450, 75, 95], [480, 76, 93], [510, 76, 93], [540, 79, 95], [570, 72, 89], [600, 62, 84], [630, 67, 97], [660, 72, 100], [690, 75, 100], [720, 80, 100], [750, 75, 94], [780, 74, 99], [810, 62, 87], [840, 59, 97], [870, 73, 96], [900, 76, 95], [930, 77, 92], [960, 59, 83], [990, 59, 81], [1020, 62, 88], [1050, 58, 94], [1080, 82, 96], [1110, 85, 97], [1140, 75, 100], [1170, 70, 92], [1200, 66, 90], [1230, 62, 87], [1260, 65, 89], [1290, 66, 87], [1320, 57, 77], [1350, 58, 86], [1380, 66, 91], [1410, 71, 96], [1440, 70, 97], [1470, 70, 88], [1500, 55, 91], [1530, 58, 77], [1560, 47, 74], [1590, 53, 85], [1620, 65, 95], [1650, 84, 100], [1680, 75, 91], [1710, 69, 96], [1740, 66, 97], [1770, 74, 97], [1800, 63, 80], [1830, 66, 78], [1860, 63, 78], [1890, 67, 78], [1920, 60, 81], [1950, 52, 87], [1980, 56, 86], [2010, 60, 85], [2040, 58, 79], [2070, 58, 78], [2100, 59, 79], [2130, 61, 99], [2160, 48, 100], [2190, 44, 84], [2220, 43, 81], [2250, 62, 86], [2280, 68, 90], [2310, 82, 100], [2340, 73, 100], [2370, 69, 79], [2400, 67, 80], [2430, 53, 73], [2460, 63, 76], [2490, 63, 83], [2520, 61, 81], [2550, 60, 81], [2580, 61, 95], [2610, 62, 93], [2640, 57, 76], [2670, 57, 74], [2700, 61, 85], [2730, 63, 84], [2760, 66, 96], [2790, 67, 95], [2820, 67, 100], [2849, 80, 100], [2878, 77, 96], [2907, 83, 100], [2936, 81, 98], [2965, 81, 100]]
        },
        {
            id: '4e9897bc-52d9-4c76-b831-e5a8d9688352',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/load_throttle_95pct/egress_capture__10_9_24_10__232_10_10_4__49410.ts',
            demo: 'load_throttle_95pct',
            measurementPointId: 'c6322b9f-4f37-4943-85eb-3db2b2bd2626',
            bitrateList: [4649, 3788, 2365, 3213, 3719, 2876, 3515, 3945, 3652, 3384, 3211, 4120, 2808, 2961, 3774, 4067, 3966, 2912, 3897, 2454, 3684, 2586, 4350, 3687, 4554, 1870, 4214, 3037, 3802, 3476, 2858, 3438, 3887, 3158, 3426, 4167, 3470, 3563, 3150, 3516, 3527, 2957, 3610, 3501, 3262, 2855, 5435, 3140, 3089, 3154, 3235, 4746, 2512, 3531, 3803, 2636, 4263, 2643],
            eaId: 'ab846005-2db3-47a9-8756-8f8862bca33b',
            vmafReduct: [[0, 67, 78], [15, 70, 84], [30, 73, 83], [45, 73, 83], [60, 69, 88], [75, 80, 100], [90, 77, 94], [105, 60, 85], [120, 59, 93], [135, 71, 92], [150, 75, 100], [165, 81, 100], [180, 78, 100], [195, 73, 100], [210, 80, 99], [225, 79, 95], [240, 78, 99], [255, 81, 100], [270, 66, 93], [285, 62, 78], [300, 69, 100], [315, 78, 100], [330, 78, 100], [345, 86, 100], [360, 80, 100], [375, 74, 100], [390, 61, 90], [405, 56, 100], [420, 74, 98], [435, 79, 93], [450, 72, 92], [465, 66, 82], [480, 68, 88], [495, 63, 94], [510, 67, 99], [525, 86, 98], [540, 91, 100], [555, 79, 100], [570, 75, 97], [585, 65, 89], [600, 66, 91], [615, 73, 95], [630, 70, 94], [645, 64, 87], [660, 65, 96], [675, 74, 95], [690, 74, 92], [705, 73, 100], [720, 72, 94], [735, 63, 86], [750, 59, 73], [765, 67, 90], [780, 69, 96], [795, 78, 100], [810, 80, 100], [825, 76, 88], [840, 80, 96], [855, 79, 100], [870, 76, 93], [885, 69, 80], [900, 67, 83], [915, 61, 80], [930, 64, 85], [945, 58, 89], [960, 58, 92], [975, 62, 90], [990, 65, 87], [1005, 61, 83], [1020, 60, 85], [1035, 65, 79], [1050, 65, 100], [1065, 60, 100], [1080, 56, 91], [1095, 54, 83], [1109, 63, 84], [1123, 65, 100], [1137, 91, 100], [1151, 79, 100], [1165, 68, 85], [1179, 64, 81], [1193, 67, 80], [1207, 62, 73], [1221, 67, 86], [1235, 53, 77], [1249, 51, 80], [1263, 62, 98], [1277, 74, 86], [1291, 72, 100], [1305, 67, 86], [1319, 66, 74], [1333, 68, 88], [1347, 62, 84], [1361, 68, 100], [1375, 77, 100], [1389, 85, 100], [1403, 88, 100], [1417, 81, 94], [1431, 83, 100], [1445, 85, 100], [1459, 84, 100]]
        },
        {
            id: 'fd4072b0-d08c-429f-9e3a-cd3f9f10dc8d',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/load_throttle_95pct/egress_capture__10_9_24_10__232_10_10_5__49410.ts',
            demo: 'load_throttle_95pct',
            measurementPointId: '5e6d8f6a-0648-4a96-ad63-7fc00dae7996',
            bitrateList: [3244, 2124, 3229, 1987, 2527, 3412, 1731, 2560, 2827, 2666, 2756, 3079, 2488, 1677, 2558, 2876, 2877, 2375, 2879, 2809, 2374, 2072, 2169, 3716, 2054, 2755, 2304, 3076, 2179, 2264, 2567, 2878, 2053, 3163, 2533, 3475, 1428, 2446, 3211, 2224, 2543, 2814, 2097, 2945, 3369, 2127, 1977, 4424, 1131, 3758, 1236, 3005, 2968, 2055, 2595, 2617, 3318, 2430, 2435],
            eaId: 'a025323d-87ba-4176-8179-83ab9c172aaf',
            vmafReduct: [[0, 68, 80], [15, 64, 75], [30, 63, 82], [45, 70, 77], [60, 69, 84], [75, 67, 86], [90, 74, 100], [105, 76, 89], [120, 57, 85], [135, 58, 93], [150, 67, 85], [165, 70, 89], [180, 74, 94], [195, 75, 100], [210, 68, 91], [225, 73, 92], [240, 74, 89], [255, 72, 94], [270, 77, 95], [285, 53, 89], [300, 45, 76], [315, 52, 98], [330, 80, 100], [345, 79, 100], [360, 82, 100], [375, 72, 98], [390, 67, 97], [405, 51, 84], [420, 52, 99], [435, 77, 92], [450, 79, 95], [465, 77, 91], [480, 58, 81], [495, 51, 80], [510, 33, 84], [525, 61, 92], [540, 83, 93], [555, 83, 98], [570, 78, 100], [585, 71, 94], [600, 59, 80], [615, 56, 82], [630, 59, 83], [645, 73, 92], [660, 60, 81], [675, 59, 94], [690, 67, 96], [705, 59, 85], [720, 64, 87], [735, 68, 83], [750, 55, 90], [765, 49, 65], [780, 52, 82], [795, 60, 88], [810, 76, 99], [825, 87, 100], [840, 71, 87], [855, 72, 88], [870, 74, 97], [885, 71, 89], [900, 63, 75], [915, 64, 76], [930, 60, 71], [945, 59, 82], [960, 49, 80], [975, 51, 81], [990, 59, 83], [1005, 58, 79], [1020, 55, 76], [1035, 45, 71], [1050, 55, 79], [1065, 46, 100], [1080, 56, 97], [1095, 53, 83], [1110, 47, 79], [1125, 58, 81], [1140, 60, 99], [1155, 84, 100], [1170, 76, 95], [1185, 65, 82], [1200, 60, 72], [1215, 56, 73], [1230, 54, 72], [1245, 68, 85], [1259, 51, 61], [1273, 49, 76], [1287, 49, 98], [1301, 69, 93], [1315, 60, 87], [1329, 54, 76], [1343, 50, 86], [1357, 56, 76], [1371, 32, 71], [1385, 52, 99], [1399, 78, 100], [1413, 85, 100], [1427, 83, 99], [1441, 79, 94], [1455, 83, 99], [1469, 78, 100]]
        },
        {
            id: '596a33a9-878b-46ec-8188-ffda35be05e7',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/load_throttle_95pct/egress_capture__10_9_24_10__232_10_10_6__49410.ts',
            demo: 'load_throttle_95pct',
            measurementPointId: 'b473a1eb-1645-4ee1-a25d-9eab50d3d52b',
            bitrateList: [2719, 2073, 1331, 2107, 2337, 1621, 1856, 2132, 2120, 2139, 2030, 2285, 1415, 1782, 2125, 2066, 2202, 2427, 2313, 1243, 1898, 1426, 2818, 2038, 1903, 1313, 3010, 1591, 1845, 2135, 2050, 1700, 2136, 1824, 2086, 2650, 2031, 2078, 1501, 1767, 2193, 1976, 1862, 2414, 2003, 1366, 3014, 1936, 1456, 1648, 1905, 3401, 1352, 1804, 2079, 1893, 2445, 1467],
            eaId: '6e605cc5-42f8-4abc-a829-606b1473c4c1',
            vmafReduct: [[0, 57, 70], [15, 63, 74], [30, 66, 73], [45, 60, 72], [60, 56, 72], [75, 64, 96], [90, 62, 80], [105, 44, 83], [120, 43, 76], [135, 47, 77], [150, 61, 81], [165, 65, 85], [180, 62, 94], [195, 54, 77], [210, 58, 81], [225, 64, 78], [240, 62, 82], [255, 63, 83], [270, 54, 78], [285, 45, 65], [300, 57, 90], [315, 72, 95], [330, 69, 91], [345, 73, 94], [360, 65, 89], [375, 63, 87], [390, 50, 69], [405, 48, 90], [420, 70, 86], [435, 66, 85], [450, 45, 78], [465, 38, 58], [480, 39, 68], [495, 31, 67], [510, 56, 81], [525, 74, 82], [540, 72, 86], [555, 67, 92], [570, 61, 80], [585, 47, 66], [600, 45, 71], [615, 52, 74], [630, 60, 80], [645, 45, 72], [660, 46, 83], [675, 54, 76], [690, 51, 77], [705, 52, 72], [720, 53, 74], [735, 38, 75], [750, 41, 63], [765, 43, 68], [780, 44, 83], [795, 69, 88], [810, 64, 93], [825, 59, 79], [840, 59, 81], [855, 64, 89], [870, 64, 81], [885, 57, 74], [900, 62, 70], [915, 58, 71], [930, 59, 75], [945, 45, 71], [960, 43, 66], [975, 50, 72], [990, 42, 68], [1005, 44, 66], [1020, 41, 67], [1035, 43, 65], [1050, 45, 93], [1065, 48, 87], [1079, 45, 75], [1093, 40, 68], [1107, 44, 69], [1121, 45, 82], [1135, 69, 88], [1149, 70, 87], [1163, 67, 78], [1177, 61, 75], [1191, 59, 68], [1205, 48, 60], [1219, 51, 69], [1233, 44, 66], [1247, 47, 66], [1261, 41, 70], [1275, 63, 91], [1289, 21, 85], [1303, 41, 68], [1317, 28, 61], [1331, 40, 71], [1345, 17, 70], [1359, 39, 91], [1373, 71, 87], [1387, 76, 93], [1401, 73, 93], [1415, 68, 86], [1429, 67, 81], [1443, 63, 86], [1457, 42, 100]]
        },
        {
            id: '94a1a99c-00b6-4304-adf3-69011426119b',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/load_throttle_95pct/egress_capture__10_9_24_10__232_10_10_7__49410.ts',
            demo: 'load_throttle_95pct',
            measurementPointId: 'b830fcc4-f98e-49c7-a5ea-f541ca55e8e8',
            bitrateList: [2037, 1586, 929, 1625, 1756, 1299, 1294, 1518, 1726, 1639, 1378, 1859, 1059, 1382, 1515, 1522, 2036, 1627, 1740, 835, 1485, 1020, 2213, 1582, 1400, 976, 2273, 1252, 1396, 1635, 1565, 1308, 1597, 1382, 1456, 2109, 1414, 1426, 1240, 1490, 1659, 1450, 1327, 1818, 1613, 1106, 2094, 1448, 1129, 1282, 1467, 2530, 1046, 1333, 1616, 1495, 1856, 999, 1254],
            eaId: '9ec82d9e-aba9-4380-81f9-15df907e03c7',
            vmafReduct: [[0, 50, 62], [15, 56, 66], [30, 58, 66], [45, 52, 63], [60, 46, 64], [75, 58, 90], [90, 54, 73], [105, 33, 75], [120, 31, 72], [135, 43, 64], [150, 46, 70], [165, 51, 76], [180, 51, 84], [195, 42, 69], [210, 47, 74], [225, 54, 73], [240, 52, 73], [255, 52, 74], [270, 47, 67], [285, 42, 58], [300, 51, 80], [315, 63, 87], [330, 60, 82], [345, 61, 87], [360, 57, 82], [375, 57, 78], [390, 45, 61], [405, 46, 85], [420, 56, 79], [435, 56, 75], [450, 40, 71], [465, 32, 60], [480, 33, 59], [495, 30, 61], [510, 47, 75], [525, 62, 74], [540, 65, 79], [555, 57, 84], [570, 50, 74], [585, 37, 57], [600, 35, 62], [615, 41, 66], [630, 50, 70], [645, 41, 70], [660, 36, 74], [675, 44, 66], [690, 41, 68], [705, 44, 65], [720, 43, 64], [735, 32, 66], [750, 34, 53], [765, 34, 61], [780, 35, 72], [795, 53, 78], [810, 56, 85], [825, 49, 73], [840, 47, 70], [855, 53, 80], [870, 53, 72], [885, 50, 65], [900, 52, 61], [915, 49, 62], [930, 50, 65], [945, 36, 62], [960, 37, 64], [975, 41, 67], [990, 37, 61], [1005, 37, 56], [1020, 33, 59], [1035, 32, 56], [1050, 37, 85], [1065, 39, 77], [1080, 32, 65], [1095, 33, 63], [1110, 37, 63], [1125, 37, 80], [1140, 61, 80], [1155, 61, 74], [1170, 56, 66], [1185, 52, 63], [1200, 43, 56], [1215, 42, 53], [1229, 42, 61], [1243, 38, 52], [1257, 36, 64], [1271, 35, 83], [1285, 49, 79], [1299, 17, 76], [1313, 31, 59], [1327, 22, 60], [1341, 34, 58], [1355, 16, 60], [1369, 34, 86], [1383, 64, 87], [1397, 60, 85], [1411, 59, 79], [1425, 54, 67], [1439, 52, 74], [1453, 48, 61], [1467, 51, 100]]
        },
        {
            id: '71cb2f3d-ae75-49ae-a541-62d7ace2e4ba',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/load_throttle_95pct/egress_capture__10_9_24_10__232_10_10_8__49410.ts',
            demo: 'load_throttle_95pct',
            measurementPointId: '0c86d423-c20e-48e5-82da-aa59ac597e12',
            bitrateList: [745, 482, 743, 492, 668, 837, 415, 606, 629, 755, 659, 756, 545, 449, 658, 677, 696, 670, 674, 685, 491, 545, 542, 1001, 494, 572, 576, 797, 489, 562, 725, 852, 343, 711, 594, 826, 456, 633, 690, 522, 614, 760, 558, 644, 811, 602, 542, 933, 320, 699, 406, 823, 825, 535, 541, 749, 688, 552, 506],
            eaId: '814fcf26-81e2-45d7-9765-b48a41827908',
            vmafReduct: [[0, 18, 31], [15, 19, 25], [30, 19, 27], [45, 23, 27], [60, 23, 42], [75, 32, 40], [90, 27, 53], [105, 25, 40], [120, 19, 37], [135, 17, 38], [150, 20, 33], [165, 17, 34], [180, 22, 43], [195, 22, 50], [210, 12, 35], [225, 14, 39], [240, 22, 37], [255, 21, 38], [270, 21, 38], [285, 18, 34], [300, 17, 23], [315, 20, 47], [330, 24, 47], [345, 22, 50], [360, 30, 52], [375, 27, 52], [390, 25, 45], [405, 28, 41], [420, 24, 50], [435, 26, 45], [450, 26, 44], [465, 25, 40], [480, 15, 35], [495, 14, 28], [510, 12, 37], [525, 27, 38], [540, 24, 39], [555, 28, 45], [570, 26, 47], [585, 24, 44], [600, 14, 31], [615, 14, 30], [630, 15, 33], [645, 19, 34], [660, 15, 34], [675, 16, 36], [690, 17, 38], [705, 16, 33], [720, 21, 32], [735, 21, 32], [750, 19, 34], [765, 15, 23], [780, 12, 30], [795, 12, 33], [810, 16, 39], [825, 29, 51], [840, 19, 37], [855, 19, 32], [870, 23, 44], [885, 24, 41], [900, 21, 29], [915, 21, 27], [930, 21, 27], [945, 21, 30], [960, 17, 32], [975, 17, 32], [990, 18, 33], [1005, 14, 28], [1020, 12, 26], [1035, 14, 27], [1050, 12, 28], [1065, 12, 57], [1080, 19, 44], [1095, 18, 33], [1110, 16, 36], [1125, 16, 35], [1140, 17, 41], [1155, 25, 47], [1170, 24, 40], [1185, 24, 29], [1200, 23, 28], [1215, 18, 27], [1230, 14, 23], [1245, 16, 29], [1260, 19, 25], [1275, 18, 32], [1290, 15, 49], [1305, 5, 44], [1320, 17, 31], [1335, 16, 27], [1350, 16, 33], [1365, 2, 28], [1380, 15, 53], [1395, 28, 55], [1410, 25, 43], [1424, 22, 46], [1438, 21, 36], [1452, 19, 36], [1466, 14, 28], [1480, 14, 100]]
        }
        ]

    let blackFfmpeg3 = [
        {
            id: 'b9b3b9b1-263d-4998-b588-16c5597b7d30',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/black_ffmpeg3/egress_capture__10_9_24_10__232_10_10_1__49410.ts',
            demo: 'black_ffmpeg3',
            measurementPointId: 'b9165908-e23a-472b-8220-df1a67608e5f',
            bitrateList:[44830, 30288, 31383, 32687, 37352, 35050, 37401, 32508, 35989, 32359, 31120, 38089, 30649, 29177, 41079, 35294, 31883, 29425, 43408, 33318, 31895, 27843, 35243, 37181, 37877, 29126, 32104, 35302, 39733, 32517, 30724, 37321, 40825, 27995, 33926, 37284, 35495, 28358, 37933, 34520, 26897, 43410, 33405, 33677, 28905, 33268, 30918, 39513, 36132, 30658, 31664, 41441, 36543, 31918, 35247, 27139, 40699, 30913, 32642],
            eaId: '166edb6b-ec61-4acb-bba3-74fa8eabfb82',
            vmafReduct: [[0, 93, 100], [30, 100, 100], [60, 100, 100], [90, 100, 100], [120, 100, 100], [150, 99, 100], [180, 98, 100], [210, 98, 100], [240, 96, 99], [270, 95, 100], [300, 95, 100], [330, 91, 100], [360, 89, 100], [390, 100, 100], [420, 99, 100], [450, 100, 100], [480, 99, 100], [510, 96, 100], [540, 90, 100], [570, 93, 100], [600, 95, 100], [630, 100, 100], [660, 95, 100], [690, 95, 100], [720, 96, 100], [750, 96, 98], [780, 95, 100], [810, 97, 100], [840, 97, 100], [870, 97, 100], [900, 100, 100], [930, 94, 100], [960, 96, 100], [990, 98, 100], [1020, 100, 100], [1050, 94, 100], [1080, 95, 100], [1110, 95, 100], [1140, 100, 100], [1170, 97, 100], [1200, 97, 100], [1230, 97, 100], [1260, 92, 98], [1290, 94, 100], [1320, 97, 100], [1350, 100, 100], [1380, 100, 100], [1410, 97, 100], [1440, 96, 99], [1470, 0, 100], [1500, 0, 0], [1530, 0, 100], [1560, 91, 96], [1590, 94, 97], [1620, 94, 96], [1650, 93, 96], [1680, 91, 100], [1710, 100, 100], [1740, 94, 100], [1770, 95, 100], [1800, 93, 98], [1830, 96, 98], [1860, 92, 98], [1890, 97, 100], [1920, 96, 100], [1950, 94, 100], [1980, 95, 100], [2010, 95, 99], [2040, 98, 100], [2069, 100, 100], [2098, 93, 100], [2127, 93, 96], [2156, 92, 96], [2185, 91, 96], [2214, 93, 96], [2243, 91, 98], [2272, 95, 98], [2301, 95, 100], [2330, 96, 100], [2359, 95, 100], [2388, 99, 100], [2417, 99, 100], [2446, 97, 100], [2475, 97, 100], [2504, 95, 100], [2533, 100, 100], [2562, 100, 100], [2591, 99, 100], [2620, 96, 100], [2649, 96, 100], [2678, 96, 100], [2707, 98, 100], [2736, 100, 100], [2765, 91, 100], [2794, 92, 100], [2823, 90, 99], [2852, 91, 96], [2881, 91, 96], [2910, 91, 100], [2939, 74, 100]]
        },
        {   id: '10084ad5-30af-4c0a-a2f0-015820ba54fd',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/black_ffmpeg3/egress_capture__10_9_24_10__232_10_10_2__49410.ts',
            demo: 'black_ffmpeg3',
            measurementPointId: '82287e69-aabf-400f-b01b-7f33888733ec',
            bitrateList:[7237, 9724, 7373, 7345, 8245, 7777, 7190, 8393, 8350, 7346, 7353, 9264, 7261, 6647, 7494, 9502, 7620, 6891, 8238, 10209, 8233, 5079, 9671, 8019, 8881, 5249, 6983, 8281, 8160, 7454, 7328, 7891, 7297, 10394, 4371, 8893, 8502, 9054, 6969, 7475, 7502, 9798, 5276, 8391, 7566, 8331, 8538, 7069, 8382, 8508, 6524, 6258, 9248, 7902, 7793, 6832, 9809, 6062, 8258],
            eaId: 'dd589158-88b4-4da2-afc6-9c94fce48b72',
            vmafReduct: [[0, 76, 93], [30, 77, 90], [60, 82, 89], [90, 84, 90], [120, 86, 97], [150, 85, 100], [180, 90, 100], [210, 87, 99], [240, 85, 95], [270, 84, 96], [300, 91, 99], [330, 90, 100], [360, 94, 100], [390, 88, 100], [420, 89, 99], [450, 92, 98], [480, 91, 98], [510, 91, 97], [540, 85, 98], [570, 81, 91], [600, 80, 100], [630, 95, 100], [660, 95, 100], [690, 98, 100], [720, 89, 100], [750, 89, 100], [780, 76, 96], [810, 69, 100], [840, 96, 100], [870, 92, 100], [900, 91, 97], [930, 88, 96], [960, 88, 94], [990, 86, 100], [1020, 97, 100], [1050, 93, 98], [1080, 92, 97], [1110, 94, 100], [1140, 95, 100], [1170, 86, 100], [1200, 85, 97], [1230, 81, 97], [1260, 88, 100], [1290, 84, 96], [1320, 87, 96], [1350, 86, 100], [1380, 89, 100], [1410, 88, 100], [1440, 88, 98], [1470, 81, 100], [1500, 84, 92], [1530, 85, 94], [1560, 0, 100], [1590, 0, 95], [1620, 85, 99], [1650, 91, 100], [1680, 93, 100], [1710, 91, 100], [1740, 93, 100], [1770, 82, 99], [1800, 85, 92], [1830, 84, 89], [1860, 83, 89], [1890, 82, 89], [1920, 80, 99], [1950, 85, 99], [1980, 82, 98], [2010, 84, 93], [2040, 83, 92], [2070, 85, 93], [2100, 84, 97], [2130, 78, 100], [2160, 71, 96], [2190, 70, 94], [2220, 71, 94], [2250, 85, 98], [2279, 98, 100], [2308, 100, 100], [2337, 82, 100], [2366, 83, 89], [2395, 83, 89], [2424, 82, 89], [2453, 83, 88], [2482, 79, 92], [2511, 82, 92], [2540, 82, 98], [2569, 82, 99], [2598, 79, 100], [2627, 87, 99], [2656, 87, 97], [2685, 87, 96], [2714, 87, 96], [2743, 86, 100], [2772, 94, 100], [2801, 98, 100], [2830, 94, 100], [2859, 90, 100], [2888, 97, 100], [2917, 92, 99], [2946, 94, 100]]
        },
        {   id: '96a20ba4-6488-4c57-8dd8-724baf328da3',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/black_ffmpeg3/egress_capture__10_9_24_10__232_10_10_3__49410.ts',
            demo: 'black_ffmpeg3',
            measurementPointId: 'ecee35c2-19b2-4bb5-afb6-e3a6d45e2253',
            bitrateList:[5461, 3997, 4679, 4890, 5262, 5047, 3970, 5052, 5220, 4877, 5054, 5422, 4387, 4500, 4988, 5113, 5041, 4302, 5341, 4745, 5806, 3874, 5806, 4723, 5348, 4098, 4704, 4907, 4763, 4733, 4809, 4492, 4930, 6452, 3290, 5340, 5065, 4740, 5152, 4589, 5465, 4916, 4060, 5338, 4781, 5043, 6715, 3620, 4478, 4314, 4217, 5384, 4891, 4814, 5524, 3997, 5636, 4223, 4574],
            eaId: '3e164039-4cb0-492e-a4b0-817653b8c8e0',
            vmafReduct: [[0, 69, 88], [30, 68, 76], [60, 69, 76], [90, 70, 77], [120, 73, 91], [150, 80, 90], [180, 79, 96], [210, 80, 93], [240, 68, 87], [270, 69, 90], [300, 72, 90], [330, 77, 91], [360, 80, 98], [390, 83, 98], [420, 81, 95], [450, 88, 95], [480, 86, 94], [510, 87, 96], [540, 85, 95], [570, 74, 90], [600, 74, 100], [630, 92, 100], [660, 93, 100], [690, 91, 100], [720, 88, 100], [750, 83, 100], [780, 67, 91], [810, 59, 88], [840, 79, 98], [870, 88, 98], [900, 87, 97], [930, 74, 93], [960, 75, 89], [990, 75, 88], [1020, 75, 100], [1050, 82, 95], [1080, 88, 94], [1110, 92, 100], [1140, 85, 100], [1170, 74, 98], [1200, 72, 86], [1230, 68, 88], [1260, 79, 92], [1290, 75, 87], [1320, 73, 89], [1350, 75, 95], [1380, 79, 94], [1410, 80, 96], [1440, 80, 89], [1470, 78, 91], [1500, 64, 81], [1530, 67, 82], [1560, 0, 100], [1590, 0, 0], [1620, 64, 84], [1650, 71, 99], [1680, 82, 100], [1710, 100, 100], [1740, 75, 85], [1770, 95, 100], [1800, 74, 83], [1830, 74, 83], [1860, 75, 81], [1890, 74, 81], [1920, 66, 92], [1950, 71, 89], [1980, 71, 89], [2010, 72, 85], [2040, 70, 86], [2070, 73, 86], [2100, 73, 89], [2130, 71, 98], [2160, 57, 87], [2190, 58, 85], [2220, 52, 87], [2250, 69, 95], [2279, 92, 100], [2308, 98, 100], [2337, 70, 100], [2366, 68, 75], [2395, 69, 76], [2424, 69, 80], [2453, 69, 79], [2482, 68, 83], [2511, 66, 83], [2540, 66, 85], [2569, 73, 94], [2598, 69, 92], [2627, 70, 85], [2656, 70, 88], [2685, 69, 87], [2714, 68, 86], [2743, 66, 100], [2772, 88, 100], [2801, 92, 100], [2830, 91, 100], [2859, 87, 97], [2888, 89, 100], [2917, 89, 100], [2946, 90, 100]]
        },
        {   id: '7f9bbca7-b8a7-454a-a212-4efaa7046beb',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/black_ffmpeg3/egress_capture__10_9_24_10__232_10_10_4__49410.ts',
            demo: 'black_ffmpeg3',
            measurementPointId: 'c6322b9f-4f37-4943-85eb-3db2b2bd2626',
            bitrateList:[3503, 3383, 3240, 3387, 4014, 2654, 3457, 3865, 3813, 3481, 3061, 3826, 3011, 3504, 3054, 3943, 4107, 2865, 3979, 2798, 3840, 2198, 5380, 3345, 3181, 2323, 4297, 3117, 3861, 3135, 4163, 2495, 3841, 4256, 1125, 4783, 3819, 3701, 3116, 3435, 3937, 2852, 3958, 3604, 3551, 3083, 4559, 2851, 3068, 3183, 3049, 4778, 2790, 3770, 3684, 2374, 3843, 3286, 4193],
            eaId: '7f2b4094-681d-4e04-911e-514f47103494',
            vmafReduct: [[0, 73, 78], [15, 76, 82], [30, 77, 83], [45, 78, 91], [60, 83, 93], [75, 87, 100], [90, 89, 95], [105, 75, 95], [120, 75, 95], [135, 77, 97], [150, 86, 98], [165, 90, 100], [180, 88, 100], [195, 87, 100], [210, 90, 100], [225, 89, 98], [240, 89, 98], [255, 89, 100], [270, 73, 98], [285, 72, 83], [300, 78, 100], [315, 96, 100], [330, 98, 100], [345, 94, 100], [360, 81, 100], [375, 79, 100], [390, 76, 92], [405, 65, 97], [420, 85, 98], [435, 89, 99], [450, 84, 97], [465, 84, 93], [480, 84, 94], [495, 82, 100], [510, 89, 100], [525, 91, 97], [540, 91, 100], [555, 82, 100], [570, 91, 100], [585, 75, 96], [600, 71, 88], [615, 77, 97], [630, 81, 97], [645, 77, 95], [660, 78, 100], [675, 88, 99], [690, 87, 100], [705, 87, 98], [720, 85, 96], [735, 70, 92], [750, 71, 88], [765, 88, 99], [780, 90, 100], [795, 94, 100], [810, 91, 100], [825, 88, 98], [840, 0, 100], [855, 0, 3], [870, 0, 100], [885, 76, 85], [900, 78, 83], [915, 78, 83], [930, 78, 85], [945, 75, 93], [960, 74, 94], [975, 76, 92], [990, 76, 85], [1005, 77, 93], [1020, 76, 91], [1035, 81, 89], [1050, 80, 100], [1065, 73, 100], [1080, 68, 93], [1095, 67, 92], [1110, 78, 92], [1125, 79, 100], [1140, 100, 100], [1154, 78, 100], [1168, 77, 82], [1182, 75, 81], [1196, 72, 80], [1210, 74, 82], [1224, 75, 83], [1238, 72, 89], [1252, 71, 89], [1266, 72, 99], [1280, 86, 99], [1294, 82, 100], [1308, 82, 96], [1322, 83, 95], [1336, 81, 95], [1350, 77, 91], [1364, 80, 100], [1378, 90, 100], [1392, 97, 100], [1406, 95, 100], [1420, 92, 100], [1434, 97, 100], [1448, 93, 100], [1462, 94, 100]]
        },
        {   id: 'ab55eb3d-c840-4a36-b79d-181799edb1e2',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/black_ffmpeg3/egress_capture__10_9_24_10__232_10_10_5__49410.ts',
            demo: 'black_ffmpeg3',
            measurementPointId: '5e6d8f6a-0648-4a96-ad63-7fc00dae7996',
            bitrateList:[3079, 2655, 2307, 2674, 2821, 1957, 2653, 2883, 2875, 2563, 2218, 3081, 2220, 2806, 2300, 2634, 3186, 2700, 2349, 1722, 2599, 2275, 3841, 2392, 2494, 1696, 3190, 2554, 2879, 2384, 2795, 1706, 3524, 3057, 530, 3828, 2734, 2464, 2406, 2685, 2630, 2590, 2967, 2402, 2381, 2797, 3788, 2014, 2009, 2061, 2393, 3401, 2118, 2834, 2959, 2002, 2759, 2040, 2864],
            eaId: '6e30d4de-dd0d-496e-99e9-23b09d770b28',
            vmafReduct: [[0, 71, 79], [15, 74, 82], [30, 75, 83], [45, 73, 91], [60, 81, 91], [75, 82, 100], [90, 85, 92], [105, 68, 92], [120, 71, 89], [135, 74, 95], [150, 82, 95], [165, 87, 100], [180, 84, 100], [195, 80, 96], [210, 84, 96], [225, 84, 94], [240, 84, 95], [255, 84, 97], [270, 69, 94], [285, 68, 83], [300, 76, 100], [315, 92, 100], [330, 94, 100], [345, 90, 100], [360, 77, 100], [375, 71, 97], [390, 69, 84], [405, 70, 100], [420, 89, 100], [435, 88, 98], [450, 75, 92], [465, 72, 83], [480, 74, 88], [495, 74, 95], [510, 77, 95], [525, 90, 96], [540, 89, 97], [555, 76, 100], [570, 85, 97], [585, 70, 92], [600, 68, 85], [615, 74, 94], [630, 77, 93], [645, 74, 89], [660, 73, 100], [675, 84, 97], [690, 82, 98], [705, 82, 93], [720, 78, 92], [735, 68, 88], [750, 68, 83], [765, 81, 92], [780, 81, 95], [795, 94, 100], [810, 87, 100], [825, 83, 95], [840, 0, 100], [855, 0, 3], [870, 0, 99], [885, 70, 84], [900, 73, 81], [915, 72, 81], [930, 71, 81], [945, 67, 82], [960, 71, 94], [975, 74, 91], [990, 71, 82], [1005, 71, 86], [1020, 71, 90], [1035, 78, 87], [1050, 79, 100], [1065, 66, 100], [1080, 55, 90], [1095, 59, 85], [1110, 72, 86], [1125, 73, 100], [1140, 99, 100], [1154, 73, 100], [1168, 72, 83], [1182, 68, 77], [1196, 66, 76], [1210, 65, 79], [1224, 68, 79], [1238, 64, 78], [1252, 68, 90], [1266, 75, 96], [1280, 81, 94], [1294, 76, 94], [1308, 78, 93], [1322, 80, 92], [1336, 80, 91], [1350, 75, 88], [1364, 79, 100], [1378, 89, 100], [1392, 92, 100], [1406, 91, 100], [1420, 85, 97], [1434, 91, 100], [1448, 85, 98], [1462, 86, 100]]
        },
        {   id: 'bf7c094d-6bb9-4efb-8417-198ec86b3e67',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/black_ffmpeg3/egress_capture__10_9_24_10__232_10_10_6__49410.ts',
            demo: 'black_ffmpeg3',
            measurementPointId: 'b473a1eb-1645-4ee1-a25d-9eab50d3d52b',
            bitrateList:[2368, 1912, 1690, 2249, 2454, 1515, 1926, 2095, 2200, 2061, 1571, 2472, 1635, 2167, 1883, 2145, 2098, 1736, 2083, 1688, 2256, 1452, 2883, 1828, 1969, 1291, 2638, 1960, 2166, 1845, 2524, 1351, 2016, 2538, 373, 3160, 2300, 2133, 1782, 1872, 1987, 1819, 2239, 2145, 1722, 2072, 2707, 1697, 1565, 1779, 1816, 3367, 1355, 1845, 2187, 1606, 2129, 1522, 2049],
            eaId: 'e27a2b6b-c985-43f2-ba9f-38def5443d05',
            vmafReduct: [[0, 63, 73], [15, 64, 74], [30, 67, 73], [45, 66, 84], [60, 72, 86], [75, 73, 98], [90, 75, 84], [105, 65, 85], [120, 69, 84], [135, 66, 85], [150, 73, 86], [165, 76, 92], [180, 72, 100], [195, 63, 80], [210, 67, 86], [225, 71, 86], [240, 71, 82], [255, 71, 84], [270, 62, 82], [285, 60, 72], [300, 63, 96], [315, 83, 99], [330, 82, 98], [345, 83, 99], [360, 73, 92], [375, 68, 88], [390, 61, 81], [405, 63, 90], [420, 77, 89], [435, 74, 87], [450, 69, 81], [465, 70, 81], [480, 73, 85], [495, 71, 93], [510, 74, 93], [525, 77, 84], [540, 75, 86], [555, 74, 96], [570, 73, 88], [585, 59, 83], [600, 60, 80], [615, 59, 85], [630, 67, 83], [645, 64, 85], [660, 65, 94], [675, 71, 86], [690, 69, 88], [705, 69, 82], [720, 67, 81], [735, 61, 76], [750, 60, 72], [765, 72, 83], [780, 72, 84], [795, 79, 98], [810, 73, 97], [825, 71, 83], [840, 0, 91], [855, 0, 3], [870, 0, 90], [885, 63, 76], [900, 67, 74], [915, 64, 75], [930, 64, 76], [945, 63, 80], [960, 61, 81], [975, 62, 81], [990, 61, 74], [1005, 61, 78], [1020, 60, 78], [1035, 66, 75], [1050, 66, 100], [1065, 58, 100], [1080, 52, 84], [1095, 52, 79], [1110, 63, 77], [1125, 64, 98], [1140, 87, 99], [1155, 67, 97], [1170, 64, 73], [1185, 61, 71], [1200, 57, 67], [1215, 60, 69], [1230, 60, 78], [1244, 65, 71], [1258, 65, 79], [1272, 63, 92], [1286, 71, 91], [1300, 66, 81], [1314, 69, 83], [1328, 69, 82], [1342, 68, 81], [1356, 65, 81], [1370, 78, 96], [1384, 81, 98], [1398, 81, 97], [1412, 76, 92], [1426, 72, 88], [1440, 70, 96], [1454, 69, 80], [1468, 72, 100]]
        },
        {   id: '3c3eb51c-1bf1-43ae-927a-695e5956a542',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/black_ffmpeg3/egress_capture__10_9_24_10__232_10_10_7__49410.ts',
            demo: 'black_ffmpeg3',
            measurementPointId: 'b830fcc4-f98e-49c7-a5ea-f541ca55e8e8',
            bitrateList:[1818, 1489, 1221, 1734, 1943, 1129, 1395, 1461, 1707, 1623, 1399, 1703, 1160, 1678, 1421, 1530, 1725, 1478, 1428, 1063, 1793, 1097, 2239, 1403, 1441, 1006, 1986, 1506, 1551, 1372, 1881, 1142, 1498, 1925, 274, 2426, 1695, 1648, 1405, 1485, 1550, 1225, 1586, 1310, 1387, 2112, 1979, 1148, 1173, 1268, 1288, 2671, 870, 1436, 1769, 1249, 1653, 1134, 1533],
            eaId: 'f48b5281-aa8a-4e33-9648-2c5a5658250d',
            vmafReduct: [[0, 55, 65], [15, 58, 67], [30, 60, 66], [45, 59, 79], [60, 63, 80], [75, 64, 91], [90, 70, 78], [105, 55, 78], [120, 59, 75], [135, 56, 77], [150, 66, 78], [165, 68, 84], [180, 65, 92], [195, 54, 72], [210, 60, 79], [225, 66, 78], [240, 65, 76], [255, 65, 78], [270, 52, 77], [285, 50, 64], [300, 56, 89], [315, 78, 93], [330, 76, 92], [345, 78, 93], [360, 68, 86], [375, 64, 82], [390, 55, 71], [405, 57, 84], [420, 71, 83], [435, 68, 82], [450, 57, 75], [465, 56, 68], [480, 61, 73], [495, 57, 86], [510, 68, 85], [525, 71, 78], [540, 70, 80], [555, 66, 90], [570, 67, 81], [585, 52, 74], [600, 49, 70], [615, 49, 77], [630, 60, 76], [645, 55, 75], [660, 55, 89], [675, 63, 79], [690, 60, 78], [705, 60, 73], [720, 58, 71], [735, 50, 67], [750, 50, 62], [765, 61, 76], [780, 63, 77], [795, 72, 91], [810, 67, 89], [825, 64, 77], [840, 0, 82], [855, 0, 3], [870, 0, 85], [885, 56, 69], [900, 58, 67], [915, 57, 68], [930, 57, 68], [945, 56, 71], [960, 54, 74], [975, 56, 75], [990, 53, 66], [1005, 53, 70], [1020, 53, 68], [1035, 54, 63], [1050, 55, 99], [1065, 47, 95], [1080, 42, 72], [1095, 46, 64], [1110, 55, 70], [1125, 59, 93], [1140, 83, 95], [1155, 56, 92], [1170, 53, 62], [1185, 51, 60], [1200, 48, 58], [1215, 53, 63], [1230, 51, 69], [1244, 54, 61], [1258, 54, 68], [1272, 51, 88], [1286, 66, 85], [1300, 53, 67], [1314, 56, 69], [1328, 58, 74], [1342, 59, 73], [1356, 55, 74], [1370, 73, 91], [1384, 72, 93], [1398, 75, 90], [1412, 69, 84], [1426, 65, 79], [1440, 62, 88], [1454, 60, 72], [1468, 65, 100]]
        },
        {   id: '35fe8b23-2ccc-4d9e-9773-c2b19f0b4e22',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo2/black_ffmpeg3/egress_capture__10_9_24_10__232_10_10_8__49410.ts',
            demo: 'black_ffmpeg3',
            measurementPointId: '0c86d423-c20e-48e5-82da-aa59ac597e12',
            bitrateList:[808, 673, 457, 713, 806, 499, 614, 576, 712, 675, 557, 789, 436, 745, 604, 628, 691, 640, 726, 412, 645, 496, 926, 555, 579, 449, 960, 541, 579, 602, 780, 501, 668, 789, 108, 1045, 710, 646, 535, 591, 627, 622, 713, 539, 598, 806, 862, 467, 464, 550, 600, 1051, 414, 562, 706, 577, 721, 493],
            eaId: 'c8a32a3a-57f8-49c5-beea-fb4cc1179110',
            vmafReduct: [[0, 20, 27], [15, 24, 28], [30, 24, 27], [45, 24, 47], [60, 34, 50], [75, 33, 56], [90, 36, 43], [105, 24, 43], [120, 25, 43], [135, 26, 45], [150, 35, 47], [165, 33, 47], [180, 30, 54], [195, 23, 37], [210, 26, 42], [225, 30, 42], [240, 29, 39], [255, 27, 41], [270, 21, 40], [285, 20, 27], [300, 24, 52], [315, 42, 57], [330, 39, 61], [345, 49, 62], [360, 38, 55], [375, 35, 50], [390, 30, 42], [405, 31, 51], [420, 38, 51], [435, 36, 49], [450, 30, 47], [465, 27, 40], [480, 26, 40], [495, 23, 45], [510, 34, 46], [525, 36, 44], [540, 37, 46], [555, 34, 57], [570, 32, 43], [585, 19, 37], [600, 18, 34], [615, 19, 41], [630, 28, 41], [645, 27, 44], [660, 26, 48], [675, 30, 41], [690, 26, 41], [705, 27, 37], [720, 25, 36], [735, 21, 32], [750, 21, 28], [765, 23, 37], [780, 25, 49], [795, 42, 59], [810, 31, 57], [825, 29, 39], [840, 0, 46], [855, 0, 3], [870, 0, 55], [885, 23, 30], [900, 23, 29], [915, 23, 30], [930, 23, 29], [945, 23, 40], [960, 24, 38], [975, 26, 41], [990, 20, 30], [1005, 19, 32], [1020, 18, 33], [1035, 21, 31], [1050, 22, 68], [1064, 20, 60], [1078, 22, 39], [1092, 20, 37], [1106, 23, 39], [1120, 24, 55], [1134, 51, 61], [1148, 47, 61], [1162, 23, 53], [1176, 21, 25], [1190, 20, 25], [1204, 18, 26], [1218, 19, 22], [1232, 19, 36], [1246, 23, 35], [1260, 21, 36], [1274, 24, 56], [1288, 29, 48], [1302, 22, 37], [1316, 22, 31], [1330, 23, 38], [1344, 23, 38], [1358, 19, 57], [1372, 39, 59], [1386, 37, 63], [1400, 36, 54], [1414, 31, 43], [1428, 30, 44], [1442, 24, 50], [1456, 25, 100]]
        }
        ]

    React.useEffect(() => {
        let mounted = true
        async function getAWSUrl() {
            try {
                const defTS0Url = await Storage.get(default_TS[0]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const defTS1Url = await Storage.get(default_TS[1]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const defTS2Url = await Storage.get(default_TS[2]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const impES0Url = await Storage.get(impairmentEs5000kbps[0]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const impES1Url = await Storage.get(impairmentEs5000kbps[1]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const impES2Url = await Storage.get(impairmentEs5000kbps[2]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const impTS0Url = await Storage.get(impairmentTS35MBps[0]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const impTS1Url = await Storage.get(impairmentTS35MBps[1]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const impTS2Url = await Storage.get(impairmentTS35MBps[2]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const impBlack0Url = await Storage.get(blackFfmpeg3[0]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const impBlack1Url = await Storage.get(blackFfmpeg3[1]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const impBlack2Url = await Storage.get(blackFfmpeg3[2]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const impLoad1Url = await Storage.get(loadThrottle95[1]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const impLoad2Url = await Storage.get(loadThrottle95[2]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const result1 = await Storage.get('94f00c9e-477a-46e0-9960-fdf531710219/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                // console.log(result)
                if (mounted) {
                    // console.log('setPlayerUrl')
                    setDefaultTS0Url(defTS0Url)
                    setDefaultTS1Url(defTS1Url)
                    setDefaultTS2Url(defTS2Url)
                    setImpairmentES0Url(impES0Url)
                    setImpairmentES1Url(impES1Url)
                    setImpairmentES2Url(impES2Url)
                    setImpairmentTS0Url(impTS0Url)
                    setImpairmentTS1Url(impTS1Url)
                    setImpairmentTS2Url(impTS2Url)
                    setBlack0Url(impBlack0Url)
                    setBlack1Url(impBlack1Url)
                    setBlack2Url(impBlack2Url)
                    setLoad1Url(impLoad1Url)
                    setLoad2Url(impLoad2Url)
                    setPlayerUrl_Source(result1)
                    setPlayerUrlBeforeEncoder(defTS0Url)
                    setPlayerUrlCBR(defTS1Url);
                    setPlayerUrlABR(defTS2Url);
                }
            } catch (err) {
                console.log('URL not found')
            }
        }

        getAWSUrl().then()
        return function cleanup() {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const VQProbeItemCBR = {
        id: '3',
        key: 'VQProbeItemCBR',
        name: 'After CBR encoder'
    }

    const VQProbeItemABR = {
        id: '4',
        key: 'VQProbeItemABR',
        name: 'After ABR encoder'
    }

    const VQProbeItem_Source = {
        id: '1',
        key: 'VQProbeItem_1',
        name: 'Source signal'
    }

    const VQProbeItemBeforeEncoder = {
        id: '2',
        key: 'VQProbeItemBeforeEncoder',
        name: 'Before encoder'
    }

    const handleNormal = async () => {
        setTimeout(() => { setShowNormal(true)}, 30000)
        setPlaySource(false)
        setPlayBeforeEncoder(false)
        setPlayCBR(false)
        setPlayABR(false)
        setSeekSource(10)
        setSeekBeforeEncoder(0)
        setSeekCBR(4)
        setSeekABR(4)
        setPlayerUrlBeforeEncoder(defaultTS0Url)
        setPlayerUrlCBR(defaultTS1Url)
        setPlayerUrlABR(defaultTS2Url)
        setBitrateListBeforeEncoder(default_TS[0]['bitrateList'])
        setBitrateListCBR(default_TS[1]['bitrateList'])
        setBitrateListABR(default_TS[2]['bitrateList'])
        setVmafReductBeforeEncoder(default_TS[0]['vmafReduct'])
        setVmafReductCBR(default_TS[1]['vmafReduct'])
        setVmafReductABR(default_TS[2]['vmafReduct'])
        setTimeout(() => {
            setRepeatSource(true)
            setRepeatBeforeEncoder(true)
            setRepeatCBR(true)
            setRepeatABR(true)
        }, 1000);
        setTimeout(() => {
            setPlaySource(true)
            setPlayBeforeEncoder(true)
            setPlayCBR(true)
            setPlayABR(true)
            setRepeatSource(false)
            setRepeatBeforeEncoder(false)
            setRepeatCBR(false)
            setRepeatABR(false)
        }, 3000);
    }

    const handleTSBandwidthExceeded = async () => {
        setTimeout(() => { setShowNetwork(true)}, 30000)
        setPlaySource(false)
        setPlayBeforeEncoder(false)
        setPlayCBR(false)
        setPlayABR(false)
        setSeekSource(11)
        setSeekBeforeEncoder(0)
        setSeekCBR(4)
        setSeekABR(4)
        setPlayerUrlBeforeEncoder(impairmentTS0Url)
        setPlayerUrlCBR(impairmentTS1Url)
        setPlayerUrlABR(impairmentTS2Url)
        setBitrateListBeforeEncoder(impairmentTS35MBps[0]['bitrateList'])
        setBitrateListCBR(impairmentTS35MBps[1]['bitrateList'])
        setBitrateListABR(impairmentTS35MBps[2]['bitrateList'])
        setVmafReductBeforeEncoder(impairmentTS35MBps[0]['vmafReduct'])
        setVmafReductCBR(impairmentTS35MBps[1]['vmafReduct'])
        setVmafReductABR(impairmentTS35MBps[2]['vmafReduct'])
        setTimeout(() => {
            setRepeatSource(true)
            setRepeatBeforeEncoder(true)
            setRepeatCBR(true)
            setRepeatABR(true)
        }, 1000);
        setTimeout(() => {
            setPlaySource(true)
            setPlayBeforeEncoder(true)
            setPlayCBR(true)
            setPlayABR(true)
            setRepeatSource(false)
            setRepeatBeforeEncoder(false)
            setRepeatCBR(false)
            setRepeatABR(false)
        }, 3000);
    }

    const handleBlackInsert = async () => {
        setTimeout(() => { setShowBlack(true)}, 43000)
        setPlaySource(false)
        setPlayBeforeEncoder(false)
        setPlayCBR(false)
        setPlayABR(false)
        setSeekSource(11)
        setSeekBeforeEncoder(0)
        setSeekCBR(4)
        setSeekABR(4)
        setPlayerUrlBeforeEncoder(black0Url)
        setPlayerUrlCBR(black1Url)
        setPlayerUrlABR(black2Url)
        setBitrateListBeforeEncoder(blackFfmpeg3[0]['bitrateList'])
        setBitrateListCBR(blackFfmpeg3[1]['bitrateList'])
        setBitrateListABR(blackFfmpeg3[2]['bitrateList'])
        setVmafReductBeforeEncoder(blackFfmpeg3[0]['vmafReduct'])
        setVmafReductCBR(blackFfmpeg3[1]['vmafReduct'])
        setVmafReductABR(blackFfmpeg3[2]['vmafReduct'])
        setTimeout(() => {
            setRepeatSource(true)
            setRepeatBeforeEncoder(true)
            setRepeatCBR(true)
            setRepeatABR(true)
        }, 1000);
        setTimeout(() => {
            setPlaySource(true)
            setPlayBeforeEncoder(true)
            setPlayCBR(true)
            setPlayABR(true)
            setRepeatSource(false)
            setRepeatBeforeEncoder(false)
            setRepeatCBR(false)
            setRepeatABR(false)
        }, 3000);
    }

    const handleESReduced = async () => {
        setTimeout(() => { setShowPoor(true)}, 30000)
        setPlaySource(false)
        setPlayBeforeEncoder(false)
        setPlayCBR(false)
        setPlayABR(false)
        setSeekSource(11)
        setSeekBeforeEncoder(0)
        setSeekCBR(5)
        setSeekABR(5)
        setPlayerUrlBeforeEncoder(impairmentES0Url)
        setPlayerUrlCBR(impairmentES1Url)
        setPlayerUrlABR(impairmentES2Url)
        setBitrateListBeforeEncoder(impairmentEs5000kbps[0]['bitrateList'])
        setBitrateListCBR(impairmentEs5000kbps[1]['bitrateList'])
        setBitrateListABR(impairmentEs5000kbps[2]['bitrateList'])
        setVmafReductBeforeEncoder(impairmentEs5000kbps[0]['vmafReduct'])
        setVmafReductCBR(impairmentEs5000kbps[1]['vmafReduct'])
        setVmafReductABR(impairmentEs5000kbps[2]['vmafReduct'])
        setTimeout(() => {
            setRepeatSource(true)
            setRepeatBeforeEncoder(true)
            setRepeatCBR(true)
            setRepeatABR(true)
        }, 1000);
        setTimeout(() => {
            setPlaySource(true)
            setPlayBeforeEncoder(true)
            setPlayCBR(true)
            setPlayABR(true)
            setRepeatSource(false)
            setRepeatBeforeEncoder(false)
            setRepeatCBR(false)
            setRepeatABR(false)
        }, 3000);
    }

    const handleOverloadServer = async () => {
        setTimeout(() => { setShowLoad(true)}, 30000)
        setPlaySource(false)
        setPlayBeforeEncoder(false)
        setPlayCBR(false)
        setPlayABR(false)
        setSeekSource(10)
        setSeekBeforeEncoder(0)
        setSeekCBR(4)
        setSeekABR(4)
        setPlayerUrlBeforeEncoder(defaultTS0Url)
        setPlayerUrlCBR(load1Url)
        setPlayerUrlABR(load2Url)
        setBitrateListBeforeEncoder(default_TS[0]['bitrateList'])
        setBitrateListCBR(loadThrottle95[1]['bitrateList'])
        setBitrateListABR(loadThrottle95[2]['bitrateList'])
        setVmafReductBeforeEncoder(default_TS[0]['vmafReduct'])
        setVmafReductCBR(loadThrottle95[1]['vmafReduct'])
        setVmafReductABR(loadThrottle95[2]['vmafReduct'])
        setTimeout(() => {
            setRepeatSource(true)
            setRepeatBeforeEncoder(true)
            setRepeatCBR(true)
            setRepeatABR(true)
        }, 1000);
        setTimeout(() => {
            setPlaySource(true)
            setPlayBeforeEncoder(true)
            setPlayCBR(true)
            setPlayABR(true)
            setRepeatSource(false)
            setRepeatBeforeEncoder(false)
            setRepeatCBR(false)
            setRepeatABR(false)
        }, 3000);
    }

    const handleLoopSource = async () => {
        console.log('Loop source')
        setRepeatSource(true)
        setRepeatBeforeEncoder(true)
        setRepeatCBR(true)
        setRepeatABR(true)
        setTimeout(() => {
            setRepeatSource(false)
            setRepeatBeforeEncoder(false)
            setRepeatCBR(false)
            setRepeatABR(false)
        }, 2000);
    }

    const handleLoopBeforeEncoder = async () => {
        console.log('Loop BeforeEncoder')
        setRepeatSource(true)
        setRepeatBeforeEncoder(true)
        setRepeatCBR(true)
        setRepeatABR(true)
        setTimeout(() => {
            setRepeatSource(false)
            setRepeatBeforeEncoder(false)
            setRepeatCBR(false)
            setRepeatABR(false)
        }, 2000);
    }

    const handleLoopCBR = async () => {
        console.log('Loop CBR')
        setRepeatSource(true)
        setRepeatBeforeEncoder(true)
        setRepeatCBR(true)
        setRepeatABR(true)
        setTimeout(() => {
            setRepeatSource(false)
            setRepeatBeforeEncoder(false)
            setRepeatCBR(false)
            setRepeatABR(false)
        }, 2000);
    }

    const handleLoopABR = async () => {
        console.log('Loop ABR')
        setRepeatSource(true)
        setRepeatBeforeEncoder(true)
        setRepeatCBR(true)
        setRepeatABR(true)
        setTimeout(() => {
            setRepeatSource(false)
            setRepeatBeforeEncoder(false)
            setRepeatCBR(false)
            setRepeatABR(false)
        }, 2000);
    }

    return (
        <>
            <div className="section" style={{paddingBottom:0, paddingTop:0}}>
                <Flex justifyContent="center" style={{fontFamily: "SourceSansPro"}}>
                    <View width={{base: '95%', xl: '95%'}} height={'100vh'}>

                        {/*My Media*/}
                        <View style={{paddingBottom: "0"}}>
                            <Flex justifyContent={"center"} direction={"row"}>
                                <NetworkDrawing
                                    onClickClear={handleNormal}
                                    onClickBlackFrame={handleBlackInsert}
                                    onClickPoorInput={handleESReduced}
                                    onClickNetworkIssues={handleTSBandwidthExceeded}
                                    onClickLoad={handleOverloadServer}
                                    onPlay={handleNormal}
                                />
                            </Flex>
                            <br/>
                            <Flex justifyContent={"center"} direction={"row"} gap={"10px"}>
                                <VQProbeCard
                                    VQProbeItem={VQProbeItem_Source}
                                    playerUrl={playerUrl_Source}
                                    bitrateList={bitrateListSource}
                                    play={playSource}
                                    onLoop={handleLoopSource}
                                    seek={seekSource}
                                    repeat={repeatSource}
                                />
                                <VQProbeCard
                                    VQProbeItem={VQProbeItemBeforeEncoder}
                                    playerUrl={playerUrlBeforeEncoder}
                                    bitrateList={bitrateListBeforeEncoder}
                                    vmafReduct={vmafReductBeforeEncoder}
                                    play={playBeforeEncoder}
                                    onLoop={handleLoopBeforeEncoder}
                                    seek={seekBeforeEncoder}
                                    repeat={repeatBeforeEncoder}
                                />
                                <VQProbeCard
                                    VQProbeItem={VQProbeItemCBR}
                                    playerUrl={playerUrlCBR}
                                    bitrateList={bitrateListCBR}
                                    vmafReduct={vmafReductCBR}
                                    play={playCBR}
                                    onLoop={handleLoopCBR}
                                    seek={seekCBR}
                                    repeat={repeatCBR}
                                />
                                <VQProbeCard
                                    VQProbeItem={VQProbeItemABR}
                                    playerUrl={playerUrlABR}
                                    bitrateList={bitrateListABR}
                                    vmafReduct={vmafReductABR}
                                    play={playABR}
                                    onLoop={handleLoopABR}
                                    seek={seekABR}
                                    repeat={repeatABR}
                                />
                            </Flex>
                            <br/>
                            <svg id="eHZOWYBAnTF1" xmlns="http://www.w3.org/2000/svg xmlns:xlink=http://www.w3.org/1999/xlink" viewBox="0 5 1280 215" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
                                { showLoad ?
                                    <g transform="translate(1069.098873-.221491)">
                                        <rect width="200" height="200" rx="0" ry="0" transform="translate(-19.098874 9.686441)" fill="#fff" stroke="#f33" strokeWidth="4" />
                                        <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" fontWeight="700" transform="translate(-9.098874 36.421315)" fill="#f33" strokeWidth="0">
                                            <tspan y="0" fontWeight="700" strokeWidth="0">
                                                {'Overloaded CPU '}
                                            </tspan>
                                        </text>
                                        <g transform="translate(-270.046028 89.922394)">
                                            <ellipse rx="20" ry="20" transform="translate(307.759 72.1952)" fill="#3380ff" stroke="#3380ff" />
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(303.034 56.3385)" fill="#fff" strokeWidth="0">
                                                <tspan y="24" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {'4'}
                                                </tspan>
                                            </text>
                                        </g>
                                        <Link as={ReactRouterLink} to={"/encode-analysis/18d36b11-f066-44f8-b0e9-7c12d8c7410c"}>
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" textDecoration="underline" fontWeight="700" transform="translate(66.349305 169.183863)" fill="#3380ff" strokeWidth="0">
                                                <tspan y="0" fontWeight="700" strokeWidth="0">
                                                    {'VMAF: 74.9'}
                                                </tspan>
                                            </text>
                                        </Link>
                                        <g transform="translate(-270.046028 44.019594)">
                                            <ellipse rx="20" ry="20" transform="translate(307.759 72.1952)" fill="#3380ff" stroke="#3380ff" />
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(302.261 80.3385)" fill="#fff" strokeWidth="0">
                                                <tspan y="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {'3'}
                                                </tspan>
                                                <tspan x="0" y="48" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {' '}
                                                </tspan>
                                            </text>
                                        </g>
                                        <Link as={ReactRouterLink} to={"/encode-analysis/79f451f5-5477-4a72-87b3-9a436cc2c106"}>
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" textDecoration="underline" fontWeight="700" transform="translate(66.349305 123.281063)" fill="#3380ff" strokeWidth="0">
                                                <tspan y="0" fontWeight="700" strokeWidth="0">
                                                    {'VMAF: 83.8'}
                                                </tspan>
                                            </text>
                                        </Link>
                                        <g transform="translate(-291.287025-3.236187)">
                                            <ellipse rx="20" ry="20" transform="translate(329 72)" fill="#3380ff" stroke="#3380ff" />
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(323.035 80.5584)" fill="#fff" strokeWidth="0">
                                                <tspan y="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {'2'}
                                                </tspan>
                                            </text>
                                        </g>
                                        <Link as={ReactRouterLink} to={"/encode-analysis/01cb9d29-1a4a-47f1-a7c6-b61a595bd549"}>
                                        <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" textDecoration="underline" fontWeight="700" transform="translate(66.349305 75.830076)" fill="#3380ff" strokeWidth="0">
                                            <tspan y="0" fontWeight="700" strokeWidth="0">
                                                {'VMAF: 97.9'}
                                            </tspan>
                                        </text>
                                        </Link>
                                    </g>  : <></>
                                }
                                { showNetwork ?
                                    <g transform="translate(819.098873-.221491)">
                                        <rect width="200" height="200" rx="0" ry="0" transform="translate(-19.098874 9.686441)" fill="#fff" stroke="#f33" strokeWidth="4" />
                                        <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" fontWeight="700" transform="translate(-9.098874 36.421315)" fill="#f33" strokeWidth="0">
                                            <tspan y="0" fontWeight="700" strokeWidth="0">
                                                {'Network Issues '}
                                            </tspan>
                                        </text>
                                        <g transform="translate(-270.046028 89.922394)">
                                            <ellipse rx="20" ry="20" transform="translate(307.759 72.1952)" fill="#3380ff" stroke="#3380ff" />
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(303.034 56.3385)" fill="#fff" strokeWidth="0">
                                                <tspan y="24" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {'4'}
                                                </tspan>
                                            </text>
                                        </g>
                                        <Link as={ReactRouterLink} to={"/encode-analysis/7d3a8c74-541a-4bf5-b110-400eb749ee39"}>
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" textDecoration="underline" fontWeight="700" transform="translate(66.349305 169.183863)" fill="#3380ff" strokeWidth="0">
                                                <tspan y="0" fontWeight="700" strokeWidth="0">
                                                    {'VMAF: 76.6'}
                                                </tspan>
                                            </text>
                                        </Link>
                                        <g transform="translate(-270.046028 44.019594)">
                                            <ellipse rx="20" ry="20" transform="translate(307.759 72.1952)" fill="#3380ff" stroke="#3380ff" />
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(302.261 80.3385)" fill="#fff" strokeWidth="0">
                                                <tspan y="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {'3'}
                                                </tspan>
                                                <tspan x="0" y="48" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {' '}
                                                </tspan>
                                            </text>
                                        </g>
                                        <Link as={ReactRouterLink} to={"/encode-analysis/38b3f038-dedc-4e6d-97db-a92401efb81a"}>
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" textDecoration="underline" fontWeight="700" transform="translate(66.349305 123.281063)" fill="#3380ff" strokeWidth="0">
                                                <tspan y="0" fontWeight="700" strokeWidth="0">
                                                    {'VMAF: 84.2'}
                                                </tspan>
                                            </text>
                                        </Link>
                                        <g transform="translate(-291.287025-3.236187)">
                                            <ellipse rx="20" ry="20" transform="translate(329 72)" fill="#3380ff" stroke="#3380ff" />
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(323.035 80.5584)" fill="#fff" strokeWidth="0">
                                                <tspan y="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {'2'}
                                                </tspan>
                                            </text>
                                        </g>
                                        <Link as={ReactRouterLink} to={"/encode-analysis/ecc44588-1659-4f24-9adc-7037c880be91"}>
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" textDecoration="underline" fontWeight="700" transform="translate(66.349305 75.830076)" fill="#3380ff" strokeWidth="0">
                                                <tspan y="0" fontWeight="700" strokeWidth="0">
                                                    {'VMAF: 27.6'}
                                                </tspan>
                                            </text>
                                        </Link>
                                    </g>  : <></>
                                }
                                { showPoor ?
                                    <g transform="translate(569.098873-.221491)">
                                        <rect width="200" height="200" rx="0" ry="0" transform="translate(-19.098874 9.686441)" fill="#fff" stroke="#f33" strokeWidth="4" />
                                        <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" fontWeight="700" transform="translate(-9.098874 36.421315)" fill="#f33" strokeWidth="0">
                                            <tspan y="0" fontWeight="700" strokeWidth="0">
                                                {'Poor Input Quality'}
                                            </tspan>
                                        </text>
                                        <g transform="translate(-270.046028 89.922394)">
                                            <ellipse rx="20" ry="20" transform="translate(307.759 72.1952)" fill="#3380ff" stroke="#3380ff" />
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(303.034 56.3385)" fill="#fff" strokeWidth="0">
                                                <tspan y="24" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {'4'}
                                                </tspan>
                                            </text>
                                        </g>
                                        <Link as={ReactRouterLink} to={"/encode-analysis/e8cfc4b1-b563-4ad4-9400-5168b8451153"}>
                                        <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" textDecoration="underline" fontWeight="700" transform="translate(66.349305 169.183863)" fill="#3380ff" strokeWidth="0">
                                            <tspan y="0" fontWeight="700" strokeWidth="0">
                                                {'VMAF: 76.4'}
                                            </tspan>
                                        </text>
                                        </Link>
                                        <g transform="translate(-270.046028 44.019594)">
                                            <ellipse rx="20" ry="20" transform="translate(307.759 72.1952)" fill="#3380ff" stroke="#3380ff" />
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(302.261 80.3385)" fill="#fff" strokeWidth="0">
                                                <tspan y="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {'3'}
                                                </tspan>
                                                <tspan x="0" y="48" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {' '}
                                                </tspan>
                                            </text>
                                        </g>
                                        <Link as={ReactRouterLink} to={"/encode-analysis/da69389e-e447-4cda-8425-0d72a76c230f"}>
                                        <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" textDecoration="underline" fontWeight="700" transform="translate(66.349305 123.281063)" fill="#3380ff" strokeWidth="0">
                                            <tspan y="0" fontWeight="700" strokeWidth="0">
                                                {'VMAF: 83.3'}
                                            </tspan>
                                        </text>
                                        </Link>
                                        <g transform="translate(-291.287025-3.236187)">
                                            <ellipse rx="20" ry="20" transform="translate(329 72)" fill="#3380ff" stroke="#3380ff" />
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(323.035 80.5584)" fill="#fff" strokeWidth="0">
                                                <tspan y="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {'2'}
                                                </tspan>
                                            </text>
                                        </g>
                                        <Link as={ReactRouterLink} to={"/encode-analysis/c69d7b8b-faf9-4bd5-8de0-77616f526c1e"}>
                                        <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" textDecoration="underline" fontWeight="700" transform="translate(66.349305 75.830076)" fill="#3380ff" strokeWidth="0">
                                            <tspan y="0" fontWeight="700" strokeWidth="0">
                                                {'VMAF: 85.0'}
                                            </tspan>
                                        </text>
                                        </Link>
                                    </g>  : <></>
                                }
                                { showBlack ?
                                    <g transform="translate(319.098873-.221491)">
                                        <rect width="200" height="200" rx="0" ry="0" transform="translate(-19.098874 9.686441)" fill="#fff" stroke="#f33" strokeWidth="4" />
                                        <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" fontWeight="700" transform="translate(-9.098874 36.421315)" fill="#f33" strokeWidth="0">
                                            <tspan y="0" fontWeight="700" strokeWidth="0">
                                                {'Black Frame'}
                                            </tspan>
                                        </text>
                                        <g transform="translate(-270.046028 89.922394)">
                                            <ellipse rx="20" ry="20" transform="translate(307.759 72.1952)" fill="#3380ff" stroke="#3380ff" />
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(303.034 56.3385)" fill="#fff" strokeWidth="0">
                                                <tspan y="24" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {'4'}
                                                </tspan>
                                            </text>
                                        </g>
                                        <Link as={ReactRouterLink} to={"/encode-analysis/45832e24-913d-4785-862e-08dded15b42e"}>
                                        <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" textDecoration="underline" fontWeight="700" transform="translate(66.349305 169.183863)" fill="#3380ff" strokeWidth="0">
                                            <tspan y="0" fontWeight="700" strokeWidth="0">
                                                {'VMAF: 81.5'}
                                            </tspan>
                                        </text>
                                        </Link>
                                        <g transform="translate(-270.046028 44.019594)">
                                            <ellipse rx="20" ry="20" transform="translate(307.759 72.1952)" fill="#3380ff" stroke="#3380ff" />
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(302.261 80.3385)" fill="#fff" strokeWidth="0">
                                                <tspan y="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {'3'}
                                                </tspan>
                                                <tspan x="0" y="48" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {' '}
                                                </tspan>
                                            </text>
                                        </g>
                                        <Link as={ReactRouterLink} to={"/encode-analysis/118f27ff-fc27-4053-9741-3ddc3d3d5cd7"}>
                                        <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" textDecoration="underline" fontWeight="700" transform="translate(66.349305 123.281063)" fill="#3380ff" strokeWidth="0">
                                            <tspan y="0" fontWeight="700" strokeWidth="0">
                                                {'VMAF: 89.6'}
                                            </tspan>
                                        </text>
                                        </Link>
                                        <g transform="translate(-291.287025-3.236187)">
                                            <ellipse rx="20" ry="20" transform="translate(329 72)" fill="#3380ff" stroke="#3380ff" />
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(323.035 80.5584)" fill="#fff" strokeWidth="0">
                                                <tspan y="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {'2'}
                                                </tspan>
                                            </text>
                                        </g>
                                        <Link as={ReactRouterLink} to={"/encode-analysis/eea21be6-0e6e-4d30-a434-9995272ef255"}>
                                        <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" textDecoration="underline" fontWeight="700" transform="translate(66.349305 75.830076)" fill="#3380ff" strokeWidth="0">
                                            <tspan y="0" fontWeight="700" strokeWidth="0">
                                                {'VMAF: 96.4 '}
                                            </tspan>
                                        </text>
                                        </Link>
                                    </g>  : <></>
                                }
                                { showNormal ?
                                    <g transform="translate(69.098873-.221491)">
                                        <rect width="200" height="200" rx="0" ry="0" transform="translate(-19.098874 9.686441)" fill="#fff" stroke="#3380ff" strokeWidth="4" />
                                        <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" fontWeight="700" transform="translate(-9.098874 36.421315)" fill="#3380ff" strokeWidth="0">
                                            <tspan y="0" fontWeight="700" strokeWidth="0">
                                                {'Normal '}
                                            </tspan>
                                        </text>
                                        <g transform="translate(-270.046028 89.922394)">
                                            <ellipse rx="20" ry="20" transform="translate(307.759 72.1952)" fill="#3380ff" stroke="#3380ff" />
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(303.034 56.3385)" fill="#fff" strokeWidth="0">
                                                <tspan y="24" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {'4'}
                                                </tspan>
                                            </text>
                                        </g>
                                        <Link as={ReactRouterLink} to={"/encode-analysis/f442a1ba-f634-4166-9062-2d410c8c8bbd"}>
                                        <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" textDecoration="underline" fontWeight="700" transform="translate(66.349305 169.183863)" fill="#3380ff" strokeWidth="0">
                                            <tspan y="0" fontWeight="700" strokeWidth="0">
                                                {'VMAF: 83.6'}
                                            </tspan>
                                        </text>
                                        </Link>
                                        <g transform="translate(-270.046028 44.019594)">
                                            <ellipse rx="20" ry="20" transform="translate(307.759 72.1952)" fill="#3380ff" stroke="#3380ff" />
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(302.261 80.3385)" fill="#fff" strokeWidth="0">
                                                <tspan y="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {'3'}
                                                </tspan>
                                                <tspan x="0" y="48" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {' '}
                                                </tspan>
                                            </text>
                                        </g>
                                        <Link as={ReactRouterLink} to={"/encode-analysis/70c74251-639c-4428-b89d-a366d01f83f2"}>
                                        <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" textDecoration="underline" fontWeight="700" transform="translate(66.349305 123.281063)" fill="#3380ff" strokeWidth="0">
                                            <tspan y="0" fontWeight="700" strokeWidth="0">
                                                {'VMAF: 92.1'}
                                            </tspan>
                                        </text>
                                        </Link>
                                        <g transform="translate(-291.287025-3.236187)">
                                            <ellipse rx="20" ry="20" transform="translate(329 72)" fill="#3380ff" stroke="#3380ff" />
                                            <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(323.035 80.5584)" fill="#fff" strokeWidth="0">
                                                <tspan y="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" fontStyle="normal" fill="#fff" strokeWidth="0">
                                                    {'2'}
                                                </tspan>
                                            </text>
                                        </g>
                                        <Link as={ReactRouterLink} to={"/encode-analysis/01cb9d29-1a4a-47f1-a7c6-b61a595bd549"}>
                                        <text dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" textDecoration="underline" fontWeight="700" transform="translate(66.349305 75.830076)" fill="#3380ff" strokeWidth="0">
                                            <tspan y="0" fontWeight="700" strokeWidth="0">
                                                {'VMAF: 99.0'}
                                            </tspan>
                                        </text>
                                        </Link>
                                    </g>  : <></>
                                }
                            </svg>
                            {/*<Flex justifyContent={"center"} direction={"column"} gap={"10px"} alignItems={"center"}>*/}
                            {/*    <Table width={'1200px'}>*/}
                            {/*        <TableHead>*/}
                            {/*            <TableRow>*/}
                            {/*                <TableCell as="th">Time</TableCell>*/}
                            {/*                <TableCell as="th">Network status</TableCell>*/}
                            {/*                <TableCell as="th">Probe before encoder</TableCell>*/}
                            {/*                <TableCell as="th">Probe CBR</TableCell>*/}
                            {/*                <TableCell as="th">Probe ABR</TableCell>*/}
                            {/*            </TableRow>*/}
                            {/*        </TableHead>*/}
                            {/*        <TableBody>*/}
                            {/*            {impairments}*/}
                            {/*        </TableBody>*/}
                            {/*    </Table>*/}
                            {/*</Flex>*/}
                        </View>
                    </View>
                </Flex>
            </div>
        </>
    );
}

export default ChannelPage;
