import React from "react";
import {Flex, View} from '@aws-amplify/ui-react';
import {Storage} from "aws-amplify";
import SmxProbeCard from "../../components/vqaas/smxProbeCard";

function SmxPage(props) {
    const [smx3Chan1, setSmx3Chan1] = React.useState()
    const [smx3Chan2, setSmx3Chan2] = React.useState()
    const [smx3Chan3, setSmx3Chan3] = React.useState()
    const [smx3Chan4, setSmx3Chan4] = React.useState()
    const [smx10Chan1, setSmx10Chan1] = React.useState()
    const [smx10Chan2, setSmx10Chan2] = React.useState()
    const [smx10Chan3, setSmx10Chan3] = React.useState()
    const [smx10Chan4, setSmx10Chan4] = React.useState()
    const [smx11Chan1, setSmx11Chan1] = React.useState()
    const [smx11Chan2, setSmx11Chan2] = React.useState()
    const [smx11Chan3, setSmx11Chan3] = React.useState()
    const [smx11Chan4, setSmx11Chan4] = React.useState()
    const [smx12Chan1, setSmx12Chan1] = React.useState()
    const [smx12Chan2, setSmx12Chan2] = React.useState()
    const [smx12Chan3, setSmx12Chan3] = React.useState()
    const [smx12Chan4, setSmx12Chan4] = React.useState()
    const [smx13Chan1, setSmx13Chan1] = React.useState()
    const [smx13Chan2, setSmx13Chan2] = React.useState()
    const [smx13Chan3, setSmx13Chan3] = React.useState()
    const [smx13Chan4, setSmx13Chan4] = React.useState()
    const [playerChan1, setPlayerChan1] = React.useState()
    const [playerChan2, setPlayerChan2] = React.useState()
    const [playerChan3, setPlayerChan3] = React.useState()
    const [playerChan4, setPlayerChan4] = React.useState()
    const [bitrateListChan1In, setBitrateListChan1In] = React.useState()
    const [bitrateListChan2In, setBitrateListChan2In] = React.useState()
    const [bitrateListChan3In, setBitrateListChan3In] = React.useState()
    const [bitrateListChan4In, setBitrateListChan4In] = React.useState()
    const [bitrateListChan1Out, setBitrateListChan1Out] = React.useState()
    const [bitrateListChan2Out, setBitrateListChan2Out] = React.useState()
    const [bitrateListChan3Out, setBitrateListChan3Out] = React.useState()
    const [bitrateListChan4Out, setBitrateListChan4Out] = React.useState()
    const [vmafListChan1, setVmafListChan1] = React.useState()
    const [vmafListChan2, setVmafListChan2] = React.useState()
    const [vmafListChan3, setVmafListChan3] = React.useState()
    const [vmafListChan4, setVmafListChan4] = React.useState()
    const [play, setPlay] = React.useState(false)
    const [smxGroup, setSmxGroup] = React.useState(3)
    const [pointer, setPointer] = React.useState(0)

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    });


    const smx3List = [
        {
            id: '92e105e3-fb1b-4e00-b5f8-8851ac7b2fa0',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx3/egress_capture__10_9_24_10__232_10_10_1__49410.ts',
            smx: '3',
            measurementPointId: 'daa82064-ca15-4022-83c5-171bb9b9179a',
            bitrateList: [15954, 14271, 13047, 15689, 22015, 12083, 13059, 12937, 16014, 15260, 12434, 14218, 13785, 13745, 17143, 14013, 14545, 15837, 13174, 14918, 13629, 15981, 18329, 12705, 14634, 16430, 12627, 12887, 15862, 13689, 12568, 14451, 14025, 15434, 17163, 15885, 15759, 12503, 16441, 12548, 14388, 17270, 16070, 15936, 13799, 12794, 13684, 14797, 15568, 14585, 13400, 15081, 13936, 14050, 16554, 13927, 15371, 16937, 13093, 11489, 13251, 17371, 14288, 15734, 16923, 12904, 12473, 12703, 16723, 15352, 17339, 12212, 11412, 18072, 15375, 12530, 14120, 14933, 13544, 14334, 14728, 16593, 13508, 13860, 15902, 16934, 14245, 15804, 9985, 14836, 18339, 14297, 13111, 15101, 13504, 15181, 14028, 15098, 14837, 18741, 12038, 10388, 16190, 14674, 19834, 15445, 12013, 12168, 12014, 15698, 18199, 12390, 17048, 14356, 14014, 12415, 14785, 14490, 15281]
        },
        {
            id: 'e90f69a2-ba9f-4336-9602-76517cb11f5d',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx3/egress_capture__10_9_24_10__232_10_10_2__49410.ts',
            smx: '3',
            measurementPointId: '81ece6a3-c40d-4c4c-955d-90841bf22891',
            bitrateList: [16310, 12720, 12555, 19119, 11128, 15522, 15962, 14443, 13701, 12188, 16247, 13962, 15824, 14922, 12771, 15684, 16874, 15216, 13184, 13476, 13636, 15071, 12669, 15061, 18771, 16214, 12262, 13050, 17792, 14209, 11851, 14812, 14694, 16829, 15602, 17285, 7225, 19568, 15537, 11042, 11409, 16559, 18059, 16172, 7956, 16285, 14955, 14687, 15610, 18380, 11458, 14065, 14157, 15775, 14446, 12439, 13592, 19823, 12514, 13338, 14504, 15980, 15321, 11356, 18428, 17106, 14341, 12300, 16254, 14733, 13070, 14889, 12869, 14455, 14346, 16999, 14643, 12315, 13807, 18080, 10409, 14567, 14729, 15636, 13606, 19718, 18044, 14875, 11702, 14181, 15681, 13838, 14381, 14810, 12763, 17225, 14100, 15459, 13862, 13321, 13612, 11754, 15668, 15039, 14938, 15474, 13383, 18256, 13057, 12159, 16237, 12990, 15846, 13551, 14743, 13761, 16745, 12670, 19515]
        },
        {
            id: '4e86ac84-d0e9-4d6d-9625-06c2254956dd',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx3/egress_capture__10_9_24_10__232_10_10_3__49410.ts',
            smx: '3',
            measurementPointId: 'ab3851f1-5502-4514-8c70-a8b1bc5ac247',
            bitrateList: [9586, 12487, 14847, 14924, 15519, 16086, 12743, 19389, 16412, 13134, 9718, 13902, 15534, 14780, 18423, 15334, 13103, 13893, 13532, 14366, 15732, 14410, 12929, 14889, 15232, 15823, 13708, 14530, 15944, 12916, 11636, 18028, 15919, 16557, 13340, 13395, 15683, 14654, 10785, 18339, 16562, 13047, 14358, 13381, 13132, 15354, 14699, 13494, 17901, 13320, 16697, 13785, 15763, 13488, 13358, 13130, 14966, 17133, 14312, 12997, 15478, 15136, 12480, 15804, 12148, 16989, 20712, 14408, 10757, 13110, 14806, 13472, 15922, 13259, 13938, 16724, 11362, 18791, 12549, 16712, 16134, 9428, 15880, 17142, 14347, 15660, 15762, 15865, 13304, 14234, 15988, 12531, 14675, 13724, 15865, 14686, 12503, 16161, 13688, 14164, 16705, 15500, 14483, 11346, 14565, 17788, 18425, 12842, 13495, 12945, 11343, 16654, 12604, 18763, 17523, 13356, 12504, 15747, 13172]
        },
        {
            id: 'cffcf303-f4ea-48fc-aab6-8c7f970792d0',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx3/egress_capture__10_9_24_10__232_10_10_4__49410.ts',
            smx: '3',
            measurementPointId: '8506146d-88c1-4fa7-ba41-3608dbfdbca2',
            bitrateList: [16193, 10470, 17237, 16820, 12462, 15584, 10396, 16895, 15685, 16238, 13210, 13960, 17600, 12185, 13629, 13761, 17815, 12883, 14728, 13957, 12369, 16623, 14501, 15534, 13266, 14003, 15651, 14105, 16986, 10719, 17245, 15937, 13100, 16225, 18565, 11591, 13255, 14781, 12206, 14132, 14967, 13302, 15574, 13757, 15593, 19275, 14102, 11041, 17110, 12145, 19585, 13273, 12322, 15009, 13993, 8813, 20292, 16262, 13275, 15708, 12650, 15808, 16214, 14108, 12379, 17021, 14438, 10026, 21009, 9923, 12409, 17540, 14988, 15919, 13780, 15080, 14353, 15126, 13083, 13956, 14954, 15338, 16807, 14415, 16578, 10455, 15689, 8854, 17054, 13988, 19662, 13184, 17792, 9894, 23493, 11542, 13903, 10655, 11593, 23219, 12197, 14541, 12466, 11727, 15166, 17200, 17661, 15991, 14790, 12967, 14479, 11549, 20142, 14637, 13207, 15662, 11060, 16561, 13089]
        },
        {
            id: '86ac630a-217b-4d78-9cb2-f88556065d64',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx3/egress_capture__10_9_24_10__232_10_10_5__49410.ts',
            smx: '3',
            measurementPointId: 'c1f62728-efe9-47b6-a18c-0b8612f28baa',
            bitrateList: [1921, 1945, 1730, 1738, 1411, 2625, 2217, 1813, 1285, 1016, 2343, 1970, 1595, 2357, 1730, 1460, 2343, 1578, 1402, 1403, 1510, 2038, 2056, 1931, 1486, 1802, 1840, 2153, 2504, 1829, 2475, 2684, 2626, 2988, 2703, 2566, 935, 1338, 1820, 2107, 2330, 1740, 1544, 2320, 2368, 1951, 2153, 2051, 1261, 2416, 1790, 1652, 1988, 1322, 2036, 1802, 2030, 1874, 2191, 1768, 2287, 1758, 1384, 1496, 1424, 1465, 1769, 1722, 1654, 2309, 1414, 1476, 2175, 2314, 2522, 2851, 1996, 1621, 1630, 1852, 1367, 2189, 2207, 1795, 1473, 2125, 2086, 1772, 1242, 1416, 1399, 2059, 3273, 2130, 2204, 2623, 2162, 1671, 2244, 1598, 1624, 1891, 1223, 1767, 1808, 1863, 1875, 1972, 1575, 1733, 2020, 1596, 1884, 2375, 1647, 2196, 2340, 2050, 1863],
            eaId: '391e0e6a-4b88-418b-aade-57734a297339',
            vmafReduct: [[0, 85, 92], [57, 84, 91], [114, 77, 87], [171, 67, 93], [228, 84, 92], [285, 82, 91], [342, 81, 91], [399, 86, 95], [456, 87, 95], [513, 83, 92], [570, 79, 89], [627, 75, 86], [684, 75, 88], [741, 80, 93], [798, 89, 93], [855, 85, 94], [912, 83, 90], [969, 76, 89], [1026, 77, 90], [1083, 73, 89], [1140, 82, 92], [1197, 82, 94], [1254, 89, 93], [1311, 86, 93], [1368, 86, 93], [1425, 86, 92], [1482, 75, 90], [1539, 76, 89], [1596, 81, 92], [1653, 79, 92], [1710, 75, 87], [1767, 72, 90], [1824, 79, 93], [1881, 84, 94], [1938, 85, 92], [1995, 79, 92], [2052, 83, 92], [2109, 87, 93], [2166, 88, 94], [2223, 85, 90], [2280, 73, 91], [2337, 65, 90], [2394, 82, 93], [2451, 85, 93], [2508, 88, 93], [2565, 64, 92], [2622, 89, 93], [2679, 89, 93], [2736, 88, 94], [2793, 90, 94], [2850, 87, 93], [2907, 88, 94], [2964, 91, 94], [3021, 84, 91], [3078, 86, 93], [3135, 86, 94], [3192, 88, 94], [3249, 83, 93], [3306, 88, 95], [3363, 85, 95], [3420, 89, 95], [3477, 89, 92], [3534, 84, 93], [3591, 83, 93], [3648, 83, 90], [3705, 83, 93], [3762, 87, 94], [3819, 86, 94], [3876, 86, 93], [3933, 85, 93], [3990, 84, 94], [4047, 79, 91], [4104, 73, 85], [4161, 76, 86], [4218, 79, 90], [4275, 85, 94], [4332, 88, 94], [4389, 86, 95], [4446, 79, 92], [4503, 80, 92], [4560, 86, 93], [4617, 84, 94], [4674, 85, 93], [4731, 81, 94], [4788, 81, 93], [4845, 80, 91], [4902, 81, 93], [4958, 78, 91], [5014, 84, 91], [5070, 73, 90], [5126, 66, 82], [5182, 65, 85], [5238, 79, 90], [5294, 81, 90], [5350, 77, 92], [5406, 79, 91], [5462, 79, 90], [5518, 79, 94], [5574, 84, 91], [5630, 85, 100]]
        },
        {
            id: '4d3dedd1-57cf-4893-8f79-ca4859fbc55a',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx3/egress_capture__10_9_24_10__232_10_10_6__49410.ts',
            smx: '3',
            measurementPointId: '043dda0b-fce1-4f20-bfba-87d5208cdd27',
            bitrateList: [1662, 856, 1010, 599, 1363, 987, 1529, 1322, 1439, 1179, 1007, 1310, 1272, 1152, 1001, 1652, 1522, 1133, 1224, 1192, 1086, 1307, 1479, 992, 1141, 1285, 1115, 1479, 1818, 935, 2034, 1280, 1700, 1417, 1774, 1435, 1425, 1152, 1551, 1108, 987, 1307, 1033, 967, 1295, 1433, 968, 1276, 1118, 1227, 1117, 1157, 1640, 1489, 990, 1336, 1082, 1504, 1141, 1031, 1308, 869, 973, 839, 1132, 1198, 1499, 2059, 1203, 1178, 1310, 1500, 1113, 1190, 1735, 1093, 1203, 1669, 1181, 1177, 1004, 1473, 1615, 2015, 1748, 1147, 1464, 1572, 1249, 1342, 1555, 1393, 1118, 2048, 1999, 1615, 1775, 1075, 1271, 1300, 1703, 1898, 1436, 1362, 1226, 1193, 1320, 1325, 1455, 1241, 1122, 1453, 1298, 1455, 741, 1121, 1636, 1002, 1280],
            eaId: '448692e1-d175-4c9e-b231-0be215755f5b',
            vmafReduct: [[0, 86, 96], [58, 90, 95], [116, 85, 93], [174, 77, 93], [232, 84, 93], [289, 86, 96], [346, 90, 95], [403, 91, 95], [460, 90, 95], [517, 92, 95], [574, 91, 95], [631, 90, 95], [688, 91, 95], [745, 92, 97], [802, 94, 96], [859, 92, 96], [916, 85, 95], [973, 81, 92], [1030, 84, 91], [1087, 79, 90], [1144, 85, 93], [1201, 85, 97], [1258, 90, 97], [1315, 91, 96], [1372, 91, 95], [1429, 89, 94], [1486, 89, 94], [1543, 88, 95], [1600, 91, 95], [1657, 91, 94], [1714, 88, 92], [1771, 88, 93], [1828, 82, 92], [1885, 87, 95], [1942, 85, 95], [1999, 87, 94], [2056, 83, 94], [2113, 85, 95], [2170, 86, 94], [2227, 86, 92], [2284, 77, 92], [2341, 72, 90], [2398, 82, 93], [2455, 88, 92], [2512, 90, 93], [2569, 89, 94], [2626, 89, 94], [2683, 90, 94], [2740, 90, 95], [2797, 72, 91], [2854, 82, 94], [2911, 91, 94], [2968, 89, 95], [3025, 80, 92], [3082, 76, 92], [3139, 81, 89], [3196, 86, 93], [3253, 88, 94], [3310, 90, 95], [3367, 89, 95], [3424, 90, 95], [3481, 89, 95], [3538, 90, 95], [3595, 85, 94], [3652, 87, 93], [3709, 87, 93], [3766, 91, 96], [3823, 86, 94], [3880, 86, 93], [3937, 87, 96], [3994, 89, 95], [4051, 90, 95], [4108, 73, 93], [4165, 74, 87], [4222, 77, 92], [4279, 85, 93], [4336, 85, 93], [4393, 69, 94], [4450, 91, 93], [4507, 86, 92], [4564, 83, 94], [4621, 91, 94], [4678, 91, 94], [4735, 90, 93], [4792, 88, 93], [4849, 91, 95], [4906, 91, 95], [4963, 90, 94], [5020, 88, 95], [5077, 89, 94], [5134, 86, 95], [5191, 91, 94], [5248, 86, 94], [5305, 89, 95], [5362, 91, 95], [5419, 91, 95], [5476, 92, 96], [5533, 91, 95], [5590, 89, 95], [5647, 88, 100]]
        },
        {
            id: '2af49972-7ee5-4f43-b63e-500633a67e78',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx3/egress_capture__10_9_24_10__232_10_10_7__49410.ts',
            smx: '3',
            measurementPointId: '6d410170-c364-4185-b5aa-f87a8954f781',
            bitrateList: [1712, 1867, 1814, 3013, 1022, 1335, 2064, 2194, 2087, 1551, 2597, 2017, 2674, 1020, 461, 1033, 2093, 2522, 2265, 1887, 1411, 1259, 1000, 1907, 2065, 2119, 2219, 1887, 1491, 1891, 2040, 1114, 1411, 1100, 710, 1284, 3312, 2073, 2260, 1877, 1657, 1951, 1895, 552, 836, 870, 1845, 2172, 1678, 2043, 2599, 2060, 2213, 1961, 3063, 2061, 1528, 2446, 1788, 3105, 3167, 3131, 2750, 2456, 2401, 3146, 2312, 2315, 1919, 2725, 3786, 2781, 2792, 1783, 2014, 2189, 2016, 2991, 2711, 3235, 3088, 1354, 2007, 2140, 3595, 1807, 2121, 2989, 4715, 3025, 2560, 2407, 1778, 1763, 2071, 1358, 2017, 2143, 2123, 3260, 2076, 1808, 2998, 1619, 2085, 2060, 2458, 1902, 1888, 2406, 1611, 3305, 1775, 1493, 1578, 1473, 2127, 2100, 2663],
            eaId: 'c63e04e9-4a43-4f9e-8416-af278e378fb1',
            vmafReduct: [[0, 86, 95], [58, 74, 95], [116, 78, 93], [174, 75, 91], [232, 72, 93], [290, 75, 92], [348, 67, 92], [406, 84, 93], [464, 86, 94], [522, 85, 94], [580, 82, 94], [638, 74, 95], [696, 79, 92], [754, 86, 95], [812, 84, 95], [870, 87, 94], [928, 86, 94], [986, 68, 91], [1044, 76, 92], [1102, 68, 92], [1160, 76, 92], [1218, 83, 94], [1276, 88, 95], [1334, 77, 94], [1392, 83, 95], [1450, 83, 96], [1507, 73, 96], [1564, 79, 92], [1621, 79, 89], [1678, 87, 92], [1735, 79, 91], [1792, 77, 92], [1849, 61, 90], [1906, 82, 94], [1963, 82, 94], [2020, 82, 96], [2077, 83, 92], [2134, 81, 93], [2191, 70, 93], [2248, 76, 88], [2305, 71, 91], [2362, 70, 89], [2419, 62, 91], [2476, 61, 94], [2533, 82, 92], [2590, 85, 95], [2647, 77, 94], [2704, 75, 91], [2761, 73, 92], [2818, 76, 90], [2875, 66, 89], [2932, 77, 91], [2989, 78, 91], [3046, 74, 90], [3103, 71, 90], [3160, 71, 93], [3217, 65, 93], [3274, 55, 88], [3331, 80, 92], [3388, 87, 93], [3445, 87, 93], [3502, 82, 93], [3559, 80, 93], [3616, 82, 94], [3673, 83, 93], [3730, 66, 93], [3787, 60, 94], [3844, 83, 95], [3901, 79, 93], [3958, 75, 95], [4015, 85, 94], [4072, 75, 94], [4129, 54, 89], [4186, 68, 87], [4243, 67, 89], [4300, 81, 93], [4357, 91, 94], [4414, 90, 95], [4471, 89, 94], [4528, 82, 93], [4585, 81, 93], [4642, 88, 94], [4699, 89, 95], [4756, 90, 94], [4813, 87, 94], [4870, 90, 95], [4927, 81, 93], [4984, 69, 91], [5041, 83, 94], [5098, 83, 94], [5155, 81, 93], [5212, 77, 92], [5269, 59, 89], [5326, 79, 93], [5383, 70, 93], [5440, 80, 94], [5497, 81, 93], [5554, 72, 94], [5611, 77, 92], [5668, 63, 100]]
        },
        {
            id: '0d16c4b5-56b8-409c-b836-852be6d094fb',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx3/egress_capture__10_9_24_10__232_10_10_8__49410.ts',
            smx: '3',
            measurementPointId: '79f01a6c-3b92-442c-ae80-daf3252566e0',
            bitrateList: [2461, 2793, 2406, 3180, 2562, 2269, 2291, 3564, 2389, 2603, 2140, 2073, 1467, 2685, 4158, 2893, 2592, 2965, 2305, 3457, 2530, 2754, 3457, 2487, 1989, 1928, 1903, 1795, 1582, 2078, 2044, 1767, 1598, 2287, 2794, 2017, 1841, 2450, 2217, 3087, 2270, 2317, 2959, 2288, 3319, 3907, 2453, 2348, 2856, 2600, 2514, 2179, 2898, 2151, 1306, 2513, 1995, 1618, 1305, 1498, 1203, 1611, 2528, 1983, 2469, 2037, 1895, 1569, 2193, 1419, 1347, 1092, 1683, 1825, 1602, 1862, 1571, 1625, 1511, 1821, 1329, 2122, 1689, 1865, 1217, 1568, 1632, 1381, 936, 1219, 1034, 1998, 2099, 1703, 1462, 1825, 1385, 1653, 2305, 2074, 1778, 1787, 1993, 1824, 2349, 2388, 2079, 1891, 2811, 1854, 2263, 2088, 2191, 2269, 2716, 2683, 1893, 2241, 1929],
            eaId: '5348080e-4641-44ab-8a7c-98e9a6f7a6c4',
            vmafReduct: [[0, 91, 95], [58, 87, 95], [116, 84, 94], [174, 79, 92], [232, 81, 94], [290, 74, 94], [348, 89, 95], [406, 92, 95], [464, 94, 96], [522, 93, 96], [580, 93, 96], [638, 91, 94], [696, 90, 95], [754, 92, 97], [812, 92, 96], [870, 92, 97], [928, 91, 96], [986, 90, 95], [1044, 89, 95], [1102, 89, 95], [1159, 90, 95], [1216, 92, 96], [1273, 93, 95], [1330, 94, 95], [1387, 92, 96], [1444, 92, 96], [1501, 92, 95], [1558, 91, 95], [1615, 94, 96], [1672, 92, 95], [1729, 91, 95], [1786, 91, 94], [1843, 91, 94], [1900, 87, 95], [1957, 90, 95], [2014, 81, 94], [2071, 89, 95], [2128, 84, 96], [2185, 93, 96], [2242, 86, 95], [2299, 83, 95], [2356, 91, 95], [2413, 90, 96], [2470, 86, 96], [2527, 92, 95], [2584, 90, 94], [2641, 88, 95], [2698, 87, 95], [2755, 94, 96], [2812, 91, 95], [2869, 88, 94], [2926, 78, 94], [2983, 83, 94], [3040, 85, 95], [3097, 91, 96], [3154, 89, 95], [3211, 89, 96], [3268, 87, 95], [3325, 94, 95], [3382, 92, 96], [3439, 93, 96], [3496, 93, 96], [3553, 88, 96], [3610, 90, 96], [3667, 91, 96], [3724, 92, 95], [3781, 90, 97], [3838, 91, 96], [3895, 92, 96], [3952, 92, 96], [4009, 91, 96], [4066, 91, 94], [4123, 90, 94], [4180, 88, 95], [4237, 91, 95], [4294, 94, 96], [4351, 95, 96], [4408, 95, 97], [4465, 92, 96], [4522, 91, 96], [4579, 91, 96], [4636, 86, 96], [4693, 90, 95], [4750, 83, 94], [4807, 82, 95], [4864, 88, 95], [4921, 72, 93], [4978, 78, 91], [5035, 87, 93], [5092, 89, 93], [5149, 79, 94], [5206, 81, 93], [5263, 81, 93], [5320, 86, 95], [5377, 86, 95], [5434, 89, 95], [5491, 86, 95], [5548, 89, 94], [5605, 81, 94], [5662, 88, 100]]
        }
    ]

    const smx10List = [
        {
            id: 'c1ecf46e-9510-421c-b8f1-07893b1a7da7',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx10/egress_capture__10_9_24_10__232_10_10_1__49410.ts',
            smx: '10',
            measurementPointId: 'daa82064-ca15-4022-83c5-171bb9b9179a',
            bitrateList: [14633, 13206, 15385, 13857, 14382, 13186, 23701, 12625, 13071, 12374, 15165, 16062, 13120, 14197, 12908, 13918, 16862, 14907, 13780, 16466, 12984, 13222, 14561, 14452, 21173, 12437, 15635, 14805, 13258, 13053, 15070, 14228, 10866, 15547, 14509, 14048, 16562, 16386, 17221, 13417, 13064, 14883, 13989, 17459, 15119, 14759, 16356, 13567, 10722, 17143, 12513, 16527, 14050, 12972, 16197, 14268, 13258, 15813, 16407, 15054, 14801, 13486, 8944, 19265, 15133, 13279, 18986, 12858, 13181, 12722, 15948, 15629, 17610, 12749, 10985, 14282, 18994, 12325, 12816, 15271, 15132, 12381, 16080, 17122, 12973, 13642, 15981, 15675, 14998, 16889, 10733, 13130, 16407, 17743, 12370, 14674, 14284, 14344, 14231, 15538, 13886, 18948, 13344, 10347, 14699, 14445, 18951, 17524, 12322, 12081, 13706, 12598, 18428, 12649, 17761, 13322, 15487, 12339, 14379]
        },
        {
            id: '820769e8-d900-47a0-94b4-772de3e7804f',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx10/egress_capture__10_9_24_10__232_10_10_2__49410.ts',
            smx: '10',
            measurementPointId: '81ece6a3-c40d-4c4c-955d-90841bf22891',
            bitrateList: [13508, 16357, 15454, 14321, 11418, 19483, 11620, 14425, 16046, 13595, 15406, 10616, 16633, 15057, 15524, 14096, 13611, 15133, 16594, 16239, 14059, 11659, 14439, 14548, 13637, 13857, 16390, 18593, 12599, 13588, 16594, 14041, 13822, 13350, 15916, 14977, 17023, 16762, 7388, 19660, 15102, 12519, 10150, 16162, 19184, 15268, 8635, 16150, 14077, 15798, 15528, 17316, 13301, 13315, 14463, 14492, 14989, 13513, 13137, 19103, 13012, 13360, 14924, 14275, 13898, 14020, 17661, 17119, 13980, 14119, 14800, 14011, 14867, 14368, 13482, 14638, 13892, 16144, 14074, 14193, 13307, 17995, 10804, 13647, 15750, 14647, 15415, 18563, 16402, 15100, 13305, 14569, 13478, 14108, 15080, 15223, 13023, 15581, 13878, 17365, 13716, 13820, 12347, 12624, 15456, 13940, 15907, 15472, 13017, 17652, 14263, 11926, 15556, 14958, 13520, 13916, 16301, 12332, 15832]
        },
        {
            id: 'ec2ec8c0-cd0c-4b36-b19b-279870831c0b',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx10/egress_capture__10_9_24_10__232_10_10_3__49410.ts',
            smx: '10',
            measurementPointId: 'ab3851f1-5502-4514-8c70-a8b1bc5ac247',
            bitrateList: [24103, 10409, 9609, 13833, 14062, 16282, 14204, 15011, 14536, 19347, 14914, 13899, 10098, 13454, 15921, 14654, 19102, 15149, 12444, 15441, 12597, 13608, 16617, 12110, 14086, 15858, 15428, 14831, 14218, 15443, 14926, 11430, 14774, 16176, 17550, 12701, 17407, 12529, 13201, 14175, 12583, 19482, 16366, 12880, 13707, 13880, 12259, 15808, 13721, 13996, 17038, 15148, 15924, 14850, 16164, 12222, 12990, 13814, 14551, 18675, 12735, 13804, 15577, 14839, 13577, 13873, 14114, 16491, 19898, 12369, 12844, 12104, 15390, 14010, 15667, 12712, 14904, 16202, 11394, 19013, 12964, 16587, 13955, 10544, 17123, 17058, 11777, 18426, 16212, 12952, 15778, 14355, 13648, 15151, 14326, 13739, 13564, 16949, 13291, 13017, 15607, 12375, 19697, 12057, 17170, 12000, 13884, 17850, 16928, 11810, 14440, 14392, 11499, 15244, 13001, 22552, 13471, 11662, 15756]
        },
        {
            id: '1ae6340c-0cb5-4239-9ff8-bad614b31217',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx10/egress_capture__10_9_24_10__232_10_10_4__49410.ts',
            smx: '10',
            measurementPointId: '8506146d-88c1-4fa7-ba41-3608dbfdbca2',
            bitrateList: [16478, 15201, 13646, 13917, 15044, 14827, 14544, 14601, 13736, 15309, 14776, 14147, 15514, 15293, 14714, 14129, 13724, 14118, 17999, 13340, 13229, 15070, 14250, 14516, 14672, 14123, 13333, 16194, 14903, 14940, 15749, 15244, 12967, 15174, 14778, 13938, 15179, 15588, 12961, 15927, 14104, 13802, 14785, 15090, 13525, 17103, 14740, 14152, 13909, 13690, 15000, 14901, 14488, 14971, 15631, 15730, 12245, 15611, 14524, 14029, 15831, 13625, 13607, 16253, 15108, 14659, 14051, 15096, 13548, 14786, 13823, 15174, 14711, 15494, 13528, 14160, 15048, 15235, 15826, 13992, 14513, 15938, 14125, 13738, 14145, 13816, 14766, 15749, 13990, 14742, 16373, 14018, 13678, 13550, 15700, 14252, 16134, 14970, 13317, 15007, 15579, 13485, 14409, 15570, 14242, 15066, 15568, 13959, 13868, 15423, 14538, 15177, 13847, 13702, 15162, 15408, 14310, 14943, 14664]
        },
        {
            id: '3b08bb46-dced-4438-b534-198508b23511',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx10/egress_capture__10_9_24_10__232_10_10_5__49410.ts',
            smx: '10',
            measurementPointId: 'c1f62728-efe9-47b6-a18c-0b8612f28baa',
            bitrateList: [1531, 1635, 2077, 1873, 1945, 1431, 1903, 1804, 1518, 1469, 1090, 1425, 1910, 1698, 1492, 2464, 1387, 1664, 1247, 1214, 1322, 1323, 1405, 1233, 1339, 1436, 1684, 1309, 1670, 2090, 1697, 1706, 2118, 1670, 1806, 1973, 1973, 1853, 993, 1318, 1742, 1619, 2178, 1585, 1714, 2385, 1845, 1713, 2615, 1228, 1903, 1972, 1564, 1380, 1575, 1768, 1602, 1424, 2031, 1600, 1675, 2142, 1374, 1418, 1603, 1408, 1501, 1601, 1158, 1286, 1787, 1369, 1019, 1120, 1399, 1550, 1934, 1694, 1579, 1374, 1408, 1379, 1089, 1876, 1515, 1610, 1551, 1565, 1408, 1250, 1239, 1060, 1429, 2001, 1800, 1180, 1815, 2162, 1826, 1408, 1731, 1353, 1276, 1457, 1477, 1368, 1730, 1427, 1340, 1502, 1545, 1393, 1537, 1131, 1576, 2226, 1541, 2294, 1811],
            eaId: '3c1f3022-f8de-4b49-bbe9-c51af01ce5bd',
            vmafReduct: [[0, 80, 91], [58, 79, 92], [116, 79, 92], [174, 77, 88], [232, 78, 90], [290, 70, 87], [348, 86, 91], [406, 83, 90], [464, 79, 91], [522, 86, 93], [580, 79, 93], [638, 78, 87], [696, 77, 87], [754, 75, 86], [812, 77, 88], [870, 76, 90], [928, 83, 91], [985, 76, 91], [1042, 79, 89], [1099, 76, 88], [1156, 61, 89], [1213, 73, 87], [1270, 76, 93], [1327, 83, 92], [1384, 82, 89], [1441, 79, 90], [1498, 79, 88], [1555, 82, 90], [1612, 77, 89], [1669, 75, 88], [1726, 78, 89], [1783, 74, 89], [1840, 75, 89], [1897, 65, 91], [1954, 84, 91], [2011, 82, 93], [2068, 85, 92], [2125, 85, 91], [2182, 77, 91], [2239, 89, 93], [2296, 84, 93], [2353, 82, 89], [2410, 61, 87], [2467, 71, 92], [2524, 84, 91], [2581, 84, 91], [2638, 83, 93], [2695, 87, 92], [2752, 86, 92], [2809, 82, 94], [2866, 88, 93], [2923, 89, 94], [2980, 88, 94], [3037, 89, 93], [3094, 86, 93], [3151, 82, 91], [3208, 81, 94], [3265, 88, 92], [3322, 80, 92], [3379, 78, 91], [3436, 80, 92], [3493, 80, 93], [3550, 83, 92], [3607, 84, 90], [3664, 84, 89], [3721, 83, 93], [3778, 79, 91], [3835, 83, 92], [3892, 83, 93], [3949, 85, 93], [4006, 84, 93], [4063, 81, 93], [4120, 81, 90], [4177, 67, 90], [4234, 70, 83], [4291, 71, 89], [4348, 78, 88], [4405, 76, 92], [4462, 28, 90], [4519, 79, 92], [4576, 76, 89], [4633, 81, 89], [4690, 84, 92], [4747, 84, 92], [4804, 86, 91], [4861, 83, 92], [4918, 49, 91], [4975, 61, 89], [5032, 70, 91], [5089, 75, 87], [5146, 75, 87], [5203, 68, 84], [5260, 59, 84], [5317, 67, 89], [5374, 53, 88], [5431, 77, 89], [5488, 85, 88], [5545, 75, 90], [5602, 78, 90], [5659, 74, 100]]
        },
        {
            id: 'db762dba-584d-4a0c-aa61-421ebaf5e77c',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx10/egress_capture__10_9_24_10__232_10_10_6__49410.ts',
            smx: '10',
            measurementPointId: '043dda0b-fce1-4f20-bfba-87d5208cdd27',
            bitrateList: [1590, 1338, 1460, 1054, 786, 756, 1049, 1084, 1398, 1219, 1303, 1218, 859, 1314, 1018, 1318, 1122, 1315, 1135, 1265, 1105, 1079, 1010, 997, 894, 983, 869, 1373, 975, 1260, 1390, 634, 1461, 1103, 1007, 1421, 1312, 1645, 1090, 1343, 1067, 1439, 1135, 995, 1175, 966, 1412, 1255, 948, 1320, 1017, 1214, 1085, 1063, 1347, 1654, 1112, 854, 1345, 1131, 1128, 989, 924, 1043, 1034, 787, 901, 1262, 1333, 1588, 1348, 969, 1117, 989, 1333, 1173, 1055, 1339, 1103, 1053, 1284, 1104, 897, 1004, 1639, 1672, 1236, 1316, 1292, 1129, 1243, 1215, 1125, 1287, 1062, 1390, 1996, 1369, 1452, 1266, 976, 1087, 1393, 1343, 1282, 1427, 1124, 953, 1172, 1463, 1497, 979, 916, 1541, 1289, 1317, 599, 1051, 1489],
            eaId: '01218c70-e1aa-411c-aa71-cf9c6eea6bb8',
            vmafReduct: [[0, 85, 94], [58, 87, 94], [116, 90, 94], [174, 89, 94], [232, 82, 93], [290, 89, 93], [347, 86, 92], [404, 87, 95], [461, 90, 95], [518, 91, 95], [575, 89, 94], [632, 90, 95], [689, 89, 94], [746, 90, 94], [803, 91, 95], [860, 92, 95], [917, 91, 95], [974, 91, 95], [1031, 80, 94], [1088, 81, 92], [1145, 77, 90], [1202, 75, 89], [1259, 68, 97], [1316, 80, 96], [1373, 88, 95], [1430, 89, 95], [1487, 87, 94], [1544, 87, 93], [1601, 89, 93], [1658, 89, 95], [1715, 90, 95], [1772, 89, 93], [1829, 88, 93], [1886, 90, 94], [1943, 78, 92], [2000, 88, 95], [2057, 86, 94], [2114, 88, 94], [2171, 86, 93], [2228, 84, 95], [2285, 88, 93], [2342, 83, 92], [2399, 75, 91], [2456, 75, 91], [2513, 86, 94], [2570, 84, 92], [2627, 88, 93], [2684, 88, 93], [2741, 89, 94], [2798, 89, 94], [2855, 89, 95], [2912, 71, 91], [2969, 83, 94], [3026, 89, 95], [3083, 85, 94], [3140, 81, 90], [3197, 68, 91], [3254, 80, 89], [3311, 82, 93], [3368, 87, 93], [3425, 88, 94], [3482, 88, 95], [3539, 89, 94], [3596, 90, 94], [3653, 85, 94], [3710, 85, 93], [3767, 85, 92], [3824, 84, 93], [3881, 90, 94], [3938, 86, 93], [3995, 85, 93], [4052, 88, 95], [4109, 87, 95], [4166, 85, 95], [4223, 74, 85], [4280, 73, 85], [4337, 72, 92], [4394, 85, 91], [4451, 89, 92], [4508, 87, 92], [4565, 88, 91], [4622, 89, 92], [4679, 86, 93], [4736, 89, 93], [4793, 86, 94], [4850, 88, 92], [4907, 88, 91], [4964, 89, 95], [5021, 91, 94], [5078, 88, 94], [5135, 89, 95], [5192, 88, 94], [5249, 87, 94], [5306, 91, 94], [5363, 86, 93], [5420, 88, 95], [5477, 92, 95], [5534, 91, 95], [5591, 91, 96], [5648, 91, 100]]
        },
        {
            id: '3ba04574-e47d-40b5-8cc7-259e7f9a45da',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx10/egress_capture__10_9_24_10__232_10_10_7__49410.ts',
            smx: '10',
            measurementPointId: '6d410170-c364-4185-b5aa-f87a8954f781',
            bitrateList: [2471, 1448, 1865, 1704, 1787, 3003, 1369, 940, 1525, 1900, 2180, 1832, 2210, 1939, 2199, 1391, 493, 459, 1736, 1876, 2055, 2030, 1256, 1199, 698, 1281, 1919, 1800, 2134, 1561, 1341, 1363, 1413, 1190, 918, 1179, 651, 694, 2498, 2512, 1827, 1789, 1328, 2096, 1847, 1142, 783, 483, 1728, 2048, 1566, 1749, 2077, 2240, 1932, 1814, 2093, 2338, 1448, 1927, 1308, 2492, 2606, 2499, 2552, 2282, 2295, 2978, 1912, 2035, 1878, 1840, 2644, 2685, 2325, 1672, 1361, 1756, 1403, 2010, 2196, 2255, 2776, 1333, 1660, 1506, 3141, 1825, 1680, 2018, 3805, 3244, 1905, 1877, 1578, 1244, 1552, 1148, 1776, 1757, 1551, 2344, 1757, 1552, 1922, 1817, 1344, 1857, 1710, 1782, 1448, 2137, 1944, 2891, 1688, 1312, 1541, 1648, 1994],
            eaId: '35370ac9-f287-4f1a-a970-4f5b5c6b798e',
            vmafReduct: [[0, 46, 90], [58, 83, 94], [115, 88, 94], [172, 71, 93], [229, 75, 91], [286, 77, 92], [343, 77, 92], [400, 54, 89], [457, 74, 90], [514, 82, 94], [571, 86, 93], [628, 82, 93], [685, 78, 91], [742, 80, 95], [799, 81, 93], [856, 84, 94], [913, 83, 93], [970, 83, 93], [1027, 85, 93], [1084, 75, 90], [1141, 77, 89], [1198, 61, 92], [1255, 72, 91], [1312, 76, 92], [1369, 83, 94], [1426, 77, 93], [1483, 82, 94], [1540, 83, 96], [1597, 73, 95], [1654, 67, 90], [1711, 78, 91], [1768, 87, 91], [1825, 75, 90], [1882, 84, 91], [1939, 78, 91], [1996, 86, 94], [2053, 82, 94], [2110, 80, 96], [2167, 81, 90], [2224, 82, 93], [2281, 82, 92], [2338, 68, 87], [2395, 73, 89], [2452, 63, 90], [2509, 65, 89], [2566, 41, 93], [2623, 86, 92], [2680, 83, 92], [2737, 71, 93], [2794, 68, 89], [2851, 74, 90], [2908, 66, 87], [2965, 63, 87], [3022, 73, 89], [3079, 69, 90], [3136, 72, 89], [3193, 61, 88], [3250, 71, 91], [3307, 54, 90], [3364, 51, 85], [3421, 75, 91], [3478, 80, 91], [3535, 82, 92], [3592, 75, 91], [3649, 78, 92], [3706, 78, 91], [3763, 78, 90], [3820, 68, 90], [3877, 63, 93], [3934, 77, 93], [3991, 74, 93], [4048, 70, 94], [4105, 87, 93], [4162, 66, 92], [4219, 45, 86], [4276, 63, 83], [4333, 61, 86], [4390, 79, 92], [4447, 89, 92], [4504, 86, 92], [4561, 87, 91], [4618, 82, 92], [4675, 81, 91], [4732, 83, 93], [4789, 86, 93], [4846, 87, 92], [4903, 79, 93], [4960, 86, 91], [5017, 82, 91], [5074, 58, 91], [5131, 80, 91], [5188, 76, 92], [5245, 78, 92], [5302, 75, 93], [5359, 63, 91], [5416, 68, 90], [5473, 77, 93], [5530, 83, 94], [5587, 82, 92], [5644, 0, 100]]
        },
        {
            id: '835f2ae0-567b-4b52-bbfb-a58c3066aec8',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx10/egress_capture__10_9_24_10__232_10_10_8__49410.ts',
            smx: '10',
            measurementPointId: '79f01a6c-3b92-442c-ae80-daf3252566e0',
            bitrateList: [2774, 2588, 2223, 2735, 3056, 2281, 2956, 3612, 3078, 3116, 2634, 2706, 3154, 2607, 2215, 2809, 3542, 3892, 3760, 3269, 2990, 2917, 3338, 3775, 4370, 3749, 3109, 3246, 2647, 2605, 3238, 3284, 2934, 3197, 3389, 3360, 3601, 3010, 2521, 2614, 3031, 2548, 2739, 2628, 2423, 3378, 3448, 3184, 3307, 2982, 2781, 3231, 2298, 2529, 2460, 2583, 2598, 2615, 3025, 2331, 2600, 2414, 2174, 2672, 2313, 2542, 2664, 2285, 2375, 2832, 2464, 3017, 2844, 2488, 2919, 3143, 2859, 3159, 2922, 2702, 3037, 2683, 2595, 2914, 3176, 2370, 2284, 2835, 2667, 2280, 1794, 1679, 2373, 3060, 2817, 3391, 2852, 2335, 2377, 3001, 2750, 2989, 3025, 2778, 2859, 3221, 3077, 2872, 3269, 3001, 2563, 3158, 3058, 2290, 2657, 2844, 2743, 3145, 2745],
            eaId: '990ad413-19b5-4862-ad65-0fb0cf7fcb2c',
            vmafReduct: [[0, 70, 91], [58, 85, 97], [116, 81, 99], [173, 72, 98], [230, 83, 96], [287, 77, 100], [344, 85, 98], [401, 67, 99], [458, 78, 100], [515, 87, 100], [572, 89, 100], [629, 84, 99], [686, 75, 97], [743, 77, 99], [800, 83, 100], [857, 80, 100], [914, 88, 100], [971, 82, 100], [1028, 95, 100], [1085, 90, 100], [1142, 86, 100], [1199, 84, 100], [1256, 87, 100], [1313, 86, 100], [1370, 68, 100], [1427, 74, 98], [1484, 74, 97], [1541, 79, 98], [1598, 80, 99], [1655, 75, 97], [1712, 83, 98], [1769, 79, 98], [1826, 81, 96], [1883, 76, 93], [1940, 77, 95], [1997, 76, 97], [2054, 80, 99], [2111, 83, 100], [2168, 78, 99], [2225, 84, 100], [2282, 78, 96], [2339, 79, 94], [2396, 77, 95], [2453, 76, 98], [2510, 67, 98], [2567, 62, 98], [2624, 73, 98], [2681, 72, 94], [2738, 75, 96], [2795, 74, 99], [2852, 80, 95], [2909, 76, 93], [2966, 67, 98], [3023, 81, 100], [3080, 81, 98], [3137, 83, 98], [3194, 78, 98], [3251, 82, 99], [3308, 75, 99], [3365, 78, 96], [3422, 74, 98], [3479, 71, 98], [3536, 76, 97], [3593, 76, 99], [3650, 80, 96], [3707, 76, 98], [3764, 75, 100], [3821, 75, 94], [3878, 79, 97], [3935, 83, 95], [3992, 80, 95], [4049, 76, 96], [4106, 85, 99], [4163, 81, 98], [4220, 77, 98], [4277, 73, 95], [4334, 77, 100], [4391, 78, 100], [4448, 71, 97], [4505, 69, 100], [4562, 81, 100], [4619, 80, 100], [4676, 93, 100], [4733, 88, 100], [4790, 88, 100], [4847, 80, 100], [4904, 72, 100], [4961, 69, 100], [5018, 73, 100], [5075, 69, 98], [5132, 68, 97], [5189, 75, 100], [5246, 69, 97], [5303, 77, 97], [5360, 73, 94], [5417, 74, 98], [5474, 83, 98], [5531, 83, 97], [5588, 86, 97], [5645, 79, 100]]
        }
        ]

    const smx11List = [
        {
            id: '42c4264a-6eb7-4d08-884f-1c5ffaad8547',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx11/egress_capture__10_9_24_10__232_10_10_1__49410.ts',
            smx: '11',
            measurementPointId: 'daa82064-ca15-4022-83c5-171bb9b9179a',
            bitrateList: [15573, 12593, 15920, 13614, 13390, 15862, 19192, 15059, 13074, 12522, 14234, 17462, 12398, 14208, 13401, 14093, 17116, 14052, 14251, 16204, 13139, 13005, 15024, 16435, 18398, 12175, 15191, 16141, 12922, 12877, 15523, 14014, 11240, 15628, 14164, 13886, 18692, 15931, 15767, 12502, 13856, 15102, 14413, 16951, 15857, 13382, 16876, 10501, 15987, 14790, 12595, 17567, 13388, 12190, 16837, 14043, 14196, 16290, 15371, 14337, 15700, 11484, 11567, 19051, 14270, 13179, 19504, 12480, 12875, 12726, 16219, 15770, 17427, 12190, 11424, 16209, 17241, 12531, 12376, 16599, 13621, 12692, 16242, 16713, 13076, 14263, 15897, 16491, 14733, 15378, 10415, 14730, 17463, 15278, 13080, 14735, 13901, 15156, 14043, 15055, 14434, 18954, 12282, 10357, 15478, 15342, 19906, 15223, 12238, 12166, 11962, 15695, 18235, 11692, 17757, 13832, 14496, 12460, 14490]
        },
        {
            id: '75a3dc9f-2382-4b17-a63a-ee6b941df967',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx11/egress_capture__10_9_24_10__232_10_10_2__49410.ts',
            smx: '11',
            measurementPointId: '81ece6a3-c40d-4c4c-955d-90841bf22891',
            bitrateList: [13508, 16357, 15454, 14321, 11418, 19483, 11620, 14425, 16046, 13595, 15406, 10616, 16633, 15057, 15524, 14096, 13611, 15133, 16594, 16239, 14059, 11659, 14439, 14548, 13637, 13857, 16390, 18593, 12599, 13588, 16594, 14041, 13822, 13350, 15916, 14977, 17023, 16762, 7388, 19660, 15102, 12519, 10150, 16162, 19184, 15268, 8635, 16150, 14077, 15798, 15528, 17316, 13301, 13315, 14463, 14492, 14989, 13513, 13137, 19103, 13012, 13360, 14924, 14275, 13898, 14020, 17661, 17119, 13980, 14119, 14800, 14011, 14867, 14368, 13482, 14638, 13892, 16144, 14074, 14193, 13307, 17995, 10804, 13647, 15750, 14647, 15415, 18563, 16402, 15100, 13305, 14569, 13478, 14108, 15080, 15223, 13023, 15581, 13878, 17365, 13716, 13820, 12347, 12624, 15456, 13940, 15907, 15472, 13017, 17652, 14263, 11926, 15556, 14958, 13520, 13916, 16301, 12332, 15832, 12102]
        },
        {
            id: '0b7edcbd-79ac-4caf-8dce-ee06222375dd',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx11/egress_capture__10_9_24_10__232_10_10_3__49410.ts',
            smx: '11',
            measurementPointId: 'ab3851f1-5502-4514-8c70-a8b1bc5ac247',
            bitrateList: [14767, 14479, 14334, 16816, 11965, 14027, 17877, 14443, 11126, 15565, 14909, 16824, 15072, 14406, 14954, 13834, 12591, 14237, 14271, 15418, 15060, 15660, 16160, 12048, 15499, 15177, 14742, 15156, 12437, 14763, 17128, 13846, 13710, 16096, 15144, 15053, 13945, 13809, 15796, 13057, 15155, 14135, 14766, 14080, 13788, 14967, 14049, 13709, 19221, 14974, 13862, 13904, 16260, 14516, 13015, 13550, 14597, 16792, 13837, 16835, 13173, 14574, 14574, 13903, 15010, 14045, 13574, 16601, 15807, 13241, 14673, 13782, 15598, 13203, 15758, 14678, 14322, 13258, 16333, 13845, 14448, 14873, 14998, 14127, 14782, 11939, 15346, 15986, 13789, 14667, 15209, 15522, 13873, 14110, 15319, 15038, 16472, 13883, 13176, 14920, 14498, 15089, 13524, 15684, 16658, 13809, 14420, 13818, 14069, 14992, 14683, 12675, 13334, 16200, 16259, 14664, 14539, 14786, 15833]
        },
        {
            id: '6519fcca-786d-43a6-82c5-7af32108b4ca',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx11/egress_capture__10_9_24_10__232_10_10_4__49410.ts',
            smx: '11',
            measurementPointId: '8506146d-88c1-4fa7-ba41-3608dbfdbca2',
            bitrateList: [16478, 15201, 13646, 13917, 15044, 14827, 14544, 14601, 13736, 15309, 14776, 14147, 15514, 15293, 14714, 14129, 13724, 14118, 17999, 13340, 13229, 15070, 14250, 14516, 14672, 14123, 13333, 16194, 14903, 14940, 15749, 15244, 12967, 15174, 14778, 13938, 15179, 15588, 12961, 15927, 14104, 13802, 14785, 15090, 13525, 17103, 14740, 14152, 13909, 13690, 15000, 14901, 14488, 14971, 15631, 15730, 12245, 15611, 14524, 14029, 15831, 13625, 13607, 16253, 15108, 14659, 14051, 15096, 13548, 14786, 13823, 15174, 14711, 15494, 13528, 14160, 15048, 15235, 15826, 13992, 14513, 15938, 14125, 13738, 14145, 13816, 14766, 15749, 13990, 14742, 16373, 14018, 13678, 13550, 15700, 14252, 16134, 14970, 13317, 15007, 15579, 13485, 14409, 15570, 14242, 15066, 15568, 13959, 13868, 15423, 14538, 15177, 13847, 13702, 15162, 15408, 14310, 14943, 14664]
        },
        {
            id: '2a0f3345-97b2-411c-ad98-74caab65f13c',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx11/egress_capture__10_9_24_10__232_10_10_5__49410.ts',
            smx: '11',
            measurementPointId: 'c1f62728-efe9-47b6-a18c-0b8612f28baa',
            bitrateList: [1869, 2043, 2354, 1989, 2248, 1283, 1681, 1282, 1368, 1364, 1118, 1610, 1875, 1845, 1750, 1924, 1451, 1407, 1216, 1182, 1463, 1328, 1189, 1140, 1310, 1422, 1664, 1503, 1894, 2159, 1522, 1952, 1552, 1609, 1379, 1887, 1649, 1186, 1273, 1444, 1731, 1725, 1957, 1743, 1474, 2171, 1499, 1637, 2456, 1374, 1832, 2143, 1692, 1646, 1295, 1468, 1137, 1050, 1472, 1274, 1218, 1576, 1460, 1281, 1626, 1107, 1234, 1309, 1140, 1134, 1364, 1232, 1352, 1487, 1377, 1644, 1324, 2040, 1514, 1250, 1659, 1450, 1257, 1673, 1263, 1313, 1458, 1346, 1307, 1243, 1610, 1677, 1647, 2501, 1877, 1417, 2031, 1978, 1768, 1523, 1995, 1614, 1402, 1755, 1423, 1221, 1558, 1657, 1408, 1486, 1364, 1259, 1737, 1291, 1177, 1818, 1800, 2372, 1595],
            eaId: '8dbb2d32-21ec-49ec-94ad-c54edd83dd8b',
            vmafReduct: [[0, 63, 89], [58, 75, 89], [116, 75, 88], [174, 77, 87], [232, 75, 88], [290, 74, 90], [348, 85, 92], [406, 85, 91], [463, 80, 91], [520, 86, 93], [577, 66, 93], [634, 75, 87], [691, 77, 86], [748, 77, 86], [805, 75, 88], [862, 74, 89], [919, 81, 89], [976, 82, 90], [1033, 81, 89], [1090, 75, 88], [1147, 72, 89], [1204, 75, 87], [1261, 80, 92], [1318, 84, 93], [1375, 77, 88], [1432, 78, 86], [1489, 78, 86], [1546, 78, 87], [1603, 77, 88], [1660, 78, 89], [1717, 81, 89], [1774, 73, 89], [1831, 75, 86], [1888, 69, 91], [1945, 83, 90], [2002, 84, 92], [2059, 83, 91], [2116, 84, 90], [2173, 86, 93], [2230, 89, 92], [2287, 87, 93], [2344, 83, 91], [2401, 63, 90], [2458, 76, 88], [2515, 80, 89], [2572, 56, 87], [2629, 54, 88], [2686, 82, 89], [2743, 83, 89], [2800, 85, 91], [2857, 88, 93], [2914, 88, 93], [2971, 88, 94], [3028, 87, 92], [3085, 81, 93], [3142, 77, 88], [3199, 80, 90], [3256, 78, 92], [3313, 85, 92], [3370, 81, 91], [3427, 87, 93], [3484, 81, 91], [3541, 83, 91], [3598, 79, 89], [3655, 83, 93], [3712, 82, 91], [3769, 83, 92], [3826, 85, 92], [3883, 85, 91], [3940, 83, 90], [3997, 78, 91], [4054, 85, 90], [4111, 76, 89], [4168, 67, 87], [4225, 77, 90], [4282, 80, 90], [4339, 79, 90], [4396, 84, 93], [4453, 86, 92], [4510, 69, 94], [4567, 70, 90], [4624, 78, 92], [4681, 86, 94], [4738, 86, 92], [4795, 88, 93], [4852, 84, 92], [4909, 80, 90], [4966, 62, 90], [5023, 65, 88], [5080, 79, 89], [5137, 51, 91], [5194, 63, 81], [5251, 62, 78], [5308, 69, 86], [5365, 76, 89], [5422, 79, 84], [5479, 81, 86], [5536, 76, 90], [5593, 79, 93], [5650, 81, 100]]
        },
        {
            id: 'b1f30e2a-831d-4d4d-bdee-549b897280ab',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx11/egress_capture__10_9_24_10__232_10_10_6__49410.ts',
            smx: '11',
            measurementPointId: '043dda0b-fce1-4f20-bfba-87d5208cdd27',
            bitrateList: [1603, 1596, 1166, 985, 789, 876, 1026, 960, 1405, 1196, 1282, 976, 1015, 1243, 972, 1315, 992, 1070, 1000, 1236, 1101, 1006, 1066, 991, 836, 829, 1103, 1274, 1283, 1375, 1507, 678, 1659, 1069, 912, 1290, 1135, 1588, 1180, 1558, 1050, 1401, 1003, 970, 1382, 942, 1305, 1078, 1060, 1336, 1080, 897, 1344, 1119, 1225, 1421, 873, 930, 1110, 823, 1072, 971, 1268, 782, 903, 843, 880, 1212, 1102, 1095, 926, 1042, 1298, 1088, 1178, 1102, 917, 1164, 1034, 1106, 1369, 1056, 963, 1006, 1805, 1352, 1108, 1138, 1193, 1117, 1919, 1711, 1437, 1031, 1346, 1469, 1707, 1462, 1528, 1147, 927, 1553, 1416, 1756, 1379, 1679, 1171, 1050, 937, 1201, 1390, 929, 970, 1431, 1208, 942, 655, 1348, 1252],
            eaId: '933eae5f-67d6-4074-92d6-3c85bad6a724',
            vmafReduct: [[0, 87, 94], [58, 81, 94], [116, 89, 94], [174, 87, 94], [232, 82, 93], [290, 89, 93], [348, 86, 93], [406, 87, 95], [464, 90, 95], [522, 90, 95], [580, 85, 94], [638, 87, 94], [696, 86, 94], [754, 87, 95], [812, 91, 95], [870, 91, 95], [928, 91, 95], [986, 91, 95], [1044, 75, 93], [1102, 81, 93], [1160, 75, 90], [1218, 77, 91], [1276, 82, 97], [1333, 85, 95], [1390, 88, 95], [1447, 86, 94], [1504, 87, 93], [1561, 87, 92], [1618, 88, 95], [1675, 91, 96], [1732, 90, 95], [1789, 90, 93], [1846, 88, 92], [1903, 85, 93], [1960, 83, 94], [2017, 92, 94], [2074, 86, 93], [2131, 87, 94], [2188, 87, 95], [2245, 86, 94], [2302, 88, 93], [2359, 83, 91], [2416, 79, 91], [2473, 72, 90], [2530, 83, 92], [2587, 87, 92], [2644, 74, 92], [2701, 85, 92], [2758, 88, 94], [2815, 90, 94], [2872, 85, 95], [2929, 70, 93], [2986, 90, 94], [3043, 91, 94], [3100, 83, 94], [3157, 75, 88], [3214, 67, 85], [3271, 72, 90], [3328, 86, 94], [3385, 88, 94], [3442, 88, 93], [3499, 88, 94], [3556, 88, 94], [3613, 88, 93], [3670, 86, 94], [3727, 86, 92], [3784, 86, 93], [3841, 85, 93], [3898, 90, 93], [3955, 83, 93], [4012, 76, 93], [4069, 88, 95], [4126, 85, 94], [4183, 82, 93], [4240, 77, 92], [4297, 80, 91], [4354, 84, 92], [4411, 85, 91], [4468, 89, 92], [4525, 38, 92], [4582, 89, 92], [4639, 88, 92], [4696, 88, 94], [4753, 89, 94], [4810, 90, 94], [4867, 89, 92], [4924, 89, 95], [4981, 92, 95], [5038, 91, 95], [5095, 89, 94], [5152, 86, 95], [5209, 82, 93], [5266, 84, 93], [5323, 82, 94], [5380, 86, 92], [5437, 89, 94], [5494, 87, 93], [5551, 92, 95], [5608, 91, 96], [5665, 89, 100]]
        },
        {
            id: '71bec36b-8ecb-40b3-9ad1-60a80e5f0dc8',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx11/egress_capture__10_9_24_10__232_10_10_7__49410.ts',
            smx: '11',
            measurementPointId: '6d410170-c364-4185-b5aa-f87a8954f781',
            bitrateList: [1581, 1011, 864, 1386, 1361, 2125, 2728, 1866, 1714, 2180, 2690, 2067, 2416, 1713, 2177, 2241, 2537, 1860, 2539, 1848, 2142, 2396, 1721, 1811, 1696, 1479, 1345, 1299, 1395, 1336, 1111, 1142, 1619, 1561, 2091, 1501, 1554, 1995, 1502, 1610, 1881, 1641, 1787, 1456, 2058, 1659, 1596, 2098, 1503, 1561, 1611, 1827, 1763, 2110, 2581, 2359, 2349, 3063, 2616, 2549, 2990, 2797, 2925, 2516, 2392, 2676, 3341, 2983, 3288, 3352, 3237, 2189, 2008, 1804, 2300, 2020, 2036, 2344, 1361, 1572, 1562, 1992, 2098, 2238, 2310, 2668, 2549, 2411, 2904, 2254, 1182, 1072, 1201, 1165, 1341, 1052, 1182, 1284, 1744, 1467, 1559, 1270, 1153, 1474, 972, 1247, 1195, 1133, 2312, 2312, 2565, 2676, 2525, 2364, 2477, 2369, 1474, 1453, 1626],
            eaId: '3a320873-e57f-4467-aeeb-f745f64a1e60',
            vmafReduct: [[0, 73, 92], [58, 74, 89], [116, 83, 88], [174, 82, 89], [232, 76, 89], [290, 80, 90], [348, 85, 89], [406, 76, 90], [463, 71, 88], [520, 63, 87], [577, 72, 88], [634, 80, 92], [691, 79, 89], [748, 78, 89], [805, 75, 91], [862, 77, 90], [919, 71, 90], [976, 75, 89], [1033, 79, 89], [1090, 79, 88], [1147, 79, 90], [1204, 78, 87], [1261, 80, 89], [1318, 75, 89], [1375, 79, 92], [1432, 76, 88], [1489, 79, 88], [1546, 80, 89], [1603, 85, 90], [1660, 86, 91], [1717, 85, 90], [1774, 85, 90], [1831, 84, 94], [1888, 85, 91], [1945, 81, 92], [2002, 81, 89], [2059, 80, 89], [2116, 75, 89], [2173, 72, 90], [2230, 75, 89], [2287, 66, 87], [2344, 65, 88], [2401, 54, 84], [2458, 61, 85], [2515, 47, 84], [2572, 61, 85], [2629, 67, 87], [2686, 71, 86], [2743, 57, 86], [2800, 70, 87], [2857, 58, 86], [2914, 68, 86], [2971, 56, 87], [3028, 59, 85], [3085, 58, 83], [3142, 40, 82], [3199, 53, 80], [3256, 50, 86], [3313, 74, 87], [3370, 69, 87], [3427, 72, 87], [3484, 75, 86], [3541, 74, 87], [3598, 72, 86], [3655, 72, 88], [3712, 79, 89], [3769, 79, 90], [3826, 74, 86], [3883, 77, 87], [3940, 76, 87], [3997, 63, 87], [4054, 74, 86], [4111, 61, 86], [4168, 65, 85], [4225, 70, 90], [4282, 85, 91], [4339, 83, 90], [4396, 85, 90], [4453, 85, 91], [4510, 79, 89], [4567, 85, 92], [4624, 85, 92], [4681, 86, 92], [4738, 84, 93], [4795, 88, 93], [4852, 86, 92], [4909, 87, 93], [4966, 84, 91], [5023, 83, 93], [5080, 83, 91], [5137, 71, 91], [5194, 72, 90], [5251, 50, 90], [5308, 45, 84], [5365, 54, 83], [5422, 43, 83], [5479, 30, 83], [5536, 41, 87], [5593, 59, 88], [5650, 38, 100]]
        },
        {
            id: '2b771b66-7efa-4537-8975-191f77c586ac',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx11/egress_capture__10_9_24_10__232_10_10_8__49410.ts',
            smx: '11',
            measurementPointId: '79f01a6c-3b92-442c-ae80-daf3252566e0',
            bitrateList: [3022, 2511, 2588, 3557, 3339, 2965, 2559, 2837, 2714, 2758, 2606, 2640, 2806, 2481, 2306, 2301, 2303, 2381, 3156, 3048, 3044, 2824, 2659, 3570, 3676, 3288, 3120, 3466, 2976, 2669, 3508, 3311, 3008, 3195, 3274, 2847, 2986, 2616, 2898, 3342, 2942, 2602, 2943, 2816, 2714, 3009, 2771, 2578, 2864, 3188, 2807, 3105, 2413, 2220, 2337, 2306, 2603, 2761, 2604, 1968, 2201, 2346, 2233, 2641, 2609, 2624, 2171, 2047, 1831, 2049, 2004, 2473, 2974, 2703, 2711, 3051, 2656, 2766, 2688, 3184, 3329, 3097, 2877, 2663, 2433, 2063, 2141, 2499, 2167, 2119, 2785, 3020, 2979, 3508, 3081, 3313, 2598, 2405, 2441, 3171, 3037, 3095, 3357, 2964, 3276, 3507, 3238, 3292, 2891, 2707, 2064, 2780, 2443, 2338, 2630, 2244, 2621, 3149, 2751],
            eaId: '756ea030-2b58-4160-8292-d0fd60853c00',
            vmafReduct: [[0, 72, 97], [57, 67, 96], [114, 74, 98], [171, 75, 98], [228, 84, 97], [285, 81, 97], [342, 74, 98], [399, 80, 99], [456, 69, 95], [513, 65, 95], [570, 73, 98], [627, 72, 98], [684, 70, 98], [741, 75, 98], [798, 74, 98], [855, 71, 100], [912, 86, 100], [969, 84, 100], [1026, 93, 100], [1083, 94, 100], [1140, 86, 100], [1197, 88, 100], [1254, 72, 100], [1311, 82, 100], [1368, 82, 100], [1425, 76, 99], [1482, 62, 95], [1539, 63, 97], [1596, 85, 98], [1653, 85, 99], [1710, 87, 100], [1767, 78, 99], [1824, 83, 97], [1881, 83, 95], [1938, 76, 95], [1995, 74, 94], [2052, 82, 97], [2109, 75, 98], [2166, 70, 100], [2223, 79, 100], [2280, 76, 96], [2337, 77, 96], [2394, 76, 96], [2451, 79, 98], [2508, 71, 98], [2565, 71, 98], [2622, 68, 97], [2679, 78, 92], [2736, 75, 94], [2793, 80, 100], [2850, 79, 94], [2907, 73, 96], [2964, 69, 97], [3021, 83, 97], [3078, 80, 95], [3135, 73, 95], [3192, 76, 94], [3249, 77, 97], [3306, 81, 97], [3363, 78, 94], [3420, 83, 97], [3477, 84, 95], [3534, 76, 96], [3591, 77, 98], [3648, 74, 97], [3705, 75, 99], [3762, 79, 100], [3819, 74, 96], [3876, 82, 94], [3933, 73, 93], [3990, 74, 96], [4047, 81, 94], [4104, 75, 95], [4161, 81, 97], [4218, 89, 100], [4275, 87, 100], [4332, 84, 100], [4389, 75, 100], [4446, 75, 100], [4503, 65, 100], [4560, 72, 100], [4617, 78, 100], [4674, 91, 100], [4731, 87, 100], [4788, 90, 100], [4845, 85, 100], [4902, 78, 100], [4959, 85, 100], [5016, 72, 99], [5073, 80, 100], [5130, 65, 96], [5187, 62, 96], [5244, 72, 95], [5301, 70, 96], [5358, 77, 94], [5415, 82, 97], [5472, 78, 95], [5529, 80, 97], [5586, 85, 97], [5643, 83, 100]]
        }
        ]

    const smx12List = [
        {
            id: '9ce810c9-c624-4da9-9e21-60c657ec1445',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx12/egress_capture__10_9_24_10__232_10_10_1__49410.ts',
            smx: '12',
            measurementPointId: 'daa82064-ca15-4022-83c5-171bb9b9179a',
            bitrateList: [14826, 14348, 14724, 11976, 18853, 16426, 14865, 12734, 12992, 16884, 14587, 13731, 13305, 13391, 16155, 15458, 13374, 15666, 14380, 13712, 14074, 13872, 19698, 14838, 12309, 18105, 13293, 12989, 15292, 13392, 12270, 13957, 14438, 14495, 15437, 17034, 16241, 14765, 13217, 15500, 12817, 17921, 14085, 15873, 13807, 16609, 10001, 16781, 13643, 15810, 14215, 13411, 15615, 14175, 13534, 16291, 15128, 16237, 14604, 13880, 9990, 15634, 16331, 14477, 16452, 16191, 12008, 13055, 15343, 13781, 16499, 16299, 11915, 12423, 18596, 13012, 13998, 13771, 15481, 13258, 14442, 15955, 15367, 13030, 16096, 15073, 15678, 17678, 11856, 10372, 16306, 18994, 12465, 15125, 13751, 12807, 14887, 17260, 12971, 18111, 14571, 11242, 13318, 14193, 17011, 20221, 12497, 12164, 14388, 9348, 18959, 15912, 13749, 15369, 13862, 14959, 12627, 14987, 14019]
        },
        {
            id: 'b63162d0-9ede-4efa-bdf0-0c94b131caa4',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx12/egress_capture__10_9_24_10__232_10_10_2__49410.ts',
            smx: '12',
            measurementPointId: '81ece6a3-c40d-4c4c-955d-90841bf22891',
            bitrateList: [15635, 16162, 12646, 14045, 15594, 15056, 14033, 15338, 15986, 14978, 13384, 13943, 14923, 13886, 14511, 15718, 13919, 14397, 15880, 14752, 13414, 15335, 13781, 15308, 15870, 13887, 14310, 14235, 14897, 14209, 14853, 14465, 15223, 16445, 13617, 12974, 15743, 16384, 12676, 13473, 14591, 19896, 10122, 13437, 17127, 12211, 15769, 16153, 14192, 13134, 14649, 15101, 15724, 14776, 14479, 12060, 16932, 14544, 13296, 16698, 14887, 12949, 15727, 14419, 12903, 15353, 16231, 12748, 16684, 14587, 13114, 14875, 13369, 15659, 17338, 13277, 12511, 16032, 15938, 15387, 11774, 16464, 13674, 14692, 16045, 13642, 13333, 14652, 16348, 15743, 15471, 12856, 12635, 16608, 14860, 13403, 15580, 13254, 13585, 15565, 16005, 12597, 14936, 16195, 14129, 14907, 13532, 16564, 12030, 16050, 14299, 15936, 14405, 13268, 17135, 13725, 14792, 14559, 13864]
        },
        {
            id: '3ba81f49-fe5a-4182-bbe4-7e0fda008dbd',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx12/egress_capture__10_9_24_10__232_10_10_3__49410.ts',
            smx: '12',
            measurementPointId: 'ab3851f1-5502-4514-8c70-a8b1bc5ac247',
            bitrateList: [16824, 13931, 13111, 15684, 14907, 14375, 15582, 12291, 14884, 16525, 14695, 16503, 13914, 12752, 16117, 12349, 12371, 16972, 14215, 15843, 16252, 13308, 14409, 14406, 15294, 14805, 13337, 15787, 14861, 14681, 15954, 13370, 14616, 17744, 13254, 14441, 14183, 14764, 13298, 14561, 15343, 13594, 14505, 13740, 14265, 15532, 15329, 16323, 15227, 12979, 15835, 14994, 13487, 15363, 13438, 15108, 15376, 15745, 14783, 13308, 14253, 15674, 12956, 15016, 14704, 13936, 16182, 15829, 13056, 13408, 16467, 14278, 13650, 13968, 15260, 14640, 14682, 14064, 15336, 15088, 13436, 16104, 14678, 13120, 12084, 15306, 15392, 16017, 13892, 15168, 14319, 15076, 14099, 15419, 16036, 12759, 16521, 12786, 15792, 13529, 14235, 15831, 15170, 14882, 14540, 14259, 14316, 13674, 15015, 14123, 12326, 14433, 17054, 15952, 14139, 14345, 15690, 14967, 14834]
        },
        {
            id: '6ea358f6-f1ac-4515-a09e-7c2374e2b2fd',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx12/egress_capture__10_9_24_10__232_10_10_4__49410.ts',
            smx: '12',
            measurementPointId: '8506146d-88c1-4fa7-ba41-3608dbfdbca2',
            bitrateList: [15759, 14426, 12950, 15111, 15399, 13632, 15206, 14117, 14242, 15295, 14986, 14169, 16234, 14686, 13514, 13996, 15439, 15584, 14634, 13936, 13241, 15440, 15048, 13102, 14955, 13881, 15249, 15698, 15327, 14399, 15941, 13074, 14516, 15331, 14147, 14201, 16067, 13574, 14947, 14455, 14863, 13110, 15976, 14464, 15471, 15296, 14964, 12263, 14623, 15469, 13809, 15780, 14895, 14445, 16049, 13241, 14404, 14883, 15077, 14113, 14761, 13515, 15643, 15633, 14916, 12846, 15942, 13684, 13903, 15123, 14772, 13842, 16138, 13737, 13312, 16121, 15546, 14457, 14705, 14970, 14868, 14206, 14411, 13277, 14415, 15518, 14224, 14789, 15825, 14504, 14947, 13605, 13251, 15796, 14832, 15246, 15544, 13378, 14376, 16271, 13464, 13923, 15787, 14862, 14163, 15980, 14202, 12957, 16030, 14698, 14440, 14315, 14679, 13643, 16060, 15002, 13360, 15494, 13821]
        },
        {
            id: 'de731ee1-2153-48c7-b93a-57e638df4795',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx12/egress_capture__10_9_24_10__232_10_10_5__49410.ts',
            smx: '12',
            measurementPointId: 'c1f62728-efe9-47b6-a18c-0b8612f28baa',
            bitrateList: [1944, 2064, 1728, 2031, 1381, 1544, 1206, 1262, 1206, 871, 1333, 1583, 1452, 1650, 1281, 943, 1178, 1049, 1088, 1238, 1179, 1221, 993, 1053, 1294, 1620, 1402, 1885, 2039, 1535, 1708, 1659, 1538, 1848, 1502, 1726, 1196, 1092, 1147, 1514, 1683, 1729, 1539, 1469, 1789, 1367, 1784, 1624, 1330, 1610, 1555, 1390, 1327, 1271, 1309, 1271, 1244, 1545, 1231, 1338, 1557, 1259, 1179, 1384, 1105, 1113, 1316, 1172, 1134, 1369, 1035, 1199, 1367, 1497, 1493, 1769, 1452, 1548, 1258, 1470, 1214, 1059, 1504, 1130, 1232, 1383, 1273, 1244, 1283, 1711, 1469, 1455, 1915, 1520, 1408, 2144, 1900, 1725, 1746, 1327, 1284, 1676, 1513, 1378, 1609, 1723, 1435, 1451, 1106, 1288, 1319, 1015, 1226, 1354, 1181, 1715, 1997, 1530, 1751],
            eaId: 'c34d9a5a-bd4e-46a7-a2dd-edbb31ff3ace',
            vmafReduct: [[0, 72, 88], [58, 74, 87], [116, 76, 85], [174, 70, 86], [232, 70, 90], [289, 83, 90], [346, 81, 88], [403, 77, 87], [460, 81, 90], [517, 71, 91], [574, 73, 83], [631, 67, 83], [688, 75, 85], [745, 72, 88], [802, 81, 89], [859, 76, 87], [916, 76, 89], [973, 79, 89], [1030, 70, 86], [1087, 70, 88], [1144, 76, 87], [1201, 78, 92], [1258, 81, 89], [1315, 79, 87], [1372, 79, 86], [1429, 79, 86], [1486, 75, 87], [1543, 77, 88], [1600, 72, 86], [1657, 75, 88], [1714, 74, 86], [1771, 70, 84], [1828, 68, 89], [1885, 80, 90], [1942, 81, 90], [1999, 82, 89], [2056, 81, 89], [2113, 85, 92], [2170, 88, 92], [2227, 83, 90], [2284, 80, 89], [2341, 63, 86], [2398, 67, 89], [2455, 72, 88], [2512, 83, 88], [2569, 83, 88], [2626, 83, 89], [2683, 84, 92], [2740, 81, 90], [2797, 87, 92], [2854, 87, 92], [2911, 87, 91], [2968, 86, 91], [3025, 76, 92], [3082, 76, 91], [3139, 82, 90], [3196, 76, 90], [3253, 83, 92], [3310, 79, 92], [3367, 81, 91], [3424, 80, 92], [3481, 85, 91], [3538, 81, 89], [3595, 87, 93], [3652, 77, 90], [3709, 80, 91], [3766, 81, 90], [3823, 81, 91], [3880, 80, 90], [3937, 82, 90], [3994, 83, 90], [4051, 80, 89], [4108, 67, 85], [4165, 77, 88], [4222, 79, 90], [4279, 76, 87], [4336, 82, 91], [4393, 84, 93], [4450, 77, 89], [4507, 74, 89], [4564, 79, 92], [4621, 83, 91], [4678, 84, 92], [4735, 86, 91], [4792, 83, 92], [4849, 84, 91], [4906, 81, 90], [4963, 78, 89], [5020, 81, 87], [5077, 60, 87], [5134, 60, 77], [5191, 65, 76], [5248, 65, 83], [5305, 67, 84], [5362, 73, 84], [5419, 59, 84], [5476, 76, 89], [5533, 76, 92], [5590, 79, 92], [5647, 81, 100]]
        },
        {
            id: 'b5c530a9-f40a-49d8-8ca3-c8042d4239a2',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx12/egress_capture__10_9_24_10__232_10_10_6__49410.ts',
            smx: '12',
            measurementPointId: '043dda0b-fce1-4f20-bfba-87d5208cdd27',
            bitrateList: [1879, 1468, 1262, 1225, 1987, 1616, 1965, 2263, 2038, 2209, 2162, 1952, 1946, 2079, 2505, 1860, 1827, 2084, 1687, 1664, 1793, 1587, 1596, 1655, 1859, 1461, 1655, 1811, 1469, 1655, 2020, 1336, 1534, 1476, 1294, 1438, 1848, 2428, 3011, 1817, 1963, 1723, 1748, 2656, 1875, 1836, 2224, 1857, 1571, 1724, 2538, 2243, 2179, 2537, 1639, 1958, 1998, 1712, 1605, 1416, 1849, 1640, 1645, 1882, 1584, 1512, 1717, 1261, 1296, 1781, 1469, 1723, 1759, 1430, 1420, 1580, 1324, 1623, 1996, 1771, 1803, 2029, 1994, 1849, 1828, 1864, 1382, 1396, 1655, 2179, 2281, 1908, 1899, 1634, 1606, 1418, 1696, 2028, 1620, 1758, 1812, 1620, 1620, 1750, 1545, 1704, 2053, 1449, 1500, 2170, 2011, 1579, 1621, 1736, 1481, 1836, 1892, 2061, 1724],
            eaId: '78899e46-3115-4e83-a5af-36ef935e94b8',
            vmafReduct: [[0, 82, 98], [57, 77, 96], [114, 82, 98], [171, 78, 100], [228, 69, 98], [285, 76, 100], [342, 74, 91], [399, 79, 95], [456, 82, 97], [513, 87, 95], [570, 85, 95], [627, 84, 96], [684, 84, 98], [741, 83, 98], [798, 87, 97], [855, 83, 94], [912, 86, 95], [969, 91, 99], [1026, 86, 98], [1083, 88, 98], [1140, 85, 98], [1197, 85, 95], [1254, 86, 94], [1311, 86, 99], [1368, 84, 98], [1425, 83, 96], [1482, 86, 97], [1539, 88, 96], [1596, 87, 100], [1653, 81, 100], [1710, 84, 95], [1767, 85, 96], [1824, 82, 91], [1881, 85, 93], [1938, 84, 92], [1995, 81, 100], [2052, 92, 100], [2109, 92, 100], [2166, 75, 100], [2223, 84, 98], [2280, 72, 100], [2337, 70, 98], [2394, 72, 98], [2451, 77, 100], [2508, 84, 100], [2565, 95, 100], [2622, 98, 100], [2679, 79, 100], [2736, 88, 98], [2793, 81, 96], [2850, 82, 95], [2907, 82, 93], [2964, 82, 93], [3021, 84, 93], [3078, 81, 93], [3135, 76, 90], [3192, 84, 92], [3249, 81, 95], [3306, 84, 95], [3363, 76, 95], [3420, 82, 93], [3477, 85, 94], [3534, 86, 97], [3591, 87, 99], [3648, 84, 94], [3705, 83, 92], [3762, 82, 99], [3819, 73, 100], [3876, 77, 91], [3933, 78, 95], [3990, 78, 96], [4047, 81, 94], [4104, 81, 95], [4161, 84, 94], [4218, 84, 98], [4275, 73, 100], [4332, 80, 100], [4389, 80, 99], [4446, 66, 100], [4503, 86, 93], [4560, 84, 100], [4617, 85, 100], [4674, 86, 98], [4731, 87, 98], [4788, 88, 99], [4845, 88, 99], [4902, 84, 96], [4959, 80, 91], [5016, 82, 94], [5073, 78, 90], [5130, 61, 98], [5187, 78, 100], [5244, 77, 95], [5301, 79, 93], [5358, 80, 95], [5415, 77, 100], [5472, 85, 97], [5529, 85, 97], [5585, 82, 96], [5641, 84, 100]]
        },
        {
            id: '27616027-af83-474d-a910-63015a85b750',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx12/egress_capture__10_9_24_10__232_10_10_7__49410.ts',
            smx: '12',
            measurementPointId: '6d410170-c364-4185-b5aa-f87a8954f781',
            bitrateList: [1189, 914, 1323, 1553, 1810, 1973, 2044, 1597, 1720, 2368, 1840, 1179, 1926, 2028, 1482, 2704, 1720, 1787, 1997, 2022, 1832, 1620, 1512, 1715, 1262, 1108, 1463, 1239, 970, 1328, 1167, 1618, 1287, 1606, 1751, 1622, 1533, 1964, 1069, 1230, 1370, 1903, 1345, 1350, 1704, 1370, 1601, 1414, 1631, 1254, 1650, 1596, 1880, 2012, 2075, 2025, 1848, 2655, 2357, 2319, 2620, 2455, 2195, 2314, 2085, 3096, 2796, 3263, 3203, 2895, 1755, 2012, 1638, 1813, 2296, 1950, 1889, 1341, 1586, 1286, 1409, 1938, 2458, 1938, 2290, 2524, 2285, 2272, 2372, 1148, 1031, 993, 1159, 1070, 1072, 1379, 1314, 1378, 1700, 1145, 1521, 1120, 1160, 1145, 936, 1324, 966, 2125, 2758, 2021, 2258, 2446, 2294, 2111, 2400, 1206, 1285, 1519, 1801],
            eaId: '9a94cc2f-2534-44d6-a649-db76b38c4162',
            vmafReduct: [[0, 77, 88], [58, 76, 87], [116, 72, 89], [174, 71, 86], [232, 71, 88], [290, 68, 89], [348, 69, 88], [405, 70, 85], [462, 72, 85], [519, 70, 89], [576, 76, 88], [633, 72, 88], [690, 78, 90], [747, 73, 87], [804, 71, 88], [861, 72, 87], [918, 74, 88], [975, 77, 88], [1032, 79, 87], [1089, 79, 89], [1146, 75, 87], [1203, 77, 88], [1260, 75, 89], [1317, 67, 91], [1374, 67, 88], [1431, 75, 89], [1488, 83, 89], [1545, 87, 91], [1602, 81, 89], [1659, 60, 90], [1716, 77, 92], [1773, 84, 92], [1830, 85, 91], [1887, 77, 89], [1944, 73, 87], [2001, 76, 88], [2058, 72, 85], [2115, 73, 89], [2172, 66, 87], [2229, 67, 85], [2286, 54, 82], [2343, 41, 77], [2400, 53, 78], [2457, 62, 81], [2514, 56, 81], [2571, 66, 83], [2628, 66, 85], [2685, 49, 84], [2742, 62, 86], [2799, 70, 84], [2856, 64, 84], [2913, 52, 86], [2970, 57, 82], [3027, 60, 83], [3084, 43, 81], [3141, 51, 82], [3198, 49, 86], [3255, 74, 86], [3312, 70, 86], [3369, 67, 86], [3426, 67, 87], [3483, 67, 86], [3540, 72, 85], [3597, 78, 88], [3654, 77, 87], [3711, 75, 88], [3768, 72, 85], [3825, 75, 86], [3882, 70, 85], [3939, 71, 85], [3996, 70, 85], [4053, 64, 85], [4110, 69, 84], [4167, 69, 90], [4224, 84, 91], [4281, 79, 90], [4338, 85, 90], [4395, 84, 90], [4452, 77, 89], [4509, 85, 92], [4566, 86, 92], [4623, 85, 91], [4680, 81, 93], [4737, 86, 93], [4794, 86, 93], [4851, 84, 92], [4908, 81, 91], [4965, 81, 91], [5022, 81, 90], [5079, 70, 88], [5136, 75, 89], [5193, 48, 83], [5250, 46, 83], [5307, 48, 83], [5364, 48, 80], [5421, 41, 81], [5478, 61, 86], [5535, 53, 82], [5592, 37, 82], [5649, 36, 100]]
        },
        {
            id: '87b6f2d8-b957-449a-aabd-df9d0a4bcb31',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx12/egress_capture__10_9_24_10__232_10_10_8__49410.ts',
            smx: '12',
            measurementPointId: '79f01a6c-3b92-442c-ae80-daf3252566e0',
            bitrateList: [2688, 2624, 3157, 3080, 2211, 2303, 2472, 2269, 2104, 2213, 2137, 2190, 2302, 1889, 1902, 2243, 2528, 2527, 2843, 2532, 2409, 2973, 3267, 2837, 3277, 2924, 2684, 2953, 2752, 2875, 3508, 2608, 2839, 3041, 2910, 2287, 2623, 2218, 1889, 2705, 2427, 2326, 2564, 2313, 2173, 2401, 2220, 2657, 2919, 2343, 2271, 2216, 1690, 1799, 2248, 2100, 2139, 2126, 1701, 1769, 2279, 1976, 2078, 2530, 1907, 1694, 2032, 1729, 1654, 2165, 2363, 2522, 2845, 2485, 2314, 2553, 2307, 2632, 3373, 2573, 2338, 2531, 2185, 2014, 2070, 2165, 2047, 2152, 2263, 2762, 2795, 2466, 3037, 2946, 3433, 2394, 2589, 2772, 2639, 2815, 2992, 2779, 3252, 3315, 2713, 3080, 2696, 2240, 2146, 2141, 2258, 2495, 2102, 1923, 2365, 2611, 2378, 2464, 2344],
            eaId: '80bb275c-3ab7-45f2-b6da-9f9a15a346ea',
            vmafReduct: [[0, 70, 92], [58, 71, 95], [116, 69, 95], [174, 75, 93], [232, 72, 96], [290, 71, 97], [348, 74, 96], [406, 72, 94], [464, 76, 92], [521, 75, 96], [578, 72, 94], [635, 66, 97], [692, 78, 97], [749, 79, 98], [806, 80, 96], [863, 87, 99], [920, 89, 100], [977, 90, 100], [1034, 86, 100], [1091, 77, 100], [1148, 88, 99], [1205, 84, 100], [1262, 80, 100], [1319, 76, 98], [1376, 69, 95], [1433, 65, 96], [1490, 71, 94], [1547, 77, 96], [1604, 74, 97], [1661, 78, 96], [1718, 78, 97], [1775, 78, 96], [1832, 79, 92], [1889, 77, 94], [1946, 66, 92], [2003, 61, 96], [2060, 71, 93], [2117, 77, 100], [2174, 77, 100], [2231, 83, 94], [2288, 73, 93], [2345, 72, 93], [2402, 74, 93], [2459, 76, 95], [2516, 68, 96], [2573, 72, 92], [2630, 77, 91], [2687, 70, 92], [2744, 70, 97], [2801, 74, 93], [2858, 69, 93], [2915, 63, 95], [2972, 77, 93], [3029, 69, 95], [3086, 72, 92], [3143, 72, 92], [3200, 74, 96], [3257, 81, 95], [3314, 83, 97], [3371, 81, 95], [3428, 67, 95], [3485, 70, 93], [3542, 75, 97], [3599, 81, 97], [3656, 77, 98], [3713, 75, 96], [3770, 73, 94], [3827, 77, 93], [3884, 70, 93], [3941, 77, 92], [3998, 77, 95], [4055, 76, 95], [4112, 81, 99], [4169, 89, 100], [4226, 83, 100], [4283, 73, 100], [4340, 85, 100], [4397, 71, 99], [4454, 63, 100], [4511, 85, 100], [4568, 83, 100], [4625, 89, 100], [4682, 75, 100], [4739, 86, 100], [4796, 81, 100], [4853, 91, 100], [4910, 77, 100], [4967, 75, 100], [5024, 73, 97], [5081, 64, 88], [5138, 65, 95], [5195, 72, 93], [5252, 77, 94], [5309, 74, 94], [5366, 72, 94], [5423, 78, 97], [5480, 85, 95], [5537, 84, 97], [5594, 80, 96], [5651, 75, 100]]
        }
        ]

    const smx13List = [
        {
            id: 'd4a33c53-bb21-4ba4-98da-ccda982236cf',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx13/egress_capture__10_9_24_10__232_10_10_1__49410.ts',
            smx: '13',
            measurementPointId: 'daa82064-ca15-4022-83c5-171bb9b9179a',
            bitrateList: [13797, 13976, 18066, 15217, 12806, 12384, 15716, 15127, 12532, 15390, 16158, 13990, 12748, 14322, 15953, 15052, 14552, 14867, 15178, 15656, 14945, 13418, 15929, 14603, 13704, 13231, 15950, 12494, 18285, 12968, 13902, 15407, 13347, 13258, 15887, 15899, 14826, 12521, 15118, 17699, 12886, 15152, 13406, 16987, 13539, 12323, 20560, 15057, 16005, 13613, 13794, 15378, 13354, 12797, 10214, 17651, 14301, 14107, 15302, 13678, 13898, 15847, 12926, 14828, 17329, 17808, 10751, 14013, 12879, 16469, 12729, 15067, 16379, 19372, 9445, 15931, 16054, 13505, 13371, 13743, 14327, 16490, 14591, 12770, 15882, 18340, 16244, 11806, 13980, 12303, 13451, 14974, 16012, 16562, 12545, 14782, 14639, 15761, 15622, 14351, 14994, 14965, 12640, 14066, 15543, 14017, 13961, 15994, 12469, 14425, 18693, 13685, 15804, 13841, 13032, 14874, 16766, 14322, 10995]
        },
        {
            id: '4ec8a132-2b73-4267-b6aa-5a6b1263ce69',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx13/egress_capture__10_9_24_10__232_10_10_2__49410.ts',
            smx: '13',
            measurementPointId: '81ece6a3-c40d-4c4c-955d-90841bf22891',
            bitrateList: [16226, 15395, 12684, 14538, 16443, 14539, 13341, 15662, 16219, 14487, 13176, 14096, 15048, 14452, 13955, 15916, 13481, 15900, 14662, 13957, 13700, 16303, 13076, 15540, 17336, 11960, 14670, 14579, 14659, 14122, 15066, 14056, 17254, 15425, 12292, 13587, 16513, 14945, 12908, 14026, 15587, 18102, 10190, 15320, 15245, 12704, 16291, 15596, 14238, 13383, 14862, 14906, 15578, 14657, 14734, 11903, 17331, 13856, 13436, 17022, 14809, 12728, 16781, 13522, 12811, 16157, 15040, 13360, 16176, 15332, 12972, 13519, 15307, 15647, 14864, 15221, 13178, 15325, 14717, 15794, 11348, 17839, 12741, 15639, 15538, 13854, 13150, 14545, 18242, 13579, 15800, 12055, 13769, 16486, 14741, 13425, 15388, 13083, 14108, 15909, 15273, 12827, 18295, 12492, 14186, 15268, 13733, 16335, 12399, 15805, 14617, 15990, 13593, 14131, 16088, 14219, 15117, 14594, 13270]
        },
        {
            id: '0114e509-e29c-439e-a46e-753d08d1d62f',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx13/egress_capture__10_9_24_10__232_10_10_3__49410.ts',
            smx: '13',
            measurementPointId: 'ab3851f1-5502-4514-8c70-a8b1bc5ac247',
            bitrateList: [16382, 13736, 13092, 16729, 15099, 13025, 14008, 14997, 16199, 15362, 14125, 16248, 12827, 13746, 14754, 11922, 15712, 15545, 15142, 16729, 13642, 12992, 16351, 14674, 13713, 14822, 13497, 16733, 14905, 13716, 15689, 14303, 15429, 13528, 14624, 16229, 13144, 14710, 14423, 14390, 14397, 14049, 14649, 13797, 15294, 14918, 17239, 14398, 13872, 14780, 16363, 13183, 14575, 13144, 16144, 15221, 14307, 15418, 14065, 14604, 14928, 13735, 14579, 13619, 15483, 15305, 15268, 14645, 12754, 15225, 14575, 15166, 13636, 14120, 14410, 16133, 14303, 13574, 16534, 13764, 14514, 15690, 12600, 12689, 15549, 15320, 14601, 15513, 15160, 14242, 13439, 15975, 12720, 18725, 12927, 14221, 15241, 13727, 15497, 13784, 14402, 16344, 15249, 14433, 13915, 13807, 14363, 14846, 14207, 12000, 14534, 16876, 15825, 14641, 13841, 15604, 15776, 13541, 14930]
        },
        {
            id: 'bbb5f20e-ae8f-41ec-b6ae-e0fd74a6e534',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx13/egress_capture__10_9_24_10__232_10_10_4__49410.ts',
            smx: '13',
            measurementPointId: '8506146d-88c1-4fa7-ba41-3608dbfdbca2',
            bitrateList: [15171, 13226, 14255, 16081, 14047, 13952, 14819, 14678, 14361, 15290, 14752, 15091, 15457, 13782, 13174, 15201, 17053, 13003, 14804, 13995, 13820, 16008, 13603, 13730, 14515, 15780, 14374, 15827, 15176, 14772, 13864, 14861, 14265, 14842, 14583, 15116, 14157, 15346, 13399, 15481, 13689, 14655, 14954, 16217, 14152, 15500, 12973, 13467, 15915, 14287, 14623, 15658, 14894, 15125, 13573, 15065, 13861, 15205, 15110, 13426, 14228, 15933, 14639, 15711, 13316, 14704, 14506, 14274, 13510, 15975, 14301, 14967, 14367, 13686, 14902, 16097, 15166, 13697, 15528, 15275, 13441, 14838, 13687, 13435, 15893, 14813, 13845, 15803, 15489, 13801, 14475, 13223, 15331, 14997, 15733, 14687, 13923, 14588, 15395, 14191, 14134, 15056, 15246, 14515, 14971, 14880, 13437, 14935, 15463, 14742, 13267, 15148, 14240, 15031, 15429, 14093, 14332, 14323, 14249]
        },
        {
            id: '2fd5caa5-fc78-41d3-a8d3-4c450b155c0f',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx13/egress_capture__10_9_24_10__232_10_10_5__49410.ts',
            smx: '13',
            measurementPointId: 'c1f62728-efe9-47b6-a18c-0b8612f28baa',
            bitrateList: [1994, 1629, 1464, 1523, 1410, 1666, 1477, 1775, 1861, 1612, 1525, 2121, 1726, 2037, 2348, 1735, 1238, 703, 998, 1002, 1052, 1495, 1395, 1484, 1679, 1530, 1555, 1980, 2057, 1594, 1526, 1825, 1475, 1983, 2666, 2906, 2125, 775, 1029, 1181, 1213, 1384, 1236, 1418, 1660, 1208, 1622, 1486, 1497, 1596, 1671, 1041, 1456, 1597, 1361, 1148, 1658, 1229, 1407, 1727, 1408, 1208, 1370, 1246, 1648, 1340, 1039, 854, 991, 1392, 1553, 739, 1045, 1202, 1316, 1471, 1770, 2019, 2099, 1083, 1681, 1499, 1024, 1351, 1372, 1463, 1394, 921, 1116, 1461, 1630, 1134, 1414, 1183, 1204, 1247, 1379, 1613, 1403, 1711, 1442, 1277, 1227, 1087, 1135, 1075, 989, 984, 775, 875, 935, 911, 1150, 1182, 1359, 4030, 3162, 3101, 2661],
            eaId: 'e2d80a61-4dc2-4db3-b419-f49be66f158b',
            vmafReduct: [[0, 10, 99], [58, 79, 94], [116, 62, 90], [174, 82, 91], [232, 81, 91], [290, 83, 92], [348, 68, 92], [406, 76, 96], [464, 79, 96], [522, 80, 95], [580, 85, 94], [637, 90, 96], [694, 86, 96], [751, 83, 93], [808, 87, 97], [865, 85, 97], [922, 88, 95], [979, 90, 99], [1036, 88, 100], [1093, 74, 97], [1150, 88, 98], [1207, 79, 98], [1264, 90, 98], [1321, 90, 97], [1378, 25, 93], [1435, 81, 95], [1492, 80, 100], [1549, 79, 94], [1606, 87, 95], [1663, 86, 96], [1720, 85, 95], [1777, 87, 92], [1834, 88, 97], [1891, 88, 97], [1948, 84, 96], [2005, 89, 99], [2062, 83, 100], [2119, 89, 96], [2176, 90, 97], [2233, 88, 96], [2290, 74, 93], [2347, 87, 94], [2404, 86, 92], [2461, 88, 93], [2518, 89, 92], [2575, 89, 92], [2632, 88, 93], [2689, 87, 93], [2746, 72, 93], [2803, 89, 94], [2860, 86, 94], [2917, 82, 99], [2974, 88, 99], [3031, 85, 95], [3088, 88, 95], [3145, 83, 98], [3202, 84, 95], [3259, 85, 94], [3316, 87, 96], [3373, 91, 98], [3430, 88, 98], [3487, 82, 97], [3544, 87, 100], [3601, 88, 100], [3658, 63, 100], [3715, 70, 96], [3772, 78, 97], [3829, 83, 95], [3886, 85, 96], [3943, 83, 95], [4000, 79, 91], [4057, 74, 93], [4114, 88, 95], [4171, 91, 99], [4228, 88, 98], [4285, 86, 95], [4342, 90, 97], [4399, 92, 98], [4456, 92, 98], [4513, 91, 98], [4570, 88, 98], [4627, 84, 98], [4684, 89, 99], [4741, 86, 98], [4798, 87, 99], [4855, 91, 98], [4912, 93, 98], [4969, 92, 98], [5026, 84, 96], [5083, 82, 93], [5140, 84, 94], [5197, 90, 96], [5254, 91, 97], [5311, 87, 96], [5368, 89, 95], [5425, 81, 96], [5482, 56, 98], [5539, 60, 91], [5596, 43, 98], [5653, 72, 100]]
        },
        {
            id: '7b70804b-b2ae-4d28-8e57-de5bcdcac86b',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx13/egress_capture__10_9_24_10__232_10_10_6__49410.ts',
            smx: '13',
            measurementPointId: '043dda0b-fce1-4f20-bfba-87d5208cdd27',
            bitrateList: [2099, 1250, 1150, 1771, 1882, 1856, 1918, 2205, 2088, 1871, 1733, 2054, 1875, 1891, 1859, 1665, 1758, 2036, 1893, 1591, 1748, 1483, 1673, 1412, 1776, 1670, 1552, 1653, 1565, 1492, 1857, 1450, 1300, 1299, 1277, 1232, 1524, 3335, 2345, 1814, 2128, 1907, 2221, 2495, 1811, 1913, 2237, 1441, 1693, 2117, 2513, 1958, 2354, 1877, 1831, 1790, 1933, 1640, 1439, 1598, 1782, 1707, 1703, 1845, 1420, 1487, 1580, 1400, 1360, 1837, 1600, 1606, 1950, 1462, 1415, 1560, 1513, 1535, 1874, 1856, 1752, 1803, 2028, 1886, 1637, 1609, 1314, 1543, 2608, 2142, 2044, 1639, 2017, 1637, 1762, 1895, 1938, 1843, 1726, 1723, 1784, 1681, 1643, 1980, 1900, 1816, 1888, 1402, 2067, 2218, 1671, 1962, 1744, 1546, 2062, 1323, 1529, 1519, 1504],
            eaId: 'cfb4f636-adcb-4728-8e4c-f978d89ced5d',
            vmafReduct: [[0, 74, 97], [58, 81, 97], [115, 78, 94], [172, 81, 100], [229, 72, 98], [286, 74, 99], [343, 72, 88], [400, 78, 92], [457, 78, 93], [514, 86, 95], [571, 88, 96], [628, 87, 97], [685, 87, 98], [742, 85, 94], [799, 86, 97], [856, 81, 94], [913, 86, 94], [970, 88, 99], [1027, 86, 94], [1084, 87, 99], [1141, 84, 97], [1198, 84, 94], [1255, 86, 96], [1312, 85, 99], [1369, 83, 96], [1426, 84, 95], [1483, 83, 95], [1540, 85, 100], [1597, 84, 100], [1654, 88, 95], [1711, 89, 96], [1768, 84, 97], [1825, 84, 93], [1882, 84, 93], [1939, 82, 92], [1996, 83, 100], [2053, 91, 100], [2110, 93, 100], [2167, 82, 100], [2224, 80, 100], [2281, 70, 100], [2338, 70, 99], [2395, 73, 92], [2452, 85, 100], [2509, 87, 100], [2566, 97, 100], [2623, 88, 100], [2680, 86, 98], [2737, 86, 98], [2794, 83, 92], [2851, 82, 96], [2908, 82, 91], [2965, 77, 93], [3022, 84, 93], [3079, 81, 95], [3136, 83, 93], [3193, 84, 91], [3250, 79, 93], [3307, 86, 97], [3364, 85, 96], [3421, 85, 94], [3478, 88, 97], [3535, 86, 97], [3592, 85, 98], [3649, 80, 93], [3706, 81, 94], [3763, 77, 97], [3820, 67, 100], [3877, 80, 93], [3934, 77, 92], [3991, 84, 95], [4048, 80, 92], [4105, 84, 97], [4162, 85, 97], [4219, 84, 99], [4276, 73, 100], [4333, 82, 100], [4390, 80, 100], [4447, 81, 94], [4504, 88, 99], [4561, 91, 100], [4618, 86, 98], [4675, 89, 98], [4732, 85, 98], [4789, 89, 99], [4846, 87, 99], [4903, 85, 97], [4960, 81, 95], [5017, 83, 93], [5074, 78, 93], [5131, 65, 100], [5188, 74, 100], [5245, 76, 97], [5302, 83, 95], [5359, 80, 98], [5416, 79, 99], [5473, 81, 97], [5530, 80, 94], [5587, 77, 95], [5644, 80, 100]]
        },
        {
            id: '5bf124aa-ace7-48d1-83c2-c85c1e70cd3e',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx13/egress_capture__10_9_24_10__232_10_10_7__49410.ts',
            smx: '13',
            measurementPointId: '6d410170-c364-4185-b5aa-f87a8954f781',
            bitrateList: [913, 1378, 1303, 2449, 2030, 1561, 1884, 1666, 1837, 2077, 1314, 1754, 2151, 1498, 1646, 1975, 1779, 1769, 2372, 1829, 1440, 1562, 1509, 1334, 1060, 1261, 1480, 1037, 1019, 1425, 1350, 1934, 1641, 1369, 1834, 1188, 1260, 2022, 1139, 1522, 1432, 1777, 1609, 1308, 1670, 1573, 1303, 1570, 1491, 1532, 1691, 2071, 1813, 1821, 2440, 1991, 2168, 2691, 2171, 2420, 2533, 2203, 1877, 2546, 2679, 2861, 3249, 3539, 2590, 1662, 1755, 1997, 2102, 1920, 2293, 1703, 1260, 1280, 1371, 1640, 1776, 2033, 2501, 2254, 2104, 2657, 2305, 2186, 1033, 1247, 926, 1301, 1170, 1108, 1371, 1702, 1352, 1307, 1339, 1120, 1323, 1035, 1197, 1105, 1034, 1504, 2384, 2196, 2649, 2422, 2158, 2527, 2331, 1839, 1267, 927, 1280, 1187, 1627],
            eaId: '55c43afa-d028-4957-8c0d-9dad5598839c',
            vmafReduct: [[0, 80, 88], [57, 57, 88], [114, 69, 85], [171, 74, 87], [228, 72, 88], [285, 57, 89], [342, 61, 83], [399, 62, 81], [456, 61, 85], [513, 75, 88], [570, 78, 88], [627, 78, 88], [684, 75, 89], [741, 72, 91], [798, 72, 88], [855, 70, 87], [912, 77, 88], [969, 78, 89], [1026, 81, 88], [1083, 78, 87], [1140, 77, 87], [1197, 75, 89], [1254, 77, 92], [1311, 79, 88], [1368, 74, 87], [1425, 80, 88], [1482, 83, 89], [1539, 33, 93], [1596, 70, 91], [1653, 71, 89], [1710, 78, 93], [1767, 85, 91], [1824, 81, 91], [1881, 77, 88], [1938, 69, 87], [1995, 73, 87], [2052, 71, 89], [2109, 73, 89], [2166, 71, 86], [2223, 65, 85], [2280, 53, 80], [2337, 56, 77], [2394, 43, 79], [2451, 60, 81], [2508, 61, 82], [2565, 73, 83], [2622, 49, 81], [2679, 63, 88], [2736, 59, 86], [2793, 63, 84], [2850, 54, 84], [2907, 47, 80], [2964, 50, 82], [3021, 40, 80], [3078, 50, 79], [3135, 51, 84], [3192, 70, 84], [3249, 70, 85], [3306, 69, 87], [3363, 76, 87], [3420, 72, 86], [3477, 74, 86], [3534, 70, 86], [3591, 76, 87], [3648, 76, 87], [3705, 72, 85], [3762, 74, 85], [3819, 62, 85], [3876, 69, 85], [3933, 70, 85], [3990, 69, 84], [4047, 67, 84], [4104, 72, 90], [4161, 84, 91], [4218, 83, 90], [4275, 83, 90], [4332, 84, 90], [4389, 83, 90], [4446, 86, 91], [4503, 86, 92], [4560, 85, 91], [4617, 81, 93], [4674, 87, 92], [4731, 85, 91], [4788, 86, 92], [4845, 84, 91], [4902, 84, 92], [4959, 81, 91], [5016, 74, 89], [5073, 77, 89], [5130, 53, 89], [5187, 45, 83], [5244, 50, 79], [5301, 52, 85], [5358, 43, 83], [5415, 43, 85], [5472, 47, 85], [5529, 33, 77], [5586, 27, 70], [5642, 36, 100]]
        },
        {
            id: '9ddb9c1e-33f3-43dd-a1d0-006b56964446',
            filename: 'arn:aws:s3:::vqaas-share-bucket/demo_smx/smx13/egress_capture__10_9_24_10__232_10_10_8__49410.ts',
            smx: '13',
            measurementPointId: '79f01a6c-3b92-442c-ae80-daf3252566e0',
            bitrateList: [2874, 3081, 3003, 2559, 2273, 2212, 2203, 2075, 1879, 2132, 1718, 1932, 1820, 1522, 1542, 2331, 2479, 2862, 2778, 2402, 2709, 3387, 2766, 2701, 3243, 2696, 2623, 3038, 2809, 2821, 2872, 2863, 2582, 2626, 2123, 1713, 2161, 1965, 2381, 2943, 2728, 2514, 2652, 2251, 2141, 2368, 2665, 2710, 2669, 2202, 2151, 1964, 1779, 1915, 2281, 2039, 1870, 1826, 1718, 2118, 2094, 2134, 2417, 1993, 1675, 1696, 1806, 1866, 1928, 2546, 2561, 2629, 2909, 2529, 2413, 2737, 2490, 2777, 2310, 2546, 2129, 2348, 1967, 1894, 2205, 2095, 1890, 2533, 2983, 2641, 2730, 2963, 3042, 3739, 2965, 2542, 2790, 2810, 2616, 3344, 2878, 3101, 3714, 3174, 3230, 3003, 2630, 2297, 2105, 2355, 2211, 2502, 2228, 2096, 2634, 1795, 1802, 1841, 1988],
            eaId: '05eb2471-965a-41ff-a3cc-45b5f7f4cf97',
            vmafReduct: [[0, 66, 92], [58, 75, 95], [116, 76, 93], [173, 70, 93], [230, 66, 90], [287, 72, 95], [344, 56, 92], [401, 59, 87], [458, 59, 94], [515, 74, 93], [572, 78, 97], [629, 71, 97], [686, 78, 98], [743, 72, 97], [800, 82, 97], [857, 84, 100], [914, 91, 100], [971, 84, 100], [1028, 82, 100], [1085, 84, 100], [1142, 67, 100], [1199, 85, 100], [1256, 79, 98], [1313, 61, 94], [1370, 58, 91], [1427, 64, 94], [1484, 74, 93], [1541, 75, 94], [1598, 74, 97], [1655, 83, 98], [1712, 80, 98], [1769, 80, 93], [1826, 74, 94], [1883, 67, 91], [1940, 71, 94], [1997, 81, 95], [2054, 72, 100], [2111, 83, 100], [2168, 79, 93], [2225, 79, 96], [2282, 73, 91], [2339, 73, 95], [2396, 74, 94], [2453, 73, 95], [2510, 66, 92], [2567, 75, 91], [2624, 65, 92], [2681, 74, 96], [2738, 72, 94], [2795, 74, 93], [2852, 71, 94], [2909, 78, 95], [2966, 74, 92], [3023, 70, 92], [3080, 72, 92], [3137, 73, 95], [3194, 80, 95], [3251, 78, 94], [3308, 75, 98], [3365, 74, 96], [3422, 75, 93], [3479, 78, 96], [3536, 77, 96], [3593, 73, 96], [3650, 72, 96], [3707, 73, 95], [3764, 72, 92], [3821, 68, 94], [3878, 78, 93], [3935, 77, 93], [3992, 81, 95], [4049, 79, 97], [4106, 89, 100], [4163, 83, 100], [4220, 80, 99], [4277, 82, 100], [4334, 82, 100], [4391, 78, 100], [4448, 83, 100], [4505, 78, 100], [4562, 92, 100], [4619, 75, 100], [4676, 90, 100], [4733, 83, 100], [4790, 88, 100], [4847, 92, 100], [4904, 76, 100], [4961, 79, 100], [5018, 69, 94], [5075, 74, 96], [5132, 68, 91], [5189, 70, 94], [5246, 70, 94], [5303, 77, 96], [5360, 79, 95], [5417, 81, 95], [5474, 74, 93], [5531, 74, 94], [5588, 62, 92], [5645, 66, 100]]
        }
    ]

    React.useEffect(() => {
        let mounted = true
        async function getAWSUrl() {
            try {
                const tmpSmx3Chan1 = await Storage.get(smx3List[0]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx3Chan2 = await Storage.get(smx3List[1]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx3Chan3 = await Storage.get(smx3List[2]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx3Chan4 = await Storage.get(smx3List[3]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx10Chan1 = await Storage.get(smx10List[0]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx10Chan2 = await Storage.get(smx10List[1]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx10Chan3 = await Storage.get(smx10List[2]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx10Chan4 = await Storage.get(smx10List[3]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx11Chan1 = await Storage.get(smx11List[0]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx11Chan2 = await Storage.get(smx11List[1]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx11Chan3 = await Storage.get(smx11List[2]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx11Chan4 = await Storage.get(smx11List[3]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx12Chan1 = await Storage.get(smx12List[0]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx12Chan2 = await Storage.get(smx12List[1]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx12Chan3 = await Storage.get(smx12List[2]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx12Chan4 = await Storage.get(smx12List[3]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx13Chan1 = await Storage.get(smx13List[0]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx13Chan2 = await Storage.get(smx13List[1]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx13Chan3 = await Storage.get(smx13List[2]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                const tmpSmx13Chan4 = await Storage.get(smx13List[3]['id'] + '/media_out_signed.m3u8',
                    {
                        level: 'protected',
                        identityId: 'us-east-2:a255afea-a4f0-4d7e-8d60-0244303fb026',
                        expires: 86400
                    });
                // console.log(result)
                if (mounted) {
                    console.log('setPlayerUrl')
                    setSmx3Chan1(tmpSmx3Chan1)
                    setSmx3Chan2(tmpSmx3Chan2)
                    setSmx3Chan3(tmpSmx3Chan3)
                    setSmx3Chan4(tmpSmx3Chan4)
                    setSmx10Chan1(tmpSmx10Chan1)
                    setSmx10Chan2(tmpSmx10Chan2)
                    setSmx10Chan3(tmpSmx10Chan3)
                    setSmx10Chan4(tmpSmx10Chan4)
                    setSmx11Chan1(tmpSmx11Chan1)
                    setSmx11Chan2(tmpSmx11Chan2)
                    setSmx11Chan3(tmpSmx11Chan3)
                    setSmx11Chan4(tmpSmx11Chan4)
                    setSmx12Chan1(tmpSmx12Chan1)
                    setSmx12Chan2(tmpSmx12Chan2)
                    setSmx12Chan3(tmpSmx12Chan3)
                    setSmx12Chan4(tmpSmx12Chan4)
                    setSmx13Chan1(tmpSmx13Chan1)
                    setSmx13Chan2(tmpSmx13Chan2)
                    setSmx13Chan3(tmpSmx13Chan3)
                    setSmx13Chan4(tmpSmx13Chan4)
                    setPlayerChan1(tmpSmx3Chan1)
                    setPlayerChan2(tmpSmx3Chan2)
                    setPlayerChan3(tmpSmx3Chan3)
                    setPlayerChan4(tmpSmx3Chan4)
                    setTimeout(() => {if (mounted) {setPlay(true)}}, 1000)
                }
            } catch (err) {
                console.log('URL not found')
            }
        }

        getAWSUrl().then()
        return function cleanup() {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (play) {
            setPointer(0)
            const interval = setInterval(function() {
                if (play) setPointer(number => {if (number < 60) {return number + 1} else {return 0}})
            }, 1000)
            return () => clearInterval(interval);
        }
    },[play])

    React.useEffect(() => {
        // console.log('smxGroup', smxGroup, 'Pointer', pointer)
        if (pointer === 0){
            switch(smxGroup){
                case 3:
                    console.log('Configure smx3')
                    setPlayerChan1(smx3Chan1)
                    setPlayerChan2(smx3Chan2)
                    setPlayerChan3(smx3Chan3)
                    setPlayerChan4(smx3Chan4)
                    setBitrateListChan1In(smx3List[0]['bitrateList'])
                    setBitrateListChan2In(smx3List[1]['bitrateList'])
                    setBitrateListChan3In(smx3List[2]['bitrateList'])
                    setBitrateListChan4In(smx3List[3]['bitrateList'])
                    setBitrateListChan1Out(smx3List[4]['bitrateList'])
                    setBitrateListChan2Out(smx3List[5]['bitrateList'])
                    setBitrateListChan3Out(smx3List[6]['bitrateList'])
                    setBitrateListChan4Out(smx3List[7]['bitrateList'])
                    setVmafListChan1(smx3List[4]['vmafReduct'])
                    setVmafListChan2(smx3List[5]['vmafReduct'])
                    setVmafListChan3(smx3List[6]['vmafReduct'])
                    setVmafListChan4(smx3List[7]['vmafReduct'])
                    break
                case 10:
                    console.log('Configure smx10')
                    setPlayerChan1(smx10Chan1)
                    setPlayerChan2(smx10Chan2)
                    setPlayerChan3(smx10Chan3)
                    setPlayerChan4(smx10Chan4)
                    setBitrateListChan1In(smx10List[0]['bitrateList'])
                    setBitrateListChan2In(smx10List[1]['bitrateList'])
                    setBitrateListChan3In(smx10List[2]['bitrateList'])
                    setBitrateListChan4In(smx10List[3]['bitrateList'])
                    setBitrateListChan1Out(smx10List[4]['bitrateList'])
                    setBitrateListChan2Out(smx10List[5]['bitrateList'])
                    setBitrateListChan3Out(smx10List[6]['bitrateList'])
                    setBitrateListChan4Out(smx10List[7]['bitrateList'])
                    setVmafListChan1(smx10List[4]['vmafReduct'])
                    setVmafListChan2(smx10List[5]['vmafReduct'])
                    setVmafListChan3(smx10List[6]['vmafReduct'])
                    setVmafListChan4(smx10List[7]['vmafReduct'])
                    break
                case 11:
                    console.log('Configure smx11')
                    setPlayerChan1(smx11Chan1)
                    setPlayerChan2(smx11Chan2)
                    setPlayerChan3(smx11Chan3)
                    setPlayerChan4(smx11Chan4)
                    setBitrateListChan1In(smx11List[0]['bitrateList'])
                    setBitrateListChan2In(smx11List[1]['bitrateList'])
                    setBitrateListChan3In(smx11List[2]['bitrateList'])
                    setBitrateListChan4In(smx11List[3]['bitrateList'])
                    setBitrateListChan1Out(smx11List[4]['bitrateList'])
                    setBitrateListChan2Out(smx11List[5]['bitrateList'])
                    setBitrateListChan3Out(smx11List[6]['bitrateList'])
                    setBitrateListChan4Out(smx11List[7]['bitrateList'])
                    setVmafListChan1(smx11List[4]['vmafReduct'])
                    setVmafListChan2(smx11List[5]['vmafReduct'])
                    setVmafListChan3(smx11List[6]['vmafReduct'])
                    setVmafListChan4(smx11List[7]['vmafReduct'])
                    break
                case 12:
                    console.log('Configure smx12')
                    setPlayerChan1(smx12Chan1)
                    setPlayerChan2(smx12Chan2)
                    setPlayerChan3(smx12Chan3)
                    setPlayerChan4(smx12Chan4)
                    setBitrateListChan1In(smx12List[0]['bitrateList'])
                    setBitrateListChan2In(smx12List[1]['bitrateList'])
                    setBitrateListChan3In(smx12List[2]['bitrateList'])
                    setBitrateListChan4In(smx12List[3]['bitrateList'])
                    setBitrateListChan1Out(smx12List[4]['bitrateList'])
                    setBitrateListChan2Out(smx12List[5]['bitrateList'])
                    setBitrateListChan3Out(smx12List[6]['bitrateList'])
                    setBitrateListChan4Out(smx12List[7]['bitrateList'])
                    setVmafListChan1(smx12List[4]['vmafReduct'])
                    setVmafListChan2(smx12List[5]['vmafReduct'])
                    setVmafListChan3(smx12List[6]['vmafReduct'])
                    setVmafListChan4(smx12List[7]['vmafReduct'])
                    break
                case 13:
                    console.log('Configure smx13')
                    setPlayerChan1(smx13Chan1)
                    setPlayerChan2(smx13Chan2)
                    setPlayerChan3(smx13Chan3)
                    setPlayerChan4(smx13Chan4)
                    setBitrateListChan1In(smx13List[0]['bitrateList'])
                    setBitrateListChan2In(smx13List[1]['bitrateList'])
                    setBitrateListChan3In(smx13List[2]['bitrateList'])
                    setBitrateListChan4In(smx13List[3]['bitrateList'])
                    setBitrateListChan1Out(smx13List[4]['bitrateList'])
                    setBitrateListChan2Out(smx13List[5]['bitrateList'])
                    setBitrateListChan3Out(smx13List[6]['bitrateList'])
                    setBitrateListChan4Out(smx13List[7]['bitrateList'])
                    setVmafListChan1(smx13List[4]['vmafReduct'])
                    setVmafListChan2(smx13List[5]['vmafReduct'])
                    setVmafListChan3(smx13List[6]['vmafReduct'])
                    setVmafListChan4(smx13List[7]['vmafReduct'])
                    break
                default:
                    console.log('Configure default')
                    setPlayerChan1(smx3Chan1)
                    setPlayerChan2(smx3Chan2)
                    setPlayerChan3(smx3Chan3)
                    setPlayerChan4(smx3Chan4)
                    setBitrateListChan1In(smx3List[0]['bitrateList'])
                    setBitrateListChan2In(smx3List[1]['bitrateList'])
                    setBitrateListChan3In(smx3List[2]['bitrateList'])
                    setBitrateListChan4In(smx3List[3]['bitrateList'])
                    setBitrateListChan1Out(smx3List[4]['bitrateList'])
                    setBitrateListChan2Out(smx3List[5]['bitrateList'])
                    setBitrateListChan3Out(smx3List[6]['bitrateList'])
                    setBitrateListChan4Out(smx3List[7]['bitrateList'])
                    setVmafListChan1(smx3List[0]['vmafReduct'])
                    setVmafListChan2(smx3List[1]['vmafReduct'])
                    setVmafListChan3(smx3List[2]['vmafReduct'])
                    setVmafListChan4(smx3List[3]['vmafReduct'])
                    break
            }
        } else if (pointer === 60){
            setPointer(0)
            switch(smxGroup){
                case 3:
                    setSmxGroup(10)
                    break
                case 10:
                    setSmxGroup(11)
                    break
                case 11:
                    setSmxGroup(12)
                    break
                case 12:
                    setSmxGroup(13)
                    break
                case 13:
                    setPlay(false)
                    break
                default:
                    setSmxGroup(10)
                    break
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointer, smxGroup])


    const SmxProbeItemIn = {
        id: 'SmxProbeItemIn',
        key: 'SmxProbeItemIn',
        name: 'SMX input'
    }

    const SmxProbeItemOut = {
        id: 'SmxProbeItemOut',
        key: 'SmxProbeItemOut',
        name: 'SMX output'
    }

    return (
        <>
            <div className="section" style={{paddingBottom:0, paddingTop:0}}>
                <Flex justifyContent="center" style={{fontFamily: "SourceSansPro"}}>
                    <View width={{base: '95%', xl: '100%'}} height={'100vh'}>

                        {/*My Media*/}
                        <View style={{paddingBottom: "0"}}>
                            <br/>
                            <Flex justifyContent={"center"} direction={"row"} gap={"0"}>
                                <SmxProbeCard
                                    SmxProbeItem={SmxProbeItemIn}
                                    pointer={pointer}
                                    playerChan1={playerChan1}
                                    playerChan2={playerChan2}
                                    playerChan3={playerChan3}
                                    playerChan4={playerChan4}
                                    bitrateListChan1={bitrateListChan1In}
                                    bitrateListChan2={bitrateListChan2In}
                                    bitrateListChan3={bitrateListChan3In}
                                    bitrateListChan4={bitrateListChan4In}
                                    bitrateListChan1Out={bitrateListChan1Out}
                                    bitrateListChan2Out={bitrateListChan2Out}
                                    bitrateListChan3Out={bitrateListChan3Out}
                                    bitrateListChan4Out={bitrateListChan4Out}
                                    play={play}
                                    poolBwVal={8000000}
                                />
                                <SmxProbeCard
                                    SmxProbeItem={SmxProbeItemOut}
                                    pointer={pointer}
                                    playerChan1={playerChan1}
                                    playerChan2={playerChan2}
                                    playerChan3={playerChan3}
                                    playerChan4={playerChan4}
                                    bitrateListChan1={[]}
                                    bitrateListChan2={[]}
                                    bitrateListChan3={[]}
                                    bitrateListChan4={[]}
                                    bitrateListChan1Out={[]}
                                    bitrateListChan2Out={[]}
                                    bitrateListChan3Out={[]}
                                    bitrateListChan4Out={[]}
                                    vmafListChan1={vmafListChan1}
                                    vmafListChan2={vmafListChan2}
                                    vmafListChan3={vmafListChan3}
                                    vmafListChan4={vmafListChan4}
                                    play={play}
                                    poolBw={8000000}
                                />
                            </Flex>
                        </View>
                    </View>
                </Flex>
            </div>
        </>
    );
}

export default SmxPage;
