import React, { useState } from 'react';
import {API, graphqlOperation} from "aws-amplify"
import {getCrossCorrelation} from "../../graphql/queries"
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';


function VqaasHCCorrelationChart(props) {
    const [analysisId, setAnalysisId] = useState(props.analysis_id)
    const [refProgress, setRefProgress] = useState(props.ref_extract_feature_progress)
    const [disProgress, setDisProgress] = useState(props.dis_extract_feature_progress)
    const [chartOptions, setChartOptions] = useState({
        chart: {
            animation: false,
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
        },
        series: [
            { data: [], color: '#3380FF' }
        ],
        xAxis: {
            title: {
                text: 'Negative values: cut frames from distorted video file | Positive values: cut frames from reference video file'
            }
        },
        yAxis: {
            title: {
                text: 'Cross-correlation'
            }
        },
        legend: {
            enabled: false
        },
        title: {
            text: 'Alignment'
        },
        subtitle: {
            text: 'Cross-Correlation between reference and distorted scene changes'
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                animation: false,
            }
        }
    });

    React.useEffect(() => {
        setAnalysisId(props.analysis_id)
    }, [props.analysis_id])

    React.useEffect(() => {
        setRefProgress(props.ref_extract_feature_progress)
    }, [props.ref_extract_feature_progress])

    React.useEffect(() => {
        setDisProgress(props.dis_extract_feature_progress)
    }, [props.dis_extract_feature_progress])

    React.useEffect(() => {
        async function onChangeAnalysisId() {
            if (analysisId){
                let cor_align_list = []
                let corAlign = await API.graphql(graphqlOperation(
                    getCrossCorrelation,
                    {
                        'id': analysisId,
                        'sequence': 0
                    }
                ));
                let item = corAlign.data.getCrossCorrelation;
                if (item){
                    let x_value = item.offset
                    for (let j = 0; j < item.values.length; j++){
                        cor_align_list.push([x_value, item.values[j]])
                        x_value = x_value + 1
                    }
                    setChartOptions(prevChartOptions => {
                        let updatedChartOptions = {...prevChartOptions}
                        updatedChartOptions.series = [{data:cor_align_list, color: '#3380FF'}]
                        return updatedChartOptions
                    })
                } else {
                    console.log("No cross correlation data available")
                }
            }
        }
        onChangeAnalysisId().then()
    }, [analysisId, refProgress, disProgress])


    return(
        <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
        />
    )
}

export default VqaasHCCorrelationChart;