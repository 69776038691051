import React, {useState} from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp } from 'react-icons/fa';
import {
    Button,
    Flex,
    View
} from '@aws-amplify/ui-react';
import {Table, TableBody, TableCell, TableHead, TableRow} from '@aws-amplify/ui-react';

import Tooltip from '@mui/material/Tooltip';

require("highcharts/modules/annotations")(Highcharts);

const argPeak = (myArray) => {
    let min = myArray[0]
    let argMin = 0

    for (let i=1; i < myArray.length; i++){
        let value = myArray[i]
        if (value < min ){
            argMin = i
            min = value
        }
    }
    return argMin
}

const argMaxPeak = (myArray) => {
    let max = myArray[0]
    let argMax = 0

    for (let i=1; i < myArray.length; i++){
        let value = myArray[i]
        if (value > max ){
            argMax = i
            max = value
        }
    }
    return argMax
}


function MetricChart(props) {
    const [lastSelectedFrame, setLastSelectedFrame] = useState(0)
    const [xExtremes, setXExtremes] = useState()
    const [vmafDis1Avg, setVmafDis1Avg] = useState(0)
    const [vmafDis2Avg, setVmafDis2Avg] = useState(0)
    const [vmafDis3Avg, setVmafDis3Avg] = useState(0)
    const [vmafDis4Avg, setVmafDis4Avg] = useState(0)
    const [psnrDis1Avg, setPsnrDis1Avg] = useState(0)
    const [psnrDis2Avg, setPsnrDis2Avg] = useState(0)
    const [psnrDis3Avg, setPsnrDis3Avg] = useState(0)
    const [psnrDis4Avg, setPsnrDis4Avg] = useState(0)
    const [frameRate, setFrameRate] = useState()

    const chartRef = React.useRef()

    const [chartOptions, setChartOptions] = useState({
        chart: {
            animation: false,
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
            marginTop: 100,
        },
        series: [
            { data: [], color: '#3380FF', lineWidth: 1 }
        ],
        xAxis: {
            crosshair: true,
            gridLineWidth: 1,
            title: {
                text: 'Reference frame number'
            },
            events: {
                afterSetExtremes: function(event) {
                    setXExtremes({min:Math.round(event.min), max:Math.round(event.max)})
                    // console.log('setExtremes event', event)
                }
            }
        },
        yAxis: [
            { // Primary axis A, B
                max: 100,
                labels: {
                    style: {
                        color: '#000'
                    }
                },
                title: {
                    text: props.metric,
                    style: {
                        color: '#000'
                    }
                },
                opposite: true
            },
            { // Secondary axis A min B
                gridLineWidth: 0,
                labels: {
                    style: {
                        color: '#51BCDA'
                    }
                },
                title: {
                    text: 'Delta ' + props.metric,
                    style: {
                        color: '#51BCDA'
                    }
                },
            },

        ],
        legend: {
            layout: 'vertical',
            align: 'right',
            x: -50,
            verticalAlign: 'top',
            y: 0,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || // theme
                'rgba(255,255,255,0.25)'
        },
        title: {
            text: ''
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                animation: false,
                point: {
                    events: {
                        select(e){
                            setLastSelectedFrame(e.target.x)
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        tooltip: {
            enabled: false
        }
    });

    const average = React.useCallback ((nums) => {
        if (xExtremes){
            let sliced_nums = nums.slice(Math.max(0,xExtremes.min), xExtremes.max)
            sliced_nums = sliced_nums.filter(function(e){ return e === 0 || e })
            let avg = "N/A"
            if (sliced_nums.length){
                avg = (sliced_nums.reduce((a, b) => (a + b)) / sliced_nums.length).toFixed(2)
            }
            return avg;
        }
    },[xExtremes])

    // console.log('Framerate: ', frameRate)

    React.useEffect(() => {
        if (props.distorted1){
            setFrameRate(props.distorted1.encMedia.spec_frame_rate)
        }
    },[props.distorted1])

    React.useEffect(() => {
        if (props.distorted1 && props.dataAminB && xExtremes) {
            // console.log('UseEffect average 1')
            setVmafDis1Avg(average(props.distorted1.scaledVmaf))
            setPsnrDis1Avg(average(props.distorted1.scaledPsnr))

        }
    }, [average, props.distorted1, props.dataAminB, xExtremes])

    React.useEffect(() => {
        if (props.distorted2 && props.dataAminB && xExtremes) {
            // console.log('UseEffect average 2')
            setVmafDis2Avg(average(props.distorted2.scaledVmaf))
            setPsnrDis2Avg(average(props.distorted2.scaledPsnr))
        }
    }, [average, props.distorted2, props.dataAminB, xExtremes])

    React.useEffect(() => {
        if (props.distorted3 && props.dataAminB && xExtremes) {
            // console.log('UseEffect average 3')
            setVmafDis3Avg(average(props.distorted3.scaledVmaf))
            setPsnrDis3Avg(average(props.distorted3.scaledPsnr))
        }
    }, [average, props.distorted3, props.dataAminB, xExtremes])

    React.useEffect(() => {
        if (props.distorted4 && props.dataAminB && xExtremes) {
            // console.log('UseEffect average 4')
            setVmafDis4Avg(average(props.distorted4.scaledVmaf))
            setPsnrDis4Avg(average(props.distorted4.scaledPsnr))
        }
    }, [average, props.distorted4, props.dataAminB, xExtremes])

    const localOnXtremesChange = props.onXtremesChange
    React.useEffect(() => {
        if (props.dataAminB && xExtremes){
            // console.log('UseEffect localOnXtremesChange')
            localOnXtremesChange(xExtremes)
        }
    }, [localOnXtremesChange, xExtremes, props.dataAminB])


    const localOnSeekTimeChange = props.onSeekTimeChange
    React.useEffect(() => {
        if (props.dataAminB){
            setChartOptions(() => {
                let updatedChartOptions = {}
                updatedChartOptions.annotations = [ {
                    labelOptions: {
                        backgroundColor: 'rgba(0,0,0,0.95)',
                        overflow: "none"
                    },
                    labels: [{
                        point: {
                            xAxis: 0,
                            x: lastSelectedFrame,
                            y: 0
                        },
                        text: String(lastSelectedFrame)
                    }]
                }]
                updatedChartOptions.xAxis = {
                    plotLines: [{
                        color: 'black',
                        dashStyle: 'dot',
                        width: 2,
                        value: lastSelectedFrame,
                        label: {
                            rotation: 0,
                            y: 15,
                            style: {
                                fontStyle: 'italic'
                            }
                        },
                        zIndex: 3
                    }]
                }
                return updatedChartOptions
            })
            if (frameRate){
                // console.log('UseEffect lastSelectedFrame changed')
                localOnSeekTimeChange(lastSelectedFrame / frameRate)
            }
        }
    }, [lastSelectedFrame, frameRate, localOnSeekTimeChange, props.dataAminB])

    React.useEffect(() => {
        if (props.dataAminB){
            // console.log('UseEffect setChartOptions')
            setChartOptions(() => {
                let updatedChartOptions = {}
                let data1, data2, data3, data4, dataDelta
                if (props.metric === 'VMAF'){
                    if (props.distorted1) {
                        data1 = [...props.distorted1.scaledVmaf]
                    }
                    if (props.distorted2) {
                        data2 = [...props.distorted2.scaledVmaf]
                    }
                    if (props.distorted3) {
                        data3 = [...props.distorted3.scaledVmaf]
                    }
                    if (props.distorted4) {
                        data4 = [...props.distorted4.scaledVmaf]
                    }
                    if (props.dataAminB){
                        dataDelta = [...props.dataAminB.vmaf]
                    }
                }
                if (props.metric === 'PSNR'){
                    if (props.distorted1){
                        data1 = [...props.distorted1.scaledPsnr]
                    }
                    if (props.distorted2){
                        data2 = [...props.distorted2.scaledPsnr]
                    }
                    if (props.distorted3) {
                        data3 = [...props.distorted3.scaledPsnr]
                    }
                    if (props.distorted4) {
                        data4 = [...props.distorted4.scaledPsnr]
                    }
                    if (props.dataAminB) {
                        dataDelta = [...props.dataAminB.psnr]
                    }
                }
                updatedChartOptions.series = []
                if (props.distorted1){
                    updatedChartOptions.series = updatedChartOptions.series.concat({
                        name:props.distorted1.filename,
                        data:data1,
                        color: '#000',
                        lineWidth: 1,
                        yAxis: 0})
                }
                if (props.distorted2){
                    updatedChartOptions.series = updatedChartOptions.series.concat({
                        name:props.distorted2.filename,
                        data:data2,
                        color: '#FFB400',
                        lineWidth: 1,
                        yAxis: 0})
                }
                if (props.distorted3){
                    updatedChartOptions.series = updatedChartOptions.series.concat({
                        name:props.distorted3.filename,
                        data:data3,
                        color: '#3380FF',
                        lineWidth: 1,
                        yAxis: 0})
                }
                if (props.distorted4){
                    updatedChartOptions.series = updatedChartOptions.series.concat({
                        name:props.distorted4.filename,
                        data:data4,
                        color: '#FF3333',
                        lineWidth: 1,
                        yAxis: 0})
                }
                updatedChartOptions.series = updatedChartOptions.series.concat({
                    name:"A-B",
                    data:dataDelta,
                    color: '#51BCDA',
                    lineWidth: 1,
                    yAxis: 1
                })
                updatedChartOptions.yAxis = [
                    { // Primary axis A, B
                        max: 100,
                        labels: {
                            style: {
                                color: '#000'
                            }
                        },
                        title: {
                            text: props.metric,
                            style: {
                                color: '#000'
                            }
                        },
                        opposite: true
                    },
                    { // Secondary axis A min B
                        gridLineWidth: 0,
                        labels: {
                            style: {
                                color: '#51BCDA'
                            }
                        },
                        title: {
                            text: 'Delta ' + props.metric,
                            style: {
                                color: '#51BCDA'
                            }
                        },
                    }
                ]
                return updatedChartOptions
            })
        }
    }, [props.distorted1, props.distorted2, props.distorted3, props.distorted4, props.dataAminB, props.metric])


    async function onButtonGroupClick(selectedLabel, action) {
        let selectedMetricData = props.distorted1.vmaf

        if (selectedLabel === "AminB"){
            if (props.metric === 'VMAF'){
                selectedMetricData = [...props.dataAminB.vmaf]
            }
            if (props.metric === 'PSNR'){
                selectedMetricData = [...props.dataAminB.psnr]
            }
        }
        if (selectedLabel === "dis1"){
            if (props.metric === 'VMAF'){
                selectedMetricData = [...props.distorted1.vmaf]
            }
            if (props.metric === 'PSNR'){
                selectedMetricData = [...props.distorted1.psnr]
            }
        }
        if (action === "peakSearch"){
            let windowMin = (xExtremes.min > 0) ? xExtremes.min : 0
            let windowMax = xExtremes.max

            if (!windowMin) {
                windowMin = 0
                windowMax = selectedMetricData.length
            }
            let localMin = argPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
            setLastSelectedFrame(localMin)
        }
        if (action === "maxPeakSearch"){
            let windowMin = (xExtremes.min > 0) ? xExtremes.min : 0
            let windowMax = xExtremes.max

            if (!windowMin) {
                windowMin = 0
                windowMax = selectedMetricData.length
            }
            let localMin = argMaxPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
            setLastSelectedFrame(localMin)
        }
        if (action === "peakLeft"){
            if (lastSelectedFrame !== null){
                let windowMin = (xExtremes.min > 0) ? xExtremes.min : 0
                let windowMax = lastSelectedFrame

                if (!windowMin) {
                    windowMin = 0
                }
                let localMin = argPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
                setLastSelectedFrame(localMin)
            }
        }
        if (action === "maxPeakLeft"){
            if (lastSelectedFrame !== null){
                let windowMin = (xExtremes.min > 0) ? xExtremes.min : 0
                let windowMax = lastSelectedFrame

                if (!windowMin) {
                    windowMin = 0
                }
                let localMax = argMaxPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
                setLastSelectedFrame(localMax)
            }
        }
        if (action === "peakRight"){
            if (lastSelectedFrame !== null){
                let windowMin
                // exclude current selected point, but limit to length of data
                windowMin = (lastSelectedFrame + 1 < selectedMetricData.length) ? lastSelectedFrame + 1 : selectedMetricData.length - 1
                // Don't go beyond current span
                if (xExtremes.max !== null){
                    windowMin = (windowMin < xExtremes.max) ? windowMin : xExtremes.max
                }
                let windowMax
                // Don't go beyond current span, if no span defined limit to length of data
                if (xExtremes.max !== null){
                    windowMax = (xExtremes.max < selectedMetricData.length) ? xExtremes.max : selectedMetricData.length - 1
                } else {
                    windowMax = selectedMetricData.length - 1
                }
                let localMin = argPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
                setLastSelectedFrame(localMin)
            }
        }
        if (action === "maxPeakRight"){
            if (lastSelectedFrame !== null){
                let windowMin
                // exclude current selected point, but limit to length of data
                windowMin = (lastSelectedFrame + 1 < selectedMetricData.length) ? lastSelectedFrame + 1 : selectedMetricData.length - 1
                // Don't go beyond current span
                if (xExtremes.max !== null){
                    windowMin = (windowMin < xExtremes.max) ? windowMin : xExtremes.max
                }
                let windowMax
                // Don't go beyond current span, if no span defined limit to length of data
                if (xExtremes.max !== null){
                    windowMax = (xExtremes.max < selectedMetricData.length) ? xExtremes.max : selectedMetricData.length - 1
                } else {
                    windowMax = selectedMetricData.length - 1
                }
                let localMax = argMaxPeak(selectedMetricData.slice(windowMin,windowMax)) + windowMin
                setLastSelectedFrame(localMax)
            }
        }
        if (action === "toLeft"){
            setLastSelectedFrame(prevLastSelectedFrame => prevLastSelectedFrame - 1)
        }
        if (action === "toRight"){
            setLastSelectedFrame(prevLastSelectedFrame => prevLastSelectedFrame + 1)
        }
    }

    return(
        <Flex direction={"column"}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell as={"th"} className="synamedia-th">Legend</TableCell>
                        <TableCell as={"th"} className="synamedia-th">Filename</TableCell>
                        <TableCell as={"th"} className="synamedia-th">VMAF window avg.</TableCell>
                        <TableCell as={"th"} className="synamedia-th">PSNR window avg.</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.distorted1 ?
                        <TableRow>
                            <TableCell className="synamedia-td"><div className="box syna_black" /></TableCell>
                            <TableCell className="synamedia-td">{props.distorted1.encMedia.filename}</TableCell>
                            <TableCell className="synamedia-td">{vmafDis1Avg}</TableCell>
                            <TableCell className="synamedia-td">{psnrDis1Avg}</TableCell>
                        </TableRow> : <TableRow/>}
                    {props.distorted2 ?
                        <TableRow>
                            <TableCell className="synamedia-td"><div className="box syna_yellow" /></TableCell>
                            <TableCell className="synamedia-td">{props.distorted2.encMedia.filename}</TableCell>
                            <TableCell className="synamedia-td">{vmafDis2Avg}</TableCell>
                            <TableCell className="synamedia-td">{psnrDis2Avg}</TableCell>
                        </TableRow> : <TableRow/>}
                    {props.distorted3 ?
                        <TableRow>
                            <TableCell className="synamedia-td"><div className="box syna_blue" /></TableCell>
                            <TableCell className="synamedia-td">{props.distorted3.encMedia.filename}</TableCell>
                            <TableCell className="synamedia-td">{vmafDis3Avg}</TableCell>
                            <TableCell className="synamedia-td">{psnrDis3Avg}</TableCell>
                        </TableRow> : <TableRow/>}
                    {props.distorted4 ?
                        <TableRow>
                            <TableCell className="synamedia-td"><div className="box syna_red" /></TableCell>
                            <TableCell className="synamedia-td">{props.distorted4.encMedia.filename}</TableCell>
                            <TableCell className="synamedia-td">{vmafDis4Avg}</TableCell>
                            <TableCell className="synamedia-td">{psnrDis4Avg}</TableCell>
                        </TableRow> : <TableRow/>}
                </TableBody>
            </Table>
            <Flex direction={"row"} justifyContent={"center"}>
                <Flex direction={"column"} justifyContent={"center"}>
                    <Flex direction={"row"} justifyContent={"center"}>
                        <Tooltip
                            title="Max Pk Left"
                            placement="top"
                        >
                            <Button className="light-blue-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('AminB', 'maxPeakLeft')}>
                                <FaArrowLeft/>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Max Pk Search"
                            placement="top"
                        >
                            <Button className="light-blue-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('AminB', 'maxPeakSearch')}>
                                <FaArrowUp/>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Max Pk Right"
                            placement="top"
                        >
                            <Button className="light-blue-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('AminB', 'maxPeakRight')}>
                                <FaArrowRight/>
                            </Button>
                        </Tooltip>
                    </Flex>
                    <Flex direction={"row"} justifyContent={"center"}>
                        <Tooltip
                            title="Min Pk Left"
                            placement="bottom"
                        >
                            <Button className="light-blue-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('AminB', 'peakLeft')}>
                                <FaArrowLeft/>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Min Pk Search"
                            placement="bottom"
                        >
                            <Button className="light-blue-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('AminB', 'peakSearch')}>
                                <FaArrowDown/>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Min Pk Right"
                            placement="bottom"
                        >
                            <Button className="light-blue-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('AminB', 'peakRight')}>
                                <FaArrowRight/>
                            </Button>
                        </Tooltip>
                    </Flex>
                </Flex>
                <View width={"800px"}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                        ref={chartRef}
                    />
                </View>
                <Flex direction={"column"} justifyContent={"center"}>
                    <Flex direction={"row"} justifyContent={"center"}>
                        <Tooltip
                            title="Min Pk Left"
                            placement="top"
                        >
                            <Button className="black-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('dis1', 'peakLeft')}>
                                <FaArrowLeft/>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Min Pk Search"
                            placement="top"
                        >
                            <Button className="black-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('dis1', 'peakSearch')}>
                                <FaArrowDown/>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Min Pk Right"
                            placement="top"
                        >
                            <Button className="black-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('dis1', 'peakRight')}>
                                <FaArrowRight/>
                            </Button>
                        </Tooltip>
                    </Flex>
                    <Flex direction={"row"} justifyContent={"center"}>
                        <Tooltip
                            title="Min Pk Left"
                            placement="top"
                        >
                            <Button className="yellow-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('dis2', 'peakLeft')}>
                                <FaArrowLeft/>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Min Pk Search"
                            placement="top"
                        >
                            <Button className="yellow-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('dis2', 'peakSearch')}>
                                <FaArrowDown/>
                            </Button>
                        </Tooltip>
                        <Tooltip
                            title="Min Pk Right"
                            placement="top"
                        >
                            <Button className="yellow-chart-button" size="small"
                                    onClick={()=>onButtonGroupClick('dis2', 'peakRight')}>
                                <FaArrowRight/>
                            </Button>
                        </Tooltip>
                    </Flex>
                        {props.distorted3 &&
                            <Flex direction={"row"} justifyContent={"center"}>
                                <Tooltip
                                    title="Min Pk Left"
                                    placement="top"
                                >
                                    <Button className="blue-chart-button" size="small"
                                            onClick={()=>onButtonGroupClick('dis3', 'peakLeft')}>
                                        <FaArrowLeft/>
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    title="Min Pk Search"
                                    placement="top"
                                >
                                    <Button className="blue-chart-button" size="small"
                                            onClick={()=>onButtonGroupClick('dis3', 'peakSearch')}>
                                        <FaArrowDown/>
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    title="Min Pk Right"
                                    placement="top"
                                >
                                    <Button className="blue-chart-button" size="small"
                                            onClick={()=>onButtonGroupClick('dis3', 'peakRight')}>
                                        <FaArrowRight/>
                                    </Button>
                                </Tooltip>
                            </Flex>
                        }
                        {props.distorted4 &&
                            <Flex direction={"row"} justifyContent={"center"}>
                                <Tooltip
                                    title="Min Pk Left"
                                    placement="top"
                                >
                                    <Button className="blue-chart-button" size="small"
                                            onClick={()=>onButtonGroupClick('dis4', 'peakLeft')}>
                                        <FaArrowLeft/>
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    title="Min Pk Search"
                                    placement="top"
                                >
                                    <Button className="blue-chart-button" size="small"
                                            onClick={()=>onButtonGroupClick('dis4', 'peakSearch')}>
                                        <FaArrowDown/>
                                    </Button>
                                </Tooltip>
                                <Tooltip
                                    title="Min Pk Right"
                                    placement="top"
                                >
                                    <Button className="blue-chart-button" size="small"
                                            onClick={()=>onButtonGroupClick('dis4', 'peakRight')}>
                                        <FaArrowRight/>
                                    </Button>
                                </Tooltip>
                            </Flex>
                        }
                </Flex>
            </Flex>
            <Flex direction={"row"} justifyContent={"center"}>
                <Tooltip
                    title="Back"
                    placement="top"
                >
                    <Button className="black-chart-button" size="small"
                            onClick={()=>onButtonGroupClick('Span', 'toLeft')}>
                        <FaArrowLeft/>
                    </Button>
                </Tooltip>
                <Tooltip
                    title="Forward"
                    placement="top"
                >
                    <Button className="black-chart-button" size="small"
                            onClick={()=>onButtonGroupClick('Span', 'toRight')}>
                        <FaArrowRight/>
                    </Button>
                </Tooltip>
            </Flex>
        </Flex>
    )
}

export default MetricChart;