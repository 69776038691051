import React from "react";

import {Table, TableBody, TableCell, TableHead, TableRow} from '@aws-amplify/ui-react';
import ResolutionCell from "../../components/vqaas/ResolutionCell";
import FramerateCell from "./FramerateCell";
import PixelFormatCell from "./PixelFormatCell";
import CodecCell from "./CodecCell";
import DurationTimeCell from "./DurationTimeCell";
import DurationFramesCell from "./DurationFramesCell";
import CreatedAtCell from "./CreatedAtCell";
import VmafCell from "./VmafCell";
import PsnrCell from "./PsnrCell";


function CompareAnalysisSpecs(props) {
    const {encode_analysis1, encode_analysis2, encode_analysis3, encode_analysis4} = props
    let encMedia1 = encode_analysis1 ? encode_analysis1.encMedia : null
    let encMedia2 = encode_analysis2 ? encode_analysis2.encMedia : null
    let encMedia3 = encode_analysis3 ? encode_analysis3.encMedia : null
    let encMedia4 = encode_analysis4 ? encode_analysis4.encMedia : null
    let filename1, filename2, filename3, filename4
    if (encMedia1) {filename1 = encMedia1.filename ? encMedia1.filename : ''} else { filename1 = '' }
    if (encMedia2) {filename2 = encMedia2.filename ? encMedia2.filename : ''} else { filename2 = '' }
    if (encMedia3) {filename3 = encMedia3.filename ? encMedia3.filename : ''} else { filename3 = '' }
    if (encMedia4) {filename4 = encMedia4.filename ? encMedia4.filename : ''} else { filename4 = '' }

    return (
        <>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell as={"th"}/>
                    <TableCell as={"th"} className="synamedia-th">{filename1}</TableCell>
                    <TableCell as={"th"} className="synamedia-th">{filename2}</TableCell>
                    <TableCell as={"th"} className="synamedia-th">{filename3}</TableCell>
                    <TableCell as={"th"} className="synamedia-th">{filename4}</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                <TableRow>
                    <TableCell className="synamedia-td">Legend</TableCell>
                    <TableCell className="synamedia-td">{(encode_analysis1) ? <div className="box syna_black" /> : ''}</TableCell>
                    <TableCell className="synamedia-td">{(encode_analysis2) ? <div className="box syna_yellow" /> : ''}</TableCell>
                    <TableCell className="synamedia-td">{(encode_analysis3) ? <div className="box syna_blue" /> : ''}</TableCell>
                    <TableCell className="synamedia-td">{(encode_analysis4) ? <div className="box syna_red" /> : ''}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell className="synamedia-td">Resolution</TableCell>
                    <ResolutionCell distorted={encMedia1}/>
                    <ResolutionCell distorted={encMedia2}/>
                    <ResolutionCell distorted={encMedia3}/>
                    <ResolutionCell distorted={encMedia4}/>
                </TableRow>
                <TableRow>
                    <TableCell className="synamedia-td">Framerate</TableCell>
                    <FramerateCell distorted={encMedia1}/>
                    <FramerateCell distorted={encMedia2}/>
                    <FramerateCell distorted={encMedia3}/>
                    <FramerateCell distorted={encMedia4}/>
                </TableRow>
                <TableRow>
                    <TableCell className="synamedia-td">Pixel format</TableCell>
                    <PixelFormatCell distorted={encMedia1}/>
                    <PixelFormatCell distorted={encMedia2}/>
                    <PixelFormatCell distorted={encMedia3}/>
                    <PixelFormatCell distorted={encMedia4}/>
                </TableRow>
                <TableRow>
                    <TableCell className="synamedia-td">Codec</TableCell>
                    <CodecCell distorted={encMedia1}/>
                    <CodecCell distorted={encMedia2}/>
                    <CodecCell distorted={encMedia3}/>
                    <CodecCell distorted={encMedia4}/>
                </TableRow>
                <TableRow>
                    <TableCell className="synamedia-td">Duration</TableCell>
                    <DurationTimeCell distorted={encMedia1}/>
                    <DurationTimeCell distorted={encMedia2}/>
                    <DurationTimeCell distorted={encMedia3}/>
                    <DurationTimeCell distorted={encMedia4}/>
                </TableRow>
                <TableRow>
                    <TableCell className="synamedia-td">Duration</TableCell>
                    <DurationFramesCell distorted={encMedia1}/>
                    <DurationFramesCell distorted={encMedia2}/>
                    <DurationFramesCell distorted={encMedia3}/>
                    <DurationFramesCell distorted={encMedia4}/>
                </TableRow>
                <TableRow>
                    <TableCell className="synamedia-td">Upload Date</TableCell>
                    <CreatedAtCell distorted={encode_analysis1}/>
                    <CreatedAtCell distorted={encode_analysis2}/>
                    <CreatedAtCell distorted={encode_analysis3}/>
                    <CreatedAtCell distorted={encode_analysis4}/>
                </TableRow>
                <TableRow>
                    <TableCell className="synamedia-td">VMAF mean</TableCell>
                    <VmafCell distorted={encode_analysis1}/>
                    <VmafCell distorted={encode_analysis2}/>
                    <VmafCell distorted={encode_analysis3}/>
                    <VmafCell distorted={encode_analysis4}/>
                </TableRow>
                <TableRow>
                    <TableCell className="synamedia-td">PSNR mean</TableCell>
                    <PsnrCell distorted={encode_analysis1}/>
                    <PsnrCell distorted={encode_analysis2}/>
                    <PsnrCell distorted={encode_analysis3}/>
                    <PsnrCell distorted={encode_analysis4}/>
                </TableRow>
                </TableBody>
            </Table>
        </>
    );
}

export default CompareAnalysisSpecs;
