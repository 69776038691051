import React from "react"
import {Button, Flex, Icon, TableCell, TableRow, View} from '@aws-amplify/ui-react';

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
function humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}


function S3TableEntry(props) {
    return (
        <TableRow>
            <TableCell>
                <Flex direction={"row"} justifyContent="flex-start" alignItems="baseline" gap={"1rem"}>
                    <View width={"20px"} height={"20px"}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="#FFF" d="M3.5 17h13c.275 0 .5-.225.5-.5v-13c0-.275-.225-.5-.5-.5h-13c-.275 0-.5.225-.5.5v13c0 .275.225.5.5.5z"/><path opacity=".64" fill="#605E5C" d="M16.5 18h-13c-.827 0-1.5-.673-1.5-1.5v-13C2 2.673 2.673 2 3.5 2h13c.827 0 1.5.673 1.5 1.5v13c0 .827-.673 1.5-1.5 1.5zM3.5 3a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-13a.5.5 0 00-.5-.5h-13z"/><path fill="none" stroke="#69AFE5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" d="M12.5 10l-4-2.5v5z"/></svg>
                    </View>
                    {
                        props.s3_object.Key ?
                            props.s3_object.Key.split('/')[props.s3_object.Key.split('/').length - 1]  :
                            <div className="uil-reload-css reload-small mr-1 "><div /></div>
                    }
                </Flex>
            </TableCell>
            <TableCell>
                {
                    props.s3_object.LastModified ?
                        props.s3_object.LastModified :
                        <div className="uil-reload-css reload-small mr-1 "><div /></div>
                }
            </TableCell>
            <TableCell>
                {
                    parseInt(props.s3_object.Size) ?
                        humanFileSize(parseInt(props.s3_object.Size), true, 1)  :
                        <></>
                }
            </TableCell>
            { props.s3_object.Uploaded ?
                <TableCell className="text-right">Uploaded</TableCell> :
                <TableCell className="td-actions text-right">
                    <Button
                        className="reference-card-button"
                        data-toggle="tooltip"
                        data-s3_object_id={props.s3_object.id}
                        data-s3_object_key={props.s3_object.Key}
                        onClick={props.onImportFromS3}
                        size="small"
                        gap="0.5rem"
                    >
                        <Icon
                            ariaLabel="Plus"
                            viewBox={{width: 24, height: 24}}
                            pathData="M20.484,20.485 C18.218,22.752 15.205,24.000 12.000,24.000 C8.795,24.000 5.782,22.752 3.516,20.485 C1.248,18.219 0.000,15.205 0.000,12.000 C0.000,8.795 1.248,5.782 3.516,3.516 C5.781,1.249 8.795,-0.000 12.000,-0.000 C15.205,-0.000 18.219,1.249 20.485,3.516 C22.752,5.782 24.000,8.795 24.000,12.000 C24.000,15.205 22.752,18.219 20.484,20.485 ZM19.070,4.930 C17.182,3.040 14.671,2.000 12.000,2.000 C9.329,2.000 6.818,3.040 4.930,4.930 C3.040,6.819 2.000,9.329 2.000,12.000 C2.000,14.671 3.040,17.182 4.930,19.071 C6.818,20.960 9.329,22.000 12.000,22.000 C14.671,22.000 17.182,20.960 19.070,19.071 C20.960,17.182 22.000,14.671 22.000,12.000 C22.000,9.329 20.960,6.819 19.070,4.930 ZM17.000,13.000 L13.000,13.000 L13.000,17.000 C13.000,17.552 12.552,18.000 12.000,18.000 C11.448,18.000 11.000,17.552 11.000,17.000 L11.000,13.000 L7.000,13.000 C6.448,13.000 6.000,12.552 6.000,12.000 C6.000,11.448 6.448,11.000 7.000,11.000 L11.000,11.000 L11.000,7.000 C11.000,6.448 11.448,6.000 12.000,6.000 C12.552,6.000 13.000,6.448 13.000,7.000 L13.000,11.000 L17.000,11.000 C17.552,11.000 18.000,11.448 18.000,12.000 C18.000,12.552 17.552,13.000 17.000,13.000 Z"
                        />Import
                    </Button>
                </TableCell>
            }
        </TableRow>
    )
}

export default S3TableEntry