import React from "react";
import {TableCell} from '@aws-amplify/ui-react';

function ResolutionCell(props) {
  return (
      <>
        <TableCell className="synamedia-td">
          {
            props.distorted ?
                props.distorted.spec_width + 'x' + props.distorted.spec_height
                :
                ''
          }
        </TableCell>
      </>
  );
}

export default ResolutionCell;
