import React from "react";
import {Link as ReactRouterLink} from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
import {MenuButton, Divider, Flex, Heading, Icon, Image, Link, Menu, MenuItem} from '@aws-amplify/ui-react';

function WhiteNavbar(props) {
    const [bodyClick, setBodyClick] = React.useState(false);

    // Headroom effect
    React.useEffect(() => {
        let headroom = new Headroom(document.getElementById("navbar-main"));
        // initialise
        headroom.init();
    });

    async function handleSignOutClick() {
        props.onSignOutClick()
        props.signOut()
    }

    return (
        <>
            {bodyClick ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setBodyClick(false);
                    }}
                />
            ) : null}
            <Flex
                height="70px"
                className="fixed-top"
                id={"navbar-main"}
                backgroundColor={"white"}
                direction="row"
                alignItems={"center"}
                justifyContent={{ base: 'space-between', large: 'center' }}
                gap={{ large: '20rem' }}
            >
                <Menu triggerClassName="custom-trigger-button">
                    <Heading level={5} padding={"1rem"}>Media</Heading>
                    <MenuItem><Link className="custom-link" as={ReactRouterLink} to="/media-page">Media Center</Link></MenuItem>
                    <MenuItem><Link className="custom-link" as={ReactRouterLink} to="/s3-page">REST API</Link></MenuItem>
                </Menu>
                <Image
                    height={"50%"}
                    minWidth={"140px"}
                    backgroundColor={"white"}
                    src="https://www.synamedia.com/wp-content/themes/synamedia-wp-theme/images/brand/synamedia-logo.gif"
                    alt=" "
                />
                <Menu
                    trigger={
                        <MenuButton className="custom-trigger-button" size="Large">
                            <Icon
                                ariaLabel="male-user"
                                viewBox={{ width: 32, height: 32 }}
                                pathData="M30.000,32.000 L2.000,32.000 C0.898,32.000 0.000,31.103 0.000,30.001 L0.000,28.001 C0.000,27.635 0.200,27.298 0.522,27.123 L10.310,21.785 C7.032,18.238 6.996,13.223 6.996,12.991 L7.002,6.996 C7.002,6.941 7.007,6.885 7.016,6.831 C7.705,2.745 12.218,-0.000 16.000,-0.000 C16.001,-0.000 16.003,-0.000 16.004,-0.000 C19.784,-0.000 24.295,2.743 24.986,6.828 C24.995,6.883 25.000,6.939 25.000,6.994 L25.004,12.989 C25.004,13.221 24.968,18.236 21.689,21.783 L31.478,27.121 C31.800,27.296 32.000,27.633 32.000,27.999 L32.000,30.001 C32.000,31.103 31.103,32.000 30.000,32.000 ZM30.000,30.001 L30.000,31.000 L30.000,30.001 L30.000,30.001 ZM2.000,28.595 L2.000,30.001 L29.998,30.001 L29.999,28.594 L19.521,22.878 C19.229,22.718 19.034,22.423 19.004,22.091 C18.974,21.759 19.111,21.434 19.370,21.223 C22.968,18.306 23.004,13.043 23.004,12.991 L23.000,7.083 C22.438,4.082 18.879,1.998 16.000,1.998 C13.120,1.999 9.563,4.085 9.002,7.085 L8.996,12.993 C8.996,13.044 9.052,18.323 12.630,21.225 C12.889,21.436 13.026,21.761 12.996,22.093 C12.966,22.425 12.772,22.720 12.479,22.880 L2.000,28.595 Z"
                            />
                        </MenuButton>
                    }
                >
                    <MenuItem><Link className="custom-link" as={ReactRouterLink} to="/profile-page">Profile</Link></MenuItem>
                    {props.adminVisible ?
                        <>
                            <Divider/>
                            <MenuItem><Link className="custom-link" as={ReactRouterLink} to="/dashboard-accounts">Accounts</Link></MenuItem>
                            <Divider/>
                            <MenuItem><Link className="custom-link" as={ReactRouterLink} to="/add-team">Add Team</Link></MenuItem>
                            <MenuItem><Link className="custom-link" as={ReactRouterLink} to="/list-team">List Team</Link></MenuItem>
                        </>
                        :
                        <></>
                    }
                    <Divider/>
                    <MenuItem className="custom-link" onClick={handleSignOutClick}>Sign Out</MenuItem>
                </Menu>
            </Flex>
            {/*        <Navbar className="fixed-top" expand="lg" id="navbar-main">*/}
            {/*          <Container>*/}
            {/*            <div className="navbar-translate">*/}
            {/*              <NavbarBrand id="navbar-brand" to="/synamedia" tag={Link}>*/}
            {/*                <img alt={"Synamedia logo"} src="https://www.synamedia.com/wp-content/themes/synamedia-wp-theme/images/brand/synamedia-logo.gif"/>*/}
            {/*              </NavbarBrand>*/}
            {/*            </div>*/}
            {/*            <Collapse navbar isOpen={collapseOpen}>*/}
            {/*              <Nav className="ml-auto" navbar>*/}
            {/*                <UncontrolledDropdown nav inNavbar>*/}
            {/*                  <DropdownToggle className="mr-2" color="default" caret nav>*/}
            {/*                    References*/}
            {/*                  </DropdownToggle>*/}
            {/*                  <DropdownMenu className="dropdown-danger" right>*/}
            {/*                    <DropdownItem className="synamedia-dropdown-header" header>*/}
            {/*                      VQaaS Storage*/}
            {/*                    </DropdownItem>*/}
            {/*                    <DropdownItem className="synamedia-dropdown-item" to="/media-page" tag={Link}>*/}
            {/*                      My References*/}
            {/*                    </DropdownItem>*/}
            {/*                    {props.account.s3_bucket ?*/}
            {/*                        <>*/}
            {/*                          <DropdownItem className="synamedia-dropdown-header" header>*/}
            {/*                            Cross account S3 bucket*/}
            {/*                          </DropdownItem>*/}
            {/*                          <DropdownItem className="synamedia-dropdown-item" to="/s3-page" tag={Link}>*/}
            {/*                            {props.account.s3_bucket}*/}
            {/*                          </DropdownItem>*/}
            {/*                        </> : <></>*/}
            {/*                    }*/}
            {/*                    /!**/}
            {/*                    {mySubscriptions.length ?*/}
            {/*                        <DropdownItem header>*/}
            {/*                          Subscriptions*/}
            {/*                        </DropdownItem> : <></>*/}
            {/*                    }*/}
            {/*                    {tableSubscriptions}*/}
            {/**!/*/}
            {/*                    {props.account.teams.items.length ?*/}
            {/*                        <DropdownItem className="synamedia-dropdown-header" header>*/}
            {/*                          Teams*/}
            {/*                        </DropdownItem> : <></>}*/}
            {/*                    {tableTeams}*/}
            {/*                  </DropdownMenu>*/}
            {/*                </UncontrolledDropdown>*/}
            {/*                <UncontrolledDropdown nav inNavbar>*/}
            {/*                  <DropdownToggle className="mr-2" color="default" caret nav>*/}
            {/*                    Account*/}
            {/*                  </DropdownToggle>*/}
            {/*                  <DropdownMenu className="dropdown-danger" right>*/}
            {/*                    <DropdownItem className="synamedia-dropdown-item" to="/profile-page" tag={Link}>*/}
            {/*                      <i className="nc-icon nc-single-02"/>*/}
            {/*                      Profile*/}
            {/*                    </DropdownItem>*/}
            {/*                    /!**/}
            {/*                    <DropdownItem to="/manage-subscription" tag={Link}>*/}
            {/*                      <i className="nc-icon nc-money-coins" />*/}
            {/*                      Credits*/}
            {/*                    </DropdownItem>*/}
            {/**!/*/}
            {/*                  </DropdownMenu>*/}
            {/*                </UncontrolledDropdown>*/}
            {/*                {props.adminVisible ?*/}
            {/*                    <UncontrolledDropdown nav inNavbar>*/}
            {/*                      <DropdownToggle className="mr-2" color="default" caret nav>*/}
            {/*                        Rate-Quality Analysis*/}
            {/*                      </DropdownToggle>*/}
            {/*                      <DropdownMenu className="dropdown-danger" right>*/}
            {/*                        <DropdownItem className="synamedia-dropdown-item" to="/rate-quality-dashboard" tag={Link}>*/}
            {/*                          <i className="nc-icon nc-settings"/>*/}
            {/*                          Dashboard*/}
            {/*                        </DropdownItem>*/}
            {/*                        <DropdownItem className="synamedia-dropdown-item" to="/rate-quality-testing" tag={Link}>*/}
            {/*                          <i className="nc-icon nc-settings"/>*/}
            {/*                          Testing wizard*/}
            {/*                        </DropdownItem>*/}
            {/*                      </DropdownMenu>*/}
            {/*                    </UncontrolledDropdown> : <></>}*/}
            {/*                {props.adminVisible ?*/}
            {/*                    <UncontrolledDropdown nav inNavbar>*/}
            {/*                      <DropdownToggle className="mr-2" color="default" caret nav>*/}
            {/*                        VOD Monitoring*/}
            {/*                      </DropdownToggle>*/}
            {/*                      <DropdownMenu className="dropdown-danger" right>*/}
            {/*                        <DropdownItem className="synamedia-dropdown-item" to="/vod-dashboard" tag={Link}>*/}
            {/*                          <i className="nc-icon nc-settings"/>*/}
            {/*                          Dashboard*/}
            {/*                        </DropdownItem>*/}
            {/*                        <DropdownItem className="synamedia-dropdown-header" header>*/}
            {/*                          Add VOD Source*/}
            {/*                        </DropdownItem>*/}
            {/*                        <DropdownItem className="synamedia-dropdown-item" to="/vod-local-source" tag={Link}>*/}
            {/*                          <i className="nc-icon nc-settings"/>*/}
            {/*                          Local Source*/}
            {/*                        </DropdownItem>*/}
            {/*                        <DropdownItem className="synamedia-dropdown-item" to="/vod-remote-source" tag={Link}>*/}
            {/*                          <i className="nc-icon nc-settings"/>*/}
            {/*                          Remote Source*/}
            {/*                        </DropdownItem>*/}
            {/*                      </DropdownMenu>*/}
            {/*                    </UncontrolledDropdown> : <></>}*/}
            {/*                {props.adminVisible ?*/}
            {/*                    <UncontrolledDropdown nav inNavbar>*/}
            {/*                      <DropdownToggle className="mr-2" color="default" caret nav>*/}
            {/*                        Admin*/}
            {/*                      </DropdownToggle>*/}
            {/*                      <DropdownMenu className="dropdown-danger" right>*/}
            {/*                        <DropdownItem className="synamedia-dropdown-header" header>*/}
            {/*                          Account*/}
            {/*                        </DropdownItem>*/}
            {/*                        <DropdownItem className="synamedia-dropdown-item" to="/dashboard-accounts" tag={Link}>*/}
            {/*                          <i className="nc-icon nc-settings"/>*/}
            {/*                          Dashboard*/}
            {/*                        </DropdownItem>*/}
            {/*                        <DropdownItem className="synamedia-dropdown-item" to="/list-accounts" tag={Link}>*/}
            {/*                          <i className="nc-icon nc-settings"/>*/}
            {/*                          List*/}
            {/*                        </DropdownItem>*/}
            {/*                        <DropdownItem className="synamedia-dropdown-header" header>*/}
            {/*                          Subscription*/}
            {/*                        </DropdownItem>*/}
            {/*                        <DropdownItem className="synamedia-dropdown-item" to="/add-subscription" tag={Link}>*/}
            {/*                          <i className="nc-icon nc-settings"/>*/}
            {/*                          Create*/}
            {/*                        </DropdownItem>*/}
            {/*                        <DropdownItem className="synamedia-dropdown-header" header>*/}
            {/*                          Team*/}
            {/*                        </DropdownItem>*/}
            {/*                        <DropdownItem className="synamedia-dropdown-item" to="/add-team" tag={Link}>*/}
            {/*                          <i className="nc-icon nc-settings"/>*/}
            {/*                          Create*/}
            {/*                        </DropdownItem>*/}
            {/*                        <DropdownItem className="synamedia-dropdown-item" to="/list-team" tag={Link}>*/}
            {/*                          <i className="nc-icon nc-settings"/>*/}
            {/*                          List*/}
            {/*                        </DropdownItem>*/}
            {/*                      </DropdownMenu>*/}
            {/*                    </UncontrolledDropdown> : <></>}*/}
            {/*                <NavItem>*/}
            {/*                  <NavLink href="/help.pdf">Help</NavLink>*/}
            {/*                </NavItem>*/}
            {/*              </Nav>*/}
            {/*              <Button*/}
            {/*                  className="btn-synamedia"*/}
            {/*                  onClick={handleSignOutClick}>*/}
            {/*                Sign out*/}
            {/*              </Button>*/}
            {/*            </Collapse>*/}
            {/*          </Container>*/}
            {/*        </Navbar>*/}
        </>
    );
}

export default WhiteNavbar;
