import React from "react";
import {ScrollView, Table, TableBody, TableCell, TableHead, TableRow} from '@aws-amplify/ui-react';

// core components
import S3TableEntry from "../../components/vqaas/S3TableEntry";
import S3FolderEntry from "../../components/vqaas/S3FolderEntry";

function S3Table(props) {

    const folderEntries = props.s3Folders.map(s3Folder =>
        <S3FolderEntry
            key={s3Folder.id}
            s3_object={s3Folder}
            onFolderClick={props.onFolderClick}/>)

    const tableEntries = props.s3Objects.map(s3Object =>
        <S3TableEntry
            key={s3Object.Key}
            s3_object={s3Object}
            onImportFromS3={props.onImportFromS3}/>)

    return (
        <ScrollView width="100%" height="500px" maxWidth="1200px">
            <Table variation="bordered" size="small" highlightOnHover={true}>
                <TableHead>
                <TableRow>
                    <TableCell as="th" className="synamedia-th">Name</TableCell>
                    <TableCell as="th" className="synamedia-th">Last Modified</TableCell>
                    <TableCell as="th" className="synamedia-th">Size</TableCell>
                    <TableCell as="th" className="synamedia-th text-right">Actions</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {folderEntries}
                {tableEntries}
                </TableBody>
            </Table>
        </ScrollView>
    );
}

export default S3Table;
