import React, {useState} from "react";
import {Button, TextAreaField, TextField, Flex, Heading, View} from '@aws-amplify/ui-react';

import FooterBlack from "components/Footers/FooterBlack.js";
import {v4 as uuid} from "uuid";
import MediaPageHeader from "../../components/Headers/MediaPageHeader";
import {API, graphqlOperation} from "aws-amplify";
import {listAccounts} from "../../graphql/queries";
import {createAccountTeam, createTeam} from "../../graphql/mutations";
import {useHistory} from "react-router-dom";
import WhiteNavbar from "../../components/Navbars/WhiteNavbar";


function AddTeam(props) {
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [accounts, setAccounts] = useState([])

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    }, []);

    let history = useHistory();

    React.useEffect(() => {
        async function getAllAccounts() {
            let nextToken = null;
            let account_list = []
            do {
                let chunk = await API.graphql(graphqlOperation(listAccounts, {limit: 999}));
                account_list = account_list.concat(chunk.data.listAccounts.items)
                nextToken = chunk.nextToken;
            } while (nextToken != null);
            if (mounted) {
                setAccounts(account_list)
            }
        }
        let mounted = true

        getAllAccounts().then()
        return function cleanup() {
            mounted = false;
        };
    }, [])

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        const addTeam = {
            id: uuid(),
            name: name,
            description: description,
        }
        let result = await API.graphql({ query: createTeam, variables: { input: addTeam } });
        for (let i=0; i < accounts.length; i++){
            if (accounts[i].checked){
                const addAccountTeam = {
                    id: uuid(),
                    accountID: accounts[i].id,
                    teamID: result.data.createTeam.id
                }
                await API.graphql({ query: createAccountTeam, variables: { input: addAccountTeam } });
            }
        }
        history.goBack()
    }

    const handleReset = async (evt) => {
        evt.preventDefault();
        history.goBack()
    }

    return (
        <>
            <WhiteNavbar {...props}/>
            <MediaPageHeader title={"Create Team"} />
            <div className="section">
                <Flex justifyContent="center" style={{fontFamily: "SourceSansPro"}}>
                    <View width={{base:'75%', xl:'75%'}}>
                        {/*Import files*/}
                        <View style={{paddingBottom:"2rem"}}>
                            <Heading level={"3"} style={{
                                borderBottomWidth:"1px",
                                borderBottomColor:"black",
                                borderBottomStyle:"solid"}}>Create Team</Heading>
                        <div>
                            <form
                                className="settings-form"
                                onSubmit={handleSubmit}
                                onReset={handleReset}>
                                <TextField
                                    labelHidden={false}
                                    label={"Name"}
                                    placeholder="Enter the team name here..."
                                    isMultiline={false}
                                    resize={'vertical'}
                                    onChange={e => setName(e.target.value)}
                                    value={name}
                                />
                                <br/>
                                <TextAreaField
                                    labelHidden={false}
                                    label={"Description"}
                                    placeholder="This is a textarea limited to 150 characters."
                                    resize={'vertical'}
                                    onChange={e => setDescription(e.target.value)}
                                    value={description}
                                />
                                <br/>
                                <Flex direction={"row"} justifyContent={"center"} >
                                    <Button
                                        className="reference-card-button"
                                        variation="primary"
                                        type={"reset"}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className="reference-card-button"
                                        variation="primary"
                                        type={"submit"}
                                    >
                                        Save
                                    </Button>
                                </Flex>
                            </form>
                        </div>
                        </View>
                    </View>
                </Flex>
            </div>
            <FooterBlack {...props}/>
        </>
    );
}

export default AddTeam;
