import React, {useState} from "react";
import {Button, Collection, Flex, Heading, Icon, ScrollView, SearchField, Table, TableBody, TableRow, TableCell, Text, View} from '@aws-amplify/ui-react';
import Tooltip from '@mui/material/Tooltip';
import FooterBlack from "components/Footers/FooterBlack.js";
import {v4 as uuid} from "uuid";
import MediaPageHeader from "../../components/Headers/MediaPageHeader";
import {API, graphqlOperation} from "aws-amplify";
import {listAccounts} from "../../graphql/queries";
import {
    createAccountTeam,
    deleteAccountTeam,
    updateTeam
} from "../../graphql/mutations";

import {useHistory, useParams} from "react-router-dom";
import WhiteNavbar from "../../components/Navbars/WhiteNavbar";
import VQTextField from "../../components/vqaas/VQTextField";

export const getTeamWithAccounts = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      description
      accounts {
        items {
          id
          account {
            id 
            email
          }
        }
        nextToken
      }
      references {
        items {
          id
          reference {
            id
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const getTeamNoReferences = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      description
      accounts {
        items {
          id
          account {
            id 
            email
          }
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

function BadgeWithClose(props) {
    const {backgroundColor, color, value, showClose} = props
    return <Flex direction={"row"} justifyContent={"flex-start"}>
        <View backgroundColor={backgroundColor} padding={"2px"} borderRadius={"10px"}>
            <Flex direction={"row"} alignItems={"center"} gap={"5px"} alignContent={"flex-start"}
                  style={{paddingRight: "8px", paddingLeft: "10px"}}>
                <Text color={color}>{value}</Text>
                { showClose ?
                <Button className="close-badge-button" size={"small"} padding={"2px"} onClick={props.onBadgeClose}>
                    <Icon
                        color={color}
                        ariaLabel="Close"
                        viewBox={{width: 24, height: 24}}
                        pathData="M12.000,24.000 C5.383,24.000 0.000,18.617 0.000,12.000 C0.000,5.383 5.383,0.000 12.000,0.000 C18.617,0.000 24.000,5.383 24.000,12.000 C24.000,18.617 18.617,24.000 12.000,24.000 ZM12.000,2.000 C6.486,2.000 2.000,6.486 2.000,12.000 C2.000,17.514 6.486,22.000 12.000,22.000 C17.514,22.000 22.000,17.514 22.000,12.000 C22.000,6.486 17.514,2.000 12.000,2.000 ZM16.707,16.707 C16.512,16.902 16.256,17.000 16.000,17.000 C15.744,17.000 15.488,16.902 15.293,16.707 L12.000,13.414 L8.707,16.707 C8.512,16.902 8.256,17.000 8.000,17.000 C7.744,17.000 7.488,16.902 7.293,16.707 C6.902,16.316 6.902,15.684 7.293,15.293 L10.586,12.000 L7.293,8.707 C6.902,8.316 6.902,7.684 7.293,7.293 C7.684,6.902 8.316,6.902 8.707,7.293 L12.000,10.586 L15.293,7.293 C15.684,6.902 16.316,6.902 16.707,7.293 C17.098,7.684 17.098,8.316 16.707,8.707 L13.414,12.000 L16.707,15.293 C17.098,15.684 17.098,16.316 16.707,16.707 Z"
                    />
                </Button> : <></>}
            </Flex>
        </View>
    </Flex>;
}


const EditTeam = React.memo( (props) => {
    const { team_id } = useParams()
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [accounts, setAccounts] = useState([])
    const [multipleSelect, setMultipleSelect] = React.useState([]);
    const [members, setMembers] = React.useState([]);
    const [searchFieldValue, setSearchFieldValue] = React.useState('')
    const [changing, setChanging] = React.useState(false)

    // console.log(multipleSelect)
    React.useEffect(() => {
        async function getTeamInfo() {
            try {
                const result = await API.graphql(graphqlOperation(getTeamWithAccounts, {
                    id: team_id
                }))
                if (mounted){
                    // console.log(result.data.getTeam)
                    setName(result.data.getTeam.name)
                    setDescription(result.data.getTeam.description)
                    for (let i=0; i < result.data.getTeam.accounts.items.length; i++){
                        result.data.getTeam.accounts.items[i].label = result.data.getTeam.accounts.items[i].account.email
                        result.data.getTeam.accounts.items[i].value = result.data.getTeam.accounts.items[i].account.id
                        delete result.data.getTeam.accounts.items[i].account
                    }
                    setMultipleSelect(result.data.getTeam.accounts.items)
                    setMembers(result.data.getTeam.accounts.items)
                }
            } catch (e) {
                console.log(e)
                const result = await API.graphql(graphqlOperation(getTeamNoReferences, {
                    id: team_id
                }))
                // console.log(result.data.getTeam)
                if (mounted) {
                    setName(result.data.getTeam.name)
                    setDescription(result.data.getTeam.description)
                    for (let i=0; i < result.data.getTeam.accounts.items.length; i++){
                        result.data.getTeam.accounts.items[i].label = result.data.getTeam.accounts.items[i].account.email
                        result.data.getTeam.accounts.items[i].value = result.data.getTeam.accounts.items[i].account.id
                        delete result.data.getTeam.accounts.items[i].account
                    }
                    setMultipleSelect(result.data.getTeam.accounts.items)
                    setMembers(result.data.getTeam.accounts.items)
                }
            }
        }

        let mounted = true
        getTeamInfo().then()
        return function cleanup() {
            mounted = false;
        };
    }, [team_id])

    document.documentElement.classList.remove("nav-open");
    React.useEffect(() => {
        document.body.classList.add("media-page");
        return function cleanup() {
            document.body.classList.remove("media-page");
        };
    }, []);

    let history = useHistory();

    React.useEffect(() => {
        async function getAllAccounts() {
            let nextToken = null;
            let account_list = []
            do {
                let chunk = await API.graphql(graphqlOperation(listAccounts, {limit: 999}));
                account_list = account_list.concat(chunk.data.listAccounts.items)
                nextToken = chunk.nextToken;
            } while (nextToken != null);
            for (let i=0; i < account_list.length; i++){
                account_list[i].label = account_list[i].email
                account_list[i].value = account_list[i].id
            }
            if (mounted) {
                setAccounts(account_list)
            }
        }

        let mounted = true
        getAllAccounts().then()
        return function cleanup() {
            mounted = false;
        };

    }, [])


    const handleChangeTeam = async (field, value) => {
        let UpdateTeamInput = {
            id: team_id,
            name: name,
            description: description,
        }

        if (field === 'name' || field === "description"){
            UpdateTeamInput[field] = value
            await API.graphql({ query: updateTeam, variables: { input: UpdateTeamInput } });
        }
    }

    function dynamicSort(property) {
        let sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers,
             * and you may want to customize it to your needs
             */
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    const tableItem = accounts.map(item =>
        <TableRow key={item['id']}>
            <TableCell
                onClick={() => {
                    if (!multipleSelect.some(oneOfSelect => oneOfSelect.label === item['email'])) {
                        setMultipleSelect(prevSelect => {
                            return [...prevSelect, {
                                'id': item['id'],
                                'value': item['id'],
                                'label': item['email'],
                            }].sort(dynamicSort("label"))
                        })
                    }
                    // console.log(item)
                }}
            >
                {item['label'] }
            </TableCell>
        </TableRow>)


    const onUpdateClick = async() => {
        // Check if new members were added
        for (let i=0; i < multipleSelect.length; i++){
            let member = false
            for (let j=0; j < members.length; j++){
                if (members[j].value === multipleSelect[i].value){
                    member = true
                }
            }
            if (member === false){
                const addAccountTeam = {
                    id: uuid(),
                    accountID: multipleSelect[i].value,
                    teamID: team_id
                }
                await API.graphql({ query: createAccountTeam, variables: { input: addAccountTeam } });
            }
        }
        // Check if members were deleted
        for (let i=0; i < members.length; i++){
            let member = false
            for (let j=0; j < multipleSelect.length; j++){
                if (members[i].value === multipleSelect[j].value){
                    member = true
                }
            }
            if (member === false){
                const DeleteAccountTeamInput = {
                    id: members[i].id
                }
                try {
                    await API.graphql({ query: deleteAccountTeam, variables: { input: DeleteAccountTeamInput } });
                } catch(err) {
                    console.log(err.message)
                }
            }
        }
        setMembers(multipleSelect)
        setChanging(false)
        // console.log('Update')
    }

    function onCancelClick() {
        setMultipleSelect(members)
        setChanging(false)
        // console.log('Update')
    }

    return (
        <>
            <WhiteNavbar {...props}/>
            <MediaPageHeader  title={"Edit Team"}/>
            <div className="section">
                <Flex justifyContent="center">
                    <View width={'75%'} >
                        {/*Personal information*/}
                        <View style={{paddingBottom:"2rem"}} direction={"column"} gap={"0.25rem"}>
                            <Heading level={"3"} style={{
                                borderBottomWidth:"1px",
                                borderBottomColor:"black",
                                borderBottomStyle:"solid"}}>
                                Team information
                            </Heading>
                            <VQTextField
                                label="Name"
                                value={name || ''}
                                onChange={(value)=>handleChangeTeam("name",value)}
                            />
                            <VQTextField
                                label="Description"
                                value={description || ''}
                                onChange={(value)=>handleChangeTeam("description",value)}/>
                            <Flex style={{paddingTop:"1rem"}} direction={"row"} alignItems={"flex-start"} gap={"10px"}>
                                <Heading level={"6"} style={{paddingRight:"1rem"}}>Team Members</Heading>
                                { changing ?
                                    <Flex direction={"column"}>
                                        <SearchField
                                            label="search"
                                            placeholder="Search..."
                                            value={searchFieldValue}
                                            onChange={(e) => setSearchFieldValue(e.target.value)}
                                            onSubmit={(e) => console.log(e)}
                                            onClear={() => setSearchFieldValue('')}
                                        />
                                        <ScrollView height="400px" orientation="vertical">
                                            <Table highlightOnHover size={"small"}>
                                                <TableBody>
                                                    {tableItem}
                                                </TableBody>
                                            </Table>
                                        </ScrollView>
                                    </Flex> :
                                    <Flex direction={"column"}>
                                    </Flex>
                                }
                                <Collection
                                    type="list"
                                    items={multipleSelect}
                                    itemsPerPage={16}
                                    direction={'row'}
                                    wrap={'wrap'}
                                >
                                    {(badge, index) => (
                                        <BadgeWithClose
                                            key={badge['id']}
                                            backgroundColor={"#3380FF"}
                                            onBadgeClose={() => console.log(badge['id'])}
                                            color={"white"}
                                            showClose={changing}
                                            value={badge['label']}
                                        />
                                    )}
                                </Collection>
                                { !changing ?
                                    <Tooltip
                                        title="Edit"
                                        placement="bottom"
                                    >
                                        <Button className="pencil-button" size="small" onClick={() => setChanging(true)} style={{paddingLeft:"6px"}}>
                                            <Icon
                                                ariaLabel="Pencil"
                                                viewBox={{ width: 24, height: 24 }}
                                                pathData="M22.728,5.403 C22.712,5.419 22.691,5.424 22.675,5.439 C22.660,5.455 22.655,5.476 22.640,5.492 L21.195,6.941 C21.195,6.941 21.195,6.941 21.195,6.941 C21.194,6.942 21.194,6.942 21.194,6.942 L6.932,21.247 C6.931,21.248 6.930,21.249 6.930,21.249 L6.926,21.254 C6.880,21.299 6.823,21.321 6.772,21.356 C6.716,21.394 6.670,21.441 6.606,21.468 L6.600,21.470 C6.600,21.470 6.599,21.471 6.599,21.471 L1.368,23.647 C1.246,23.698 1.118,23.722 0.991,23.722 C0.735,23.722 0.483,23.622 0.295,23.433 C0.013,23.150 -0.072,22.725 0.081,22.355 L2.254,17.101 C2.263,17.079 2.282,17.065 2.292,17.044 C2.339,16.950 2.391,16.857 2.467,16.781 L16.737,2.470 C16.737,2.470 16.737,2.469 16.738,2.468 C16.739,2.467 16.740,2.467 16.740,2.466 L18.183,1.019 C18.198,1.005 18.217,1.000 18.232,0.987 C18.247,0.969 18.253,0.947 18.270,0.930 C19.499,-0.301 21.499,-0.301 22.728,0.930 C23.958,2.164 23.957,4.170 22.728,5.403 ZM3.509,19.224 L2.814,20.906 L4.490,20.208 L3.509,19.224 ZM6.229,19.157 L19.105,6.242 L17.435,4.565 L4.557,17.480 L6.229,19.157 ZM21.335,2.328 C20.873,1.866 20.125,1.865 19.663,2.328 C19.648,2.343 19.628,2.348 19.613,2.362 C19.598,2.379 19.593,2.401 19.576,2.417 L18.828,3.168 L20.499,4.845 L21.247,4.095 C21.262,4.079 21.283,4.074 21.300,4.059 C21.314,4.043 21.319,4.022 21.335,4.006 C21.796,3.544 21.796,2.791 21.335,2.328 Z"
                                            />
                                        </Button>
                                    </Tooltip> : <></>
                                }
                                { changing ?
                                    <Tooltip
                                        title="Update"
                                        placement="bottom"
                                    >
                                        <Button
                                            className="pencil-button"
                                            size="small"
                                            onClick={()=>onUpdateClick()}
                                        >
                                            <Icon
                                                ariaLabel="Check"
                                                viewBox={{ width: 24, height: 24 }}
                                                pathData="M12.000,24.000 C5.383,24.000 0.000,18.617 0.000,12.000 C0.000,5.383 5.383,0.000 12.000,0.000 C18.617,0.000 24.000,5.383 24.000,12.000 C24.000,18.617 18.617,24.000 12.000,24.000 ZM12.000,2.000 C6.486,2.000 2.000,6.486 2.000,12.000 C2.000,17.514 6.486,22.000 12.000,22.000 C17.514,22.000 22.000,17.514 22.000,12.000 C22.000,6.486 17.514,2.000 12.000,2.000 ZM11.752,16.659 C11.555,16.884 11.278,17.000 10.999,17.000 C10.780,17.000 10.560,16.928 10.376,16.781 L5.376,12.781 C4.944,12.436 4.874,11.807 5.219,11.375 C5.564,10.944 6.195,10.875 6.624,11.219 L10.877,14.622 L17.248,7.341 C17.611,6.927 18.243,6.884 18.659,7.248 C19.074,7.611 19.116,8.243 18.752,8.659 L11.752,16.659 Z"
                                            />
                                        </Button>
                                    </Tooltip> : <></>
                                }
                                { changing ?
                                    <Tooltip
                                        title="Cancel"
                                        placement="bottom"
                                    >
                                        <Button
                                            className="pencil-button"
                                            size="small"
                                            style={{paddingLeft:"0"}}
                                            onClick={()=>onCancelClick()}
                                        >
                                            <Icon
                                                ariaLabel="Close"
                                                viewBox={{ width: 24, height: 24 }}
                                                pathData="M12.000,24.000 C5.383,24.000 0.000,18.617 0.000,12.000 C0.000,5.383 5.383,0.000 12.000,0.000 C18.617,0.000 24.000,5.383 24.000,12.000 C24.000,18.617 18.617,24.000 12.000,24.000 ZM12.000,2.000 C6.486,2.000 2.000,6.486 2.000,12.000 C2.000,17.514 6.486,22.000 12.000,22.000 C17.514,22.000 22.000,17.514 22.000,12.000 C22.000,6.486 17.514,2.000 12.000,2.000 ZM16.707,16.707 C16.512,16.902 16.256,17.000 16.000,17.000 C15.744,17.000 15.488,16.902 15.293,16.707 L12.000,13.414 L8.707,16.707 C8.512,16.902 8.256,17.000 8.000,17.000 C7.744,17.000 7.488,16.902 7.293,16.707 C6.902,16.316 6.902,15.684 7.293,15.293 L10.586,12.000 L7.293,8.707 C6.902,8.316 6.902,7.684 7.293,7.293 C7.684,6.902 8.316,6.902 8.707,7.293 L12.000,10.586 L15.293,7.293 C15.684,6.902 16.316,6.902 16.707,7.293 C17.098,7.684 17.098,8.316 16.707,8.707 L13.414,12.000 L16.707,15.293 C17.098,15.684 17.098,16.316 16.707,16.707 Z"
                                            />
                                        </Button>
                                    </Tooltip> : <></>
                                }

                            </Flex>
                            <br/>
                            <br/>
                            <Button
                                onClick={() => {
                                    history.goBack()
                                }}
                                className="reference-card-button"
                            >Back</Button>
                        </View>
                    </View>
                </Flex>
            </div>
            <FooterBlack {...props}/>
        </>
    );
})

export default EditTeam;
