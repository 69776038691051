import React from "react";
import {Table, TableBody, TableCell, TableHead, TableRow} from '@aws-amplify/ui-react';

import CodecCell from "./CodecCell";
import DurationTimeCell from "./DurationTimeCell";
import ResolutionCell from "./ResolutionCell";
import FramerateCell from "./FramerateCell";
import PixelFormatCell from "./PixelFormatCell";
import DurationFramesCell from "./DurationFramesCell";
import CreatedAtCell from "./CreatedAtCell";


function ReferenceSpecPage(props) {
  // console.log(props)
  return (
      <>
        <Table>
          <TableHead>
          <TableRow>
            <TableCell as={"th"}  className="synamedia-th">Specification</TableCell>
            <TableCell as={"th"}  className="synamedia-th">Value</TableCell>
          </TableRow>
          </TableHead>
          <TableBody>
          {props.refSpecData.filename &&
          <TableRow>
            <TableCell className="synamedia-td">Filename</TableCell>
            <TableCell className="synamedia-td">{props.refSpecData.filename}</TableCell>
          </TableRow>
          }
          {props.refSpecData.spec_width &&
          <TableRow>
            <TableCell className="synamedia-td">Resolution</TableCell>
            <ResolutionCell distorted={props.refSpecData}/>
          </TableRow>
          }
          {props.refSpecData.spec_frame_rate &&
          <TableRow>
            <TableCell className="synamedia-td">Framerate</TableCell>
            <FramerateCell distorted={props.refSpecData}/>
          </TableRow>
          }
          {props.refSpecData.spec_pix_fmt &&
          <TableRow>
            <TableCell className="synamedia-td">Pixel format</TableCell>
            <PixelFormatCell distorted={props.refSpecData}/>
          </TableRow>
          }
          {props.refSpecData.spec_codec &&
          <TableRow>
            <TableCell className="synamedia-td">Codec</TableCell>
            <CodecCell distorted={props.refSpecData}/>
          </TableRow>
          }
          {props.refSpecData.spec_duration_time &&
          <TableRow>
            <TableCell className="synamedia-td">Duration</TableCell>
            <DurationTimeCell distorted={props.refSpecData}/>
          </TableRow>
          }
          {props.refSpecData.spec_duration_frames &&
          <TableRow>
            <TableCell className="synamedia-td">Duration</TableCell>
            <DurationFramesCell distorted={props.refSpecData}/>
          </TableRow>
          }
          <TableRow>
            <TableCell className="synamedia-td">Upload Date</TableCell>
            <CreatedAtCell distorted={props.refSpecData}/>
          </TableRow>
          </TableBody>
        </Table>
      </>
  );
}

export default ReferenceSpecPage;
