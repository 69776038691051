import React from "react";

function NetworkDrawing(props) {
    const {onClickClear, onClickBlackFrame, onClickPoorInput, onClickNetworkIssues, onClickLoad, onPlay} = props
    const [colorImpairment, setColorImpairment] = React.useState('#3380FF')
    const [colorLoad, setColorLoad] = React.useState('#3380FF')
    const [textDecorationBlackFrame, setTextDecorationBlackFrame] = React.useState('')
    const [textDecorationPoorInput, setTextDecorationPoorInput] = React.useState('')
    const [textDecorationNetworkIssues, setTextDecorationNetworkIssues] = React.useState('')
    const [textDecorationLoad, setTextDecorationLoad] = React.useState('')
    const handleClearClicked = async () => {
        setColorImpairment('#3380FF')
        setColorLoad('#3380FF')
        setTextDecorationBlackFrame('')
        setTextDecorationPoorInput('')
        setTextDecorationNetworkIssues('')
        setTextDecorationLoad('')
        onClickClear()
    }
    const handleBlackFrame = async () => {
        setColorImpairment('#FF3333')
        setColorLoad('#3380FF')
        setTextDecorationBlackFrame('underline')
        setTextDecorationPoorInput('')
        setTextDecorationNetworkIssues('')
        setTextDecorationLoad('')
        onClickBlackFrame()
    }
    const handlePoorInput = async () => {
        setColorImpairment('#FF3333')
        setColorLoad('#3380FF')
        setTextDecorationPoorInput('underline')
        setTextDecorationBlackFrame('')
        setTextDecorationNetworkIssues('')
        setTextDecorationLoad('')
        onClickPoorInput()
    }
    const handleNetworkIssues = async () => {
        setColorImpairment('#FF3333')
        setColorLoad('#3380FF')
        setTextDecorationNetworkIssues('underline')
        setTextDecorationBlackFrame('')
        setTextDecorationPoorInput('')
        setTextDecorationLoad('')
        onClickNetworkIssues()
    }
    const handleLoad = async () => {
        setColorImpairment('#3380FF')
        setColorLoad('#FF3333')
        setTextDecorationLoad('underline')
        setTextDecorationBlackFrame('')
        setTextDecorationPoorInput('')
        setTextDecorationNetworkIssues('')
        onClickLoad()
    }

    return (	<svg id="track4_b" xmlns="http://www.w3.org/2000/svg xmlns:xlink=http://www.w3.org/1999/xlink" width={"1536px"} height={"312px"} viewBox="0 20 1280 280" shapeRendering="geometricPrecision" textRendering="geometricPrecision">
            <g id="track4_b-originserver" transform="translate(931.595245 50.195705)">
                <rect id="track4_b-rect1" width="180" height="70" rx="0" ry="0" transform="translate(115.090018 72.902325)" fill="#3380ff" stroke="#3380ff"/>
                <text id="track4_b-text1" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="26" fontWeight="400" transform="translate(134.749572 115.91153)" fill="#fff" strokeWidth="0">
                    <tspan id="track4_b-tspan1" y="0" fontWeight="400" strokeWidth="0">{'Origin server'}
                    </tspan>
                    </text>
                </g>
            <g id="track4_b-packagerdrm" transform="translate(689.320732 50.980034)">
                <rect id="track4_b-rect2" width="180" height="70" rx="0" ry="0" transform="translate(106.679261 71.019966)" fill="#3380ff" stroke="#3380ff"/>
                <text id="track4_b-text2" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="26" fontWeight="400" transform="translate(122.99788 102.246867)" fill="#fff" strokeWidth="0">
                    <tspan id="track4_b-tspan2" y="0" fontWeight="400" strokeWidth="0">
                        {'Packager &'}
                    </tspan>
                    <tspan id="track4_b-tspan3" x="0" y="26" fontWeight="400" strokeWidth="0">
                        {'     DRM  '}
                        </tspan>
                    </text>
                    </g>
            <g id="track4_b-arrow5" transform="translate(695.046235 57.352959)">
                <line id="track4_b-line1" x1="-33.333333" y1="0" x2="34.559862" y2="0" transform="matrix(.883741 0 0 1 315.458035 100)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                <line id="track4_b-line2" x1="5.686275" y1="5.490196" x2="-4.313725" y2="-4.509804" transform="translate(340.313725 94.509804)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                <line id="track4_b-line3" x1="5.686275" y1="-3.72549" x2="-4.313725" y2="6.27451" transform="translate(340.313725 103.72549)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
            </g>
            <g id="track4_b-arrow4" transform="translate(448.046234 56.568638)">
                <line id="track4_b-line4" x1="-33.333333" y1="0" x2="34.559862" y2="0" transform="matrix(.883741 0 0 1 312.458033 100)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                <line id="track4_b-line5" x1="5.686275" y1="5.490196" x2="-4.313725" y2="-4.509804" transform="translate(337.313725 94.509804)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                <line id="track4_b-line6" x1="5.686275" y1="-3.72549" x2="-4.313725" y2="6.27451" transform="translate(337.313725 103.72549)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
            </g>
            <g id="track4_b-arrow3" transform="translate(448.046234-26.901967)">
                <line id="track4_b-line7" x1="-33.333333" y1="0" x2="34.559862" y2="0" transform="matrix(.883741 0 0 1 312.458027 100)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                <line id="track4_b-line8" x1="5.686275" y1="5.490196" x2="-4.313725" y2="-4.509804" transform="translate(337.313725 94.509804)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                <line id="track4_b-line9" x1="5.686275" y1="-3.72549" x2="-4.313725" y2="6.27451" transform="translate(337.313725 103.72549)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
            </g>
            <g id="track4_b-arrow2" transform="translate(178.386587 13.09803)">
                <line id="track4_b-line10" x1="-33.333333" y1="0" x2="34.559862" y2="0" transform="matrix(.883741 0 0 1 331.411765 100)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                <line id="track4_b-line11" x1="5.686275" y1="4.509804" x2="-4.313725" y2="-4.509804" transform="translate(357.431372 95.490196)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                <line id="track4_b-line12" x1="5.686275" y1="-3.72549" x2="-4.313725" y2="6.27451" transform="translate(357.431372 103.72549)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
            </g>
            <g id="track4_b-arrow1" transform="translate(-65.953766 22.07354)">
                <line id="track4_b-line13" x1="-33.333333" y1="0" x2="34.559862" y2="0" transform="matrix(.883741 0 0 1 327.411765 89.918052)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                <line id="track4_b-line14" x1="5.686275" y1="6.553935" x2="-4.313725" y2="-3.446065" transform="translate(352.267457 83.661757)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
                <line id="track4_b-line15" x1="5.686275" y1="-4.553934" x2="-4.313725" y2="5.446066" transform="translate(352.267457 94.769626)" fill="#3380ff" stroke="#3380ff" strokeWidth="3" strokeLinecap="round"/>
            </g>
            <path id="track4_b-path1" d="" transform="translate(-41-128)" fill="none" stroke="#3f5787" strokeWidth="2.56"/>
            <g id="track4_b-circle4" transform="translate(445.240601 110.295024)">
                <ellipse id="track4_b-ellipse1" rx="20" ry="20" transform="translate(307.759378 72.195202)" fill="#3380ff" stroke="#3380ff"/>
                <text id="track4_b-text3" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(303.034447 56.3385)" fill="#fff" strokeWidth="0">
                    <tspan id="track4_b-tspan4" y="24" fontWeight="400" strokeWidth="0">{'4'}
                    </tspan></text></g>
            <g id="track4_b-circle3" transform="translate(445.240601-24.215669)">
                <ellipse id="track4_b-ellipse2" rx="20" ry="20" transform="translate(307.759378 72.195202)" fill="#3380ff" stroke="#3380ff"/>
                <text id="track4_b-text4" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(302.260649 80.338502)" fill="#fff" strokeWidth="0">
                    <tspan id="track4_b-tspan5" y="0" fontWeight="400" strokeWidth="0">{'3'}
                    </tspan><tspan id="track4_b-tspan6" x="0" y="48" fontWeight="400" strokeWidth="0">
                    {' '}
                    </tspan>
                    </text>
                    </g>
            <g id="track4_b-circle2" transform="translate(181.261894 14.999985)">
                <ellipse id="track4_b-ellipse3" rx="20" ry="20" transform="translate(329 72)" fill="#3380ff" stroke="#3380ff"/>
                <text id="track4_b-text5" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(323.035022 80.558449)" fill="#fff" strokeWidth="0">
                    <tspan id="track4_b-tspan7" y="0" fontWeight="400" strokeWidth="0">
                        {'2'}
                        </tspan>
                    </text>
                </g>
            <g id="track4_b-circle1" transform="translate(-65 14.999985)">
                <ellipse id="track4_b-ellipse4" rx="20" ry="20" transform="translate(328 72)" fill="#3380ff" stroke="#3380ff"/>
                <text id="track4_b-text6" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(322.035021 80.558449)" fill="#fff" strokeWidth="0">
                    <tspan id="track4_b-tspan8" y="0" fontWeight="400" strokeWidth="0">
                        {'1'}
                        </tspan>
                    </text>
                </g>
            <g id="track4_b-blackframe" transform="translate(0-.486632)" onClick={() => handleBlackFrame()} style={{pointerEvents: "bounding-box"}}  cursor="pointer">
                <g id="track4_b-blackframebutton" transform="translate(18.144213 1.747364)">
                    <ellipse id="track4_b-ellipse5" rx="15" ry="15" transform="translate(443.196058 180.941176)" fill="#f33" stroke="#f33"/>
                    <line id="track4_b-line16" x1="-18.182404" y1="-19.015893" x2="11.817596" y2="10.984107" transform="matrix(.5 0 0 0.5 444.78726 182.949123)" fill="none" stroke="#fff" strokeWidth="3"/>
                    <line id="track4_b-copy-of-line" x1="-18.182404" y1="-19.015893" x2="11.817596" y2="10.984107" transform="matrix(-.5 0 0 0.5 441.604856 182.949123)" fill="none" stroke="#fff" strokeWidth="3"/>
                </g>
                <text id="track4_b-text7" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" fontWeight="400" transform="translate(330.869156 189.690906)" strokeWidth="0" textDecoration={textDecorationBlackFrame}>
                    <tspan id="track4_b-tspan9" y="0" fontWeight="400" strokeWidth="0">
                        {'Black Frame'}
                        </tspan>
                    </text>
                </g>
            <g id="track4_b-poorinput" transform="translate(-.000041 34.276731)" onClick={() => handlePoorInput()} style={{pointerEvents: "bounding-box"}}  cursor="pointer">
                <g id="track4_b-poorinputbutton" transform="translate(18.144213 1.747364)">
                    <ellipse id="track4_b-ellipse6" rx="15" ry="15" transform="translate(443.196098 192.691169)" fill="#f33" stroke="#f33"/>
                    <line id="track4_b-line17" x1="-18.182404" y1="-19.015893" x2="11.817596" y2="10.984107" transform="matrix(.5 0 0 0.5 444.7873 194.699116)" fill="none" stroke="#fff" strokeWidth="3"/>
                    <line id="track4_b-copy-of-line2" x1="-18.182404" y1="-19.015893" x2="11.817596" y2="10.984107" transform="matrix(-.5 0 0 0.5 441.604896 194.699116)" fill="none" stroke="#fff" strokeWidth="3"/>
                </g>
                <text id="track4_b-text8" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" fontWeight="400" transform="translate(283.04526 201.552433)" strokeWidth="0" textDecoration={textDecorationPoorInput}>
                    <tspan id="track4_b-tspan10" y="0" fontWeight="400" strokeWidth="0">
                        {'Poor input quality '}
                    </tspan>
                    </text>
                </g>
            <g id="track4_b-networkissues" transform="translate(-.000041 84.537009)" onClick={() => {handleNetworkIssues()}} style={{pointerEvents: "bounding-box"}}  cursor="pointer">
                <g id="track4_b-networkissuesbutton" transform="translate(18.144213 1.747364)">
                    <ellipse id="track4_b-ellipse7" rx="15" ry="15" transform="translate(443.196098 190.69117)" fill="#f33" stroke="#f33"/>
                    <line id="track4_b-line18" x1="-18.182404" y1="-19.015893" x2="11.817596" y2="10.984107" transform="matrix(.5 0 0 0.5 444.7873 192.699117)" fill="none" stroke="#fff" strokeWidth="3"/>
                    <line id="track4_b-copy-of-line3" x1="-18.182404" y1="-19.015893" x2="11.817596" y2="10.984107" transform="matrix(-.5 0 0 0.5 441.604896 192.699117)" fill="none" stroke="#fff" strokeWidth="3"/>
                </g><text id="track4_b-text9" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" fontWeight="400" transform="translate(306.772563 199.609402)" strokeWidth="0" textDecoration={textDecorationNetworkIssues}>
                <tspan id="track4_b-tspan11" y="0" fontWeight="400" strokeWidth="0">
                    {'Network Issues '}
                </tspan>
                </text>
            </g>
            <g id="track4_b-overload" transform="translate(248.502725 43.036996)" onClick={() => {handleLoad()}} style={{pointerEvents: "bounding-box"}}  cursor="pointer">
                <g id="track4_b-overloadbutton" transform="translate(18.144213 1.747364)">
                    <ellipse id="track4_b-ellipse8" rx="15" ry="15" transform="translate(444.196098 232.191183)" fill="#f33" stroke="#f33"/>
                    <line id="track4_b-line19" x1="-18.182404" y1="-19.015893" x2="11.817596" y2="10.984107" transform="matrix(.5 0 0 0.5 445.7873 234.19913)" fill="none" stroke="#fff" strokeWidth="3"/>
                    <line id="track4_b-copy-of-line4" x1="-18.182404" y1="-19.015893" x2="11.817596" y2="10.984107" transform="matrix(-.5 0 0 0.5 442.604896 234.19913)" fill="none" stroke="#fff" strokeWidth="3"/>
                </g>
                <text id="track4_b-text10" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" fontWeight="400" transform="translate(298.000041 241.109415)" strokeWidth="0" textDecoration={textDecorationLoad}>
                <tspan id="track4_b-tspan12" y="0" fontWeight="400" strokeWidth="0">
                    {'Overloaded CPU '}
                </tspan>
                </text>
            </g>
            <g id="track4_b-encoders" transform="translate(-94.666666-35.294289)">
                <g id="track4_b-cbrencoder" transform="translate(516.294091 0.784313)">
                    <rect id="track4_b-rect3" width="160" height="70" rx="0" ry="0" transform="translate(133.372575 71.509976)" fill="#3380ff" stroke="#3380ff"/>
                    <text id="track4_b-text11" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="26" fontWeight="400" transform="translate(170.950264 106.168244)" fill="#fff" strokeWidth="0">
                        <tspan id="track4_b-tspan13" y="0" fontWeight="400" strokeWidth="0">
                            {'    CBR'}
                        </tspan>
                        <tspan id="track4_b-tspan14" x="0" y="26" fontWeight="400" strokeWidth="0">
                        {'Encoder'}
                        </tspan>
                        </text>
                        </g>
                <g id="track4_b-abrencoder" transform="translate(516.294109 85.490009)">
                    <rect id="track4_b-rect4" width="160" height="70" rx="0" ry="0" transform="translate(133.372557 71.80428)" fill="#3380ff" stroke="#3380ff"/>
                    <text id="track4_b-text12" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="26" fontWeight="400" transform="translate(170.950246 101.462548)" fill="#fff" strokeWidth="0">
                        <tspan id="track4_b-tspan15" y="0" fontWeight="400" strokeWidth="0">
                            {'    ABR'}
                        </tspan>
                        <tspan id="track4_b-tspan16" x="0" y="26" fontWeight="400" strokeWidth="0">
                            {'Encoder'}
                        </tspan>
                        </text>
                        </g>
                <g id="track4_b-load" transform="translate(93.823526 42.255059)">
                    <rect id="track4_b-rect5" width="160" height="39.149324" rx="0" ry="0" transform="translate(555.843088 200.039216)" fill={colorLoad} stroke={colorLoad}/>
                    <text id="track4_b-text13" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="24" fontWeight="400" transform="translate(609.246807 228.021213)" fill="#fff" strokeWidth="0">
                        <tspan id="track4_b-tspan17" y="0" fontWeight="400" strokeWidth="0">{'Load'}
                        </tspan>
                        </text>
                    </g>
                <rect id="track4_b-rect6" width="180" height="225.647045" rx="0" ry="0" transform="translate(640.509754 64.58842)" fill="none" stroke="#3380ff"/>
                </g>
            <g id="track4_b-impairmentinserter" transform="translate(182.261922 0.784317)">
                <rect id="track4_b-rect7" width="180" height="70" rx="0" ry="0" transform="translate(114.078348 76.215683)" fill={colorImpairment} stroke={colorImpairment}/>
                <text id="track4_b-text14" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="26" fontWeight="400" transform="translate(136.162033 107.403359)" fill="#fff" strokeWidth="0">
                    <tspan id="track4_b-tspan18" y="0" fontWeight="400" strokeWidth="0">
                        {'Impairment'}
                    </tspan>
                    <tspan id="track4_b-tspan19" x="0" y="26" fontWeight="400" strokeWidth="0">
                    {'   Inserter  '}
                    </tspan>
                    </text>
                    </g>
            <g id="track4_b-clearerror" transform="translate(-1.07692-49.033919)" onClick={() => {handleClearClicked()}} style={{pointerEvents: "bounding-box"}}  cursor="pointer">
                <ellipse id="track4_b-ellipse9" rx="15" ry="15" transform="translate(210.181266 231.235821)" fill="#6fde4c" stroke="#6fde4c"/>
                <line id="track4_b-line20" x1="-1.483659" y1="-1.208905" x2="3.516341" y2="3.791095" transform="translate(205.24183 233.725542)" fill="#fff" stroke="#fff" strokeWidth="3"/>
                <line id="track4_b-line21" x1="4.757745" y1="-11.25" x2="14.757745" y2="-1.25" transform="matrix(-1 0 0 1 223.515916 238.725542)" fill="#fff" stroke="#fff" strokeWidth="3" strokeLinecap="square"/>
                <text id="track4_b-cleartext" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="20" fontWeight="400" transform="translate(76.883262 238.302913)" strokeWidth="0">
                    <tspan id="track4_b-tspan20" y="0" fontWeight="400" strokeWidth="0">
                        {'Clear errors '}
                    </tspan>
                    </text>
                </g>
            <g id="track4_b-playout" transform="translate(-60.000026 0.784317)">
                <rect id="track4_b-rect8" width="180" height="70" rx="0" ry="0" transform="translate(108.000026 76.215683)" fill="#ffb400" stroke="#ffb400"/>
                <text id="track4_b-text15" dx="0" dy="0" fontFamily="SourceSansPro" fontSize="26" fontWeight="400" transform="translate(126.804855 120.48531)" fill="#fff" strokeWidth="0">
                    <tspan id="track4_b-tspan21" y="0" fontWeight="400" strokeWidth="0">
                        {'Playout'}
                        </tspan>
                    </text>
                <g id="track4_b-playbutton" transform="translate(32.479037-135.556438)" onClick={() => {onPlay()}} style={{pointerEvents: "bounding-box"}}  cursor="pointer">
                    <rect id="track4_b-rect9" width="30" height="30" rx="0" ry="0" transform="translate(205.202245 231.854069)" fill="#ffb400" stroke="#000" strokeWidth="2"/>
                    <polygon id="track4_b-polygon1" points="-20.314896,-37.314028 -8.314896,-17.314028 -32.314896,-17.314028 -20.314896,-37.314028" transform="matrix(0 1-1 0 192.76709 266.871325)" strokeWidth="0"/>
                </g>
                </g>
            </svg>
)
}
export default NetworkDrawing;